import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { TenantSettings } from '@unifii/sdk';

import { Config } from 'app-config';
import { MyAccount } from 'client';

import { ContextService } from 'services/context.service';


@Injectable()
export class ReportingExceptionHandler implements ErrorHandler {

    private lastError: Error;

    constructor(
        @Inject(Config) private config: Config,
        private context: ContextService
    ) {
        if (!config.sentryDSN) {
            return;
        }

        Sentry.init({
            dsn: config.sentryDSN,
            environment: config.env,
            release: this.config.version,
            autoSessionTracking: false,
            // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
            // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
            // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
            integrations: [new Sentry.Integrations.TryCatch({
                XMLHttpRequest: false,
            })],
        });

        console.info('Sentry Init');
    }

    handleError(error: Error | any) {

        if (this.isSameAsLast(error)) {
            return;
        }

        this.lastError = error;

        const extractedError = this.extractError(error) || 'Handled unknown error';

        // log the error to console for immediate feedback.
        console.error(extractedError);

        // no sentry guard
        if (!Sentry.getCurrentHub().getClient()) {
            return;
        }

        // tenant opped-out guard
        if (this.tenantSettings && this.tenantSettings.isCrashReportingEnabled !== true) {
            return;
        }

        // set tags at time of error
        Sentry.configureScope((scope) => {

            if (this.config.env !== 'dev' && this.user) {
                scope.setUser({ id: this.user.id });
            }

            // return username for dev
            if (this.config.env === 'dev' && this.user) {
                scope.setUser({ id: this.user.id, username: this.user.username });
                scope.setTag('username', this.user.username);
            }

            scope.setTag('baseUrl', this.config.baseUrl);

            if (this.context.project && this.context.project.id) {
                scope.setTag('projectId', this.context.project.id);
            }

        });

        // Capture handled exception and send it to Sentry.
        Sentry.captureException(extractedError);
    }

    private extractError(error: any) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    private get tenantSettings(): TenantSettings {
        return this.context.tenantSettings as TenantSettings;
    }

    private get user(): MyAccount | undefined {

        if (this.context.account) {
            return this.context.account;
        }

        return undefined;
    }

    private isSameAsLast(error: any) {

        if (this.lastError == null) {
            return false;
        }

        return this.lastError.message === error.message && ((this.lastError as Error).stack == null || (this.lastError as Error).stack === error.stack);
    }

}
