import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HierarchyUnitPipe, ModalService, UfControlGroup } from '@unifii/library/common';

import { AuthProviderMappingActionType, UcAuthProviders } from 'client';

import {
    ActionsTypesDescription, AuthProviderMappingActionModel, AuthProviderMappingConditionFormModel, AuthProviderMappingConditionType,
    AuthProviderMappingModel, AuthProviderMappingsModel, MappingsControlKeys
} from '../models';

import { AuthProviderMappingModalComponent } from './auth-provider-mapping-modal.component';
import { AuthProviderMappingsController } from './auth-provider-mapping.controller';


interface RowData {
    title: string;
    value?: string;
}

interface Row {
    identifier: number;
    control: UfControlGroup;
    providerValue: RowData[];
    unifiiValue: RowData[];
}

@Component({
    selector: 'uc-auth-provider-mappings',
    templateUrl: 'auth-provider-mappings.html',
})
export class AuthProviderMappingsComponent implements OnInit {

    @Input() providerMapping: AuthProviderMappingsModel;

    @Output() providerMappingChange = new EventEmitter<AuthProviderMappingsModel>();

    protected readonly controlKeys = MappingsControlKeys;

    protected table: Row[] = [];
    protected sourceClaims: string[];
    protected authProviderId: string;

    constructor(
        private hierarchyUnitPipe: HierarchyUnitPipe,
        private ucAuthProviders: UcAuthProviders,
        private modalService: ModalService,
        private mappingController: AuthProviderMappingsController
    ) { }

    async ngOnInit() {
        this.setupTable();
        this.authProviderId = this.providerMapping.authProviderId;
        this.sourceClaims = await this.ucAuthProviders.getAuthProviderClaims(this.authProviderId);
    }

    async rowClick(identifier: number) {
        const mapping = this.providerMapping.mappings[identifier];

        const response = await this.modalService.openFullScreen<any, any>(
            AuthProviderMappingModalComponent, { ...mapping, authProviderId: this.authProviderId, sourceClaims: this.sourceClaims });

        if (!response) {
            return;
        }

        this.providerMapping.mappings[identifier] = response;

        this.setupTable();
    }

    async addProviderValue() {
        const response = await this.modalService.openFullScreen<any, any>(
            AuthProviderMappingModalComponent, { actions: [], condition: undefined, authProviderId: this.authProviderId, sourceClaims: this.sourceClaims });

        if (!response) {
            return;
        }

        this.providerMapping.mappings.push({
            id: this.authProviderId,
            condition: response.condition,
            actions: response.actions
        });

        this.setupTable();
    }

    async deleteMapping(position: number) {
        const response = await this.modalService.openConfirm({
            cancelLabel: `Don't delete`,
            confirmLabel: `Delete`,
            message: 'Are you sure you want to delete the mapping?'
        });

        if (!response) {
            return;
        }

        this.table.splice(position, 1);
        this.providerMapping.mappings.splice(position, 1);
    }

    private setupTable() {

        const rows = this.providerMapping.mappings.map((value: AuthProviderMappingModel, identifier: number) => {
            const providerValue = this.buildTableConditionRow(value.condition);
            const actions = value.actions.map(action => this.buildTableActionRow(action));
            const unifiiValue = actions.reduce((acc, action) => acc && action ? [...acc, ...action] : acc, []);

            if (providerValue && unifiiValue) {
                return {
                    identifier,
                    providerValue,
                    unifiiValue
                };
            }

            return;
        }).filter(Boolean);

        this.table = rows as any as Row[];
    }

    private buildTableConditionRow(condition: AuthProviderMappingConditionFormModel, separator?: string): RowData[] | undefined {
        separator = separator ?? '';
        if (condition.type === AuthProviderMappingConditionType.GroupMembership && condition.group) {
            const { name } = condition.group;
            return [{ title: `${separator}Group`, value: name }];
        } else if ([AuthProviderMappingConditionType.Or, AuthProviderMappingConditionType.And].includes(condition.type) && condition.children) {
            const providers = [];
            for (const children of condition.children) {
                providers.push(this.buildTableConditionRow(children, '- '));
            }
            return providers.reduce((acc, provider) => (provider && acc) ? ([...acc, ...provider]) : acc, [{ title: `${separator}${condition.type}` }]);
        } else if (condition.type === AuthProviderMappingConditionType.ClaimValue) {
            return [{
                title: `${separator}Claim`,
                value: condition.identifier
            }, {
                title: `${separator}Value`,
                value: condition.value
            }];
        }

        return;
    }

    private buildTableActionRow(action: AuthProviderMappingActionModel) {
        const title: string | undefined = ActionsTypesDescription[action.type];
        switch (action.type) {
            case AuthProviderMappingActionType.AssignUnit:
                return [{ title, value: this.hierarchyUnitPipe.transform(action.unit) }];
            case AuthProviderMappingActionType.AssignClaim:
                return [
                    { title, value: action.identifier },
                    { title: 'Value', value: action.value }
                ];
            case AuthProviderMappingActionType.AssignRole:
            case AuthProviderMappingActionType.AssignSystemRole:
                return [{ title, value: action.identifier }];
            default:
                return;
        }
    }
}