import { EMPTY, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ToastService } from '@unifii/library/common';
import { ErrorType } from '@unifii/sdk';

import { UcProjectInfo } from 'client';

import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';


@Injectable()
export class ProjectResolver implements Resolve<UcProjectInfo> {

    constructor(
        private context: ContextService,
        private router: Router,
        private toastService: ToastService,
        private projectService: ProjectService
    ) { }

    async resolve(route: ActivatedRouteSnapshot): Promise<UcProjectInfo> {
        const { languageId, projectId } = route.params;
        if (!projectId) {
            return lastValueFrom(EMPTY);
        }

        try {
            const project = await this.projectService.setProject(projectId);

            if (!project) {
                return lastValueFrom(EMPTY);
            }

            this.context.language = (project.languages || []).find(l => l.code === languageId) || null;
            // Register optional translation language
            if (languageId && !this.context.language) {
                // this.router.navigate(['/translations']);
                console.log('Should navigate back to /translations');
                return lastValueFrom(EMPTY);
            }

            return project;

        } catch (error) {
            if (error.type !== ErrorType.Unauthorized && error.type !== ErrorType.Forbidden) {
                this.toastService.error(`Failed to load project ${projectId}`);
                this.router.navigate(['/projects']);
            }
            return lastValueFrom(EMPTY);
        }
    }
}
