import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

import { SystemRole } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';

import { IdentifierFunctions } from 'helpers/helpers';

import { ContextService } from 'services/context.service';


@Component({
    selector: 'uc-view-settings',
    templateUrl: './view-settings.html',
})
export class ViewSettingsComponent implements OnInit, OnDestroy {

    control = new UfControlGroup({
        title: new UfControl(ValidatorFunctions.required('Title is mandatory.')),
        recordName: new UfControl(ValidatorFunctions.required('Record name is mandatory.')),
        identifier: new UfControl([
            ValidatorFunctions.required('Identifier is mandatory.'),
            ValidatorFunctions.custom(v => /^[A-Za-z0-9_-]+$/.test(v), 'Identifier contains invalid characters'),
            ValidatorFunctions.custom(v => !/\s/.test(v), 'Identifier cannot contain space'),
        ])
    });

    identifierMaxLength = IdentifierFunctions.IDENTIFIER_MAX_LENGTH;

    private subscriptions = new Subscription();

    constructor(public builderService: BuilderService, private context: ContextService) { }

    ngOnInit() {

        // ProjectManager or published definition
        if (!this.context.checkRoles(SystemRole.ProjectManager) || this.builderService.compound.lastPublishedAt != null) {
            const identifierControl = (this.control.get('identifier') as UfControl);
            identifierControl.disable();
        }

        this.subscriptions.add(this.control.valueChanges.subscribe(() => this.valueChange()));

        this.subscriptions.add(this.builderService.submitted.subscribe(v => {
            this.control.setSubmitted(true);
        }));

        // New compound
        if (this.builderService.compound.lastModifiedAt == null) {
            this.subscriptions.add((this.control.controls.title as UfControl).valueChanges.subscribe(this.autofillFields));
        }

        /**
         * todo: remove when builder validation is refactored,
         * validation is backwards, now children control groups
         * should attach themselves to parent control which should validate on submit
         */
        setTimeout(() => this.valueChange(true), 0);
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private autofillFields = (value: string | undefined) => {

        const recordName = value;
        let identifier = '';

        if (value != null && value !== '') {
            identifier = IdentifierFunctions.kebabize(value || '')
                .substr(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
        }

        this.control.controls.recordName.setValue(recordName, { onlySelf: false, emitResult: true });
        this.control.controls.identifier.setValue(identifier, { onlySelf: false, emitResult: true });
    };

    private valueChange(skipEdit?: boolean) {

        /**
         * This is backwards submit should validate form then get errors
         */
        const errors = Object.keys(this.control.controls).map(key => {
            const control = this.control.get(key) as UfControl;

            if (control.errors == null) {
                return null;
            }

            return control.errors.message;
        }).filter(err => err != null).map(e => e.message);

        this.builderService.setErrors(this.builderService.definition, errors, 'all');

        if (!skipEdit) {
            this.builderService.fieldEdit.next({ subject: this.builderService.definition, atomic: false });
        }
    }

}
