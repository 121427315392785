import { Observable, Subscriber } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import {
    amendOptionsParams, ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions, mergeParams, ProjectContentOptions,
    ProjectContentOptionsInterace, Schema, TableDetail, TokenStorage, TokenStorageInterface
} from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { UcCollection } from './collection';
import { BuilderField, CompoundInfo, DefinitionInfo, SchemaInfo, TableInfo, UcDefinition, UcField, UcPage, UcTable, UcView } from './content-models';
import { UcDataForwarders } from './data-forwarders';
import { ApiKey, PublishStatus, UcProjectInfo } from './models';
import { UcClient } from './uc-client';
import { UcWorkflowNotifications } from './workflow-notification';


@Injectable()
export class UcProject {

    constructor(
        public client: UcClient,
        @Inject(ProjectContentOptions) public options: ProjectContentOptionsInterace,
        @Inject(TokenStorage) private tokenStorage: TokenStorageInterface
    ) { }

    collection(identifier: string): UcCollection {
        this.guardProjectId();
        return new UcCollection(this.client, +this.options.projectId, identifier);
    }

    workflowNotifications(bucket: string): UcWorkflowNotifications {
        this.guardProjectId();
        return new UcWorkflowNotifications(this.client, +this.options.projectId, bucket);
    }

    dataForwarders(bucket: string): UcDataForwarders {
        this.guardProjectId();
        return new UcDataForwarders(this.client, +this.options.projectId, bucket);
    }

    get(options?: ClientGetOptions): Promise<UcProjectInfo> {
        return this.client.get(this.url(), options);
    }

    save(project: UcProjectInfo, options?: ClientPutOptions): Promise<UcProjectInfo> {
        return this.client.put(this.url(), project, options);
    }

    async getCollections(options?: ClientGetOptions): Promise<DefinitionInfo[]> {
        const collections: DefinitionInfo[] = await this.client.get(this.url('collections'), options);
        // Remove local filter once the API support state
        if (options?.params?.state) {
            return collections.filter(collection => (collection.publishState === options?.params?.state));
        }
        return collections;
    }

    // todo: maybe combine with addForm
    saveCollection(collection: UcDefinition): Promise<UcDefinition> {
        if (!collection.id) {
            return this.client.post(this.url('collections'), { body: collection });
        }

        return this.client.put(this.url('collections', '' + collection.id), collection);
    }

    /** qx, sort, status, offset, limit */
    getViews(options?: ClientGetOptions): Promise<CompoundInfo[]> {

        const params = mergeParams(DefaultPaginationParams, options?.params);

        if (params.q && !params.qx) {
            params.qx = params.q;
        }
        delete params.q;

        return this.client.get(this.url('views'), { ...options, params });
    }

    /** qx, compoundType, types?: string[], offset, limit*/
    getCompounds(options?: ClientGetOptions): Promise<CompoundInfo[]> {

        const params = mergeParams(DefaultPaginationParams, options?.params);

        if (params.q && !params.qx) {
            params.qx = params.q;
        }
        delete params.q;

        if (params.types) {
            params.types = (params.types as string[]).join();
        }

        return this.client.get(this.url('compounds'), { ...options, params });
    }

    getView(id: number, options?: ClientGetOptions): Promise<UcView> {
        return this.client.get(this.url('views', '' + id), options);
    }

    getViewDefinition(id: string, options?: ClientGetOptions): Promise<UcDefinition> {
        return this.client.get(this.url('views', id, 'definition'), options);
    }

    saveView(view: UcView): Promise<UcView> {
        if (!view.id) {
            return this.client.post(this.url('views'), { body: view });
        }
        return this.client.put(this.url('views', view.id), view);
    }

    approveView(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('views', 'approved'), amendOptionsParams({ id }, options));
    }

    revertView(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('views'), amendOptionsParams({ id }, options));
    }

    archiveView(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('views', 'archived'), amendOptionsParams({ id }, options));
    }

    deleteView(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('views', '' + id), options);
    }

    /**q, sort, status, offset, limit*/
    getPages(options?: ClientGetOptions): Promise<CompoundInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params);
        return this.client.get(this.url('pages'), { ...options, params });
    }

    getPage(id: number, options?: ClientGetOptions): Promise<UcPage> {
        return this.client.get(this.url('pages', '' + id), options);
    }

    savePage(page: UcPage): Promise<UcPage> {
        if (!page.id) {
            return this.client.post(this.url('pages'), { body: page });
        }

        return this.client.put(this.url('pages', page.id), page);
    }

    approvePage(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('pages', 'approved'), amendOptionsParams({ id }, options));
    }

    revertPage(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('pages'), amendOptionsParams({ id }, options));
    }

    archivePage(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url('pages', 'archived'), amendOptionsParams({ id }, options));
    }

    deletePage(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('pages', '' + id), options);
    }

    /** q, sort, offset, limit*/
    getFormBuckets(options?: ClientGetOptions): Promise<SchemaInfo[]> {
        return this.client.get(this.url('form-buckets'), amendOptionsParams(DefaultPaginationParams, options));
    }

    getBucket(id: string, options?: ClientGetOptions): Promise<Schema> {
        return this.client.get(this.url('form-buckets', id), options);
    }
    /**q, sort, status, bucket, offset, limit */
    getForms(options?: ClientGetOptions): Promise<DefinitionInfo[]> {
        return this.client.get(this.url('forms'), amendOptionsParams(DefaultPaginationParams, options));
    }

    getForm(id: string, options?: ClientGetOptions): Promise<UcDefinition> {
        return this.client.get(this.url('forms', id), options);
    }

    deleteForm(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('forms', id), options);
    }

    saveForm(form: UcDefinition, force?: boolean): Promise<UcDefinition> {

        if (!form.id) {
            return this.client.post(this.url('forms'), { body: form, params: { force } });
        }

        return this.client.put(this.url('forms', '' + form.id), form, { params: { force } });
    }

    approveForm(id: string, options?: ClientPostOptions): Promise<DefinitionInfo> {
        return this.client.post(this.url('forms', 'approved'), amendOptionsParams({ id }, options));
    }

    revertForm(id: string, options?: ClientPostOptions): Promise<DefinitionInfo> {
        return this.client.post(this.url('forms'), amendOptionsParams({ id }, options));
    }

    archiveForm(id: string, options?: ClientPostOptions): Promise<DefinitionInfo> {
        return this.client.post(this.url('forms', 'archived'), amendOptionsParams({ id }, options));
    }
    /**q, sort, status, source, offset, limit */
    getTables(options?: ClientGetOptions): Promise<TableInfo[]> {
        return this.client.get(this.url('tables'), amendOptionsParams(DefaultPaginationParams, options));
    }

    async getTable(id: string, options?: ClientGetOptions): Promise<UcTable> {
        const table: UcTable = await this.client.get(this.url('tables', id), options);
        // SchemaField '_parent' was an alias for '_parent.seqId' and since 1.32.0 '_parent.seqId' is fully supported in Discover
        table.columns?.filter(c => c.identifier === '_parent').forEach(c => c.identifier = '_parent.seqId');
        return table;
    }

    async saveTable(table: UcTable): Promise<UcTable> {
        // New Table
        if (!table.id) {
            return await this.client.post(this.url('tables'), { body: table });
        }
        // Update table
        return this.client.put(this.url('tables', table.id), table);
    }

    approveTable(id: string, options?: ClientPostOptions): Promise<TableInfo> {
        return this.client.post(this.url('tables', 'approved'), amendOptionsParams({ id }, options));
    }

    revertTable(id: string, options?: ClientPostOptions): Promise<TableInfo> {
        return this.client.post(this.url('tables'), amendOptionsParams({ id }, options));
    }

    archiveTable(id: string, options?: ClientPostOptions): Promise<TableInfo> {
        return this.client.post(this.url('tables', 'archived'), amendOptionsParams({ id }, options));
    }

    deleteTable(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('tables', id), options);
    }

    getTableDetail(tableId: string, options?: ClientGetOptions): Promise<TableDetail> {
        return this.client.get(this.url('tables', tableId, 'detail'), options);
    }

    saveTableDetail(tableId: string, tableDetail: TableDetail, options?: ClientPutOptions): Promise<TableDetail> {
        return this.client.put(this.url('tables', tableId, 'detail'), tableDetail, options);
    }

    deleteTableDetail(tableId: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('tables', tableId, 'detail'), options);
    }

    async getFieldTemplates(options?: ClientGetOptions): Promise<UcField[]> {
        const templates = await this.client.get(this.url('field-templates'), options) as UcField[];
        for (const template of templates) {
            template.options?.forEach(option => delete option.id);
        }
        return templates;
    }

    saveFieldTemplate(field: BuilderField | UcField): Promise<BuilderField> {
        if (!field.id) {
            return this.client.post(this.url('field-templates'), { body: field });
        }
        return this.client.put(this.url('field-templates', `${field.id}`), field);
    }

    deleteFieldTemplate(id: number, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url('field-templates', `${id}`), options);
    }

    getPublishStatus(options?: ClientGetOptions): Promise<PublishStatus> {
        return this.client.get(this.url('versions', 'status'), options);
    }

    // No value returned
    publishPreview(options?: ClientPostOptions): Promise<void> {
        return this.client.post(this.url('versions', 'test'), options);
    }

    // No value returned
    publishStable(options?: ClientPostOptions): Promise<void> {
        return this.client.post(this.url('versions', 'test-stable'), options);
    }

    // No value returned
    import(options?: ClientPostOptions): Promise<void> {
        return this.client.post(this.url('import'), options);
    }

    getImportProgress(): Observable<string> {
        return new Observable<string>((s: Subscriber<string>) => {

            let url = this.url('import', 'progress');
            url += '?access_token=' + this.tokenStorage.token;

            const es = new EventSource(url);

            es.addEventListener('close', _ => {
                es.close();
                s.complete();
            });

            es.addEventListener('error', e => {
                s.error(e);
            });

            es.addEventListener('progress', (e: MessageEvent) => {
                s.next(e.data);
            });
        });
    }

    getApiKeys(options?: ClientGetOptions): Promise<ApiKey[]> {
        return this.client.get(this.url('api-keys'), options);
    }

    saveApiKey(apiKey: ApiKey, options?: ClientPostOptions): Promise<ApiKey> {
        return this.client.post(this.url('api-keys'), { ...options, body: apiKey });
    }

    // No value returned
    async revokeApiKey(apiKey: ApiKey, options?: ClientDeleteOptions): Promise<void> {
        if (!apiKey.key) {
            return null as any;
        }
        return this.client.delete(this.url('api-keys', apiKey.key), options);
    }

    private url(...extra: string[]): string {
        this.guardProjectId();
        const urlParts = ['projects', this.options.projectId].concat(extra);
        return this.client.buildUrl(urlParts);
    }

    private guardProjectId() {
        if (!this.options.projectId) {
            throw new Error('Project id not provided');
        }
    }
}
