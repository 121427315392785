import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';

import { AuthProviderSourceGroup, UcAuthProviders } from 'client';

import { ArrayHelper } from 'helpers/array-helper';

import { AuthProviderMappingConditionType } from '../new-identity/models';

import { AuthProviderMappingsController } from './auth-provider-mappings-controller';
import { ConditionControlKeys, ConditionOptions, MappingsControlKeys } from './auth-provider-model';


export interface MappingModalData {
    mappingControl: UfControlGroup;
    sourceClaims: string[];
    providerId: string;
}

@Component({
    templateUrl: './auth-provider-mapping-modal.html'
})
export class AuthProviderMappingModalComponent implements Modal<MappingModalData, UfControlGroup>, OnInit {

    @HostBinding('class.uf-form-card') cardClass = true;

    readonly conditionControlKeys = ConditionControlKeys;
    readonly authProviderMappingConditionType = AuthProviderMappingConditionType;
    readonly mappingsControlKeys = MappingsControlKeys;
    readonly conditionOptions = ConditionOptions;

    mappingControl: UfControlGroup;
    filteredSourceClaims: string[];
    filteredSourceGroups: AuthProviderSourceGroup[] = [];

    private sourceClaims = this.data.sourceClaims;
    private providerId = this.data.providerId;

    constructor(
        @Inject(ModalData) public data: MappingModalData,
        public runtime: ModalRuntime<MappingModalData, UfControlGroup>,
        public ufb: UfFormBuilder,
        private ucAuthProviders: UcAuthProviders,
        private mappingsController: AuthProviderMappingsController
    ) { }

    ngOnInit() {
        this.mappingControl = this.mappingsController.createMappingControl(this.data.mappingControl.getRawValue());
        this.mappingTypeChanged();
    }

    get conditionControl(): UfControl {
        return this.mappingControl.get(MappingsControlKeys.Condition) as UfControl;
    }

    get typeControl(): UfControl {
        return this.conditionControl.get(ConditionControlKeys.Type) as UfControl;
    }

    get valueControl(): UfControl {
        return this.conditionControl.get(ConditionControlKeys.Value) as UfControl;
    }

    get identifierControl(): UfControl {
        return this.conditionControl.get(ConditionControlKeys.Identifier) as UfControl;
    }

    get childrenControl(): UfControlArray {
        return this.conditionControl.get(ConditionControlKeys.Chlidren) as UfControlArray;
    }

    save() {
        this.mappingControl.setSubmitted();
        if (this.mappingControl.invalid) {
            return;
        }

        this.runtime.close(this.mappingControl);
    }

    addChildCondition() {
        const control = this.mappingsController.createConditionControl(undefined, AuthProviderMappingConditionType.ClaimValue);
        this.childrenControl.push(control);
    }

    deleteChildCondition(index: number) {
        this.childrenControl.removeAt(index);
    }

    mappingTypeChanged(reset = false) {
        const conditionType = this.typeControl?.value;
        if (reset) {
            this.identifierControl.setValue(null);
        }

        switch (conditionType) {
            case AuthProviderMappingConditionType.GroupMembership:
                this.identifierControl.enable();
                this.valueControl.disable();
                this.valueControl.setValue(null);
                this.childrenControl.disable();
                this.childrenControl.clear();
                break;
            case AuthProviderMappingConditionType.ClaimValue:
                this.identifierControl.enable();
                this.valueControl.enable();
                this.childrenControl.disable();
                this.childrenControl.clear();
                break;
            case AuthProviderMappingConditionType.And:
                this.childrenControl.enable();
                this.valueControl.disable();
                this.valueControl.setValue(null);
                this.identifierControl.disable();
                this.identifierControl.setValue(null);
        }
    }

    findSourceClaims(query?: string) {
        this.filteredSourceClaims = ArrayHelper.filterList(this.sourceClaims, query);
    }

    async findSourceGroups(query?: string) {
        if (query && query.trim().length) {
            try {
                this.filteredSourceGroups = await this.ucAuthProviders.getAuthProviderGroups(this.providerId, query.trim());
                return;
            } catch (e) { }
        }
        this.filteredSourceGroups = [];
    }

}
