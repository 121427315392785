import { InjectionToken } from '@angular/core';
import { DataPropertyDescriptor } from '@unifii/library/common';
import { DataSourceInputType, DataSourceType, FieldType, Option, Schema } from '@unifii/sdk';

import { ConsoleDataSource, Integration, IntegrationProviderFeature, UcClaimConfig, UcDataSource, UcDefinition } from 'client';


export interface DataSourceEditorCache {
    getSchema(bucket?: string | null): Promise<Schema | null>;
    getCollectionDefinition(identifier: string): Promise<UcDefinition | null>;
    getCompanyClaimConfig(id: string): Promise<UcClaimConfig | null>;
    getUserClaimConfig(id: string): Promise<UcClaimConfig | null>;
    getExternalDataSource(id: string): Promise<ConsoleDataSource | null>;
    getIntegration(id: string): Promise<Integration | null>;
    reset(): void;
}
export const DataSourceEditorCache = new InjectionToken<DataSourceEditorCache>('DataSourceEditorCache');

export interface ExternalInfo {
    dataSource: ConsoleDataSource;
    feature: IntegrationProviderFeature;
}
export interface DataSourceMapping {
    uuid: string;
    isFromExpression?: boolean; // for _display an expression can be used instead of a source field
    from?: DataPropertyDescriptor; // value
    fromExpression?: string;
    type: FieldType;
    to: string; // attribute
    label: string;
    isVisible?: boolean;
    hideEmpty?: boolean;
    isReportable?: boolean;
}

export interface DataSourceExternalInfo {
    parameter: string;
    source: string;
    type: DataSourceInputType;
    required: boolean;
    placeholder?: string;
}
export interface DataSourceExternalInput {
    info: DataSourceExternalInfo;
    value?: string;
}

export interface DataSourceFormModel extends Omit<UcDataSource, 'type' | 'id' | 'outputs' | 'outputFields' | 'outputDescriptors' | 'inputs' | 'inputArgs' | 'sortDirection'> {
    type: DataSourceType | null;
    resource: Option | null;
    namedId: string | null;
    mappings: DataSourceMapping[];
    externalInputs: DataSourceExternalInput[];
}

export enum DataSourceControlKeys {
    Type = 'type',
    Resource = 'resource', // id for Collection, Bucket, UserClaims, CompanyClaims, External
    NamedId = 'namedId', // id for Named
    Mappings = 'mappings',
    ExternalInputs = 'externalInputs',
    Sort = 'sort',
    FindBy = 'findBy',
    VisibleFilters = 'visibleFilters',
    Filter = 'filter',
    AdvancedFilter = 'advancedFilter'
}

export enum DataSourceMappingControlKeys {
    Uuid = 'uuid',
    IsFromExpression = 'isFromExpression',
    From = 'from',
    FromExpression = 'fromExpression',
    Type = 'type',
    To = 'to',
    Label = 'label',
    IsVisible = 'isVisible',
    HideEmpty = 'hideEmpty',
    IsReportable = 'isReportable'
}

export enum DataSourceExternalInputControlKey {
    Info = 'info',
    Value = 'value'
}

export const descriptorBasedDSTypes = [
    DataSourceType.Collection,
    DataSourceType.Bucket,
    DataSourceType.Users,
    DataSourceType.Company,
    DataSourceType.UserClaims,
    DataSourceType.CompanyClaims
];

export const idBasedDSTypes = [
    DataSourceType.Collection,
    DataSourceType.Bucket,
    DataSourceType.UserClaims,
    DataSourceType.CompanyClaims,
    DataSourceType.External
];