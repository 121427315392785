import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Resource, ResourceElement, UcResources } from 'client';


export interface ResourceData {
    resource: Resource;
    projectResources: ResourceElement[];
    userClaimResources: ResourceElement[];
    companyClaimResources: ResourceElement[];
}


@Injectable({ providedIn: 'root' })
export class ResourceDataResolver implements Resolve<ResourceData | undefined> {

    constructor(
        private ucResources: UcResources
    ) { }

    async resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<ResourceData | undefined> {
        try {
            const resource = await this.ucResources.get();
            const projectResources = await this.ucResources.getProjects();
            const userClaimResources = await this.ucResources.getUserClaims();
            const companyClaimResources = await this.ucResources.getCompanyClaims();

            return { resource, projectResources, userClaimResources, companyClaimResources };
        } catch (e) {
            return;
        }
    }
}
