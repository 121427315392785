import { Subject, zip } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    ClipboardService, FilterEntry, FilterValue, ModalService, TableAction, TableConfig, TableConfigColumn, TableRowContext, ToastService
} from '@unifii/library/common';

import { Permission, PermissionPrincipalType, Resource, ResourceElement, ResourceType, UcPermissions } from 'client';

import { ArrayHelper } from 'helpers/array-helper';

import { DialogsService } from 'services/dialogs.service';

import { PermissionEditorComponent, PermissionEditorData } from './permission-editor.component';
import { PermissionsClonerComponent } from './permissions-cloner.component';
import { PermissionsDataSource } from './permissions-datasource';
import { PermissionChangeAction, PermissionsManagerService } from './permissions-manager.service';
import { ResourceData } from './resource-data-resolver';


@Injectable()
export class PermissionsTableManager implements TableContainerManager<Permission, FilterValue, FilterEntry> {

    readonly: boolean;
    tableConfig: TableConfig<Permission>;
    addActionConfig = true;

    reload = new Subject<void>();
    updateItem = new Subject<Permission>();

    private resource: Resource;

    constructor(
        route: ActivatedRoute,
        private ucPermissions: UcPermissions,
        private permissionsManager: PermissionsManagerService,
        private modalService: ModalService,
        private dialogs: DialogsService,
        private clipboard: ClipboardService,
        private toastService: ToastService,
    ) {
        const resourceData = route.snapshot.data.resourceData as ResourceData | undefined;
        if (resourceData == null) {
            return;
        }

        this.readonly = route.snapshot.data.readonly === true;
        this.addActionConfig = this.readonly === false;
        this.resource = resourceData.resource;

        this.tableConfig = {
            id: `permissions-${this.ucPermissions.entityType}`,
            columns: this.getColumns(resourceData),
            actions: this.getActions(),
            pageSize: -1,
            columnToggles: true,
            selectable: !this.readonly,
            row: { action: permission => this.edit(permission) }
        };
    }

    createDataSource() {
        return new PermissionsDataSource(this.ucPermissions);
    }

    private getColumns(data: ResourceData): TableConfigColumn<Permission>[] {
        const replacers = this.getReplacers(data);

        return [{
            name: 'path',
            label: 'Path',
            value: item => {
                const parts = [...item.path || []];

                for (const replacer of replacers) {
                    const idx = parts.indexOf(replacer.parentResource.segment);
                    if (parts.length > idx + 1) {
                        const replaceItem = replacer.items.find(i => `${i.id}` === parts[idx + 1]);
                        if (replaceItem) {
                            parts[idx + 1] = replaceItem.name;
                        }
                    }
                }

                return '/' + parts.join('/');
            }
        }, {
            name: 'description',
            label: 'Description'
        }, {
            name: 'actions',
            label: 'Actions',
            value: item => item.actions?.join(', ') ?? ''
        }, {
            name: 'condition',
            label: 'Condition'
        }, {
            name: 'fields',
            label: 'Fields',
            value: item => item?.fields?.join(', ') ?? ''
        }];
    }

    private getActions(): TableAction<Permission>[] {
        return [
            {
                label: 'Copy',
                action: (rows: TableRowContext<Permission>[]) => this.copy(rows.map(row => row.$implicit)),
                predicate: row => this.ucPermissions.entityType === PermissionPrincipalType.Role && !this.isCompanyClaimsPermission(row.$implicit)
            },
            {
                label: 'Duplicate',
                action: (rows: TableRowContext<Permission>[]) => this.duplicate(rows.map(row => row.$implicit)),
                predicate: row => !this.readonly && !this.isCompanyClaimsPermission(row.$implicit)
            },
            {
                label: 'Delete',
                action: (rows: TableRowContext<Permission>[]) => this.delete(rows.map(row => row.$implicit)),
                predicate: () => !this.readonly
            }
        ];
    }

    private isCompanyClaimsPermission(permission: Permission): boolean {
        return permission.path != null && permission.path.length > 0 && permission.path[0] === 'company-claims';
    }

    private async edit(permission: Permission) {

        if (this.isCompanyClaimsPermission(permission)) {
            return;
        }

        const permissionCopy = JSON.parse(JSON.stringify(permission));
        const data: PermissionEditorData = { permission: permissionCopy, resources: this.resource, readonly: this.readonly };

        const edited = await this.modalService.openLarge<PermissionEditorData, Permission>(
            PermissionEditorComponent, data
        );
        if (!edited) {
            return;
        }

        try {
            await this.ucPermissions.save(edited);
            this.updateItem.next(edited);

            this.permissionsManager.notify.next({
                action: PermissionChangeAction.Edited,
                principalType: edited.principalType,
                principalId: edited.principalId
            });
            this.toastService.success('Permission modified');
        } catch (error) {
            console.error(error);
            this.toastService.error('Permission edit failed');
        }
    }

    private async delete(permissions: Permission[]) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            await zip(...permissions.map(permission => this.ucPermissions.delete(permission.id as string))).toPromise();
            this.reload.next();
            this.permissionsManager.notify.next({
                action: PermissionChangeAction.Deleted,
                principalType: this.ucPermissions.entityType,
                principalId: this.ucPermissions.entityId
            });
        } catch (error) {
            console.error(error);
            this.toastService.error('Failed to delete');
        }
    }

    private duplicate(permissions: Permission[]) {
        this.modalService.openLarge<Permission[], void>(
            PermissionsClonerComponent,
            permissions
        );
    }

    private async copy(permissions: Permission[]) {
        const copies = permissions.map(p => {
            const copy = Object.assign({}, p);
            delete copy.id;
            copy.principalId = undefined as any;
            copy.principalType = undefined as any;
            return copy;
        });

        const text = JSON.stringify(copies);
        this.clipboard.setText(text);
    }

    private getReplacers({ resource, projectResources, userClaimResources, companyClaimResources }: ResourceData): { parentResource: Resource; items: ResourceElement[] }[] {
        const flatResources = (ArrayHelper.flatTree(resource) as Resource[]);

        const replacers: { parentResource: Resource; items: ResourceElement[] }[] = [];
        replacers.push({
            parentResource: flatResources.find(r => r.name === ResourceType.Projects) as Resource,
            items: projectResources
        });
        replacers.push({
            parentResource: flatResources.find(r => r.name === ResourceType.UserClaims) as Resource,
            items: userClaimResources
        });
        replacers.push({
            parentResource: flatResources.find(r => r.name === ResourceType.CompanyClaims) as Resource,
            items: companyClaimResources
        });
        return replacers;
    }


}
