import { Subscription } from 'rxjs';

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClipboardService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Definition } from '@unifii/sdk';

import { BuilderField, SystemRole } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';

import { IdentifierFunctions } from 'helpers/helpers';

import { ContextService } from 'services/context.service';


@Component({
    selector: 'uc-collection-settings',
    templateUrl: './collection-settings.html'
})
export class CollectionSettingsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() definition: Definition;

    control = new UfControlGroup({
        label: new UfControl(ValidatorFunctions.required('Label is mandatory.')),
        identifier: new UfControl([
            ValidatorFunctions.required('Identifier is mandatory.'),
            ValidatorFunctions.custom(v => /^[A-Za-z0-9_-]+$/.test(v), 'Identifier contains invalid characters'),
            ValidatorFunctions.custom(v => !/\s/.test(v), `Identifier  can't contain a space`),
            ValidatorFunctions.custom(v => !/^toDate$|^add$|^toTime$/.test(v), `Identifier can't be a date expression`)
        ])
    });
    maxLength = IdentifierFunctions.IDENTIFIER_MAX_LENGTH;

    private subscriptions = new Subscription();

    constructor(
        private builderService: BuilderService,
        private context: ContextService,
        private toast: ToastService,
        private clipboard: ClipboardService
    ) { }

    ngOnInit() {

        this.subscriptions.add(this.builderService.submitted.subscribe(v => {
            this.control.setSubmitted(true);
        }));

        // New definition
        if (this.definition.lastModifiedAt == null) {
            const labelControl = this.control.get('label') as UfControl;
            this.subscriptions.add(labelControl.valueChanges.subscribe(this.autofillFields));
        }

        // Published definition
        if (!this.context.checkRoles(SystemRole.ProjectManager) || this.definition.lastPublishedAt != null) {
            const identifierControl = (this.control.get('identifier') as UfControl);
            identifierControl.disable();
        }

        this.subscriptions.add(this.control.valueChanges.subscribe(() => setTimeout(() => {
            this.valueChange();
        }, 0)));
    }

    ngAfterViewInit() {
        // Wait for template bindings to run and set errors in builder service
        this.valueChange(true);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    valueChange(skipEdit?: boolean) {

        /**
         * This is backward's submit should validate form then get errors
         */
        const errors = Object.keys(this.control.controls).map(key => {
            const control = this.control.get(key) as UfControl;

            if (control.errors == null) {
                return null;
            }

            return control.errors.message;
        }).filter(err => err != null).map(e => e.message);

        this.builderService.setErrors(this.builderService.definition, errors, 'all');

        if (!skipEdit) {
            this.builderService.fieldEdit.next({ subject: this.builderService.definition, atomic: false });
        }
    }

    async copyFields() {
        this.clipboard.setText(JSON.stringify(this.builderService.definition.fields));
    }

    async pasteFields() {

        try {
            const text = await this.clipboard.getText();
            const fields = !text ? undefined : this.cleanFields(JSON.parse(text));

            if (!fields) {
                this.toast.error('Failed to paste');
                return;
            }

            this.builderService.definition.fields.push(...fields);
            this.toast.success('Pasted from clipboard');
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
            this.toast.error('Failed to paste');
        }
    }

    private autofillFields = (value: string | undefined) => {

        if (!value) {
            this.definition.identifier = '';
            return;
        }

        this.definition.identifier = IdentifierFunctions.kebabize(value)
            .substring(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
    };

    private cleanFields(fields: BuilderField[]): BuilderField[] | null {

        if (!Array.isArray(fields)) {
            return null;
        }

        for (const field of this.builderService.fieldIterable(fields)) {

            /** field type required on field interface */
            if (!field.type) {
                return null;
            }

            if (field.id) {
                delete field.id;
            }
        }
        return fields;
    }

}
