<ng-container *ngIf="ready">
    <uc-builder-header>
    </uc-builder-header>
    <div class="builder-content">

        <uc-field-reference-picker></uc-field-reference-picker>

        <div class="builder-growing-section">
            <div #fieldlist class="field-list uf-box flat">

                <uc-builder-fields-header>
                    <uc-expander-controls *ngIf="page?.fields?.length" [container]="fieldlist"
                        [className]="selected == null ? 'primary' : ''">
                    </uc-expander-controls>
                </uc-builder-fields-header>

                <uc-drag-list class="scrollable" [items]="page.fields" [canDrop]="canDrop"
                    [convertAdded]="convertFieldRef" (insert)="inserted($event.item)" (moved)="moved($event.item)">
                    <ng-template ngFor let-field [ngForOf]="page.fields" let-i="index">
                        <uf-field class="col-1of1--no-margin--no-padding" [field]="field" [(content)]="field.value"
                            dragItem>
                        </uf-field>
                    </ng-template>
                    <div *ngIf="!page.fields.length" class="empty">
                        <div>
                            <img src="/assets/svg/icon-projects.svg">
                            <p>Drag your items here!</p>
                        </div>
                    </div>
                </uc-drag-list>
            </div>

            <div class="field-settings">
                <uf-tabs class="scrollable">
                    <uf-tab label="Details">
                        <div class="tab-content scrollable uf-box flat">
                            <uc-page-settings *ngIf="!selected" [page]="page">
                            </uc-page-settings>
                            <uc-field-builder *ngIf="selected" [field]="selected">
                            </uc-field-builder>
                        </div>
                    </uf-tab>
                    <uf-tab label="Preview" *ngIf="!selected">
                        <div class="tab-content scrollable uf-box flat">
                            <uf-page *ngIf="displayPage" [classList]="['grid', 'body-copy']"
                                [page]="displayPage"></uf-page>
                        </div>
                    </uf-tab>
                </uf-tabs>
            </div>
        </div>

    </div>

</ng-container>