import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ModalService, ToastService } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';

import { UcDefinition, UcProject } from 'client';

import { FormMetadataModalComponent } from 'pages/form-editor';
import { FormMetadataModalData } from 'pages/form-editor/form-metadata-modal.component';


/**
 * @description
 * Ensures that minimum settings are provided to FormEditor component to initialise
 */
@Injectable({
    providedIn: 'root'
})
export class DefinitionResolver implements Resolve<UcDefinition | undefined> {

    type: CompoundType;

    constructor(
        private ucProject: UcProject,
        private modalService: ModalService,
        private toast: ToastService,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UcDefinition> {
        const prevUrl = state.url.replace('/new', '');
        const type = route.data.type;
        const { id, duplicate } = route.params;

        return this.resolvePromise(this.getDefinition(id, type, duplicate), prevUrl);
    }

    private resolvePromise(promise: Promise<UcDefinition | undefined>, prevUrl: string): Observable<UcDefinition> {
        // Wraps promises with an obserable that completes which will cancel navigation
        return new Observable(subscriber => {
            promise.then(d => {
                if (d) {
                    subscriber.next(d);
                } else {
                    this.router.navigateByUrl(prevUrl);
                }
                subscriber.complete();
            }).catch(err => this.toast.error(err?.message || 'Failed to load definition'));
        });
    }

    private async getDefinition(id: string, type: CompoundType, duplicate = false): Promise<UcDefinition> {
        if (id === 'new') {
            return await this.createNewDefinition(type);
        }

        const definition = await this.getDefinitionProvider(id, type);
        if (duplicate) {
            // duplicate
            delete definition.id;
            delete definition.lastModifiedAt;
            delete definition.lastPublishedAt;
            definition.identifier += '-copy';
            definition.label += ' - COPY';
        }

        return definition;
    }

    private createNewDefinition(type: CompoundType): Promise<UcDefinition> {
        switch (type) {
            case CompoundType.Form: return this.modalService.openMedium<FormMetadataModalData, UcDefinition>(FormMetadataModalComponent);
            // todo: implement more modals as needed
            default: throw new Error('type not available');
        }
    }

    private getDefinitionProvider(id: string, type: CompoundType): Promise<UcDefinition> {
        switch (type) {
            case CompoundType.Form: return this.ucProject.getForm(id);
            // todo: implement more requests as needed
            default: throw new Error('type not provided');
        }
    }

}
