<div class="uf-app-bar">
    <button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Unit Mapping</h3>
</div>
<uf-panel class="grow">
    <div *ngIf="mappingControl" [formGroup]="mappingControl" class="uf-grid pad">
        <!-- TODO the following container can be converted to a uc-mapping-condition component once we allow full implementation for nested condition logic -->
        <ng-container [formGroupName]="mappingsControlKeys.Condition">
            <uf-select [formControlName]="conditionControlKeys.Type" [options]="conditionOptions"
                valueProperty="identifier" nameProperty="name" label="Mapping Type" class="col-12"
                (valueChange)="mappingTypeChanged(true)">
            </uf-select>

            <!-- Group -->
            <uf-autocomplete
                *ngIf="filteredSourceGroups && typeControl?.value === authProviderMappingConditionType.GroupMembership"
                placeholder="Select a source" label="Group" [formControlName]="conditionControlKeys.Identifier"
                (searchChange)="findSourceGroups($event)" [options]="filteredSourceGroups" [allowCustom]="true"
                nameProperty="name" class="col-12">
            </uf-autocomplete>

            <!-- Claim Value -->
            <ng-container *ngIf="typeControl?.value === authProviderMappingConditionType.ClaimValue">
                <uf-autocomplete label="Claim" [formControlName]="conditionControlKeys.Identifier"
                    (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims"
                    placeholder="Select a source" [allowCustom]="true" class="col-6">
                </uf-autocomplete>
                <uf-text label="Claim Value" [formControlName]="conditionControlKeys.Value" class="col-6">
                </uf-text>
            </ng-container>

            <!-- Add -->
            <ng-container *ngIf="typeControl?.value === authProviderMappingConditionType.And">
                <ul class="col-12 uf-list uf-box flat">
                    <!-- TODO as commented above, instead of this simple implementation, in the future we could have nested uc-mapping-condition components -->
                    <li *ngFor="let control of (childrenControl?.controls || []) | asUfControlsGroups; let i = index"
                        [formGroup]="control | asUfControlGroup" class="row">
                        <div class="uf-grid grow pad">
                            <uf-autocomplete label="Claim" [formControlName]="conditionControlKeys.Identifier"
                                (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims"
                                placeholder="Select a source" [allowCustom]="true" class="col-6">
                            </uf-autocomplete>
                            <uf-text label="Claim Value" [formControlName]="conditionControlKeys.Value" class="col-6">
                            </uf-text>
                        </div>
                        <div class="col center">
                            <div class="uf-action tertiary">
                                <uf-icon name="delete" (click)="deleteChildCondition(i)"></uf-icon>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="col-12 row">
                    <button (click)="addChildCondition()" class="uf-button right">Add Condition</button>
                </div>
            </ng-container>
        </ng-container>
        <uf-hierarchy-multi-select label="Unifii Unit" [formControlName]="mappingsControlKeys.Targets"
            [selectLeafsOnly]="false" class="col-12">
        </uf-hierarchy-multi-select>
    </div>
</uf-panel>
<div class="row space-children pad">
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>