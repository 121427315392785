import { Subscription } from 'rxjs';

import { AfterViewInit, Directive, InjectionToken, Input, OnDestroy, OnInit } from '@angular/core';
import { Scope, UfControl } from '@unifii/library/common';
import { FieldHelperFunctions, FormField } from '@unifii/library/smart-forms';
import { Field } from '@unifii/sdk';

import { BuilderEventInfo, BuilderService } from '../compound-builder/builder.service';
import { DialogsService } from 'services/dialogs.service';


export type ContentMode = 'Edit' | 'Preview' | 'DataEntry';

export interface ContentSettings {
    mode: ContentMode;
}
export const ContentSettings = new InjectionToken<ContentSettings>('ContentSettings');

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class Content implements FormField, OnInit, AfterViewInit, OnDestroy {

    field: Field;
    control: UfControl;
    content: any;
    scope: Scope;
    isGroup: boolean;

    // Flags of field status within the CMS Builder
    ready: boolean;
    isSelected: boolean;

    // Subscribers
    protected subscribers: Subscription[] = [];

    private _disabled: boolean;

    constructor(
        protected builderService: BuilderService,
        private dialogs: DialogsService,
        protected contentSettings: ContentSettings = { mode: 'Edit' }
    ) { }

    get isValid(): boolean {

        if (!this.field || !this.builderService) {
            return true;
        }

        return this.builderService.isValid(this.field);
    }

    ngOnInit() {

        this.isGroup = FieldHelperFunctions.isGroup(this.field.type);

        // React to builder ready status
        this.subscribers.push(this.builderService.ready.subscribe(v => {
            this.ready = true;
        }));

        // React to field selection in builder
        this.subscribers.push(this.builderService.fieldSelected.subscribe(i => {
            this.isSelected = this.canSelect && this.isMe(i);
        }));
    }

    ngAfterViewInit() {

        this.builderService.fieldReady.next({ subject: this.field, atomic: true });

        /**
         * Control value needs to be set so
         * todo: setting control value in ngOnInit throws an error
         */
        if (this.content != null && this.control != null) {
            this.control.setValue(this.content, { onlySelf: true, emitEvent: false });
        }
    }

    ngOnDestroy() {
        this.subscribers.forEach(s => s.unsubscribe());
    }

    @Input() set disabled(v: boolean) {
        this._disabled = v;
    }

    get disabled(): boolean {
        return this._disabled || this.control.disabled;
    }

    get canRemove(): boolean {
        return this.contentSettings.mode === 'Edit' && !this.disabled;
    }

    get canSelect(): boolean {
        return this.contentSettings.mode === 'Edit' && !this.disabled;
    }

    canDrop = (_item: any, _parent: any): boolean => false;

    async remove() {
        if (!this.canRemove) {
            return;
        }

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.builderService.fieldRemove.next({ subject: this.field, atomic: true });

    }

    select() {
        if (this.canSelect) {
            this.builderService.fieldSelect.next({ subject: this.field });
        }
    }

    private isMe(i: BuilderEventInfo = { subject: null }): boolean {
        return i.subject != null && i.subject === this.field;
    }
}
