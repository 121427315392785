import { UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { AuthProviderMappingConditionType } from '../new-identity/models';

import { ConditionControlKeys, ConditionOptions, MappingsControlKeys } from './auth-provider-model';


export const conditionDictionary = ConditionOptions.reduce((dict, option) => {
    dict[option.identifier] = option.name;
    return dict;
}, {} as Dictionary<string>);

// TODO this will need to change once we expand functionality for more complex nested conditions
export const getMappingConditionLabels = (mappingControl: UfControlGroup): string[] => {
    const conditionControl = mappingControl.get(MappingsControlKeys.Condition) as UfControlGroup;
    switch (conditionControl.get(ConditionControlKeys.Type)?.value) {
        case AuthProviderMappingConditionType.ClaimValue:
            return [getMappingConditionClaimLabel(conditionControl)];
        case AuthProviderMappingConditionType.GroupMembership:
            return [conditionControl.get(ConditionControlKeys.Identifier)?.value?.name || conditionControl?.get(ConditionControlKeys.Identifier)?.value || ''];
        case AuthProviderMappingConditionType.And:
            return ((conditionControl.get(ConditionControlKeys.Chlidren) as UfControlArray | undefined)?.controls || []).map(c => getMappingConditionClaimLabel(c as UfControlGroup));
        default:
            return [''];
    }
};

const getMappingConditionClaimLabel = (conditionControl: UfControlGroup | undefined) =>
    [conditionControl?.get(ConditionControlKeys.Identifier)?.value, conditionControl?.get(ConditionControlKeys.Value)?.value].filter(s => s != null).join(': ');
