import { Inject, Injectable } from '@angular/core';
import { HierarchyUnitProvider, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { HierarchyUnit, HierarchyUnitWithPath } from '@unifii/sdk';

import { AuthProviderMapping, AuthProviderMappingActionType, AuthProviderMappingCondition, AuthProviderSourceGroup, UcAuthProviders } from 'client';

import { AuthProviderMappingConditionType } from '../new-identity/models';

import { ConditionControlKeys, MappingsControlKeys } from './auth-provider-model';


export interface AuthProviderMappingFormModel {
    condition: AuthProviderMappingConditionFormModel;
    targets: HierarchyUnit[];
}

export interface AuthProviderMappingConditionFormModel {
    type: AuthProviderMappingConditionType;
    identifier?: string | AuthProviderSourceGroup;
    value?: string;
    children?: AuthProviderMappingConditionFormModel[];
}

@Injectable({
    providedIn: 'root'
})
export class AuthProviderMappingsController {

    constructor(
        private ufb: UfFormBuilder,
        private ucAuthProviders: UcAuthProviders,
        @Inject(HierarchyUnitProvider) private hierarchyProvider: HierarchyUnitProvider,
    ) { }

    createMappingControl(formModel?: AuthProviderMappingFormModel) {
        return this.ufb.group({
            [MappingsControlKeys.Condition]: this.createConditionControl(formModel?.condition),
            [MappingsControlKeys.Targets]: this.ufb.control(formModel?.targets, ValidatorFunctions.required('Unit is required'))
        });
    }

    createConditionControl(condition?: AuthProviderMappingConditionFormModel, defaultType = AuthProviderMappingConditionType.GroupMembership): UfControlGroup {
        return this.ufb.group({
            [ConditionControlKeys.Type]: this.ufb.control(condition?.type || defaultType, ValidatorFunctions.required('Mapping type is required')),
            [ConditionControlKeys.Identifier]: this.ufb.control({ value: condition?.identifier, disabled: condition?.type === AuthProviderMappingConditionType.And }, ValidatorFunctions.required('Claim/Group is required')),
            [ConditionControlKeys.Value]: this.ufb.control(condition?.value),
            [ConditionControlKeys.Chlidren]: this.ufb.array((condition?.children || []).map((child: AuthProviderMappingConditionFormModel) => this.createConditionControl(child)))
        });
    }

    toDataModel(mapping: AuthProviderMappingFormModel): AuthProviderMapping {
        return {
            condition: this.conditionToDataModel(mapping.condition),
            actions: mapping.targets?.map((target: HierarchyUnit) => ({
                type: AuthProviderMappingActionType.AssignUnit,
                identifier: target.id
            }))
        };
    }

    async toFormModel(providerId: string | undefined, mapping: AuthProviderMapping, units: HierarchyUnitWithPath[]): Promise<AuthProviderMappingFormModel> {
        return {
            condition: await this.conditionToFormModel(providerId, mapping.condition),
            targets: mapping?.actions?.length ? units.filter(unit => mapping.actions.find(action => action.identifier === unit.id)) : []
        };
    }

    private conditionToDataModel(condition: AuthProviderMappingConditionFormModel): AuthProviderMappingCondition {
        return {
            type: condition.type,
            identifier: (condition.identifier as AuthProviderSourceGroup)?.id ?? condition.identifier,
            value: condition.value,
            children: (condition.children || []).map(child => this.conditionToDataModel(child))
        };
    }

    private async conditionToFormModel(providerId: string | undefined, condition: AuthProviderMappingCondition): Promise<AuthProviderMappingConditionFormModel> {
        return {
            type: condition.type,
            identifier: await this.getIdentifierControlValue(providerId, condition),
            value: condition.value,
            children: await Promise.all((condition.children || []).map(child => this.conditionToFormModel(providerId, child)))
        };
    }

    private async getIdentifierControlValue(providerId: string | undefined, condition: AuthProviderMappingCondition): Promise<string | AuthProviderSourceGroup | undefined> {
        switch (condition?.type) {
            case (AuthProviderMappingConditionType.GroupMembership):
                return await this.getGroupIdentifierControlValue(providerId, condition?.identifier);
            case (AuthProviderMappingConditionType.ClaimValue):
                return condition.identifier;
        }
        return;
    }

    private async getGroupIdentifierControlValue(providerId: string | undefined, identifier: string | AuthProviderSourceGroup | undefined): Promise<string | AuthProviderSourceGroup | undefined> {
        if (identifier == null) {
            return;
        }

        if ((identifier as AuthProviderSourceGroup).name != null) {
            return identifier;
        }

        // identifier passed as string, try to load the name and convert it to authprovidergroup
        let authProviderGroup;
        try {
            authProviderGroup = await this.ucAuthProviders.getAuthProviderGroup(providerId as string, identifier as string);
        } catch (e) {
            console.error(e);
        }
        return authProviderGroup || { id: identifier as string, name: identifier as string };
    }
}