export enum DefinitionControlKeys {
    Bucket = 'bucket',
    CompoundType = 'compoundType',
    // 'fields', use the FieldControlKeys one
    Id = 'id',
    Identifier = 'identifier',
    Label = 'label',
    LastModifiedAt = 'lastModifiedAt',
    LastModifiedBy = 'lastModifiedBy',
    LastPublishedAt = 'lastPublishedAt',
    LastPublishedBy = 'lastPublishedBy',
    PublishState = 'publishState',
    SequenceNumberFormat = 'sequenceNumberFormat',
    Settings = 'settings',
    State = 'state',
    Version = 'version',
    Tags = 'tags',
    ReportableMetaFields = 'reportableMetaFields',
    HasRollingVersion = 'hasRollingVersion'
}

export enum DefinitionSettingsControlKeys {
    OptionalSuffix = 'optionalSuffix',
    RequiredSuffix = 'requiredSuffix',
    InputStyle = 'inputStyle'
}

export enum FieldControlKeys {
    // Editor runtime properties
    Uuid = 'uuid',
    ScopeUuid = 'scopeUuid',
    RepeatSortableProperties = 'repeatSortableProperties',
    // Hiddens
    Type = 'type',
    Id = 'id',
    Fields = 'fields',
    // Details
    Label = 'label',
    ShortLabel = 'shortLabel',
    Identifier = 'identifier',
    Template = 'template',
    Roles = 'roles',
    Description = 'description',
    // Workflow
    Transitions = 'transitions',
    // Settings
    IsRequired = 'isRequired',
    IsReadOnly = 'isReadOnly',
    AutoDetect = 'autoDetect',
    Currency = 'currency',
    Autofill = 'autofill',
    BindTo = 'bindTo',
    ShowIf = 'showIf',
    ShowOn = 'showOn',
    Precision = 'precision',
    MaxLength = 'maxLength',
    Step = 'step', // Interval
    Format = 'format',
    HierarchyConfig = 'hierarchyConfig',
    Sort = 'sort',
    // Options
    DataSourceConfig = 'dataSourceConfig',
    Options = 'options',
    AvoidDuplicates = 'avoidDuplicates',
    // Nested Fields
    AddressAutocomplete = 'addressAutocomplete',
    AddressNested = 'addressNested',
    GeoLocationNested = 'geoLocationNested',
    // Details or Display
    Help = 'help',
    // Display
    Placeholder = 'placeholder',
    ItemLabel = 'itemLabel',
    AddButtonLabel = 'addButtonLabel',
    TemplateConfig = 'templateConfig', // Column visibility
    Width = 'width',
    BreakAfter = 'breakAfter',
    ColumnCount = 'columnCount', // Columns
    LayoutDirection = 'layoutDirection',
    VisibleTo = 'visibleTo',
    // Variations
    Variations = 'variations',
    // Validators
    Validators = 'validators',
    // Advanced
    DataCaptures = 'dataCaptures',
    AllowedTypes = 'allowedTypes',
    IsReportable = 'isReportable',
    IsSearchable = 'isSearchable',
    IsTranslatable = 'isTranslatable',
    Tags = 'tags'
}

export enum VariationControlKeys {
    Name = 'name',
    Condition = 'condition',
    Label = 'label',
    Placeholder = 'placeholder',
    Help = 'help',
    Options = 'options',
    DataSourceConfig = 'dataSourceConfig',
    Validators = 'validators'
}

export enum OptionControlKeys {
    Uuid = 'uuid',
    Id = 'id',
    Identifier = 'identifier',
    Name = 'name',
    Content = 'content'
}

export enum FieldMappingControlKeys {
    ParentField = 'parentField',
    ChildField = 'childField'
}

export enum TransitionTargetControlKeys {
    Label = 'label',
    Form = 'form',
    Transition = 'transition',
    Condition = 'condition',
    FieldMappings = 'fieldMappings'
}

export enum TransitionControlKeys {
    Source = 'source',
    Action = 'action',
    Target = 'target',
    Result = 'result',
    ActionLabel = 'actionLabel',
    Notify = 'notify',
    Validate = 'validate',
    Roles = 'roles',
    ShowIf = 'showIf',
    Triggers = 'triggers',
    Spawns = 'spawns',
    Tags = 'tags'
}

export enum HierarchyConfigControlKeys {
    Ceiling = 'ceiling',
    SelectionMode = 'selectionMode'
}

export enum ValidatorControlKeys {
    Type = 'type',
    Message = 'message',
    Value = 'value'
}

export enum AddressNestedControlKey {
    Address1 = 'address1',
    Address2 = 'address2',
    Suburb = 'suburb',
    State = 'state',
    Postcode = 'postcode',
    Country = 'country',
    Map = 'map'
}

export enum GeoLocationNestedControlKey {
    Map = 'map',
    LatitudeLongitude = 'latlng'
}

export enum NestedControlKey {
    Visible = 'visible',
    ReadOnly = 'readOnly',
    Required = 'required'
}