import { debounceTime } from 'rxjs/operators';

import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { FieldHelperFunctions, FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { FormComponentRegistry, InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { CompoundType, Definition, Field, FieldType } from '@unifii/sdk';

import { CompoundInfo, UcCollection, UcCompound } from 'client';

import { EditMode } from 'components/common/edit-data';
import { SaveOption } from 'components/common/save-options/save-options.component';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentSettings } from 'components/content/content';
import { MarkdownEditorRegistry } from 'components/markdown-editor-registry';

import { CollectionService } from 'pages/content/collections/collection-service';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { UcTableManager } from 'services/table/models';


@Component({
    templateUrl: './collection-item-builder.html',
    styleUrls: ['./collection-item-builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        { provide: FormSettings, useClass: InputFormSettings },
        { provide: FormComponentRegistry, useClass: MarkdownEditorRegistry },
        { provide: ContentSettings, useValue: { mode: 'DataEntry' } }
    ]
})
export class CollectionItemBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    @ViewChild('form', { static: true }) form: ElementRef;

    type = CompoundType.Collection;
    subject = BuilderCompoundSubjects.CONTENT;
    config: FormConfiguration = { hideLabel: true };
    ready: boolean;
    rootControl: UfControlGroup;
    displayDefinition: Definition | null;
    displayCompound: UcCompound | null;
    expanded = true;
    compound: UcCompound;

    // Check if this is working correctly
    protected lastEditedField = null;

    private recordNameCtrl = new UfControl(ValidatorFunctions.required('This field is mandatory'));
    private titleCtrl = new UfControl(ValidatorFunctions.required('This field is mandatory'));

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected router: Router,
        protected route: ActivatedRoute,
        private service: CollectionService,
        private toastService: ToastService,
        private displayService: DisplayService,
        private cdr: ChangeDetectorRef,
        private breadcrumbService: BreadcrumbService,
        public expandersService: ExpandersService,
        private builderHeaderService: BuilderHeaderService,
        @Optional() @Inject(TableContainerManager) protected tableManager: UcTableManager<CompoundInfo>
    ) {
        super(builderService, modalService, route, tableManager);
    }

    get ucCollection(): UcCollection {
        return this.service.ucCollection;
    }

    get definition(): Definition {
        const definition = this.service.definition;
        definition.settings = Object.assign(definition.settings ? definition.settings : {}, { inputStyle: 'small' });
        return definition as Definition;
    }

    async ngOnInit() {

        // wait for collection service to finish loading
        await this.service.definitionLoadPromise;

        // Load data
        this.compound = await this.load();

        // Register event listeners
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.definition, this.compound);

        this.updatePreview();

        // Automatically update recordName with title value if new entry
        if (!Object.keys(this.compound || {}).length) {
            this.subscriptions.add(this.titleCtrl.valueChanges
                .subscribe(v => {
                    if (!this.recordNameCtrl.touched) {
                        this.recordNameCtrl.setValue(v, { onlySelf: false, emitEvent: true });
                    }
                }));
        }

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
        this.buildHeaderConfig(this.compound);
    }

    @ViewChild(UfFormComponent, { static: false }) set ufForm(form: UfFormComponent) {

        /** Because of ready bool form will be undefined until ready changes */
        if (form == null || this.rootControl) {
            return;
        }

        form.rootControl.setControl('_recordName', this.recordNameCtrl);
        form.rootControl.setControl('_title', this.titleCtrl);
        this.rootControl = form.rootControl;

        /**
         * Possibly combine debounced and immediate subscription
         */
        this.subscriptions.add(this.rootControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            if (form.rootControl.dirty) {
                this.builderService.memento.edited = true;
                this.builderService.memento.setLastAtomic();
            }
            this.updatePreview();
        }));

        this.cdr.detectChanges();
    }

    addSubscribers() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));
    }

    async save(saveOption?: SaveOption) {

        // Notify the other components of a builder save/submit action
        // this.builderService.submit.next(null); // TODO Why different from the other buidlers
        this.rootControl.setSubmitted();

        if (this.rootControl.invalid) {
            this.toastService.error('Unable to save. There are errors in your Collection.');
            return;
        }

        if (this.editMode === EditMode.New || this.editMode === EditMode.Duplicate) {
            /**
             * Form component creates id on init
             * this needs to be removed for new entries
             */
            delete this.compound.id;
        }

        this.rootControl.markAsPristine();

        // Trim Website fields value
        if (this.definition.fields) {
            for (const field of FieldHelperFunctions.fieldIterator(this.definition.fields as Field[])) {
                if (field.type === FieldType.Website && field.identifier && this.compound[field.identifier]) {
                    this.compound[field.identifier] = (this.compound[field.identifier] as string).trim();
                }
            }
        }

        // Save the compound
        try {
            this.builderService.busy.next(true);
            this.compound = await this.ucCollection.saveItem(this.compound);

            // Notify user of the save success
            this.toastService.success('Collection saved');

            this.saved(this.compound, saveOption);
            this.builderService.init(this, this.definition, this.compound);
            this.buildHeaderConfig(this.compound);
        } catch (err) {
            const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
            this.toastService.error(message);
        } finally {
            this.builderService.busy.next(false);
        }
    }

    get title() {
        let title = null;

        switch (this.builderService.builder.subject) {
            case BuilderCompoundSubjects.DEFINITION:
                title = this.builderService.definition.label; break;
            case BuilderCompoundSubjects.CONTENT:
            case BuilderCompoundSubjects.TRANSLATION:
                title = this.builderService.compound.id ? this.builderService.compound.recordName : 'New'; break;
        }

        return title;
    }

    private async updatePreview() {

        /**
         * No need for an async call if definition doesn't contain a LinkList
         * note - Links need to be converted to Compounds before rendered
         */
        if (this.definition.fields?.find(field => field.type === FieldType.LinkList) == null) {
            this.displayDefinition = this.definition as Definition;
            this.displayCompound = this.compound;
            return;
        }

        const displayContent = await this.displayService.renderCompound(this.definition as Definition, this.compound);
        this.displayDefinition = displayContent.definition || null;
        this.displayCompound = displayContent.compound || null;
    }

    private async load(): Promise<UcCompound> {

        if (this.editMode === EditMode.New) {
            return {} as UcCompound;
        }

        const compound = await this.ucCollection.getItem(+this.route.snapshot.params.id);

        if (this.editMode === EditMode.Existing) {
            return compound;
        }

        delete compound.id;
        compound.recordName += ' COPY';
        compound._title += ' COPY';
        return compound;
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.compound, i.atomic);
        this.builderService.memento.edited = true;
        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(definition: UcCompound) {
        this.builderHeaderService.buildConfig({
            ...definition,
            title: this.title,
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.builderService.definition?.label || '', this.title]),
            publishState: definition.publishState,
            saveOptions: this.saveOptions,
            restrictSave: 'ContentEditor',
            cancelRoute: ['../'],
        });
    }
}
