import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageLevel, UfControlGroup } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetails, AuthProviderMapping, UcAuthProviders } from 'client';

import { EditData } from 'components/common/edit-data';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';

import { AuthProviderDetailsButtonEvents } from './detail/auth-provider-detail.component';
import { AuthProviderDetailController } from './detail/auth-provider-detail.controller';
import { AuthProviderMappingsController } from './mappings/auth-provider-mapping.controller';
import { AuthProviderMappingsModel, DetailsControlKeys } from './models';


@Component({
    templateUrl: './auth-provider.html',
})
export class AuthProviderComponent implements OnInit, OnDestroy, EditData {

    protected id = '';
    protected type: AuthProvider;
    protected authProvider?: AuthProviderDetails;
    protected form: UfControlGroup;
    protected mappingsForm: UfControlGroup;
    protected mapping: AuthProviderMappingsModel;

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucAuthProviders: UcAuthProviders,
        private builderHeaderService: BuilderHeaderService,
        private breadcrumbService: BreadcrumbService,
        private authProviderController: AuthProviderDetailController,
        private mappingsController: AuthProviderMappingsController,
        private dialogs: DialogsService,
    ) { }

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    get isNew() {
        return this.id.toLowerCase() === 'new';
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    async ngOnInit() {
        this.id = this.route.snapshot.params.id;
        this.type = this.route.snapshot.params.type;
        const { tenant } = this.route.snapshot.queryParams;

        if (tenant) {
            const provider = await this.createProviderWithoutManualRegistration(tenant, this.id);
            this.router.navigate(['../', provider.id], { relativeTo: this.route });

            this.id = (provider.id ?? this.id) + '';
            this.type = provider.type;
        }

        await this.setupIdentityDetails();

        if (!this.isNew) {
            await this.setupMappings(this.authProvider?.mappings);
        }

        this.setupHeader();
    }

    protected async delete() {

        if (!await this.dialogs.confirmSSODisconnect()) {
            return;
        }

        await this.ucAuthProviders.delete(this.id as string);

        this.builderHeaderService.notify.next({
            level: MessageLevel.Success,
            message: 'Disconnected'
        });

        this.router.navigate(['../'], { relativeTo: this.route });
    }

    protected detailButtonClicked(event: AuthProviderDetailsButtonEvents) {
        switch (event) {
            case AuthProviderDetailsButtonEvents.Activate:
                return this.activate();
            case AuthProviderDetailsButtonEvents.Deactivate:
                return this.deactivate();
            case AuthProviderDetailsButtonEvents.Delete:
                return this.delete();
            default:
                return;
        }
    }

    private async createProviderWithoutManualRegistration(tenant: string, id: string) {
        const type = id as AuthProvider;
        const details: AuthProviderDetails = {
            isActive: true,
            type,
            tenant
        };

        return this.ucAuthProviders.save(details);
    }

    private async activate() {
        try {
            const identity = await this.ucAuthProviders.get(this.id);
            identity.isActive = true;
            await this.ucAuthProviders.save(identity);

            this.builderHeaderService.notify.next({
                level: MessageLevel.Success,
                message: 'Provider activated successfully'
            });

            this.form.get(DetailsControlKeys.IsActive)?.setValue(true);
        } catch (e) {
            this.builderHeaderService.notify.next({
                level: MessageLevel.Error,
                message: e.message ?? 'Error activating provider'
            });
        }
    }

    private async deactivate() {
        try {
            const identity = await this.ucAuthProviders.get(this.id);
            identity.isActive = false;
            await this.ucAuthProviders.save(identity);

            this.builderHeaderService.notify.next({
                level: MessageLevel.Success,
                message: 'Provider deactivated successfully'
            });

            this.form.get(DetailsControlKeys.IsActive)?.setValue(false);
        } catch (e) {
            this.builderHeaderService.notify.next({
                level: MessageLevel.Error,
                message: e.message ?? 'Error deactivating provider'
            });
        }
    }

    private async save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        try {
            const identity = this.authProviderController.toDataModel(this.form.getRawValue());
            const mappings = this.mappingsController.toDataModel(this.mapping);

            // needs to be updated with mappings controller
            identity.mappings = mappings;

            const updatedProvider = await this.ucAuthProviders.save(identity);

            this.builderHeaderService.notify.next({
                level: MessageLevel.Success,
                message: 'Provider saved successfully'
            });

            if (this.isNew) {
                this.id = updatedProvider.id + '';
                this.form.get(DetailsControlKeys.Id)?.setValue(this.id);
                this.form.get(DetailsControlKeys.Id)?.setValue(updatedProvider.id);
                this.router.navigate(['../', updatedProvider.id], { relativeTo: this.route });
            } else {
                this.router.navigate(['../'], { relativeTo: this.route });
            }

            this.builderHeaderService.config.edited = false;

        } catch (e) {
            this.builderHeaderService.notify.next({
                level: MessageLevel.Error,
                message: e.message ?? 'Error saving provider'
            });
        }


    }

    private async setupIdentityDetails() {
        if (!this.isNew) {
            this.authProvider = await this.ucAuthProviders.get(this.id);
        }
        const model = this.authProviderController.toFormModel(this.authProvider);
        this.form = this.authProviderController.buildRoot(this.type ?? this.authProvider?.type, model);
    }

    private async setupMappings(mappings?: AuthProviderMapping[]) {
        this.mapping = await this.mappingsController.toFormModel(this.id, mappings);
        this.mappingsForm = this.mappingsController.buildRoot(this.mapping);
    }

    private setupHeader() {
        this.builderHeaderService.init();
        this.builderHeaderService.buildConfig({
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.type ?? this.authProvider?.type]),
            cancelRoute: ['../'],
        });

        this.subscriptions.add(this.form.valueChanges.subscribe(_ => this.builderHeaderService.updateConfig({ edited: true })));
        // this.subscriptions.add(this.form.valueChanges.subscribe(_ => this.builderHeaderService.updateConfig({ edited: true })));
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(_ => this.save()));
    }
}