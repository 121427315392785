import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, HierarchyUnitProvider, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions
} from '@unifii/library/common';
import { AuthProvider, Dictionary, Error, HierarchyUnitWithPath } from '@unifii/sdk';

import { AuthProviderDetails, SystemRole, TenantSettings, UcAuthProviders, UcRoles } from 'client';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { AuthProviderMappingConditionType } from '../new-identity/models';

import { conditionDictionary, getMappingConditionLabels } from './auth-provider-helpers';
import { AuthProviderMappingModalComponent } from './auth-provider-mapping-modal.component';
import { AuthProviderMappingFormModel, AuthProviderMappingsController } from './auth-provider-mappings-controller';
import { ConditionControlKeys, ExtrasControlKeys, MappingsControlKeys } from './auth-provider-model';
import { AuthProvidersTableManager } from './auth-providers-table-manager';
import { AuthProviderClaimMappingFormModel, ClaimControlKeys, ClaimMappingController } from './claim-mapping-controller';
import { ClaimMappingModalComponent } from './claim-mapping-modal.component';
import { loadMappingUnits } from './identity-functions';


enum ControlKeys {
    ID = 'id',
    Type = 'type',
    Tenant = 'tenant',
    ClientId = 'clientId',
    IsActive = 'isActive',
    ClientSecret = 'clientSecret',
    SystemRolesMapping = 'systemRolesMapping',
    TenantRolesMapping = 'tenantRolesMapping',
    ClaimsMapping = 'claimsMapping',
    Mappings = 'mappings',
    ScimToken = 'scimToken',
    Extras = 'extras',
    ProviderLoginLabel = 'providerLoginLabel',
}

enum RoleControlKeys {
    Source = 'source',
    Targets = 'targets'
}

interface AuthProviderDetailsFormData extends Omit<AuthProviderDetails, 'claimsMapping' | 'mappings'> {
    claimsMapping: AuthProviderClaimMappingFormModel[];
    mappings: AuthProviderMappingFormModel[];
    authorizationServer: string;
}

@Component({
    templateUrl: './okta.html',
    styleUrls: ['./auth-providers.less']
})
export class OktaComponent implements OnInit, OnDestroy, EditData {

    readonly controlKeys = ControlKeys;
    readonly roleControlKeys = RoleControlKeys;
    readonly claimControlKeys = ClaimControlKeys;
    readonly mappingsControlKeys = MappingsControlKeys;
    readonly conditionControlKeys = ConditionControlKeys;
    readonly extrasControlKeys = ExtrasControlKeys;
    readonly conditionDictionary = conditionDictionary;
    readonly objectKeys = Object.keys;
    readonly getMappingConditionLabels = getMappingConditionLabels;

    tenant: TenantSettings;
    error: Error;
    edited = false;
    recentlyModified: boolean;

    filteredTenantRoles: string[];
    filteredSystemRoles: string[];

    form: UfControlGroup;

    loading = true;
    breadcrumbs: Breadcrumb[] = this.breadcrumbService.getBreadcrumbs(this.route, ['Okta']);

    private readonly authName: AuthProvider = AuthProvider.Okta;
    private tenantRoles: string[];
    private sourceClaims: string[] = [];
    private statusChangesSubscription: Subscription;

    private units: HierarchyUnitWithPath[];

    constructor(
        protected modalService: ModalService,
        private router: Router,
        private route: ActivatedRoute,
        private ucAuthProviders: UcAuthProviders,
        private toastService: ToastService,
        private context: ContextService,
        private ucRoles: UcRoles,
        private ufb: UfFormBuilder,
        private dialogs: DialogsService,
        private breadcrumbService: BreadcrumbService,
        private claimMappingController: ClaimMappingController,
        private mappingsController: AuthProviderMappingsController,
        @Inject(HierarchyUnitProvider) private hierarchyProvider: HierarchyUnitProvider,
        @Inject(TableContainerManager) private manager: AuthProvidersTableManager
    ) { }

    get details(): AuthProviderDetailsFormData {
        return this.form?.getRawValue();
    }

    get systemRolesControl(): UfControlArray | undefined {
        return this.form.get(ControlKeys.SystemRolesMapping) as UfControlArray | undefined;
    }

    get tenantRolesControl(): UfControlArray | undefined {
        return this.form.get(ControlKeys.TenantRolesMapping) as UfControlArray | undefined;
    }

    get claimsControl(): UfControlArray | undefined {
        return this.form.get(ControlKeys.ClaimsMapping) as UfControlArray | undefined;
    }

    get mappingsControl(): UfControlArray | undefined {
        return this.form.get(ControlKeys.Mappings) as UfControlArray | undefined;
    }

    get authorizationServerControl(): UfControl | undefined {
        return this.form.get(ControlKeys.Extras)?.get(ExtrasControlKeys.AuthorizationServer) as UfControl | undefined;
    }

    async ngOnInit() {

        this.tenant = this.context.tenantSettings as TenantSettings;
        const id = this.route.snapshot.params.id;

        try {
            // Load auth provider details
            const details: AuthProviderDetails = id ? await this.ucAuthProviders.get(id) : {
                isActive: false,
                type: this.authName
            };

            if (id) {
                this.tenantRoles = (await this.ucRoles.get()).map(r => r.name);
                this.sourceClaims = await this.getSourceClaims(id);

                // load units before mapping it
                this.units = await loadMappingUnits(details.mappings ?? [], this.hierarchyProvider);
            }

            this.form = this.ufb.group(this.getFormConfig(await this.toFormModel(details)));
            this.statusChangesSubscription = this.form.statusChanges.subscribe(() => this.edited = true);
        } catch (e) {
            console.error(e);
            this.error = { type: e.type, message: 'An error has occured' };
        }

        this.loading = false;
    }

    ngOnDestroy() {
        if (this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    async deactivate() {
        this.form.setSubmitted();
        if (!this.form.valid) {
            this.toastService.error('Check configuration');
            return;
        }

        if (!await this.dialogs.confirmSSODeactivate()) {
            return;
        }

        this.details.isActive = false;
        this.save(true);
    }

    async activate() {
        if (!this.valid) {
            this.toastService.error('Check configuration');
            return;
        }

        if (!await this.dialogs.confirmSSOActivate()) {
            return;
        }

        this.details.isActive = true;
        this.save(true);
    }

    async disconnect() {

        if (!await this.dialogs.confirmSSODisconnect()) {
            return;
        }

        await this.ucAuthProviders.delete(this.details.id as string);
        this.toastService.success('Disconnected');
        this.close();
    }

    async save(close = false) {
        if (!this.valid) {
            return;
        }

        const details = this.toDataModel(this.details);

        try {
            await this.ucAuthProviders.save(details);
            this.edited = false;
            this.toastService.success('Saved!');

            if (close) {
                this.close();
            }
        } catch (e) {
            this.error = e;
        }
    }

    addSystemRole() {
        this.systemRolesControl?.push(this.createRoleControl());
    }

    addTenantRole() {
        this.tenantRolesControl?.push(this.createRoleControl());
    }

    removeSystemRole(index: number) {
        this.systemRolesControl?.removeAt(index);
    }

    removeTenantRole(index: number) {
        this.tenantRolesControl?.removeAt(index);
    }

    findTenantRole(query?: string) {
        this.filteredTenantRoles = (query && query.trim().length) ?
            this.tenantRoles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...this.tenantRoles];
    }

    findSystemRoles(query?: string) {
        const roles = Object.keys(SystemRole)
            .filter(key => key !== SystemRole.SuperUser);

        this.filteredSystemRoles = (query && query.trim().length) ?
            roles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...roles];
    }

    async addClaim() {
        const newControl = await this.modalService.openLarge(ClaimMappingModalComponent, { control: this.claimMappingController.createClaimControl(), sourceClaims: this.sourceClaims });
        if (newControl != null) {
            this.claimsControl?.push(newControl);
        }
    }

    async editClaim(index: number) {
        const claimControl = this.claimsControl?.at(index);
        if (claimControl) {
            const newControl = await this.modalService.openLarge(ClaimMappingModalComponent, { control: claimControl, sourceClaims: this.sourceClaims });
            if (newControl) {
                this.claimsControl?.removeAt(index);
                this.claimsControl?.insert(index, newControl);
            }
        }
    }

    removeClaim(index: number, event: MouseEvent) {
        event.stopPropagation();
        this.claimsControl?.removeAt(index);
    }

    async addMapping() {
        if (this.mappingsControl == null || !this.details.id) {
            return;
        }

        const newMapping = await this.modalService.openMedium(AuthProviderMappingModalComponent, {
            mappingControl: this.mappingsController.createMappingControl(),
            sourceClaims: this.sourceClaims,
            providerId: this.details.id
        });
        if (newMapping != null) {
            this.mappingsControl.push(newMapping);
        }
    }

    removeMapping(index: number, event?: MouseEvent) {
        event?.stopPropagation();
        this.mappingsControl?.removeAt(index);
    }

    async editMapping(index: number, event?: MouseEvent) {
        event?.stopPropagation();

        if (this.mappingsControl == null || !this.details.id) {
            return;
        }

        // create a copy of the control to edit in a modal
        const mappingControl = await this.modalService.openMedium(AuthProviderMappingModalComponent, {
            mappingControl: this.mappingsControl.at(index) as UfControlGroup,
            sourceClaims: this.sourceClaims,
            providerId: this.details.id
        });

        // replace old control with new
        if (mappingControl) {
            this.mappingsControl.removeAt(index);
            this.mappingsControl.insert(index, mappingControl);
        }
    }

    changeMappingConditionType(index: number, conditionType: AuthProviderMappingConditionType) {
        const control = this.mappingsControl?.controls[index]?.get(MappingsControlKeys.Condition)?.get(ConditionControlKeys.Value);
        if (conditionType === AuthProviderMappingConditionType.GroupMembership) {
            control?.disable();
            control?.setValue(null);
        } else {
            control?.enable();
        }
    }

    close() {
        const backRoute = this.details.id ? ['../../'] : ['..'];
        this.manager.reload.next();
        this.router.navigate(backRoute, { relativeTo: this.route });
    }

    private get valid(): boolean {
        this.form.setSubmitted();
        return this.form.valid;
    }

    private async getSourceClaims(id: string) {
        try {
            return await this.ucAuthProviders.getAuthProviderClaims(id as string);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    private async toFormModel(details: AuthProviderDetails): Promise<AuthProviderDetailsFormData> {
        const formModel: any = Object.assign({}, details);

        formModel.systemRolesMapping = details.systemRolesMapping || [];
        formModel.tenantRolesMapping = details.tenantRolesMapping || [];
        formModel.claimsMapping = details.claimsMapping || [];

        const mappingsFormModel = [];
        for (const mapping of details.mappings || []) {
            mappingsFormModel.push(await this.mappingsController.toFormModel(details.id, mapping, this.units));
        }
        formModel.mappings = mappingsFormModel;

        const claimsMappingFormModel = [];
        for (const claim of details.claimsMapping || []) {
            claimsMappingFormModel.push(await this.claimMappingController.toFormModel(claim));
        }
        formModel.claimsMapping = claimsMappingFormModel;


        return formModel;
    }

    private toDataModel(details: AuthProviderDetailsFormData): AuthProviderDetails {
        const dataModel: AuthProviderDetails = Object.assign({}, details as any);

        if (details.claimsMapping?.length) {
            dataModel.claimsMapping = details.claimsMapping.map(mapping => this.claimMappingController.toDataModel(mapping));
        }

        if (details.mappings?.length) {
            dataModel.mappings = details.mappings.map(mapping => this.mappingsController.toDataModel(mapping));
        }

        return dataModel;
    }

    private getFormConfig(details: AuthProviderDetailsFormData): Dictionary<any> {
        const config: Dictionary<any> = {
            [ControlKeys.ID]: this.ufb.control({ value: details.id, disabled: true }),
            [ControlKeys.Type]: this.ufb.control({ value: details.type, disabled: true }),
            [ControlKeys.IsActive]: this.ufb.control({ value: details.isActive, disabled: true }),
            [ControlKeys.Tenant]: this.ufb.control(details.tenant, details.id ? undefined : ValidatorFunctions.required('Tenant name is required')),
            [ControlKeys.ClientId]: this.ufb.control(details.clientId, details.id ? undefined : ValidatorFunctions.required('Client Id is required')),
            [ControlKeys.ClientSecret]: this.ufb.control(details.clientSecret, ValidatorFunctions.required('Client Secret is required')),
            [ControlKeys.Extras]: this.ufb.group({
                [ExtrasControlKeys.AuthorizationServer]: this.ufb.control(details.extras?.authorizationServer)
            }),
            [ControlKeys.ScimToken]: this.ufb.control(details.scimToken),
            [ControlKeys.ProviderLoginLabel]: this.ufb.control(details.providerLoginLabel),
        };

        if (details.id) {
            config[ControlKeys.Mappings] = this.ufb.array(details.mappings.map(mapping => this.mappingsController.createMappingControl(mapping)));
            config[ControlKeys.SystemRolesMapping] = this.ufb.array((details.systemRolesMapping || []).map(role => this.createRoleControl(role)));
            config[ControlKeys.TenantRolesMapping] = this.ufb.array((details.tenantRolesMapping || []).map(role => this.createRoleControl(role)));
            config[ControlKeys.ClaimsMapping] = this.ufb.array(details.claimsMapping.map(claim => this.claimMappingController.createClaimControl(claim)));
        }
        return config;
    }

    private createRoleControl(data?: { source: string; targets: string[] }) {
        return this.ufb.group({
            [RoleControlKeys.Source]: this.ufb.control(data?.source, ValidatorFunctions.required('Group is required')),
            [RoleControlKeys.Targets]: this.ufb.control(data?.targets, ValidatorFunctions.required('Select a group'))
        });
    }

}
