import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Optional, ViewChild } from '@angular/core';
import { ExpanderComponent, ExpandersService, HelpModalComponent, ModalService, Scope } from '@unifii/library/common';
import { ComponentRegistry, FormField } from '@unifii/library/smart-forms';
import { CompoundType, FieldType } from '@unifii/sdk';

import { SystemRole } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { BuilderService } from '../compound-builder/builder.service';
import { MarkdownComponentRegistry } from '../markdown-component-registry';

import { ContentSettings } from './content';
import { ContentValue } from './content-value';


@Component({
    selector: 'uc-group-input',
    templateUrl: './group-input.html',
    providers: [{ provide: ComponentRegistry, useClass: MarkdownComponentRegistry }],
    styleUrls: ['./group-input.less'],
})
export class GroupInputComponent extends ContentValue implements FormField, AfterViewInit {

    @ViewChild('container', { static: true }) container: ElementRef;

    readonly fieldTypes = FieldType;

    content: any;
    contentChange: EventEmitter<any>;
    scope: Scope;
    icon: string;
    removeAuthorizedRoles: SystemRole[];
    draggable = true;

    private _expander: ExpanderComponent;

    constructor(
        builderService: BuilderService,
        dialogs: DialogsService,
        @Inject(ContentSettings) @Optional() contentSettings: ContentSettings,
        private cdr: ChangeDetectorRef,
        @Optional() private expanderService: ExpandersService
    ) {
        super(builderService, dialogs, contentSettings);
    }

    ngAfterViewInit() {

        this.builderService.fieldReady.next({ subject: this.field, atomic: true });
    }

    init() {

        const type = this.builderService.builder.type;

        this.icon = this.builderService.getFieldRef(this.field).icon;

        if (type !== CompoundType.Page) {
            this.removeAuthorizedRoles = [SystemRole.ProjectManager];
        }

        if (type === CompoundType.Collection) {
            this.draggable = false;
        }

        this.cdr.detectChanges();
    }

    @ViewChild(ExpanderComponent, { static: true }) set expander(v: ExpanderComponent) {

        if (!v) {
            return;
        }

        v.toggle = this.toggle;
        this._expander = v;
    }

    get expander(): ExpanderComponent {
        return this._expander;
    }

    private toggle = () => {

        if (this.expander.isExpanded) {
            this.expander.collapse();
            return;
        }

        this.expander.expand();
        this.expanderService.expandAll(this.container.nativeElement);

    };

}
