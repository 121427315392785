<uf-expander class="uc-group" [class.uc-group--draggable--no-shrink]="!disabled && draggable && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="!disabled && draggable && isSelected">
    <div class="uf-app-bar flat" [class.accent]="isSelected" (click)="select()" dragHandle expanderHeader>
        <uf-icon name="imageList"></uf-icon>
        <div class="title primary">
            <span>Image List</span>
            <span *ngIf="field.label"> | {{field.label}}</span>
        </div>
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning"></uf-icon>
        <uf-help class="uf-action tertiary" *ngIf="field.help" [content]="field.help"></uf-help>
        <a class="uf-action tertiary" *ngIf="canRemove" [restrict]="removeRestrictions" (click)="remove()"
            title="Delete">
            <uf-icon name="delete"></uf-icon>
        </a>
    </div>
    <div expanderBody class="uc-group-content" [class.content--accent]="isSelected">
        <uc-drag-list *ngIf="content" canDrop="false" canReorder="true" [items]="content" [parent]="content"
            (moved)="moved()">
            <ul class="uc-list--gap">
                <li class="row center-all" [class.uc-list-item--small--no-shrink--bordered]="disabled"
                    [class.uc-list-item--draggable--small--no-shrink]="!disabled"
                    *ngFor="let item of content, let i = index" dragItem [dragDisabled]="disabled">
                    <div class="shrink">
                        <img class="thumb" [src]="item.url + '?w=80&h=80&mode=crop'" alt="item.title">
                    </div>
                    <div class="list-content">
                        <div class="list-context">
                            <a class="uf-action tertiary" [routerLink]="['/assets', 'media',item.id]" target="_blank"
                                title="Open">
                                <uf-icon name="open" class=""></uf-icon>
                            </a>
                            <button class="uf-action tertiary" *ngIf="canEdit && canCrop" (click)="crop(item, i)"
                                title="Crop">
                                <uf-icon name="crop"></uf-icon>
                            </button>
                            <button class="uf-action tertiary" *ngIf="canDelete" (click)="delete(i)" title="Delete">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </div>
                        <div>{{item.title}}</div>
                        <div class="fieldset-item--x-small">
                            <span>Description: </span>
                            <span>{{item.description}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Original size: </span>
                            <span>{{item.width}}x{{item.height}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </uc-drag-list>
        <div class="row padded">
            <button class="uf-button x-small right" *ngIf="canAdd" (click)="add()">Add Image</button>
        </div>
    </div>
</uf-expander>
<uf-error [control]="control"></uf-error>