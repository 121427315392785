import { Dictionary, HierarchyUnitWithPath, Option } from '@unifii/sdk';

import { AuthProviderDetails, AuthProviderMappingAction, AuthProviderMappingActionType, AuthProviderSourceGroup } from 'client';


// Types

export enum AuthProviderMappingConditionType {
    GroupMembership = 'GroupMembership',
    ClaimValue = 'ClaimValue',
    And = 'And',
    Or = 'Or'
}

// Models

export interface AuthProviderMappingConditionFormModel {
    id?: string;
    type: AuthProviderMappingConditionType;
    identifier?: string;
    value?: string;
    children?: AuthProviderMappingConditionFormModel[];
    group?: AuthProviderSourceGroup;
}

export interface AuthProviderMappingActionModel extends AuthProviderMappingAction {
    unit?: HierarchyUnitWithPath;
}

// TODO Remove this interface here (Felipe)
export interface AuthProviderDetailsModel extends Omit<AuthProviderDetails, 'mappings'> {
    description?: string;
}

export interface AuthProviderMappingModel {
    id: string;
    condition: AuthProviderMappingConditionFormModel;
    actions: AuthProviderMappingActionModel[];
}

export interface AuthProviderMappingsModel {
    authProviderId: string;
    mappings: AuthProviderMappingModel[];
}

// Control Keys

export enum MappingsControlKeys {
    Condition = 'condition',
    Actions = 'actions',
    Id = 'id',
    Type = 'type',
    Identifier = 'identifier',
    Value = 'value',
    Children = 'children',
    Group = 'group',
    Mappings = 'mappings',
    Unit = 'unit',
    AuthProviderId = 'authProviderId',
}

export enum DetailsControlKeys {
    Id = 'id',
    Tenant = 'tenant',
    ClientId = 'clientId',
    ClientSecret = 'clientSecret',
    ScimToken = 'scimToken',
    ProviderLoginLabel = 'providerLoginLabel',
    IsActive = 'isActive',
    Manual = 'manual',
    Type = 'type',
    Extras = 'extras',
    Mappings = 'mappings',
    Audience = 'audience',
    ManualRegistration = 'manualRegistration',
    UseDirectory = 'useDirectory',
    AuthorizationServer = 'authorizationServer'
}

// Options

export const ConditionOptions: Option[] = [
    { identifier: AuthProviderMappingConditionType.GroupMembership, name: 'Group Membership' },
    { identifier: AuthProviderMappingConditionType.ClaimValue, name: 'Claim Value' },
    { identifier: AuthProviderMappingConditionType.And, name: 'And' },
    { identifier: AuthProviderMappingConditionType.Or, name: 'Or' },
];

export const ActionOptions: Option[] = [
    { identifier: AuthProviderMappingActionType.AssignUnit, name: 'Hierarchy Unit' },
    { identifier: AuthProviderMappingActionType.AssignClaim, name: 'Claim Value' },
    { identifier: AuthProviderMappingActionType.AssignRole, name: 'Role' },
    { identifier: AuthProviderMappingActionType.AssignSystemRole, name: 'System Role' },
];

// Descriptions

export const ConditionTypesDescription = {
    [AuthProviderMappingConditionType.And]: 'And',
    [AuthProviderMappingConditionType.ClaimValue]: 'Claim',
    [AuthProviderMappingConditionType.GroupMembership]: 'Group',
    [AuthProviderMappingConditionType.Or]: 'Or',
};

export const ActionsTypesDescription = {
    [AuthProviderMappingActionType.AssignUnit]: 'Hierarchy Unit',
    [AuthProviderMappingActionType.AssignClaim]: 'Claim',
    [AuthProviderMappingActionType.AssignSystemRole]: 'System Role',
    [AuthProviderMappingActionType.AssignRole]: 'Role',
    [AuthProviderMappingActionType.AssignClaimFrom]: 'Claim From',
};

export const AuthProviderTypeDescription: Dictionary<string> = {
    Azure: 'Azure',
    Auth0: 'Auth0',
    Okta: 'Okta'
};