<ng-container *ngIf="ready && node">

    <li *ngIf="!container" class="uc-list-item--draggable--no-shrink--small row center-all" [class.selected]="selected"
        (click)="select($event)">
        <uf-icon [name]="iconName"></uf-icon>
        <div class="node-label gap-left grow">{{ label }}</div>
        <uf-icon *ngIf="node?.variations?.length" name="variation"></uf-icon>
        <uf-icon *ngIf="!valid" name="warning" class="icon-warning"></uf-icon>
        <a class="uf-action tertiary" (click)="remove($event)" title="Delete">
            <uf-icon name="delete"></uf-icon>
        </a>
    </li>

    <li *ngIf="container">
        <uf-expander (click)="select($event)" [isExpanded]="expanded"
            class="uc-group uc-group--draggable--no-shrink grow"
            [class.uc-group--draggable--no-shrink--accent]="selected"
            [class.group-container]="node.type === fieldTypes.Group || node.type === fieldTypes.ActionGroup || node.type === fieldTypes.Repeat"
            [class.section-container]="node.type === fieldTypes.Section">
            <div expanderHeader class="uf-app-bar flat row clickable" [class.accent]="selected">
                <uf-icon [name]="iconName"></uf-icon>
                <div class="node-label title primary grow">{{label}}</div>
                <uf-icon *ngIf="node?.variations?.length" name="variation"></uf-icon>
                <uf-icon *ngIf="!valid" name="warning" class="icon-warning"></uf-icon>
                <a class="uf-action tertiary" (click)="remove($event)" title="Delete">
                    <uf-icon name="delete"></uf-icon>
                </a>
            </div>
            <div expanderBody class="uc-group-content" [class.content--accent]="selected">
                <uc-builder-nodes [parent]="node" [childrenProperty]="childrenProperty"></uc-builder-nodes>
            </div>
        </uf-expander>
    </li>

</ng-container>