<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="save(true)">Save</button>
</div>

<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <uf-blockquote *ngIf="error" [content]="error.message || 'Failed to load Okta provider'"
                class="col-12 error" icon="errorSolid">
            </uf-blockquote>

            <ng-container *ngIf="form" [formGroup]="form">

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Settings
                        </div>
                    </div>

                    <div class="uc-group-content uf-grid pad">

                        <ng-container *ngIf="!details.id">
                            <uf-text label="Tenant Name" [formControlName]="controlKeys.Tenant" class="col-12">
                            </uf-text>
                            <uf-text class="col-12" label="Client Id" [formControlName]="controlKeys.ClientId">
                            </uf-text>
                        </ng-container>

                        <uf-description-list *ngIf="details.id" class="col-12">
                            <dt>Tenant Name:</dt>
                            <dd>{{ details.tenant }}</dd>
                            <dt>Client ID:</dt>
                            <dd>{{ details.clientId }}</dd>
                            <dt>Status:</dt>
                            <dd>
                                <div class="uf-lozenge" [class.success]="details.isActive"
                                    [class.error]="!details.isActive">
                                    {{details.isActive ? 'Active' : 'Inactive'}}
                                </div>
                            </dd>
                        </uf-description-list>

                        <uf-text [formControlName]="controlKeys.ProviderLoginLabel" class="col-12"
                            label="Provider Login Label">
                        </uf-text>

                        <ng-container [formGroupName]="controlKeys.Extras">
                            <uf-text class="col-12" label="Authorization Server ID" *ngIf="authorizationServerControl"
                                [formControlName]="extrasControlKeys.AuthorizationServer">
                            </uf-text>
                        </ng-container>

                        <uc-secret-input class="col-12" label="Client Secret"
                            [formControlName]="controlKeys.ClientSecret">
                        </uc-secret-input>

                        <uc-secret-input class="col-12" label="SCIM Token" [formControlName]="controlKeys.ScimToken">
                        </uc-secret-input>

                        <div class="col-12 row space-children">
                            <button *ngIf="details.id" class="uf-button right"
                                (click)="disconnect()">Disconnect</button>
                            <button *ngIf="details.isActive" class="uf-button primary" [class.right]="!details.id"
                                (click)="deactivate()">Deactivate</button>
                            <button *ngIf="!details.isActive" class="uf-button primary"
                                (click)="activate()">Activate</button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="details.id">

                    <div class="col-12 uf-box">
                        <div class="uf-app-bar flat accent">
                            <div class="title">Mapping</div>
                        </div>

                        <ng-container *ngIf="mappingsControl">
                            <div class="uf-app-bar">
                                <div class="title">Unit Mapping</div>
                            </div>
                            <div class="uf-grid pad">
                                <uf-blockquote *ngIf="mappingsControl.showError" icon="errorSolid"
                                    content="There are errors in your Unit Mapping" class="col-12 error">
                                </uf-blockquote>

                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-4">Mapping Type</th>
                                                <th>Claim/Group</th>
                                                <th class="table-col-4">Unifii Hierarchy Unit</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of (mappingsControl.controls || []) | asUfControlsGroups; let i = index"
                                                (click)="editMapping(i)" class="clickable">
                                                <td>
                                                    {{conditionDictionary[control.get(mappingsControlKeys.Condition)?.get(conditionControlKeys.Type)?.value]}}
                                                </td>
                                                <td>
                                                    <div *ngFor="let label of getMappingConditionLabels(control)">
                                                        {{ label }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        *ngFor="let unit of (control.get(mappingsControlKeys.Targets)?.value || [])">
                                                        {{ unit | hierarchyUnit }}
                                                    </div>
                                                </td>
                                                <td class="input-cell">
                                                    <div class="row center-all">
                                                        <button class="uf-action tertiary right"
                                                            (click)="editMapping(i, $event)" title="Edit">
                                                            <uf-icon name="edit"></uf-icon>
                                                        </button>
                                                        <button class="uf-action tertiary"
                                                            (click)="removeMapping(i, $event)" title="Delete">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!mappingsControl.controls.length">
                                                <td colspan="5" class="padded">
                                                    No mappings
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 row space-children">
                                    <button class="uf-button right" (click)="addMapping()">Add</button>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="claimsControl">
                            <div class="uf-app-bar">
                                <div class="title">Claims Mapping</div>
                            </div>

                            <div class="uf-grid pad">
                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-3">Okta Claim</th>
                                                <th class="table-col-3">Unifii Claim</th>
                                                <th>Values</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of claimsControl.controls | asUfControlsGroups; let i = index"
                                                [formGroup]="control" (click)="editClaim(i)" class="clickable">
                                                <td>
                                                    {{ (control.get(claimControlKeys.Source) | asSafeAbstractControl |
                                                    asUfControl).value }}
                                                </td>
                                                <td>
                                                    {{ (control.get(claimControlKeys.Target) | asSafeAbstractControl |
                                                    asUfControl).value?.type }}
                                                </td>
                                                <td>
                                                    <div
                                                        *ngIf="(control.get(claimControlKeys.Target) | asSafeAbstractControl | asUfControl).value">
                                                        <div
                                                            *ngFor="let claim of control.get(claimControlKeys.ValueMap)?.value">
                                                            {{ [claim.key, claim.value].join(': ') }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="input-cell">
                                                    <div class="row">
                                                        <button class="uf-action tertiary right" title="Edit">
                                                            <uf-icon name="edit"></uf-icon>
                                                        </button>
                                                        <button class="uf-action tertiary right"
                                                            (click)="removeClaim(i, $event)" title="Delete">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!claimsControl.controls.length">
                                                <td colspan="5" class="padded">
                                                    No claims mapped
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 row space-children">
                                    <button class="uf-button right" (click)="addClaim()">Add</button>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="tenantRolesControl">
                            <div class="uf-app-bar">
                                <div class="title">Company Roles Mapping</div>
                            </div>

                            <div class="uf-grid pad">
                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-3">Okta Role</th>
                                                <th>{{tenant.name}} Roles</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of tenantRolesControl.controls | asUfControlsGroups; let i = index"
                                                [formGroup]="control">
                                                <td class="input-cell">
                                                    <uf-text class="table-cell"
                                                        [formControlName]="roleControlKeys.Source">
                                                    </uf-text>
                                                <td class="input-cell">
                                                    <uf-chips class="table-cell"
                                                        [formControlName]="roleControlKeys.Targets"
                                                        [options]="filteredTenantRoles"
                                                        (searchChange)="findTenantRole($event)">
                                                    </uf-chips>
                                                </td>
                                                <td class="input-cell">
                                                    <div class="row">
                                                        <button class="uf-action tertiary right"
                                                            (click)="removeTenantRole(i)" title="Delete">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!tenantRolesControl.controls.length">
                                                <td colspan="3" class="padded">
                                                    No roles mapped
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-12 row space-children">
                                    <button class="uf-button right" (click)="addTenantRole()">Add</button>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="systemRolesControl">
                            <div class="uf-app-bar">
                                <div class="title">Unifii System Roles Mapping</div>
                            </div>

                            <div class="uf-grid pad">
                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-3">Okta Role</th>
                                                <th>Unifii Roles</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of systemRolesControl.controls | asUfControlsGroups; let i = index"
                                                [formGroup]="control">
                                                <td class="input-cell">
                                                    <uf-text class="table-cell"
                                                        [formControlName]="roleControlKeys.Source">
                                                    </uf-text>
                                                </td>
                                                <td class="input-cell">
                                                    <uf-chips class="table-cell"
                                                        [formControlName]="roleControlKeys.Targets"
                                                        [options]="filteredSystemRoles"
                                                        (searchChange)="findSystemRoles($event)">
                                                    </uf-chips>
                                                </td>
                                                <td class="input-cell">
                                                    <div class="row">
                                                        <button class="uf-action tertiary right"
                                                            (click)="removeSystemRole(i)" title="Delete">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!systemRolesControl.controls.length">
                                                <td colspan="3" class="padded">
                                                    No roles mapped
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 row space-children">
                                    <button class="uf-button right" (click)="addSystemRole()">Add</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <div class="col-12 pad-sm-top pad-sm-left form-error-message"
                    [style.visibility]="form.invalid && form.submitted ? 'visible' : 'hidden'">
                    Unable to proceed, please check errors above.
                </div>

            </ng-container>

            <div class="col-12" *ngIf="loading">
                <uf-spinner></uf-spinner>
            </div>
        </div>
    </div>
</uf-panel>