/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { CompoundType } from '@unifii/sdk';

import { SystemRole, UcProject } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';
import { ContentSettings } from 'components/content/content';
import { ContentList } from 'components/content/content-list';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from 'components/content/modals/link-search.component';

import { DialogsService } from 'services/dialogs.service';


@Component({
    templateUrl: './link-list.html',
    styleUrls: ['./group-input.less']
})
export class LinkListComponent extends ContentList implements FormField, AfterViewInit {

    searchConfig: LinkSearchConfig;
    draggable = true;

    constructor(
        @Inject(ContentSettings) @Optional() contentSettings: ContentSettings,
        modalService: ModalService,
        public builderService: BuilderService,
        private ucProject: UcProject,
        private cdr: ChangeDetectorRef,
        dialogs: DialogsService
    ) {
        super(LinkSearchComponent, modalService, builderService, contentSettings, dialogs);

        if (this.builderService.builder.type === CompoundType.Collection) {
            this.draggable = false;
        }

        this.subscribers.push(this.builderService.fieldReady.subscribe(() => {

            let name: string | null = null;
            if (this.builderService.collections && this.field.definitionIdentifier) {
                name = this.builderService.collections.find(c => c.identifier === this.field.definitionIdentifier).name;
            }

            this.searchConfig = {
                title: 'Add ' + name + ' Item',
                multiSelect: true,
                ucProject: this.ucProject,
                type: LinkSearchType.Compound,
                compoundType: CompoundType.Collection,
                types: [this.field.definitionIdentifier as string],
                ignore: this.getIgnoreList()
            };

            this.cdr.detectChanges();
        }));
    }

    protected getSearchConfig(): LinkSearchConfig {
        this.searchConfig.ignore = this.getIgnoreList();
        return this.searchConfig;
    }

    get collectionLabel(): string | null {
        if (this.field.label) {
            return this.field.label;
        }

        if (this.builderService.collections && this.field.definitionIdentifier) {
            return this.builderService.collections.find(c => c.identifier === this.field.definitionIdentifier).name;
        }

        return null;
    }

    get removeRestrictions(): string | null {

        if (this.builderService.builder.type === CompoundType.Page) {
            return null;
        }

        return SystemRole.ProjectManager;
    }

    getLink(link: any) {
        if (link && link.definitionIdentifier) {
            return ['/projects', this.ucProject.options.projectId, 'content', 'collections', link.definitionIdentifier, link.id];
        }
        return [''];
    }

    getIgnoreList(): string[] {
        return this.content && this.content.length ? this.content.map(item => item.id) : [];
    }

    protected convertResultToContent(item: any): any {
        return {
            id: item.id,
            recordName: item.name,
            type: item.definitionIdentifier,
            definitionIdentifier: item.definitionIdentifier
        };
    }
}
