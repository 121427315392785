<svg>
    <defs>

        <symbol id="collections">
            <path
                d="M12.55,13.05a3.65,3.65,0,0,1,1.81-.44A3.44,3.44,0,0,1,17,13.75l1.18-1.1a4.55,4.55,0,0,0-1.68-1.22,5.64,5.64,0,0,0-2.2-.42,5.54,5.54,0,0,0-2.68.65,4.81,4.81,0,0,0-1.89,1.8A5,5,0,0,0,9,16.05a4.94,4.94,0,0,0,.68,2.59,4.76,4.76,0,0,0,1.87,1.8,5.56,5.56,0,0,0,2.68.65,5.65,5.65,0,0,0,2.21-.42,4.51,4.51,0,0,0,1.69-1.22L17,18.33a3.39,3.39,0,0,1-2.62,1.16,3.65,3.65,0,0,1-1.81-.44,3.16,3.16,0,0,1-1.24-1.22,3.75,3.75,0,0,1,0-3.56A3.16,3.16,0,0,1,12.55,13.05Z" />
            <path
                d="M24,6.66H14.44l-.95-1.83a2,2,0,0,0-1.78-1.07H5.17A2,2,0,0,0,3.4,4.83L2.12,7.19A1,1,0,0,0,2,7.66V23.77a1,1,0,0,0,1,1H23a3,3,0,0,0,3-3V8.62A2,2,0,0,0,24,6.66ZM5.17,5.77h6.54l.95,1.83A2,2,0,0,0,13,8H4V7.92ZM23,22.77H4V9H24V21.77A1,1,0,0,1,23,22.77Z" />
        </symbol>

        <symbol id="collectionItem">
            <path
                d="M10.28,15.12c-.8-.43-1.42-1.03-1.88-1.8s-.68-1.63-.68-2.59,.23-1.83,.69-2.59c.46-.76,1.08-1.36,1.88-1.8,.8-.43,1.69-.65,2.68-.65,.8,0,1.54,.14,2.2,.42,.66,.28,1.22,.69,1.68,1.22l-1.18,1.11c-.71-.77-1.58-1.15-2.62-1.15-.67,0-1.27,.15-1.81,.44-.53,.29-.95,.7-1.25,1.22-.3,.52-.45,1.12-.45,1.78s.15,1.25,.45,1.78c.3,.52,.71,.93,1.25,1.23,.53,.29,1.13,.44,1.81,.44,1.04,0,1.91-.39,2.62-1.16l1.18,1.12c-.46,.53-1.02,.94-1.69,1.22-.67,.28-1.4,.42-2.21,.42-.99,0-1.88-.22-2.68-.65Z" />
            <path
                d="M19.61,23.76c-.18,.14-.4,.24-.65,.24H5.05c-.58,0-1.05-.45-1.05-1V5c0-.55,.47-1,1.05-1h13.91c.25,0,.47,.1,.65,.24,.18-.68,.58-1.26,1.13-1.66-.5-.36-1.12-.58-1.79-.58H5.05c-1.68,0-3.05,1.35-3.05,3V23c0,1.65,1.37,3,3.05,3h13.91c.67,0,1.28-.22,1.79-.58-.55-.4-.95-.98-1.13-1.66Z" />
            <g>
                <rect x="11.16" y="18" width="6" height="2" />
                <rect x="11.16" y="21" width="6" height="2" />
                <rect x="7.16" y="18" width="2" height="2" />
                <rect x="7.16" y="21" width="2" height="2" />
            </g>
            <path
                d="M25.96,3.77h-2.91c-1.13,0-2.04,.92-2.04,2.04v6.1c.43-.44,1-.73,1.64-.82V5.81c0-.22,.19-.41,.41-.41h2.91c.22,0,.41,.19,.41,.41V14.09c0,.22-.19,.41-.41,.41h-2.5v1.64h2.5c1.13,0,2.04-.92,2.04-2.04V5.81c0-1.13-.92-2.04-2.04-2.04Z" />
            <path
                d="M26.36,16.91v5.28c0,.22-.19,.41-.41,.41h-2.91c-.22,0-.41-.19-.41-.41V13.91c0-.22,.19-.41,.41-.41h2.49v-1.64h-2.49c-1.13,0-2.04,.92-2.04,2.04v8.28c0,1.13,.92,2.04,2.04,2.04h2.91c1.13,0,2.04-.92,2.04-2.04v-6.1c-.43,.44-1,.73-1.64,.82Z" />
        </symbol>

        <symbol id="collectionLink">
            <path
                d="M11.06,12.32c.53-.29,1.13-.44,1.81-.44,1.04,0,1.91,.38,2.62,1.15l1.18-1.11c-.46-.53-1.02-.94-1.68-1.22-.66-.28-1.4-.42-2.2-.42-.99,0-1.88,.22-2.68,.65s-1.43,1.03-1.88,1.8c-.46,.77-.69,1.63-.69,2.59s.23,1.83,.68,2.59c.45,.76,1.08,1.36,1.88,1.8,.8,.43,1.69,.65,2.68,.65,.8,0,1.54-.14,2.21-.42,.67-.28,1.23-.69,1.69-1.22l-1.18-1.12c-.71,.78-1.58,1.16-2.62,1.16-.67,0-1.27-.15-1.81-.44-.53-.29-.95-.7-1.25-1.22s-.45-1.12-.45-1.78,.15-1.25,.45-1.78,.71-.93,1.25-1.23Z" />
            <path
                d="M19.5,23v-.88H4.96c-.54,0-.98-.44-.98-.98V8.33h15.52v-2.31h-5.08l-.95-1.83c-.34-.65-1.01-1.06-1.76-1.06H5.17c-.75,0-1.42,.41-1.75,1.04l-1.28,2.36c-.08,.14-.12,.3-.12,.47v14.15c0,1.62,1.32,2.94,2.94,2.94h14.75c-.13-.33-.21-.7-.21-1.08ZM3.98,7.24l1.2-2.17h6.56l.95,1.83c.08,.15,.18,.29,.3,.42H3.98v-.08Z" />
            <path
                d="M25.96,3.77h-2.91c-1.13,0-2.04,.92-2.04,2.04v6.1c.43-.44,1-.73,1.64-.82V5.81c0-.22,.19-.41,.41-.41h2.91c.22,0,.41,.19,.41,.41V14.09c0,.22-.19,.41-.41,.41h-2.5v1.64h2.5c1.13,0,2.04-.92,2.04-2.04V5.81c0-1.13-.92-2.04-2.04-2.04Z" />
            <path
                d="M26.36,16.91v5.28c0,.22-.19,.41-.41,.41h-2.91c-.22,0-.41-.19-.41-.41V13.91c0-.22,.19-.41,.41-.41h2.49v-1.64h-2.49c-1.13,0-2.04,.92-2.04,2.04v8.28c0,1.13,.92,2.04,2.04,2.04h2.91c1.13,0,2.04-.92,2.04-2.04v-6.1c-.43,.44-1,.73-1.64,.82Z" />
        </symbol>

        <symbol id="dashboard">
            <path d="M18.68,8.73s-5.28,3.44-5.78,3.95a1.29,1.29,0,0,0,1.83,1.83C15.23,14,18.68,8.73,18.68,8.73Z" />
            <rect x="13.63" y="6.43" width="0.75" height="1.93" />
            <rect x="10.57" y="7.25" width="0.75" height="1.93" transform="translate(-2.64 6.58) rotate(-30.01)" />
            <rect x="8.34" y="9.48" width="0.75" height="1.93" transform="translate(-4.69 12.77) rotate(-59.99)" />
            <rect x="6.93" y="13.13" width="1.93" height="0.75" />
            <rect x="7.75" y="16.18" width="1.93" height="0.75" transform="translate(-7.11 6.57) rotate(-30)" />
            <rect x="9.98" y="18.41" width="1.93" height="0.75" transform="translate(-10.8 18.89) rotate(-60.03)" />
            <rect x="19.14" y="13.13" width="1.93" height="0.75" />
            <rect x="18.32" y="10.07" width="1.93" height="0.75" transform="translate(-2.64 11.05) rotate(-30)" />
            <rect x="16.09" y="7.84" width="1.93" height="0.75" transform="translate(1.42 18.88) rotate(-60.03)" />
            <path d="M2.2,17.62s1.66,2.53,1.9,2.78A.62.62,0,1,0,5,19.52C4.74,19.27,2.2,17.62,2.2,17.62Z" />
            <rect x="4.25" y="16.86" width="0.5" height="0.86" />
            <rect x="1.9" y="18.21" width="0.5" height="0.86" transform="translate(-15.07 11.18) rotate(-59.99)" />
            <rect x="1.72" y="21.11" width="0.86" height="0.5" transform="translate(-10.4 3.94) rotate(-30.02)" />
            <rect x="6.42" y="18.39" width="0.86" height="0.5" transform="translate(-8.41 5.93) rotate(-30.02)" />
            <path
                d="M23.76,15.53a9.72,9.72,0,0,0,.24-2,10,10,0,0,0-20,0,9.72,9.72,0,0,0,.24,2,4.49,4.49,0,1,0,4.34,6.35,9.87,9.87,0,0,0,10.85,0,4.49,4.49,0,1,0,4.33-6.34ZM4.5,23.5A3.5,3.5,0,1,1,8,20,3.5,3.5,0,0,1,4.5,23.5ZM9,19.71a4.5,4.5,0,0,0-2.59-3.79A7.76,7.76,0,0,1,6,13.5a8,8,0,0,1,16,0,7.82,7.82,0,0,1-.38,2.43A4.49,4.49,0,0,0,19,19.7,8,8,0,0,1,9,19.71ZM23.51,23.5A3.5,3.5,0,1,1,27,20,3.5,3.5,0,0,1,23.51,23.5Z" />
            <path d="M24.39,16.81S23.05,19.52,23,19.86a.62.62,0,0,0,1.21.31C24.25,19.83,24.39,16.81,24.39,16.81Z" />
            <rect x="23.25" y="16.86" width="0.5" height="0.86" />
            <rect x="20.9" y="18.21" width="0.5" height="0.86" transform="translate(-5.57 27.63) rotate(-59.99)" />
            <rect x="20.72" y="21.11" width="0.86" height="0.5" transform="translate(-7.85 13.45) rotate(-30.02)" />
            <rect x="25.42" y="18.39" width="0.86" height="0.5" transform="translate(-5.86 15.43) rotate(-30.02)" />
        </symbol>

        <symbol id="emptyNode">
            <path
                d="M26,11c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V11z M24,16H6v-4h18V16z" />
        </symbol>

        <symbol id="event">
            <path
                d="M22,4H19V3a1,1,0,0,0-2,0V4H11V3A1,1,0,0,0,9,3V4H6A3,3,0,0,0,3,7V23a3,3,0,0,0,3,3H22a3,3,0,0,0,3-3V7A3,3,0,0,0,22,4Zm1,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V7A1,1,0,0,1,6,6H9V7a1,1,0,0,0,2,0V6h6V7a1,1,0,0,0,2,0V6h3a1,1,0,0,1,1,1Z" />
            <path d="M10.52,19.73a1.5,1.5,0,0,1,2.2-2.05L13.9,19l.1-.12V15H8v6h3.75Z" />
            <path
                d="M14,22.24a1,1,0,0,1-.73-.32l-2.37-2.53A1,1,0,1,1,12.35,18l1.58,1.69,6.3-7.83a1,1,0,0,1,1.56,1.26l-7,8.73a1,1,0,0,1-.74.37Z" />
        </symbol>

        <symbol id="externalView">
            <path
                d="M24,2.5H4a3,3,0,0,0-3,3v17a3,3,0,0,0,3,3H24a3,3,0,0,0,3-3V5.5A3,3,0,0,0,24,2.5ZM4,4.5H24a1,1,0,0,1,1,1v1H3v-1A1,1,0,0,1,4,4.5Zm20,19H4a1,1,0,0,1-1-1V7.5H25v15A1,1,0,0,1,24,23.5Z" />
            <path
                d="M16.17,9.53a.5.5,0,0,0-.64.3l-4,11a.5.5,0,0,0,.3.64.5.5,0,0,0,.64-.3l4-11A.5.5,0,0,0,16.17,9.53Z" />
            <path
                d="M18.85,11.15a.49.49,0,0,0-.7.7l3.64,3.65-3.64,3.65a.48.48,0,0,0,0,.7.48.48,0,0,0,.7,0l4-4a.48.48,0,0,0,0-.7Z" />
            <path
                d="M9.85,11.15a.48.48,0,0,0-.7,0l-4,4a.48.48,0,0,0,0,.7l4,4a.48.48,0,0,0,.7,0,.48.48,0,0,0,0-.7L6.21,15.5l3.64-3.65A.48.48,0,0,0,9.85,11.15Z" />
        </symbol>

        <symbol id="formBucket">
            <rect x="13" y="19" width="6" height="2" />
            <rect x="9" y="19" width="2" height="2" />
            <path d="M11.18,9.36v3h4.73v1.54H11.18v3.75H9.36V7.83h7.15V9.36Z" />
            <path
                d="M20.63,25H7.37A2.33,2.33,0,0,1,5,22.71L4.07,7H3A1,1,0,0,1,3,5H6L7,22.65a.35.35,0,0,0,.36.35H20.63a.34.34,0,0,0,.36-.29L22,5h3a1,1,0,0,1,0,2H23.93L23,22.77A2.31,2.31,0,0,1,20.63,25Z" />
        </symbol>

        <symbol id="formLink">
            <g>
                <rect x="13" y="19" width="6" height="2" />
                <rect x="9" y="19" width="2" height="2" />
            </g>
            <path d="M10.89,8.36v2.98h4.73v1.54h-4.73v3.75h-1.82V6.83h7.15v1.53h-5.33Z" />
            <path
                d="M19.68,24H7c-.55,0-1-.45-1-1V5c0-.55,.45-1,1-1h9.21l3.29,3.93v-2.93c0-.06,.01-.11,.02-.17l-2.07-2.47c-.19-.23-.47-.36-.77-.36H7c-1.65,0-3,1.35-3,3V23c0,1.65,1.35,3,3,3h14c.26,0,.51-.04,.75-.11-.97-.25-1.74-.96-2.07-1.89Z" />
            <path
                d="M25.96,3.77h-2.91c-1.13,0-2.04,.92-2.04,2.04v6.1c.43-.44,1-.73,1.64-.82V5.81c0-.22,.19-.41,.41-.41h2.91c.22,0,.41,.19,.41,.41V14.09c0,.22-.19,.41-.41,.41h-2.5v1.64h2.5c1.13,0,2.04-.92,2.04-2.04V5.81c0-1.13-.92-2.04-2.04-2.04Z" />
            <path
                d="M26.36,16.91v5.28c0,.22-.19,.41-.41,.41h-2.91c-.22,0-.41-.19-.41-.41V13.91c0-.22,.19-.41,.41-.41h2.49v-1.64h-2.49c-1.13,0-2.04,.92-2.04,2.04v8.28c0,1.13,.92,2.04,2.04,2.04h2.91c1.13,0,2.04-.92,2.04-2.04v-6.1c-.43,.44-1,.73-1.64,.82Z" />
        </symbol>

        <symbol id="forms">
            <path
                d="M21.68,26H6.41A2.35,2.35,0,0,1,4,23.67V4.33A2.35,2.35,0,0,1,6.41,2H16.68a1,1,0,0,1,.77.36l6.36,7.56a1,1,0,0,1,.23.64V23.67A2.35,2.35,0,0,1,21.68,26ZM6.41,4A.36.36,0,0,0,6,4.33V23.67a.36.36,0,0,0,.37.33H21.68a.35.35,0,0,0,.36-.33V10.92L16.21,4Z" />
            <rect x="13" y="19" width="6" height="2" />
            <rect x="9" y="19" width="2" height="2" />
            <path d="M11.18,9.36v3h4.73v1.54H11.18v3.75H9.36V7.83h7.15V9.36Z" />
        </symbol>

        <symbol id="grid">
            <path
                d="M12.5,13.06h-9a.56.56,0,0,1-.56-.56v-9a.56.56,0,0,1,.56-.56h9a.56.56,0,0,1,.56.56v9A.56.56,0,0,1,12.5,13.06ZM4.06,11.94h7.88V4.06H4.06Z" />
            <path
                d="M24.5,13.06h-9a.56.56,0,0,1-.56-.56v-9a.56.56,0,0,1,.56-.56h9a.56.56,0,0,1,.56.56v9A.56.56,0,0,1,24.5,13.06Zm-8.44-1.12h7.88V4.06H16.06Z" />
            <path
                d="M12.5,25.06h-9a.56.56,0,0,1-.56-.56v-9a.56.56,0,0,1,.56-.56h9a.56.56,0,0,1,.56.56v9A.56.56,0,0,1,12.5,25.06ZM4.06,23.94h7.88V16.06H4.06Z" />
            <path
                d="M24.5,25.06h-9a.56.56,0,0,1-.56-.56v-9a.56.56,0,0,1,.56-.56h9a.56.56,0,0,1,.56.56v9A.56.56,0,0,1,24.5,25.06Zm-8.44-1.12h7.88V16.06H16.06Z" />
        </symbol>

        <symbol id="group">
            <path
                d="M23.99,4H4.01c-1.11,0-2.01,.9-2.01,2.01v15.98c0,1.11,.9,2.01,2.01,2.01H23.99c1.11,0,2.01-.9,2.01-2.01V6.01c0-1.11-.9-2.01-2.01-2.01ZM4,21.99V8H24v14H4Z" />
            <path d="M21.5,10.5H6.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h15c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Z" />
            <path d="M21.5,14.5H6.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h15c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Z" />
            <path d="M21.5,18.5H6.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h15c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Z" />
        </symbol>

        <symbol id="groupAction">
            <g>
                <path d="M21.5,15.5H6.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h15c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Z" />
                <path d="M21.5,19.5H6.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h15c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Z" />
                <path
                    d="M27,2.5h-6c-.55,0-1,.45-1,1s.45,1,1,1h3.96l-4.27,4.27c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l3.9-3.9v3.21c0,.55,.45,1,1,1s1-.45,1-1V3.5c0-.55-.45-1-1-1Z" />
                <path d="M21.5,11.5H6.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h15c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Z" />
            </g>
            <path
                d="M24,13v10H4V9.01h14V5.01H4.01c-1.11,0-2.01,.89-2.01,2v15.98c0,1.11,.9,2.01,2.01,2.01H23.99c1.11,0,2.01-.9,2.01-2.01V13h-2Z" />
        </symbol>

        <symbol id="groupRepeat">
            <path d="M21.5,12.5H6.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h15c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" />
            <path d="M21.5,16.5H6.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h15c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" />
            <path d="M21.5,20.5H6.5c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h15c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" />
            <g>
                <path
                    d="M24,13.03v9.97H4V9.01h14V5.01H4.01c-1.11,0-2.01,.89-2.01,2v15.98c0,1.11,.9,2.01,2.01,2.01H23.99c1.11,0,2.01-.9,2.01-2.01V13.03h-2Z" />
                <path
                    d="M27,6.01h-2.5V3.51c0-.55-.45-1-1-1s-1,.45-1,1v2.5h-2.5c-.55,0-1,.45-1,1s.45,1,1,1h2.5v2.5c0,.55,.45,1,1,1s1-.45,1-1v-2.5h2.5c.55,0,1-.45,1-1s-.45-1-1-1Z" />
            </g>
        </symbol>

        <symbol id="heading">
            <path d="M25,18H3a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H25a1,1,0,0,1,1,1v6A1,1,0,0,1,25,18ZM4,16H24V12H4Z" />
            <rect x="4" y="12" width="2" height="4" />
        </symbol>

        <symbol id="import">
            <path
                d="M24,20.83A1.19,1.19,0,0,1,22.79,22H5.21A1.19,1.19,0,0,1,4,20.83V9.5H9v-1H4V7.17A1.19,1.19,0,0,1,5.21,6H18V4H5.21A3.19,3.19,0,0,0,2,7.17V20.83A3.19,3.19,0,0,0,5.21,24H22.79A3.19,3.19,0,0,0,26,20.83V11H24Z" />
            <path
                d="M26.71,1.29a1,1,0,0,0-1.42,0L13,13.59V8.5a1,1,0,0,0-2,0V16a1,1,0,0,0,.08.38,1,1,0,0,0,.54.54A1,1,0,0,0,12,17h7.5a1,1,0,0,0,0-2H14.41L26.71,2.71A1,1,0,0,0,26.71,1.29Z" />
        </symbol>

        <symbol id="lab">
            <path
                d="M24.42,20.32,17.78,10V5H19a1,1,0,0,0,0-2H16.78a1,1,0,0,0-1,1v6.25a.93.93,0,0,0,.16.54l4.94,7.71H7.13l4.93-7.71a.93.93,0,0,0,.16-.54V4a1,1,0,0,0-1-1H9A1,1,0,0,0,9,5h1.22v5L3.55,20.37a3.17,3.17,0,0,0-.2,3.2A3.19,3.19,0,0,0,6.26,25H21.74a3.19,3.19,0,0,0,2.91-1.43A3.21,3.21,0,0,0,24.42,20.32Zm-1.5,2.25a1.35,1.35,0,0,1-1.18.43H6.26a1.35,1.35,0,0,1-1.18-.43,1.25,1.25,0,0,1,.18-1.17l1.22-1.9h15l1.19,1.86A1.28,1.28,0,0,1,22.92,22.57Z" />
            <path
                d="M14.06,16.22a2.17,2.17,0,1,0,2.16-2.16A2.17,2.17,0,0,0,14.06,16.22Zm2.16-1.16a1.17,1.17,0,1,1-1.16,1.16A1.16,1.16,0,0,1,16.22,15.06Z" />
            <path
                d="M14,14.5a1.61,1.61,0,1,0-1.61-1.61A1.61,1.61,0,0,0,14,14.5Zm0-2.22a.61.61,0,1,1-.61.61A.61.61,0,0,1,14,12.28Z" />
            <path
                d="M11.22,19.61a1.61,1.61,0,1,0,1.61,1.61A1.61,1.61,0,0,0,11.22,19.61Zm0,2.22a.61.61,0,0,1-.61-.61.62.62,0,0,1,.61-.61.61.61,0,0,1,.61.61A.61.61,0,0,1,11.22,21.83Z" />
        </symbol>

        <symbol id="pageLink">
            <path
                d="M16.49,10.41c-.63-.28-1.37-.42-2.22-.42h-4.03v9.8h1.82v-2.83h2.21c.85,0,1.59-.14,2.22-.42,.63-.28,1.11-.68,1.45-1.21s.5-1.15,.5-1.85-.17-1.34-.5-1.86-.82-.92-1.45-1.2Zm-.5,4.51c-.41,.34-1.01,.5-1.81,.5h-2.13v-3.89h2.13c.79,0,1.4,.17,1.81,.5,.41,.34,.62,.82,.62,1.44s-.21,1.11-.62,1.44Z" />
            <path
                d="M19.68,24H7c-.55,0-1-.45-1-1V6.41h13.5v-1H6v-.41c0-.55,.45-1,1-1h12.69c.33-.93,1.1-1.64,2.06-1.89-.24-.06-.49-.11-.75-.11H7c-1.65,0-3,1.35-3,3V23c0,1.65,1.35,3,3,3h14c.26,0,.51-.04,.75-.11-.96-.25-1.73-.96-2.06-1.89Z" />
            <path
                d="M25.96,3.77h-2.91c-1.13,0-2.04,.92-2.04,2.04v6.1c.43-.44,1-.73,1.64-.82V5.81c0-.22,.19-.41,.41-.41h2.91c.22,0,.41,.19,.41,.41V14.09c0,.22-.19,.41-.41,.41h-2.5v1.64h2.5c1.13,0,2.04-.92,2.04-2.04V5.81c0-1.13-.92-2.04-2.04-2.04Z" />
            <path
                d="M26.36,16.91v5.28c0,.22-.19,.41-.41,.41h-2.91c-.22,0-.41-.19-.41-.41V13.91c0-.22,.19-.41,.41-.41h2.49v-1.64h-2.49c-1.13,0-2.04,.92-2.04,2.04v8.28c0,1.13,.92,2.04,2.04,2.04h2.91c1.13,0,2.04-.92,2.04-2.04v-6.1c-.43,.44-1,.73-1.64,.82Z" />
        </symbol>

        <symbol id="pages">
            <path
                d="M16.74,10.41A5.46,5.46,0,0,0,14.52,10h-4v9.8H12.3V17h2.22a5.46,5.46,0,0,0,2.22-.42,3.14,3.14,0,0,0,1.44-1.21,3.34,3.34,0,0,0,.51-1.85,3.38,3.38,0,0,0-.51-1.87A3.18,3.18,0,0,0,16.74,10.41Zm-.5,4.51a2.83,2.83,0,0,1-1.81.5H12.3V11.53h2.13a2.83,2.83,0,0,1,1.81.5,1.77,1.77,0,0,1,.61,1.45A1.76,1.76,0,0,1,16.24,14.92Z" />
            <path
                d="M21,2H7A3,3,0,0,0,4,5V23a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V5A3,3,0,0,0,21,2ZM7,4H21a1,1,0,0,1,1,1v.41H6V5A1,1,0,0,1,7,4ZM21,24H7a1,1,0,0,1-1-1V6.41H22V23A1,1,0,0,1,21,24Z" />
        </symbol>

        <symbol id="permissions">
            <defs>
                <style>
                    .cls-ufIconOpacity30percent {
                        opacity: 0.3;
                    }
                </style>
            </defs>
            <g class="cls-ufIconOpacity30percent">
                <path
                    d="M27.5,17h-1V14a1.36,1.36,0,0,0-1.35-1.37H22.41l-1,1.81a.52.52,0,0,1-.88,0l-1-1.81H16.85A1.36,1.36,0,0,0,15.5,14V17h-1V14a2.36,2.36,0,0,1,2.35-2.37h3a.48.48,0,0,1,.44.27L21,13.1l.67-1.24a.48.48,0,0,1,.44-.27h3A2.36,2.36,0,0,1,27.5,14Z" />
            </g>
            <g class="cls-ufIconOpacity30percent">
                <path
                    d="M21,10.64A3.65,3.65,0,1,1,24.64,7,3.65,3.65,0,0,1,21,10.64Zm0-6.29A2.65,2.65,0,1,0,23.64,7,2.64,2.64,0,0,0,21,4.35Z" />
            </g>
            <path
                d="M11.65,11.59h-3a.48.48,0,0,0-.44.27L7.5,13.1l-.67-1.24a.48.48,0,0,0-.44-.27h-3A2.36,2.36,0,0,0,1,14V17H2V14a1.36,1.36,0,0,1,1.35-1.37H6.09l1,1.81a.52.52,0,0,0,.88,0l1-1.81h2.74A1.36,1.36,0,0,1,13,14V17h1V14A2.36,2.36,0,0,0,11.65,11.59Z" />
            <path
                d="M7.5,10.64A3.65,3.65,0,1,0,3.86,7,3.65,3.65,0,0,0,7.5,10.64Zm0-6.29A2.65,2.65,0,1,1,4.86,7,2.64,2.64,0,0,1,7.5,4.35Z" />
            <path
                d="M22.37,20,25,17.18a1,1,0,1,0-1.46-1.36L21,18.54l-2.55-2.72A1,1,0,1,0,17,17.18L19.63,20,17,22.82a1,1,0,0,0,.73,1.68,1,1,0,0,0,.73-.32L21,21.46l2.55,2.72a1,1,0,0,0,.73.32A1,1,0,0,0,25,22.82Z" />
            <path
                d="M10.76,15.82,5.11,22,3.24,20a1,1,0,0,0-1.42-.06,1,1,0,0,0-.06,1.41l2.52,2.75a1.07,1.07,0,0,0,.13.19,1.05,1.05,0,0,0,.68.26h0a1,1,0,0,0,.67-.26,1.15,1.15,0,0,0,.14-.19l6.3-6.87a1,1,0,0,0-1.48-1.36Z" />
        </symbol>

        <symbol id="projects">
            <path
                d="M12.48,11.57a5.41,5.41,0,0,0-2.21-.42h-4V21H8.05V18.12h2.22a5.41,5.41,0,0,0,2.21-.42,3.16,3.16,0,0,0,1.45-1.21,3.34,3.34,0,0,0,.51-1.85,3.38,3.38,0,0,0-.51-1.87A3.21,3.21,0,0,0,12.48,11.57ZM12,16.08a2.83,2.83,0,0,1-1.81.5H8.05V12.69h2.13a2.83,2.83,0,0,1,1.81.5,1.77,1.77,0,0,1,.61,1.45A1.76,1.76,0,0,1,12,16.08Z" />
            <rect x="18" y="15" width="3" height="2" />
            <rect x="18" y="11" width="3" height="2" />
            <rect x="18" y="19" width="3" height="2" />
            <path
                d="M24,6.66H14.44l-.95-1.83a2,2,0,0,0-1.78-1.07H5.17a2,2,0,0,0-1.8,1.1L2.1,7.57A.94.94,0,0,0,2,8V21.77a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V8.62A2,2,0,0,0,24,6.66Zm-12.29-.9.95,1.83A2,2,0,0,0,13,8H4.11L5.17,5.77ZM23,22.77H5a1,1,0,0,1-1-1V9H24V21.77A1,1,0,0,1,23,22.77Z" />
        </symbol>

        <symbol id="publish">
            <path
                d="M23,21.83A1.19,1.19,0,0,1,21.79,23H4.21A1.19,1.19,0,0,1,3,21.83V10.5h9.42v-1H3V8.17A1.19,1.19,0,0,1,4.21,7H14V5H4.21A3.19,3.19,0,0,0,1,8.17V21.83A3.19,3.19,0,0,0,4.21,25H21.79A3.19,3.19,0,0,0,25,21.83V14H23Z" />
            <path
                d="M25.92,2.62a1,1,0,0,0-.54-.54A1,1,0,0,0,25,2H17.5a1,1,0,0,0,0,2h5.09L12.29,14.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L24,5.41V10.5a1,1,0,0,0,2,0V3A1,1,0,0,0,25.92,2.62Z" />
        </symbol>

        <symbol id="required">
            <path
                d="M22,12.5H17.62l3.1-3.1A1.5,1.5,0,1,0,18.6,7.28l-3.1,3.1V6a1.5,1.5,0,0,0-3,0v4.38L9.4,7.28A1.5,1.5,0,1,0,7.28,9.4l3.1,3.1H6a1.5,1.5,0,0,0,0,3h4.38l-3.1,3.1a1.51,1.51,0,0,0,0,2.12,1.53,1.53,0,0,0,1.06.44,1.49,1.49,0,0,0,1.06-.44l3.1-3.1V22a1.5,1.5,0,0,0,3,0V17.62l3.1,3.1a1.49,1.49,0,0,0,1.06.44,1.53,1.53,0,0,0,1.06-.44,1.51,1.51,0,0,0,0-2.12l-3.1-3.1H22a1.5,1.5,0,0,0,0-3Z" />
        </symbol>

        <symbol id="structure">
            <rect x="12" y="3" width="4" height="4" rx="0.78" />
            <rect x="12" y="21.5" width="4" height="4" rx="0.78" />
            <rect x="2.5" y="21.5" width="4" height="4" rx="0.78" />
            <rect x="21.5" y="21.5" width="4" height="4" rx="0.78" />
            <path d="M14,20a1,1,0,0,1-1-1V10a1,1,0,0,1,2,0v9A1,1,0,0,1,14,20Z" />
            <path
                d="M23.5,19.67a.5.5,0,0,1-.5-.5V14.35c0-.48-.26-.85-.5-.85H5.5c-.24,0-.5.37-.5.85v4.82a.5.5,0,0,1-1,0V14.35A1.68,1.68,0,0,1,5.5,12.5h17A1.68,1.68,0,0,1,24,14.35v4.82A.5.5,0,0,1,23.5,19.67Z" />
            <rect x="11.5" y="2" width="5" height="5" rx="0.78" />
            <rect x="11.5" y="21.5" width="5" height="5" rx="0.78" />
            <rect x="2" y="21.5" width="5" height="5" rx="0.78" />
            <rect x="21" y="21.5" width="5" height="5" rx="0.78" />
            <path
                d="M4.5,20.17a1,1,0,0,0,1-1V14.35A.65.65,0,0,1,5.59,14H13v5a1,1,0,0,0,2,0V14h7.41a.65.65,0,0,1,.09.35v4.82a1,1,0,0,0,2,0V14.35a2.17,2.17,0,0,0-2-2.35H15V10a1,1,0,0,0-2,0v2H5.5a2.17,2.17,0,0,0-2,2.35v4.82A1,1,0,0,0,4.5,20.17Z" />
        </symbol>

        <symbol id="unpublished">
            <g opacity=".5">
                <path d="M24.59,14.59c.13-.13,.27-.22,.41-.31v-.28h-2v2.17l1.59-1.59Z" />
                <path
                    d="M14.59,24.59l1.59-1.59H4.21c-.67,0-1.21-.53-1.21-1.17V10.5H12.42v-1H3v-1.33c0-.65,.54-1.17,1.21-1.17H14v-2H4.21c-1.77,0-3.21,1.42-3.21,3.17v13.66c0,1.75,1.44,3.17,3.21,3.17H14.28c.09-.15,.18-.29,.31-.41Z" />
            </g>
            <path
                d="M22.41,20.56l4.29-4.29c.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0l-4.29,4.29-4.29-4.29c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l4.29,4.29-4.29,4.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l4.29-4.29,4.29,4.29c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29c.39-.39,.39-1.02,0-1.41l-4.29-4.29Z" />
            <path
                d="M25.92,2.62c-.1-.24-.3-.44-.54-.54-.12-.05-.25-.08-.38-.08h-7.5c-.55,0-1,.45-1,1s.45,1,1,1h5.09L12.29,14.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29L24,5.41v5.09c0,.55,.45,1,1,1s1-.45,1-1V3c0-.13-.03-.26-.08-.38Z"
                opacity=".5" />
        </symbol>

        <symbol id="variation">
            <path
                d="M13,7H26a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V2.5H5a1,1,0,0,0-1,1V9.09L.29,12.79a1,1,0,0,0,0,1.42L4,17.91V23a1,1,0,0,0,1,1h7v1.5a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1v-5a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V22H6V17.91L9.41,14.5H12V16a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v1.5H9.41L6,9.09V4.5h6V6A1,1,0,0,0,13,7Zm1-5H25V5H14Zm0,19.5H25v3H14ZM14,12H25v3H14ZM5,16.09,2.41,13.5,5,10.91,7.59,13.5Z" />
        </symbol>

        <symbol id="viewLink">
            <polygon points="10.67 16.76 7.41 9.2 5.44 9.2 9.72 19 11.51 19 15.79 9.2 13.96 9.2 10.67 16.76" />
            <path
                d="M19.5,23v-1H5c-.55,0-1-.45-1-1V7c0-.55,.45-1,1-1h14.5v-1c0-.35,.07-.69,.18-1H5c-1.65,0-3,1.35-3,3v14c0,1.65,1.35,3,3,3h14.69c-.11-.31-.18-.65-.18-1Z" />
            <rect x="17" y="9" width="2.5" height="2" />
            <rect x="17" y="13" width="2.5" height="2" />
            <rect x="17" y="17" width="2.5" height="2" />
            <path
                d="M25.96,3.77h-2.91c-1.13,0-2.04,.92-2.04,2.04v6.1c.43-.44,1-.73,1.64-.82V5.81c0-.22,.19-.41,.41-.41h2.91c.22,0,.41,.19,.41,.41V14.09c0,.22-.19,.41-.41,.41h-2.5v1.64h2.5c1.13,0,2.04-.92,2.04-2.04V5.81c0-1.13-.92-2.04-2.04-2.04Z" />
            <path
                d="M26.36,16.91v5.28c0,.22-.19,.41-.41,.41h-2.91c-.22,0-.41-.19-.41-.41V13.91c0-.22,.19-.41,.41-.41h2.49v-1.64h-2.49c-1.13,0-2.04,.92-2.04,2.04v8.28c0,1.13,.92,2.04,2.04,2.04h2.91c1.13,0,2.04-.92,2.04-2.04v-6.1c-.43,.44-1,.73-1.64,.82Z" />
        </symbol>

        <symbol id="views">
            <path
                d="M23,24H5a3,3,0,0,1-3-3V7A3,3,0,0,1,5,4H23a3,3,0,0,1,3,3V21A3,3,0,0,1,23,24ZM5,6A1,1,0,0,0,4,7V21a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1Z" />
            <path d="M16.71,9.2,12.44,19H10.65L6.36,9.2h2l3.26,7.56L14.89,9.2Z" />
            <rect x="18" y="13" width="3" height="2" />
            <rect x="18" y="9" width="3" height="2" />
            <rect x="18" y="17" width="3" height="2" />
        </symbol>

        <symbol id="workflowActions">
            <title></title>
            <path
                d="M21.5,15V12.71a1.84,1.84,0,0,0,.91-.51L26.2,8.41a2,2,0,0,0,0-2.82L22.41,1.8a2,2,0,0,0-2.82,0L15.8,5.59a1.92,1.92,0,0,0-.51.91H13A6,6,0,1,0,6.5,13v2H3a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V17a2,2,0,0,0-2-2H7.5V13A6,6,0,0,0,13,7.5h2.32a1.84,1.84,0,0,0,.51.91l3.79,3.79a1.92,1.92,0,0,0,.91.51V15a6,6,0,1,0,1,0ZM11,25H3V17h8ZM7,11a4,4,0,1,1,4-4A4,4,0,0,1,7,11ZM24.79,7l.7.71L24.79,7ZM17.21,7,21,3.21,24.78,7,21,10.79ZM21,25a4,4,0,1,1,4-4A4,4,0,0,1,21,25Z" />
        </symbol>

    </defs>
</svg>