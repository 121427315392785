<ng-container *ngIf="ready">
    <uc-builder-header>
    </uc-builder-header>

    <div class="builder-content">
        <uc-field-reference-picker>
        </uc-field-reference-picker>

        <div class="builder-growing-section">
            <div class="field-list uf-box flat">
                <uc-builder-fields-header></uc-builder-fields-header>
                <div class="nodes-container scrollable grow" keySelect [entries]="definition.fields"
                    [current]="selected" (next)="selectField({ subject: $event, atomic: false })">
                    <uc-builder-nodes [parent]="definition" childrenProperty="fields" root="true">
                    </uc-builder-nodes>
                </div>
            </div>

            <div class="field-settings">
                <uf-tabs class="scrollable">
                    <uf-tab label="Details">
                        <div class="tab-content scrollable uf-box flat">
                            <uc-collection-settings *ngIf="!selected" [definition]="definition">
                            </uc-collection-settings>
                            <uc-field-builder [field]="selected" *ngIf="selected">
                            </uc-field-builder>
                        </div>
                    </uf-tab>
                    <uf-tab label="Preview" *ngIf="!selected">
                        <div class="tab-content scrollable uf-box flat">
                            <uf-field *ngFor="let field of definition.fields" [scope]="definition"
                                [field]="$any(field)">
                            </uf-field>
                        </div>
                    </uf-tab>
                </uf-tabs>
            </div>
        </div>
    </div>
</ng-container>