/* eslint-disable @typescript-eslint/member-ordering */
import { Subscription } from 'rxjs';

import { ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';

import { BuilderField } from 'client';

import { BuilderEventInfo, BuilderService } from '../compound-builder/builder.service';


export interface FieldAttributeConfig {
    show: boolean;
    control: UfControl;
    enabled?: boolean;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FieldDetailBasic implements OnInit, OnDestroy {

    @Output() edited = new EventEmitter<BuilderEventInfo>();
    @Output() refresh = new EventEmitter<BuilderEventInfo>();

    ready = false;
    configured = false;
    notifyEnabled = true;
    form: UfControlGroup;
    subscriptions = new Subscription();

    private _field: BuilderField;

    protected abstract setup(field: BuilderField): void;
    protected abstract update(): void;

    constructor(protected builderService: BuilderService, public elementName: string, protected ref: ChangeDetectorRef) {

        this.form = new UfControlGroup({});

        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            // console.log('FieldDetailBasic form.valueChanges');
            this.notifyRefresh();
            this.notifyEdited();
        }));

        this.subscriptions.add(this.builderService.submitted.subscribe(v => {
            this.form.setSubmitted(true);
        }));

        this.subscriptions.add(this.builderService.fieldEdited.subscribe(i => {
            if (this.isMe(i)) {
                this.notifyEnabled = true;
                // todo: remove timeout
                // needs time for change to be applied to field
                // before firing update
                setTimeout(() => {
                    this.update();
                }, 0);

            }
        }));
    }

    ngOnInit() {
        this.ready = true;
        this.ref.detectChanges();
    }

    ngOnDestroy() {
        this.ref.detach();
        this.subscriptions.unsubscribe();
    }

    get field(): BuilderField {
        return this._field;
    }

    @Input()
    set field(v: BuilderField) {
        if (v && v === this._field) {
            return;
        }

        this.notifyEnabled = false;
        // Force hide form and refresh component
        this.configured = false;
        this.ref.detectChanges();

        this._field = v;

        // Get data ready for autocomplete inputs
        this.setup(v);


        // Force show form and refresh component
        this.configured = true;
        this.ref.detectChanges();
        this.notifyEnabled = true;

        // Form is slow to update values after set of new field values
        setTimeout(() => {
            this.ref.detectChanges();
            this.notifyRefresh();

            if (this._field.isSubmitted) {
                this.form.setSubmitted(true);
            }

        }, 0);
    }

    notifyRefresh() {

        if (this.ready && this.notifyEnabled) {

            const errors: { message: string }[] = [];

            if (this.form.invalid) {
                errors.push(...this.builderService.getControlErrors(this.form).map(message => ({ message })));
            }

            this.refresh.emit({ subject: this.field, source: this.elementName, atomic: false, errors });
        }
    }

    notifyEdited() {
        if (this.ready && this.notifyEnabled) {
            // console.log('FieldDetailBasic notifyEdited', this.field, this.elementName);
            this.edited.emit({ subject: this.field, source: this.elementName, atomic: false });
        }
    }

    private isMe(i: BuilderEventInfo = { subject: null }): boolean {
        return i.subject != null && i.subject === this.field;
    }
}
