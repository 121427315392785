<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
</div>

<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <uf-blockquote *ngIf="error" [content]="error.message || 'Failed to load Auth0 details'"
                class="col-12 error" icon="errorSolid">
            </uf-blockquote>

            <ng-container *ngIf="details">

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Settings
                        </div>
                    </div>

                    <div class="uc-group-content uf-grid pad">

                        <ng-container *ngIf="!providerId">
                            <uf-text label="Tenant Name" [(value)]="details.tenant" class="col-12"
                                [control]="form.controls.tenant | asUfControl">
                            </uf-text>
                            <uf-text class="col-12" label="Client Id" [(value)]="details.clientId"
                                [control]="form.controls.clientId | asUfControl">
                            </uf-text>
                        </ng-container>

                        <uf-description-list *ngIf="providerId" class="col-12">
                            <dt>Tenant Name:</dt>
                            <dd>{{ details.tenant }}</dd>
                            <dt>Client ID:</dt>
                            <dd>{{ details.clientId }}</dd>
                            <dt>Status:</dt>
                            <dd>
                                <div class="uf-lozenge" [class.success]="details.isActive"
                                    [class.error]="!details.isActive">
                                    {{details.isActive ? 'Active' : 'Inactive'}}
                                </div>
                            </dd>
                        </uf-description-list>

                        <uc-secret-input class="col-12" label="Client Secret" [(value)]="details.clientSecret"
                            [control]="form.controls.clientSecret | asUfControl">
                        </uc-secret-input>

                        <uf-text class="col-12" *ngIf="details.extras != null" label="Management API Audience"
                            [(value)]="details.extras.audience" [control]="form.controls.audience | asUfControl">
                        </uf-text>

                        <div class="col-12 row space-children">
                            <button *ngIf="providerId" class="uf-button right"
                                (click)="disconnect()">Disconnect</button>
                            <button *ngIf="details.isActive" class="uf-button primary" [class.right]="!providerId"
                                (click)="deactivate()">Deactivate</button>
                            <button *ngIf="!details.isActive" class="uf-button primary"
                                (click)="activate()">Activate</button>
                        </div>
                    </div>
                </div>

                <uf-blockquote *ngIf="groupsError" class="error col-12" icon="errorSolid"
                    [content]="groupsError.message || 'Failed to load Auth0 roles'">
                </uf-blockquote>

                <div class="col-12" *ngIf="groupsLoading">
                    <uf-spinner></uf-spinner>
                </div>

                <div class="col-12 uf-box">
                    <div class="uf-app-bar flat accent">
                        <div class="title">Mapping</div>
                    </div>

                    <div class="uf-app-bar">
                        <div class="title">Field Mapping</div>
                    </div>

                    <div class="uf-grid pad">
                        <div class="col-12">
                            <table class="uf-table inputs accent">
                                <thead>
                                    <tr>
                                        <th class="table-col-3">Unifii User Field</th>
                                        <th>Provider's User Field</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let fm of fieldMappingControl.controls | asUfControlsGroups; let i = index">
                                        <td class="input-cell">
                                            <div class="pad-sm-sides">
                                                {{fm.value.key}}
                                            </div>
                                        </td>
                                        <td class="input-cell">
                                            <uf-autocomplete placeholder="Select a source"
                                                *ngIf="allMappingFieldsOptions[fm.value.key]"
                                                [control]="fm.controls.source | asUfControl"
                                                (searchChange)="findFieldMapping(fm.value.key, $event)"
                                                [options]="allMappingFieldsOptions[fm.value.key]" nameProperty="display"
                                                class="table-cell">
                                            </uf-autocomplete>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <ng-container *ngIf="providerId && !groupsLoading">
                        <div class="uf-app-bar">
                            <div class="title">Company Roles Mapping</div>
                        </div>

                        <div class="uf-grid pad">
                            <div class="col-12">
                                <table class="uf-table inputs accent">
                                    <thead>
                                        <tr>
                                            <th class="table-col-3">Auth0 Role</th>
                                            <th>{{tenant.name}} Roles</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let c of tenantRolesControl.controls | asUfControlsGroups; let i = index">
                                            <td class="input-cell">
                                                <uf-autocomplete class="table-cell" *ngIf="filteredSourceGroups"
                                                    [control]="c.controls.source | asUfControl" nameProperty="name"
                                                    (searchChange)="findSourceGroups($event)"
                                                    [options]="filteredSourceGroups" placeholder="Select a source"
                                                    [disabled]="groupsError != null || groupsLoading">
                                                </uf-autocomplete>
                                            <td class="input-cell">
                                                <uf-chips class="table-cell"
                                                    [control]="c.controls.targets | asUfControl"
                                                    [options]="filteredTenantRoles"
                                                    (searchChange)="findTenantRole($event)"
                                                    [disabled]="groupsError != null || groupsLoading">
                                                </uf-chips>
                                            </td>
                                            <td class="input-cell">
                                                <div class="row">
                                                    <button class="uf-action tertiary right"
                                                        (click)="removeTenantRole(i)" title="Delete"
                                                        [disabled]="groupsError || groupsLoading">
                                                        <uf-icon name="delete"></uf-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!tenantRolesControl.controls.length">
                                            <td colspan="3" class="padded">
                                                No roles mapped
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-12 row space-children">
                                <button class="uf-button right" (click)="addTenantRole()"
                                    [disabled]="groupsError">Add</button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="providerId && !groupsLoading">

                        <div class="uf-app-bar">
                            <div class="title">Unifii System Roles Mapping</div>
                        </div>

                        <div class="uf-grid pad">
                            <div class="col-12">
                                <table class="uf-table inputs accent">
                                    <thead>
                                        <tr>
                                            <th class="table-col-3">Auth0 Role</th>
                                            <th>Unifii Roles</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let c of systemRolesControl.controls | asUfControlsGroups; let i = index">
                                            <td class="input-cell">
                                                <uf-autocomplete class="table-cell"
                                                    [control]="c.controls.source | asUfControl"
                                                    *ngIf="filteredSourceGroups" nameProperty="name"
                                                    (searchChange)="findSourceGroups($event)"
                                                    [options]="filteredSourceGroups" placeholder="Select a source"
                                                    [disabled]="groupsError != null || groupsLoading">
                                                </uf-autocomplete>
                                            </td>
                                            <td class="input-cell">
                                                <uf-chips class="table-cell"
                                                    [control]="c.controls.targets | asUfControl"
                                                    [options]="filteredSystemRoles"
                                                    (searchChange)="findSystemRoles($event)"
                                                    [disabled]="groupsError != null || groupsLoading">
                                                </uf-chips>
                                            </td>
                                            <td class="input-cell">
                                                <div class="row">
                                                    <button class="uf-action tertiary right"
                                                        (click)="removeSystemRole(i)" title="Delete"
                                                        [disabled]="groupsError || groupsLoading">
                                                        <uf-icon name="delete"></uf-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!systemRolesControl.controls.length">
                                            <td colspan="3" class="padded">
                                                No roles mapped
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 row space-children">
                                <button class="uf-button right" (click)="addSystemRole()"
                                    [disabled]="groupsError">Add</button>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="col-12 row space-children">
                    <button type="button" class="uf-button tertiary right" (click)="close()">Cancel</button>
                    <button type="button" class="uf-button primary" (click)="save(true)">Save</button>
                </div>

            </ng-container>

            <div class="col-12" *ngIf="loading">
                <uf-spinner></uf-spinner>
            </div>
        </div>
    </div>
</uf-panel>