import { from, Observable } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, TimezonesTranslationKey } from '@unifii/library/common';
import { SmartFormsTranslationKey } from '@unifii/library/smart-forms';
import { DisplayTranslationKey } from '@unifii/library/smart-forms/display';
import { InputTranslationKey } from '@unifii/library/smart-forms/input';
import { Dictionary } from '@unifii/sdk';

import { TranslationEntry, TranslationsFunctions } from './translation-functions';


export const translatedLanguages: string[] = ['en', 'it', 'ru', 'pt-BR'];

export class ConsoleTranslateLoader implements TranslateLoader {

    readonly entries: TranslationEntry[] = [
        { identifier: 'library-shared-terms', dictionary: SharedTermsTranslationKey },
        { identifier: 'library-common', dictionary: CommonTranslationKey },
        { identifier: 'library-smart-forms', dictionary: SmartFormsTranslationKey },
        { identifier: 'library-input', dictionary: InputTranslationKey },
        { identifier: 'library-display', dictionary: DisplayTranslationKey },
        { identifier: 'timezones', dictionary: TimezonesTranslationKey }
    ];

    constructor(private translationsUrl: string) { }

    getTranslation(lang: string): Observable<Dictionary<string>> {
        const promise = TranslationsFunctions.loadDictionaries(lang, this.translationsUrl, this.entries);
        return from(promise);
    }
}
