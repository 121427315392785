import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';

import { AuthProviderDetails, UcAuthProviders } from 'client';

import { AuthProviderDatasource } from './auth-provider-datasource';
import { ProviderTypeDictionary } from './auth-provider-model';


@Injectable()
export class AuthProvidersTableManager implements TableContainerManager<AuthProviderDetails, FilterValue, FilterEntry> {

    tableConfig: TableConfig<AuthProviderDetails>;
    addActionConfig = true;
    reload = new Subject<void>();

    constructor(private ucAuthProviders: UcAuthProviders) {
        this.tableConfig = {
            columns: [{
                name: 'providerLoginLabel',
                label: 'Provider',
                value: item => item.providerLoginLabel ?? item.type
            }, {
                name: 'tenant',
                label: 'Tenant'
            }, {
                name: 'type',
                label: 'Type',
                value: item => item.manual ? 'Manual' : 'Automatic'
            }, {
                name: 'status',
                label: 'Status',
                value: item => item.isActive ? 'Active' : 'Inactive'
            }],
            row: {
                image: item => `assets/svg/${item.type?.toLocaleLowerCase()}.svg`,
                label: item => ProviderTypeDictionary[item.type],
                link: item => [item.id]
            }
        };
    }

    createDataSource() {
        return new AuthProviderDatasource(this.ucAuthProviders);
    }

}