<div class="uf-box col-12 gap-top gap-sides">
    <div class="uf-app-bar flat accent">
        <div class="title grow">
            Settings
        </div>
    </div>
    <div class="uf-grid">
        <ng-template [ngIf]="!isNew && !edit" [ngIfElse]="inputs">
            <uf-description-list [items]="descriptionListItem" class="pad col-12">
            </uf-description-list>

            <div class="col-12 row space-children pad">
                <button (click)="buttonClicked.emit(events.Delete)" class="uf-button small right">Delete</button>
                <button (click)="buttonClicked.emit(events.Deactivate)" *ngIf="form.get(controlKeys.IsActive)?.value"
                    class="uf-button small">Deactivate</button>
                <button (click)="buttonClicked.emit(events.Activate)" *ngIf="!form.get(controlKeys.IsActive)?.value"
                    class="uf-button small">Activate</button>
                <button (click)="editDetails()" class="uf-button small primary">Edit</button>
            </div>
        </ng-template>

        <ng-template #inputs>
            <div *ngIf="form" [formGroup]="form" class="col-12 pad">

                <uf-text [formControlName]="controlKeys.Tenant" class="col-1of1" label="Tenant Name">
                </uf-text>

                <uf-text [formControlName]="controlKeys.ClientId" class="col-1of1" label="Client Id">
                </uf-text>

                <uf-text *ngIf="showProviderLoginLabel" [formControlName]="controlKeys.ProviderLoginLabel"
                    class="col-1of1" label="Provider Login Label">
                </uf-text>

                <ng-container [formGroup]="extrasControl">
                    <uf-text *ngIf="showAuthorizationServerId" [formControlName]="controlKeys.AuthorizationServer"
                        class="col-1of1" label="Authorization Server ID">
                    </uf-text>

                    <uf-text *ngIf="showManagementApiAudience" [formControlName]="controlKeys.Audience" class="col-1of1"
                        label="Management API Audience">
                    </uf-text>
                </ng-container>

                <uc-secret-input [formControlName]="controlKeys.ClientSecret" label="Client Secret" class="col-1of1">
                </uc-secret-input>

                <uc-secret-input [formControlName]="controlKeys.ScimToken" label="SCIM Token" class="col-1of1">
                </uc-secret-input>

                <div *ngIf="!isNew" class="col-1of1 row space-children pad">
                    <button (click)="cancelEdit()" type="button" class="uf-button tertiary right"
                        (click)="edit = false">Cancel</button>
                    <button (click)="saveDetails()" type="button" class="uf-button primary">Save</button>
                </div>

            </div>
        </ng-template>

    </div>
</div>