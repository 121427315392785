<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button *ngIf="details?.tenant == null && !details?.extras?.manualRegistration" (click)="connect()"
        class="uf-button secondary">
        Connect
    </button>
    <button *ngIf="details?.tenant != null || details?.extras?.manualRegistration" (click)="save(true)" type="button"
        class="uf-button primary">Save</button>
</div>

<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <uf-blockquote class="col-12 error" *ngIf="error" icon="errorSolid"
                [content]="error.message || 'Failed to load Microsoft details'">
            </uf-blockquote>

            <ng-container *ngIf="details && form" [formGroup]="form">

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Settings
                        </div>
                    </div>
                    <div class="pad uf-grid">

                        <ng-container *ngIf="details.tenant == null">
                            <uf-text label="Tenant Name" [formControlName]="controlKeys.Tenant" class="col-12">
                            </uf-text>

                            <ng-container *ngIf="details.extras?.manualRegistration">
                                <uf-text label="Client ID" [formControlName]="controlKeys.ClientId" class="col-12">
                                </uf-text>
                                <uc-secret-input [formControlName]="controlKeys.ClientSecret" label="Client Secret"
                                    class="col-12">
                                </uc-secret-input>
                                <uc-secret-input [formControlName]="controlKeys.ScimToken" label="SCIM Token"
                                    class="col-12">
                                </uc-secret-input>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="details.tenant">
                            <uf-description-list class="col-12">
                                <dt>Tenant Name:</dt>
                                <dd>{{ details.tenant }}</dd>
                                <dt>Client ID:</dt>
                                <dd>{{ details.clientId }}</dd>
                                <ng-container *ngIf="details.extras?.manualRegistration === true">
                                    <dt>Registration Type:</dt>
                                    <dd>Manual</dd>
                                </ng-container>
                                <dt>Status:</dt>
                                <dd>
                                    <div class="uf-lozenge" [class.success]="details.isActive"
                                        [class.error]="!details.isActive">
                                        {{details.isActive ? 'Active' : 'Inactive'}}
                                    </div>
                                </dd>
                            </uf-description-list>

                            <uf-text [formControlName]="controlKeys.ProviderLoginLabel" class="col-12"
                                label="Provider Login Label">
                            </uf-text>

                            <uc-secret-input *ngIf="details.extras?.manualRegistration" label="Client Secret"
                                [formControlName]="controlKeys.ClientSecret" class="col-12">
                            </uc-secret-input>

                            <uc-secret-input class="col-12" label="SCIM Token"
                                [formControlName]="controlKeys.ScimToken">
                            </uc-secret-input>

                            <div class="col-12 row space-children">
                                <button class="uf-button right" (click)="disconnect()">Disconnect</button>
                                <button *ngIf="details.isActive" class="uf-button primary"
                                    (click)="deactivate()">Deactivate</button>
                                <button *ngIf="!details.isActive" class="uf-button primary"
                                    (click)="activate()">Activate</button>
                            </div>
                        </ng-container>
                    </div>
                </div>


                <ng-container *ngIf="details.tenant">
                    <div class="col-12" *ngIf="groupsError && !recentlyModified">
                        <uf-blockquote class="error" icon="errorSolid"
                            [content]="groupsError.message || 'Failed to load Microsoft Groups'">
                        </uf-blockquote>
                    </div>

                    <div class="col-12" *ngIf="groupsError && recentlyModified">
                        <uf-blockquote class="info" icon="errorSolid"
                            content="Microsoft connection is getting set up, please wait or come back later to edit role map.">
                        </uf-blockquote>
                    </div>

                    <div *ngIf="featureConfigKeys.length" class="col-12 uf-box gap-top">
                        <div class="uf-app-bar flat accent">
                            <div class="title">
                                Feature Configuration
                            </div>
                        </div>
                        <div class="uf-grid pad" [formGroupName]="controlKeys.FeatureConfig">
                            <ul class="uf-list uf-box flat col-12">
                                <li *ngFor="let key of featureConfigKeys" class="uf-list-item" [formGroupName]="key">
                                    <uf-checkbox [formControlName]="featureConfigControlKeys.Enabled">
                                    </uf-checkbox>
                                    <div class="multi-text">{{$any(details.featureConfig)[key].label}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <ng-container *ngIf="mappingsControl && ((!claimsError && !groupsError) || !recentlyModified)">
                        <div class="col-12 uf-box gap-top">
                            <div class="uf-app-bar flat accent">
                                <div class="title">Mapping</div>
                            </div>

                            <!-- Hierarchy / Unit -->

                            <div class="uf-app-bar">
                                <div class="title">Unit Mapping</div>
                            </div>
                            <div class="uf-grid pad">
                                <uf-blockquote *ngIf="mappingsControl.showError" icon="errorSolid"
                                    content="There are errors in your Unit Mapping" class="col-12 error">
                                </uf-blockquote>

                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-4">Mapping Type</th>
                                                <th>Claim/Group</th>
                                                <th class="table-col-4">Unifii Hierarchy Unit</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of (mappingsControl.controls || []) | asUfControlsGroups; let i = index"
                                                (click)="editMapping(i)" class="clickable">
                                                <td>
                                                    {{conditionDictionary[control.get(mappingsControlKeys.Condition)?.get(conditionControlKeys.Type)?.value]}}
                                                </td>
                                                <td>
                                                    <div *ngFor="let label of getMappingConditionLabels(control)">
                                                        {{ label }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        *ngFor="let unit of (control.get(mappingsControlKeys.Targets)?.value || [])">
                                                        {{ unit | hierarchyUnit }}
                                                    </div>
                                                </td>
                                                <td class="input-cell">
                                                    <div class="row center-all">
                                                        <button class="uf-action tertiary right"
                                                            (click)="editMapping(i, $event)" title="Edit">
                                                            <uf-icon name="edit"></uf-icon>
                                                        </button>
                                                        <button class="uf-action tertiary"
                                                            (click)="removeMapping(i, $event)" title="Delete">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!mappingsControl.controls.length">
                                                <td colspan="5" class="padded">
                                                    No mappings
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 row space-children">
                                    <button class="uf-button right" (click)="addMapping()">Add</button>
                                </div>
                            </div>

                            <!-- Field Mapping  -->

                            <div class="uf-app-bar">
                                <div class="title">Field Mapping</div>
                            </div>
                            <div class="uf-grid pad">
                                <div class="col-12">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-3">Unifii User Field</th>
                                                <th>Provider's User Field</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let fm of (fieldMappingControl.controls || []) | asUfControlsGroups; let i = index">
                                                <td class="input-cell">
                                                    <div class="pad-sm-sides">
                                                        {{fm.value.key}}
                                                    </div>
                                                </td>
                                                <td class="input-cell">
                                                    <uf-autocomplete placeholder="Select a source"
                                                        *ngIf="allMappingFieldsOptions[fm.value.key]"
                                                        [control]="fm.controls.source | asUfControl"
                                                        (searchChange)="findFieldMapping(fm.value.key, $event)"
                                                        [options]="allMappingFieldsOptions[fm.value.key]"
                                                        nameProperty="display" class="table-cell">
                                                    </uf-autocomplete>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Company Roles -->

                            <ng-container *ngIf="!groupsError || !recentlyModified">
                                <div class="uf-app-bar">
                                    <div class="title">Company Roles Mapping</div>
                                </div>
                                <div class="uf-grid pad">

                                    <div class="col-12">
                                        <table class="uf-table inputs accent">
                                            <thead>
                                                <tr>
                                                    <th class="table-col-3">Microsoft Group</th>
                                                    <th>{{tenant.name}} Roles</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let c of (tenantRolesControl.controls || []) | asUfControlsGroups; let i = index">
                                                    <td class="input-cell">
                                                        <uf-autocomplete placeholder="Select a source"
                                                            *ngIf="filteredSourceGroups"
                                                            [control]="c.controls.source | asUfControl"
                                                            (searchChange)="findSourceGroups($event)"
                                                            [options]="filteredSourceGroups" [allowCustom]="true"
                                                            nameProperty="name" class="table-cell">
                                                        </uf-autocomplete>
                                                    <td class="input-cell">
                                                        <uf-chips class="table-cell"
                                                            [control]="c.controls.targets | asUfControl"
                                                            [options]="filteredTenantRoles"
                                                            (searchChange)="findTenantRole($event)"
                                                            [disabled]="groupsError != null">
                                                        </uf-chips>
                                                    </td>
                                                    <td class="input-cell">
                                                        <button class="uf-action tertiary right"
                                                            (click)="removeTenantRole(i)" title="Delete"
                                                            [disabled]="groupsError">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="!tenantRolesControl.controls?.length">
                                                    <td colspan="3" class="padded">
                                                        No roles mapped
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-12 row space-children">
                                        <button class="uf-button right" (click)="addTenantRole()"
                                            [disabled]="groupsError">Add</button>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Claims -->

                            <div class="uf-app-bar">
                                <div class="title">Claims Mapping</div>
                            </div>
                            <div class="uf-grid pad">
                                <div class="col-12" *ngIf="claimsError && !recentlyModified">
                                    <uf-blockquote class="error" icon="errorSolid"
                                        [content]="claimsError.message || 'Failed to load Microsoft Claims'">
                                    </uf-blockquote>
                                </div>

                                <div class="col-12" *ngIf="claimsError && recentlyModified">
                                    <uf-blockquote class="info" icon="errorSolid"
                                        content="Microsoft connection is getting set up please wait or come back later to edit claim map.">
                                    </uf-blockquote>
                                </div>

                                <div class="col-12" *ngIf="!claimsError || !recentlyModified">
                                    <table class="uf-table inputs accent">
                                        <thead>
                                            <tr>
                                                <th class="table-col-3">Microsoft Claim</th>
                                                <th>Unifii Claim</th>
                                                <th>Values</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of claimsControl.controls | asUfControlsGroups; let i = index"
                                                [formGroup]="control" (click)="editClaim(i)" class="clickable">
                                                <td>
                                                    {{ (control.get(claimControlKeys.Source) | asSafeAbstractControl |
                                                    asUfControl).value }}
                                                </td>
                                                <td>
                                                    {{ (control.get(claimControlKeys.Target) | asSafeAbstractControl |
                                                    asUfControl).value?.type }}
                                                </td>
                                                <td>
                                                    <div
                                                        *ngIf="(control.get(claimControlKeys.Target) | asSafeAbstractControl | asUfControl).value">
                                                        <div
                                                            *ngFor="let claim of control.get(claimControlKeys.ValueMap)?.value">
                                                            {{ [claim.key, claim.value].join(': ') }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="input-cell">
                                                    <div class="row">
                                                        <button class="uf-action tertiary right" title="Edit">
                                                            <uf-icon name="edit"></uf-icon>
                                                        </button>
                                                        <button class="uf-action tertiary right"
                                                            (click)="removeClaim(i, $event)" title="Delete">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!claimsControl.controls.length">
                                                <td colspan="5" class="padded">
                                                    No claims mapped
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-12 row space-children">
                                    <button class="uf-button right" (click)="addClaim()"
                                        [disabled]="claimsLoading || claimsError">Add</button>
                                </div>
                            </div>

                            <!-- Unifii System Roles -->

                            <ng-container *ngIf="!groupsError || !recentlyModified">
                                <div class="uf-app-bar">
                                    <div class="title">Unifii System Roles Mapping</div>
                                </div>
                                <div class="uf-grid pad">

                                    <div class="col-12">
                                        <table class="uf-table inputs accent">
                                            <thead>
                                                <tr>
                                                    <th class="table-col-3">Microsoft Group</th>
                                                    <th>Unifii Roles</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let c of (systemRolesControl.controls || []) | asUfControlsGroups; let i = index">
                                                    <td class="input-cell">
                                                        <uf-autocomplete placeholder="Select a source"
                                                            *ngIf="filteredSourceGroups"
                                                            [control]="c.controls.source | asUfControl"
                                                            (searchChange)="findSourceGroups($event)"
                                                            [options]="filteredSourceGroups" nameProperty="name"
                                                            class="table-cell">
                                                        </uf-autocomplete>
                                                    </td>
                                                    <td class="input-cell">
                                                        <uf-chips class="table-cell"
                                                            [control]="c.controls.targets | asUfControl"
                                                            [options]="filteredSystemRoles"
                                                            (searchChange)="findSystemRoles($event)"
                                                            [disabled]="groupsError != null">
                                                        </uf-chips>
                                                    </td>
                                                    <td class="input-cell">
                                                        <button class="uf-action tertiary right"
                                                            (click)="removeSystemRole(i)" title="Delete"
                                                            [disabled]="groupsError">
                                                            <uf-icon name="delete"></uf-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="!systemRolesControl.controls?.length">
                                                    <td colspan="3" class="padded">
                                                        No roles mapped
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-12 row space-children">
                                        <button class="uf-button right" (click)="addSystemRole()"
                                            [disabled]="groupsError">Add</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                    <div class="col-12 pad-sm-top pad-sm-left form-error-message"
                        [style.visibility]="form.invalid && this.form.submitted ? 'visible' : 'hidden'">
                        Unable to proceed, please check errors above.
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</uf-panel>