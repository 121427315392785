import { Injectable, Type } from '@angular/core';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import * as content from './content';


// Collection Compound
@Injectable()
export class ContentEditorRegistry extends UfFormComponentRegistry implements ComponentRegistry {

    private fieldMap = {
        ImageList: content.ImageListComponent,
        SoundList: content.SoundListComponent,
        VideoList: content.VideoListComponent,
        FileList: content.FileListComponent,
        LinkList: content.LinkListComponent,
        Link: content.LinkComponent,
        DefinitionLink: content.LinkComponent,
        Address: content.GroupInputComponent,
        GeoLocation: content.GroupInputComponent
    };

    get(name: FieldType): Type<any> {
        return (this.fieldMap as any)[name] || super.get(name as any);
    }
}
