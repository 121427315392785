<div class="uf-app-bar">
    <button type="button" class="uf-action tertiary" (click)="runtime.close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Claim Mapping</h3>
</div>
<uf-panel class="grow">
    <div class="uf-grid pad" [formGroup]="control">
        <uf-autocomplete [formControlName]="claimControlKeys.Source" (searchChange)="findSourceClaims($event)"
            [options]="filteredSourceClaims" placeholder="Select a source" [allowCustom]="true" label="Source Claim"
            class="col-12">
        </uf-autocomplete>
        <uf-autocomplete [formControlName]="claimControlKeys.Target" placeholder="Search Claims"
            (searchChange)="findClaims($event)" [allowCustom]="true" [options]="claimOptions" nameProperty="type"
            label="Target Claim" class="col-12">
        </uf-autocomplete>
        <table *ngIf="targetControl.value" class="uf-table inputs accent col-12">
            <thead>
                <tr>
                    <th>Source Value</th>
                    <th>Target Value</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let valueMapControl of valueMapControls.controls; let i = index"
                    [formGroup]="valueMapControl | asUfControlGroup">
                    <td class="input-cell">
                        <uf-text class="table-cell" formControlName="key"></uf-text>
                    </td>
                    <td class="input-cell">
                        <uf-select *ngIf="targetControl.value.options != null" class="table-cell"
                            formControlName="value" [options]="targetControl.value.options" valueProperty="id">
                        </uf-select>
                        <ng-container *ngIf="targetControl.value.options == null"
                            [ngSwitch]="targetControl.value.valueType">
                            <uf-number *ngSwitchCase="fieldType.Number" class="table-cell" formControlName="value">
                            </uf-number>
                            <uf-phone *ngSwitchCase="fieldType.Phone" class="table-cell" formControlName="value">
                            </uf-phone>
                            <uf-email *ngSwitchCase="fieldType.Email" class="table-cell" formControlName="value">
                            </uf-email>
                            <uf-url *ngSwitchCase="fieldType.Website" class="table-cell" formControlName="value">
                            </uf-url>
                            <uf-date *ngSwitchCase="fieldType.Date" class="table-cell" formControlName="value">
                            </uf-date>
                            <uf-datetime *ngSwitchCase="fieldType.DateTime" class="table-cell" formControlName="value">
                            </uf-datetime>
                            <uf-checkbox *ngSwitchCase="fieldType.Bool" class="table-cell" formControlName="value">
                            </uf-checkbox>
                            <uf-text *ngSwitchDefault class="table-cell" formControlName="value"> </uf-text>
                        </ng-container>
                    </td>
                    <td class="input-cell">
                        <div class="row">
                            <button class="uf-action tertiary right" (click)="removeMapping(i)" title="Delete">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!valueMapControls.controls.length">
                    <td colspan="3" class="padded">
                        No values mapped
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- Wait for 2.1 release to actually show the deep error -->
        <!-- <uf-error-deep [control]="valueMapControls" class="col-12"></uf-error-deep> -->
        <div class="col-12 row space-children gap-top">
            <button class="uf-button right" (click)="addMapping()">Add</button>
        </div>
    </div>
</uf-panel>
<div class="row space-children pad">
    <button type="button" class="uf-button tertiary right" (click)="runtime.close()">Cancel</button>
    <button type="button" class="uf-button primary" (click)="save()">Save</button>
</div>