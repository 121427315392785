import { Component, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, GoogleLocationProvider, GoogleWindow, LocationProvider, UfLocationProvider, WindowWrapper } from '@unifii/library/common';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { Client, ExternalDataSourcesClient, ProjectInfo, PublishedContent } from '@unifii/sdk';

import { PreviewContentService } from 'components/common/preview-content.service';

import { ContextService } from 'services/context.service';
import { ProjectPublisher } from 'services/project-publisher';


export const createExternalDataSource = (client: Client, context: ContextService) =>
    new ExternalDataSourcesClient(client, { projectId: (context.project as ProjectInfo).id });

export const createMapProvider = (
    contextService: ContextService,
    window: GoogleWindow,
    translateService: TranslateService,
    contextProvider: ContextProvider
): LocationProvider => {
    if (contextService.tenantSettings && contextService.tenantSettings.googleMapsApiKey) {
        return new GoogleLocationProvider(translateService, window, contextService.tenantSettings.googleMapsApiKey, contextProvider);
    }

    return new UfLocationProvider(translateService, window);
};

// TODO Move provide of LocationProvider higher in the app DI, can it be 'root'
@Component({
    templateUrl: './project.html',
    providers: [
        DisplayService,
        ProjectPublisher,
        { provide: PublishedContent, useClass: PreviewContentService },
        { provide: ExternalDataSourcesClient, useFactory: createExternalDataSource, deps: [Client, ContextService] },
        {
            provide: LocationProvider, useFactory: createMapProvider,
            deps: [ContextService, WindowWrapper, TranslateService, ContextProvider]
        }
    ]
})
export class ProjectComponent {
    @HostBinding('class.stretch-component') class = true;
}
