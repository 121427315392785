import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { UcRoles } from 'client';

import { createFilterEntries } from 'pages/users/user-filter-entry-factory';
import { UsersTableManager } from 'pages/users/users-table-manager';


@Component({
    providers: [
        { provide: TableContainerManager, useClass: UsersTableManager },
        { provide: FilterEntries, useFactory: createFilterEntries, deps: [UcRoles] },
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute (addItem)="addItem()" class="accent list-md pad-none"></uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `
})
export class UsersComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    addItem() {
        this.router.navigate(['./new'], { relativeTo: this.route });
    }
}
