<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Display</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error"></uf-icon>
    </div>
    <div expanderBody [formGroup]="control" class="uf-grid pad-sides pad-bottom pad-sm-top">

        <uc-markdown *ngIf="showHelpText" label="Help text" [formControlName]="fieldKeys.Help"
            [showAlignmentOptions]="true" class="col-12">
        </uc-markdown>

        <uf-text label="Placeholder" *ngIf="meta.placeholder && showPlaceholder"
            [formControlName]="fieldKeys.Placeholder" class="col-12">
        </uf-text>

        <uf-text label="Item label" *ngIf="meta.itemLabel && showItemLabel" [formControlName]="fieldKeys.ItemLabel"
            class="col-12">
        </uf-text>

        <uf-text label="Add button label" *ngIf="meta.addButtonLabel" [formControlName]="fieldKeys.AddButtonLabel"
            class="col-12">
        </uf-text>

        <div class="col-12" *ngIf="meta.columnVisibility && showColumnVisibility">
            <label class="x-small">Column visibility</label>
            <div class="pad-sm-top">
                <ul class="uf-list uf-box flat column-toggles-list">
                    <ng-template ngFor [ngForOf]="fields" let-child>
                        <ng-container *ngIf="child.get(fieldKeys.Identifier)?.value as childIdentifier">
                            <li class="row center-all space-children pad-sm-sides">
                                <div class="grow">
                                    <p class="small">{{child.get(fieldKeys.Label)?.value}}</p>
                                </div>
                                <uf-checkbox label="Mobile"
                                    [value]="!$any(field.templateConfig?.hideOnMobile)[childIdentifier]"
                                    (valueChange)="updateShowFlag(true, childIdentifier)">
                                </uf-checkbox>
                                <uf-checkbox label="Desktop"
                                    [value]="!$any(field.templateConfig?.hideOnDesktop)[childIdentifier]"
                                    (valueChange)="updateShowFlag(false, childIdentifier)">
                                </uf-checkbox>
                            </li>
                        </ng-container>
                    </ng-template>
                </ul>
            </div>
        </div>

        <!-- TODO [disabled]="field.template === fieldTemplate.RadioWithContent" -->
        <uf-select label="Field width" *ngIf="meta.width" [formControlName]="fieldKeys.Width" valueProperty="identifier"
            nameProperty="name" [options]="widthOptions" class="col-12">
        </uf-select>

        <!-- TODO [disabled]="!field.width || field.width === fieldWidth.Stretch" -->
        <uf-checkbox label="Break after" *ngIf="meta.breakAfter && field.width !== fieldWidths.Stretch"
            [formControlName]="fieldKeys.BreakAfter" class="col-12">
        </uf-checkbox>

        <uf-select label="Columns" *ngIf="meta.columnCount && showColumnCount" [formControlName]="fieldKeys.ColumnCount"
            [options]="columnCountOptions" valueProperty="identifier" nameProperty="name" class="col-12">
        </uf-select>

        <uf-select label="Direction" *ngIf="meta.layoutDirection && showLayoutDirection"
            [formControlName]="fieldKeys.LayoutDirection" valueProperty="identifier" nameProperty="name"
            [options]="layoutDirectionOptions" class="col-12">
        </uf-select>

        <uf-chips label="Visible to" *ngIf="meta.visibleTo" (searchChange)="findRoles($event)" [options]="rolesResult"
            [formControlName]="fieldKeys.VisibleTo" class="col-12">
        </uf-chips>

    </div>
</uf-expander>