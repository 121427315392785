import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ControlAccessor, UfControl, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { AuthProviderClaimMapping, UcClaimConfig, UcUserClaims } from 'client';


export enum ClaimControlKeys {
    Source = 'source',
    Target = 'target',
    ValueMap = 'valueMap'
}

export enum ValueMapControlKeys {
    Key = 'key',
    Value = 'value'
}

export interface AuthProviderClaimMappingFormModel {
    source: string | undefined;
    target: UcClaimConfig | undefined;
    valueMap: AuthProviderClaimValueMapFormModel[];
}

export interface AuthProviderClaimValueMapFormModel {
    key: string;
    value: string | undefined;
}

@Injectable({
    providedIn: 'root'
})
export class ClaimMappingController {

    constructor(
        private ufb: UfFormBuilder,
        private ucUserClaims: UcUserClaims
    ) { }

    createClaimControl(data?: AuthProviderClaimMappingFormModel) {
        return this.ufb.group({
            [ClaimControlKeys.Source]: this.ufb.control(data?.source, ValidatorFunctions.required('Group is required')),
            [ClaimControlKeys.Target]: this.ufb.control(data?.target, ValidatorFunctions.required('Select a group')),
            [ClaimControlKeys.ValueMap]: this.ufb.array((data?.valueMap || []).map(v => this.createMappingControl(v)))
        });
    }

    async toFormModel(data?: AuthProviderClaimMapping): Promise<AuthProviderClaimMappingFormModel> {
        return {
            source: data?.source,
            target: data ? (await this.ucUserClaims.list({ params: { q: data.target } }))[0] : undefined, // TODO request BE to make an enpoint for finding claims by type and all at once
            valueMap: Object.keys(data?.valueMap || {}).map(key => ({ key, value: data?.valueMap ? data?.valueMap[key] : undefined }))
        };
    }

    toDataModel(mapping: AuthProviderClaimMappingFormModel): AuthProviderClaimMapping {
        const dataModel: any = Object.assign({}, mapping);
        dataModel.target = mapping.target?.type;
        dataModel.valueMap = (mapping.valueMap || []).reduce((p: Dictionary<string>, c: AuthProviderClaimValueMapFormModel) => {
            p[c.key] = c.value as string;
            return p;
        }, {} as Dictionary<string>);
        return dataModel;
    }

    createMappingControl(value?: AuthProviderClaimValueMapFormModel) {
        return this.ufb.group({
            [ValueMapControlKeys.Key]: this.ufb.control(value?.key, ValidatorFunctions.compose([
                ValidatorFunctions.required('Source is required'),
                (control) => this.duplicatesValidator(control as UfControl)
            ])),
            [ValueMapControlKeys.Value]: this.ufb.control(value?.value, ValidatorFunctions.compose([
                ValidatorFunctions.required('Target is required')
            ]))
        });
    }

    private duplicatesValidator(control: UfControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        const message = 'Duplicate source keys are not allowed';
        const accessor = new ControlAccessor(control as UfControl);
        const values = accessor.get(`../../[*]${ValueMapControlKeys.Key}`).map(c => c.value);

        const exists = values.includes(control.value);
        if (exists) {
            return { message };
        }

        return null;
    }
}