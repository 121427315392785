import { AfterViewInit, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { CompoundType } from '@unifii/sdk';

import { SystemRole, UcProject } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';
import { ContentSettings } from 'components/content/content';
import { ContentList } from 'components/content/content-list';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from 'components/content/modals/link-search.component';

import { DialogsService } from 'services/dialogs.service';


@Component({
    templateUrl: './link.html',
    styleUrls: ['./group-input.less']
})
export class LinkComponent extends ContentList implements FormField, AfterViewInit {

    title: string;
    icon: string;
    searchConfig: LinkSearchConfig;
    draggable = true;

    private linkSegments: string[] | null;

    constructor(
        private ucProject: UcProject,
        modalService: ModalService,
        builderService: BuilderService,
        cdr: ChangeDetectorRef,
        dialogs: DialogsService,
        @Inject(ContentSettings) @Optional() contentSettings: ContentSettings
    ) {
        super(LinkSearchComponent, modalService, builderService, contentSettings, dialogs);

        if (this.builderService.builder.type === CompoundType.Collection) {
            this.draggable = false;
        }

        this.subscribers.push(this.builderService.fieldReady.subscribe(() => {

            switch (this.field.compoundType) {

                case CompoundType.Collection:
                    this.title = 'Collection Link';
                    this.linkSegments = ['content', 'collections'];
                    this.searchConfig = {
                        title: 'Select Collection',
                        multiSelect: false,
                        ucProject: this.ucProject,
                        type: LinkSearchType.Compound,
                        compoundType: CompoundType.Collection,
                        types: this.field.types
                    };
                    break;

                case CompoundType.Page:
                    this.title = 'Page Link';
                    this.linkSegments = ['content', 'pages'];
                    this.searchConfig = {
                        title: 'Select Page',
                        multiSelect: false,
                        ucProject: this.ucProject,
                        type: LinkSearchType.Compound,
                        compoundType: CompoundType.Page
                    };
                    break;

                case CompoundType.View:
                    this.title = 'View Link';
                    this.linkSegments = ['content', 'views'];
                    this.searchConfig = {
                        title: 'Select View',
                        multiSelect: false,
                        ucProject: this.ucProject,
                        type: LinkSearchType.Compound,
                        compoundType: CompoundType.View
                    };
                    break;

                case CompoundType.Form:
                    this.title = 'Form Link';
                    this.linkSegments = ['forms'];
                    this.searchConfig = {
                        title: 'Select Form',
                        multiSelect: false,
                        ucProject: this.ucProject,
                        type: LinkSearchType.Form
                    };
                    break;
            }

            this.icon = this.builderService.builder.getFieldRef(this.field ? this.field : undefined).icon;
            cdr.detectChanges();
        }));
    }

    getLink(link: any) {
        const segments = ['/projects', this.ucProject.options.projectId, ...(this.linkSegments ?? [])];
        if (this.field.compoundType === 'Collection') {
            segments.push(link.definitionIdentifier);
        }
        segments.push(link.id);
        return segments;
    }

    protected getSearchConfig(): LinkSearchConfig {
        switch (this.field.compoundType) {

            case CompoundType.Collection:
                return {
                    title: 'Add a Collection Item',
                    multiSelect: false,
                    ucProject: this.ucProject,
                    type: LinkSearchType.Compound,
                    compoundType: CompoundType.Collection,
                    types: this.field.types
                };

            case CompoundType.Page:
                return {
                    title: 'Add a Page',
                    multiSelect: false,
                    ucProject: this.ucProject,
                    type: LinkSearchType.Compound,
                    compoundType: CompoundType.Page
                };

            case CompoundType.View:
                return {
                    title: 'Add a View',
                    multiSelect: false,
                    ucProject: this.ucProject,
                    type: LinkSearchType.Compound,
                    compoundType: CompoundType.View
                };

            case CompoundType.Form:
            default:
                return {
                    title: 'Add a Form',
                    multiSelect: false,
                    ucProject: this.ucProject,
                    type: LinkSearchType.Form
                };
        }
    }

    protected convertResultToContent(item: any): any {
        switch (this.field.compoundType) {
            case CompoundType.Form:
                return { id: item.id, name: item.name };
            case CompoundType.Collection:
                return { id: item.id, compoundType: item.compoundType, definitionIdentifier: item.definitionIdentifier, recordName: item.name };
            default:
                return { id: item.id, compoundType: item.compoundType, recordName: item.name };
        }
    }


    get removeRestrictions(): string | null {

        if (this.builderService.builder.type === CompoundType.Page) {
            return null;
        }

        return SystemRole.ProjectManager;
    }

}
