import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@unifii/components';
import { ContextProvider, FeatureFlagProvider, WindowResizeController } from '@unifii/library/common';
import { DisplayModule } from '@unifii/library/smart-forms/display';
import { InputModule } from '@unifii/library/smart-forms/input';
import { UserProvisioningModule } from '@unifii/user-provisioning';

import { UcWorkflow } from 'client';

import { COMPONENTS } from 'components';
import { ChartComponent } from 'components/chart/chart.component';
import { DropButtonsComponent } from 'components/common/drop-buttons.component';
import { GlossaryComponent } from 'components/common/glossary.component';
import { ConsoleIconsComponent } from 'components/common/icons.component';
import { ItemPickerComponent } from 'components/common/item-picker/item-picker.component';
import { SaveOptionsComponent } from 'components/common/save-options/save-options.component';
import { TypeSelectComponent } from 'components/common/type-select.component';
import { BuilderFieldsHeaderComponent } from 'components/compound-builder/builder-fields-header.component';
import { BuilderHeaderWarningDirective } from 'components/compound-builder/builder-header/builder-header-warning.component';
import { BuilderHeaderComponent } from 'components/compound-builder/builder-header/builder-header.component';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { PublishInfoComponent } from 'components/compound-builder/builder-header/publish-info.component';
import { BuilderNodeComponent } from 'components/compound-builder/builder-node.component';
import { BuilderNodesComponent } from 'components/compound-builder/builder-nodes.component';
import { FieldReferencePickerComponent } from 'components/compound-builder/field-reference-picker.component';
import { ContentComponentRegistry } from 'components/content-component-registry';
import { ContentEditorRegistry } from 'components/content-editor-registry';
import { FileListComponent } from 'components/content/file-list.component';
import { GroupInputComponent } from 'components/content/group-input.component';
import { ImageListComponent } from 'components/content/image-list.component';
import { LinkListComponent } from 'components/content/link-list.component';
import { LinkComponent } from 'components/content/link.component';
import { ContentSelectComponent } from 'components/content/modals/content-select.component';
import { ImageCropComponent } from 'components/content/modals/image-crop.component';
import { ImagePreviewComponent } from 'components/content/modals/image-preview.component';
import { LinkSearchComponent } from 'components/content/modals/link-search.component';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { ModalFormComponent } from 'components/content/modals/modal-form.component';
import { SoundListComponent } from 'components/content/sound-list.component';
import { VideoListComponent } from 'components/content/video-list.component';
import { DragHandleDirective } from 'components/dragdrop/drag-handle.directive';
import { DragItemDirective } from 'components/dragdrop/drag-item.directive';
import { DragListComponent } from 'components/dragdrop/drag-list.component';
import { DragService } from 'components/dragdrop/drag.service';
import { ExpanderControlsComponent } from 'components/expander/expander-controls.component';
import { DATA_SOURCE_EDITOR_COMPONENTS } from 'components/field-builder/data-source-editor';
import { DataSourceMappingsReorderComponent } from 'components/field-builder/data-source-editor/data-source-mappings-reorder.component';
import { FieldBuilderComponent } from 'components/field-builder/field-builder.component';
import { FieldDisplayComponent } from 'components/field-builder/field-display.component';
import { FieldNestedComponent } from 'components/field-builder/field-nested.component';
import { FieldOptionComponent } from 'components/field-builder/field-option.component';
import { FieldOptionsComponent } from 'components/field-builder/field-options.component';
import { FieldSettingsComponent } from 'components/field-builder/field-settings.component';
import { FieldValidatorsComponent } from 'components/field-builder/field-validators.component';
import { FieldVisibilityComponent } from 'components/field-builder/field-visibility.component';
import { KeySelectDirective } from 'components/field-builder/key-select.directive';
import { StepComponent } from 'components/field-builder/step.component';
import { TransitionActionEditorComponent } from 'components/field-builder/transition-action-editor.component';
import { FILTER_EDITOR_COMPONENTS } from 'components/filter-editor';
import { FORM, UcAssetComponent, UcColorComponent, UcMarkdownComponent, UcMarkdownTokenizerComponent } from 'components/form/_index';
import { UcMarkdownWrapperComponent } from 'components/form/uc-markdown-wrapper.component';
import { ImageCropperComponent } from 'components/image-cropper/image-cropper.component';
import { MarkdownComponentRegistry } from 'components/markdown-component-registry';
import { MarkdownEditorRegistry } from 'components/markdown-editor-registry';
import { NotFoundComponent } from 'components/not-found/not-found.component';
import { PermissionEditorComponent } from 'components/permissions/permission-editor.component';
import { PermissionsClonerComponent } from 'components/permissions/permissions-cloner.component';
import { PermissionsManagerService } from 'components/permissions/permissions-manager.service';
import { PermissionsComponent } from 'components/permissions/permissions.component';

import { InputMaskDirective } from 'directives/input-mask.directive';
import { MasterDetailDirective } from 'directives/master-detail.directive';
import { RestrictDirective } from 'directives/restrict.directive';
import { TooltipDirective } from 'directives/tooltip.directive';

import { LanguageComponent } from 'navigation/language.component';
import { MenuItemComponent } from 'navigation/menu-item.component';
import { NavContextMenuComponent } from 'navigation/nav-context-menu.component';
import { ProjectNavComponent } from 'navigation/project-nav.component';
import { ProjectComponent } from 'navigation/project.component';
import { TenantComponent } from 'navigation/tenant.component';

import { APIKeyDetailsComponent } from 'pages/api-keys/api-key-details.component';
import { APIKeysComponent } from 'pages/api-keys/api-keys.component';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { AppDevicesComponent } from 'pages/apps/app-devices.component';
import { AppSendNotificationComponent } from 'pages/apps/app-send-notification.component';
import { AppSettingsComponent } from 'pages/apps/app-settings.component';
import { AppsComponent } from 'pages/apps/apps.component';
import { DeviceDetailComponent } from 'pages/apps/device-detail.component';
import { SelectDevicesComponent } from 'pages/apps/select-devices.component';
import { UsersQueryBuilderComponent } from 'pages/apps/users-query-builder.component';
import { AssetsComponent } from 'pages/assets/assets.component';
import { AuditLogComponent } from 'pages/audit-log/audit-log.component';
import { ClaimDetailComponent, ClaimTableComponent } from 'pages/claim-config';
import { COLLECTION_COMPONENTS } from 'pages/content/collections/_index';
import { ContentComponent } from 'pages/content/content.component';
import { PAGE_COMPONENTS } from 'pages/content/pages/_index';
import { PageBuilderComponent } from 'pages/content/pages/page-builder.component';
import { PagesComponent } from 'pages/content/pages/pages.component';
import { VIEW_COMPONENTS } from 'pages/content/views/_index';
import { UnauthorizedComponent } from 'pages/errors/unauthorized.component';
import { BucketDataComponent } from 'pages/form-data/bucket-data.component';
import { BucketComponent } from 'pages/form-data/bucket.component';
import { BucketsComponent } from 'pages/form-data/buckets.component';
import { FormDataComponent, FormDataContextProvider } from 'pages/form-data/form-data.component';
import { MetadataModalComponent } from 'pages/form-data/metadata-modal.component';
import { FORM_EDITOR_COMPONENTS } from 'pages/form-editor';
import { SchemaFieldMapperComponent } from 'pages/form-editor/field-configuration/schema-field-mapper.component';
import { FormFakeUploader } from 'pages/forms/form-fake-uploader.service';
import { FormPreviewComponent } from 'pages/forms/form-preview.component';
import { FormsComponent } from 'pages/forms/forms.component';
import { HIERARCHY_COMPONENTS } from 'pages/hierarchy';
import { ImportComponent } from 'pages/import/import.component';
import { LAB_COMPONENTS } from 'pages/lab';
import { LandingComponent } from 'pages/landing/landing.component';
import { FileConflictModalComponent } from 'pages/media/file-conflict-modal.component';
import { MediaDetailComponent } from 'pages/media/media-detail.component';
import { MediaNewComponent } from 'pages/media/media-new.component';
import { MediaComponent } from 'pages/media/media.component';
import { MediaboxComponent } from 'pages/media/mediabox.component';
import { PageNotFoundComponent } from 'pages/page-not-found/page-not-found.component';
import { DataSourceDetailsComponent } from 'pages/project-settings/data-sources/data-source-details.compontent';
import { DataSourcesComponent } from 'pages/project-settings/data-sources/data-sources.component';
import { SettingsGeneralComponent } from 'pages/project-settings/settings-general.component';
import { SettingsThemingComponent } from 'pages/project-settings/settings-theming.component';
import { SettingsComponent } from 'pages/project-settings/settings.component';
import { ProjectOverviewComponent } from 'pages/projects/project-overview.component';
import { ProjectsComponent } from 'pages/projects/projects.component';
import { PublishComponent } from 'pages/publish/publish.component';
import { RoleDetailsComponent } from 'pages/roles/role-details.component';
import { RolesComponent } from 'pages/roles/roles.component';
import { STRUCTURE_COMPONENTS } from 'pages/structure';
import { AuthProviderMappingModalComponent } from 'pages/system-settings/identity/auth-provider-mapping-modal.component';
import { AuthProvidersComponent } from 'pages/system-settings/identity/auth-providers.component';
import { Auth0Component } from 'pages/system-settings/identity/auth0.component';
import { ClaimMappingModalComponent } from 'pages/system-settings/identity/claim-mapping-modal.component';
import { MicrosoftComponent } from 'pages/system-settings/identity/microsoft.component';
import { OktaComponent } from 'pages/system-settings/identity/okta.component';
import { IDENTITY_COMPONENTS } from 'pages/system-settings/new-identity';
import { SecretFieldComponent } from 'pages/system-settings/secret-input-field.component';
import { SecretInputComponent } from 'pages/system-settings/secret-input.component';
import { TenantSettingsComponent } from 'pages/system-settings/settings.component';
import { SystemEmailSettingsComponent } from 'pages/system-settings/system-email-settings.component';
import { SystemEmailPreviewModalComponent } from 'pages/system-settings/system-email-template-preview-modal.component';
import { SystemEmailTemplatesComponent } from 'pages/system-settings/system-email-templates.component';
import { SystemGeneralComponent } from 'pages/system-settings/system-general.component';
import { SystemIntegrationComponent } from 'pages/system-settings/system-integration.component';
import { SystemIntegrationsComponent } from 'pages/system-settings/system-integrations.component';
import { SystemSmtpTestModalComponent } from 'pages/system-settings/system-smtp-test-modal.component';
import { SystemSmtpComponent } from 'pages/system-settings/system-smtp.component';
import { TABLES_COMPONENTS } from 'pages/tables/tables';
import { DragTestComponent } from 'pages/testing/drag-test.component';
import { ImageCropperTestComponent } from 'pages/testing/image-cropper-test.component';
import { LanguageCollectionComponent } from 'pages/translations/language-collection.component';
import { LanguageCompoundComponent } from 'pages/translations/language-compound.component';
import { LanguageFieldComponent } from 'pages/translations/language-field.component';
import { LanguageOverviewComponent } from 'pages/translations/language-overview.component';
import { LanguageViewComponent } from 'pages/translations/language-view.component';
import { LanguageViewsComponent } from 'pages/translations/language-views.component';
import { TranslationsOverviewComponent } from 'pages/translations/translations-overview.component';
import { ContactInfoModalComponent } from 'pages/user-access/contact-info-modal.component';
import { LoginComponent } from 'pages/user-access/login.component';
import { UserAccessBoxComponent } from 'pages/user-access/user-access-box.component';
import { UserManagementComponent } from 'pages/user-management/user-management.component';
import { UserDetailsComponent } from 'pages/users/user-details.component';
import { UserDevicesComponent } from 'pages/users/user-devices.component';
import { UserComponent } from 'pages/users/user.component';
import { UsersComponent } from 'pages/users/users.component';
import { WORKFLOW_COMPONENTS } from 'pages/workflows';

import { BypassSanitizeUrlPipe } from 'pipes/bypass-sanitize-url.pipe';
import { DataSourceTypeLabelPipe } from 'pipes/data-source-type-label.pipe';
import { FileTypePipe } from 'pipes/file-type.pipe';
import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';
import { ToDisplayName } from 'pipes/to-display-name.pipe';

import { UcContextProvider } from 'services/console-context-provider';
import { UcFeatureFlagProvider } from 'services/console-feature-flag.provider';
import { ContextService } from 'services/context.service';
import { FileUploaderService } from 'services/file-uploader.service';
import { LimitService } from 'services/limit.service';
import { MementoService } from 'services/memento.service';


@NgModule({
    declarations: [
        DropButtonsComponent, GlossaryComponent, InputMaskDirective, ConsoleIconsComponent,
        BuilderFieldsHeaderComponent, BuilderHeaderComponent, PublishInfoComponent, BuilderHeaderWarningDirective,
        BuilderNodeComponent, BuilderNodesComponent, FieldReferencePickerComponent,
        MediaSearchComponent, ModalFormComponent, ImageCropComponent, ImagePreviewComponent, LinkSearchComponent, TypeSelectComponent, ContentSelectComponent,
        FileListComponent, ImageListComponent, LinkComponent, LinkListComponent, SoundListComponent, GroupInputComponent,
        VideoListComponent, ChartComponent,
        ClaimTableComponent, ClaimDetailComponent,
        DragHandleDirective, DragItemDirective,
        DragListComponent, FieldBuilderComponent, FieldDisplayComponent, FieldNestedComponent,
        FieldOptionsComponent, FieldOptionComponent, FieldSettingsComponent, FieldValidatorsComponent,
        FieldVisibilityComponent, KeySelectDirective, StepComponent, ImageCropperComponent,
        NotFoundComponent, ExpanderControlsComponent,
        PermissionsComponent, PermissionEditorComponent, PermissionsClonerComponent, ProjectComponent, ProjectNavComponent, TenantComponent, BucketsComponent, BucketComponent, BucketDataComponent, FormDataComponent,
        FormPreviewComponent, FormsComponent, UserAccessBoxComponent, LoginComponent, ContactInfoModalComponent, UnauthorizedComponent, FileConflictModalComponent,
        MediaComponent, MediaDetailComponent, MediaNewComponent, MediaboxComponent, PageNotFoundComponent,
        AppsComponent, AppDetailsComponent, AppSettingsComponent, AppDevicesComponent, AppSendNotificationComponent, DeviceDetailComponent, SelectDevicesComponent,
        PageBuilderComponent, PagesComponent, ProjectsComponent, ProjectOverviewComponent, PublishComponent, ImportComponent,
        SettingsComponent, SettingsGeneralComponent,
        SettingsThemingComponent, DataSourcesComponent, DataSourceDetailsComponent,
        LanguageComponent, TranslationsOverviewComponent, LanguageOverviewComponent,
        LanguageViewsComponent, LanguageViewComponent, LanguageCollectionComponent, LanguageCompoundComponent, LanguageFieldComponent,
        DragTestComponent, ImageCropperTestComponent, LandingComponent,
        SaveOptionsComponent, ItemPickerComponent, UsersQueryBuilderComponent,
        UserManagementComponent, UsersComponent, UserComponent, UserDetailsComponent, UserDevicesComponent, RolesComponent, RoleDetailsComponent,
        APIKeysComponent, APIKeyDetailsComponent,
        FileTypePipe, BypassSanitizeUrlPipe, DataSourceTypeLabelPipe, TableSourceTypeLabelPipe, ToDisplayName, MenuItemComponent, AuditLogComponent,
        MasterDetailDirective, RestrictDirective, TooltipDirective, TenantSettingsComponent, AuthProvidersComponent, MicrosoftComponent, Auth0Component, OktaComponent,
        AuthProviderMappingModalComponent, ClaimMappingModalComponent, TransitionActionEditorComponent, SchemaFieldMapperComponent,
        SystemGeneralComponent, SystemIntegrationsComponent, SystemIntegrationComponent, SecretInputComponent, SecretFieldComponent, DataSourceMappingsReorderComponent, SystemEmailSettingsComponent, SystemEmailTemplatesComponent, SystemSmtpComponent, SystemSmtpTestModalComponent, MetadataModalComponent,
        SystemEmailPreviewModalComponent, ContentComponent, NavContextMenuComponent, AssetsComponent,
        STRUCTURE_COMPONENTS, LAB_COMPONENTS, FORM_EDITOR_COMPONENTS,
        DATA_SOURCE_EDITOR_COMPONENTS, FILTER_EDITOR_COMPONENTS, VIEW_COMPONENTS, COLLECTION_COMPONENTS,
        PAGE_COMPONENTS, FORM, TABLES_COMPONENTS, WORKFLOW_COMPONENTS, COMPONENTS, HIERARCHY_COMPONENTS, IDENTITY_COMPONENTS
    ],
    imports: [
        RouterModule, CommonModule, InputModule, DisplayModule, ComponentsModule, UserProvisioningModule
    ],
    exports: [
        InputModule, DisplayModule, ComponentsModule,
        DropButtonsComponent, GlossaryComponent, MasterDetailDirective, InputMaskDirective, ConsoleIconsComponent,
        TooltipDirective, BuilderFieldsHeaderComponent, BuilderHeaderWarningDirective,
        BuilderNodeComponent, BuilderNodesComponent, FieldReferencePickerComponent,
        MediaSearchComponent, ModalFormComponent, ImageCropComponent, ImagePreviewComponent, LinkSearchComponent, TypeSelectComponent, ContentSelectComponent,
        FileListComponent, ImageListComponent, LinkComponent, LinkListComponent, SoundListComponent, GroupInputComponent,
        VideoListComponent, ChartComponent,
        ClaimTableComponent, ClaimDetailComponent,
        DragHandleDirective, DragItemDirective,
        DragListComponent, FieldBuilderComponent, FieldDisplayComponent, FieldNestedComponent,
        FieldOptionsComponent, FieldOptionComponent, FieldSettingsComponent, FieldValidatorsComponent,
        FieldVisibilityComponent, KeySelectDirective, StepComponent, ImageCropperComponent,
        NotFoundComponent, ExpanderControlsComponent,
        PermissionsComponent, PermissionEditorComponent, PermissionsClonerComponent, ProjectComponent, ProjectNavComponent, TenantComponent,
        BucketsComponent, BucketComponent, BucketDataComponent, FormDataComponent,
        FormPreviewComponent, FormsComponent, UserAccessBoxComponent, ContactInfoModalComponent, FileConflictModalComponent, LoginComponent, UnauthorizedComponent,
        MediaComponent, MediaDetailComponent, MediaNewComponent, MediaboxComponent, PageNotFoundComponent,
        AppsComponent, AppDetailsComponent, AppSettingsComponent, AppDevicesComponent, AppSendNotificationComponent, DeviceDetailComponent, SelectDevicesComponent,
        PageBuilderComponent, PagesComponent, ProjectsComponent, ProjectOverviewComponent, ProjectOverviewComponent, PublishComponent, SettingsComponent,
        SaveOptionsComponent, ItemPickerComponent,
        SettingsThemingComponent,
        TranslationsOverviewComponent, LanguageComponent, LanguageOverviewComponent,
        LanguageViewsComponent, LanguageViewComponent, LanguageCollectionComponent, LanguageCompoundComponent, LanguageFieldComponent,
        DragTestComponent, ImageCropperTestComponent, LandingComponent, UsersQueryBuilderComponent,
        UserManagementComponent, UsersComponent, UserComponent, UserDetailsComponent, UserDevicesComponent, RolesComponent, RoleDetailsComponent,
        APIKeysComponent, APIKeyDetailsComponent,
        FileTypePipe, BypassSanitizeUrlPipe, DataSourceTypeLabelPipe, TableSourceTypeLabelPipe, ToDisplayName, TenantSettingsComponent, AuthProvidersComponent, MicrosoftComponent, Auth0Component, OktaComponent,
        AuthProviderMappingModalComponent, ClaimMappingModalComponent, TransitionActionEditorComponent, SchemaFieldMapperComponent, MetadataModalComponent,
        SystemGeneralComponent, SystemIntegrationsComponent, SystemIntegrationComponent, DataSourceMappingsReorderComponent,
        UcMarkdownComponent, UcMarkdownWrapperComponent, UcMarkdownTokenizerComponent, UcColorComponent, UcAssetComponent, SystemSmtpComponent, SystemEmailSettingsComponent, SystemEmailTemplatesComponent, SystemSmtpTestModalComponent,
        UserProvisioningModule, SystemEmailPreviewModalComponent, ContentComponent, NavContextMenuComponent, AssetsComponent,
        STRUCTURE_COMPONENTS, LAB_COMPONENTS, FORM_EDITOR_COMPONENTS,
        DATA_SOURCE_EDITOR_COMPONENTS, FILTER_EDITOR_COMPONENTS, VIEW_COMPONENTS, COLLECTION_COMPONENTS,
        PAGE_COMPONENTS, TABLES_COMPONENTS, WORKFLOW_COMPONENTS, COMPONENTS, HIERARCHY_COMPONENTS, IDENTITY_COMPONENTS
    ],
    providers: [
        DragService,
        ContentComponentRegistry,
        MarkdownComponentRegistry,
        ContentEditorRegistry,
        MarkdownEditorRegistry,
        FormFakeUploader,
        ContextService,
        MementoService,
        PermissionsManagerService,
        WindowResizeController,
        { provide: ContextProvider, useClass: UcContextProvider, deps: [ContextService] },
        { provide: FeatureFlagProvider, useClass: UcFeatureFlagProvider },
        FileUploaderService,
        TableSourceTypeLabelPipe,
        LimitService,
        ToDisplayName,
        UcWorkflow,
        BuilderHeaderService,
        FormDataContextProvider
    ]
})
export class ConsoleModule { }
