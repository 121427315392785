<div class="overlay-container">
    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header>
        </uc-builder-header>
    </div>
    <div class="content-pane">
        <uf-tabs class="scrollable gap-top" *ngIf="form">
            <uf-tab label="Details">
                <uc-auth-provider-detail [form]="form"
                    (buttonClicked)="detailButtonClicked($event)"></uc-auth-provider-detail>
            </uf-tab>
            <uf-tab *ngIf="!isNew" label="Mappings">
                <uc-auth-provider-mappings *ngIf="mapping" [(providerMapping)]="mapping"></uc-auth-provider-mappings>
            </uf-tab>
        </uf-tabs>
    </div>
</div>