import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { MyAccount, TokenService, UcClient } from 'client';

import { ContextService } from '../services/context.service';


@Injectable()
export class AccountGuard implements CanActivate {

    constructor(
        private client: UcClient,
        private context: ContextService,
        private router: Router,
        private tokenService: TokenService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        const params: any = state.url !== '/' ? { next: state.url } : {};

        if (this.tokenService.token == null) {
            this.router.navigate(['/login', params]);
            return false;
        }

        const isUpdate = this.context.account != null;
        let getAccountError;
        let result: boolean | undefined;

        try {
            const promises = [this.client.getMyAccount()];

            if (isUpdate) {
                promises.push(new Promise((_r, rej) => setTimeout(() => rej(), 500)));
            }

            const getAccountPromise = Promise.race(promises);

            this.context.account = await getAccountPromise;

        } catch (error) {
            getAccountError = error;

        } finally {

            if (!isUpdate && getAccountError) {
                this.router.navigate(['/login', params]);
                result = false;
            }

            if (result != null && (this.context.account as MyAccount).changePasswordOnNextLogin) {
                this.router.navigate(['/login', params]);
                result = false;
            }

            if (result == null) {
                result = true;
            }
        }

        return result as boolean;
    }
}
