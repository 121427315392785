<uf-expander class="uc-group" [class.uc-group--draggable--no-shrink]="!disabled && draggable && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="!disabled && draggable && isSelected">
    <div expanderHeader class="uf-app-bar flat" [class.accent]="isSelected" dragHandle expanderHeader
        (click)="select()">
        <uf-icon name="fileList"></uf-icon>
        <div class="title primary">
            <span>File List</span>
            <span *ngIf="field.label"> | {{field.label}}</span>
        </div>
        <uf-help class="uf-action tertiary" *ngIf="field.help" [content]="field.help"></uf-help>
        <a *ngIf="canRemove" [restrict]="removeRestrictions" (click)="remove()" title="Delete"
            class="uf-action tertiary">
            <uf-icon name="delete"></uf-icon>
        </a>
    </div>
    <div expanderBody class="uc-group-content" [class.content--accent]="isSelected">
        <uc-drag-list *ngIf="content" canDrop="false" canReorder="true" [items]="content" [parent]="content"
            (moved)="moved()">
            <ul class="uc-list--gap">
                <li class="row center-all" [class.uc-list-item--small--no-shrink--bordered]="disabled"
                    [class.uc-list-item--draggable--small--no-shrink]="!disabled"
                    *ngFor="let file of content, let i = index" dragItem [dragDisabled]="disabled">
                    <div class="list-content">
                        <div class="list-context">
                            <a class="uf-action tertiary" [routerLink]="['/assets', 'media',file.id]" target="_blank"
                                title="Open">
                                <uf-icon name="open" class=""></uf-icon>
                            </a>
                            <button *ngIf="canDelete" (click)="delete(i)" tabindex="-1" title="Delete"
                                class="uf-action tertiary">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </div>
                        <div>{{file.title}}</div>
                        <div class="fieldset-item--x-small">
                            <span>File name: </span>
                            <span>{{file.filename}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Description: </span>
                            <span>{{file.description}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Uploaded at: </span>
                            <span>{{ file.uploadedAt | date:'short' }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </uc-drag-list>
        <div class="row padded">
            <button class="uf-button x-small right" *ngIf="canAdd" (click)="add()">Add File</button>
        </div>
    </div>
</uf-expander>
<uf-error [control]="control"></uf-error>