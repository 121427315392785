import { Injectable } from '@angular/core';
import { HierarchyUnitProvider, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { generateUUID, HierarchyUnitWithPath } from '@unifii/sdk';

import { AuthProviderMapping, AuthProviderMappingAction, AuthProviderMappingActionType, AuthProviderMappingCondition, UcAuthProviders } from 'client';

import {
    AuthProviderMappingActionModel, AuthProviderMappingConditionFormModel, AuthProviderMappingConditionType, AuthProviderMappingModel,
    AuthProviderMappingsModel, MappingsControlKeys
} from '../models';


@Injectable({
    providedIn: 'root'
})
export class AuthProviderMappingsController {
    constructor(
        private ufb: UfFormBuilder,
        private hierarchyProvider: HierarchyUnitProvider,
        private ucAuthProviders: UcAuthProviders,
    ) { }

    buildRoot(model: AuthProviderMappingsModel): UfControlGroup {
        const mappingsControl = this.ufb.array(model.mappings.map(mapping => this.buildMapping(mapping.condition, mapping.actions)));

        return this.ufb.group({
            [MappingsControlKeys.AuthProviderId]: [model.authProviderId],
            [MappingsControlKeys.Mappings]: mappingsControl
        });
    }

    async toFormModel(authProviderId: string, mappings?: AuthProviderMapping[]): Promise<AuthProviderMappingsModel> {
        const units = await this.loadUnits(mappings ?? []);

        const model: AuthProviderMappingsModel = {
            authProviderId,
            mappings: []
        };

        model.mappings = await Promise.all((mappings ?? []).map(async mapping => {
            const condition = await this.toConditionModel(mapping.condition, authProviderId);
            const actions = await Promise.all(mapping.actions.map(action => this.toActionModel(action, units)));
            return {
                id: authProviderId,
                condition,
                actions
            } as AuthProviderMappingModel;
        })) as AuthProviderMappingModel[];

        return model;
    }

    toDataModel(model: AuthProviderMappingsModel): AuthProviderMapping[] {
        return model.mappings.map(mapping => ({
            condition: this.toConditionData(mapping.condition),
            actions: mapping.actions.map(action => this.toActionData(action))
        }));
    }

    buildCondition(condition?: AuthProviderMappingConditionFormModel): UfControlGroup {
        const group = this.ufb.group({
            [MappingsControlKeys.Type]: [condition?.type],
            [MappingsControlKeys.Id]: [{ value: condition?.id ?? generateUUID(), disabled: true }],
        });

        switch (condition?.type) {
            case AuthProviderMappingConditionType.ClaimValue:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(condition.identifier, ValidatorFunctions.required('Identifier required')));
                group.addControl(MappingsControlKeys.Value, this.ufb.control(condition.value, ValidatorFunctions.required('Value required')));
                break;
            case AuthProviderMappingConditionType.GroupMembership:
                // group.addControl(MappingsControlKeys.Identifier, this.ufb.control(condition.identifier, ValidatorFunctions.required('Identifier required')));
                group.addControl(MappingsControlKeys.Group, this.ufb.control(condition.group, ValidatorFunctions.required('Group required')));
                break;
            default:
                group.addControl(MappingsControlKeys.Children, this.ufb.array((condition?.children ?? []).map(children => this.buildCondition(children)),
                    ValidatorFunctions.custom(v => (v.length > 1), 'And or Or combinations need at least two conditions')
                ));
        }

        return group;
    }

    buildActions(actions: AuthProviderMappingActionModel[]): UfControlArray {
        return this.ufb.array(actions.map(action => this.buildAction(action)));
    }

    buildAction(action: AuthProviderMappingActionModel): UfControlGroup {
        const group = this.ufb.group({
            [MappingsControlKeys.Type]: [action.type],
        });

        switch (action.type) {
            case AuthProviderMappingActionType.AssignUnit:
                group.addControl(MappingsControlKeys.Unit, this.ufb.control(action.unit, ValidatorFunctions.required('Unit required')));
                break;
            case AuthProviderMappingActionType.AssignClaim:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(action.identifier, ValidatorFunctions.required('Identifier required')));
                group.addControl(MappingsControlKeys.Value, this.ufb.control(action.value, ValidatorFunctions.required('Value required')));
                break;
            case AuthProviderMappingActionType.AssignSystemRole:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(action.identifier, ValidatorFunctions.required('System Role required')));
                break;
            case AuthProviderMappingActionType.AssignRole:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(action.identifier, ValidatorFunctions.required('Role required')));
                break;

        }

        return group;
    }

    buildMapping(condition?: AuthProviderMappingConditionFormModel, actions?: AuthProviderMappingActionModel[]) {
        return this.ufb.group({
            [MappingsControlKeys.Condition]: this.buildCondition(condition),
            [MappingsControlKeys.Actions]: this.ufb.array((actions ?? []).map(action => this.buildAction(action))),
        });
    }

    private async toConditionModel(condition: AuthProviderMappingCondition, authProviderId: string): Promise<AuthProviderMappingConditionFormModel> {
        const model: AuthProviderMappingConditionFormModel = {
            type: condition.type,
            identifier: condition.identifier,
            value: condition.value,
            children: [],
            id: generateUUID()
        };

        switch (condition.type) {
            case AuthProviderMappingConditionType.GroupMembership:
                if (condition.identifier) {
                    model.group = await this.ucAuthProviders.getAuthProviderGroup(authProviderId, condition.identifier);
                }
                break;
            default:
                // And or Or
                model.children = await Promise.all((condition.children ?? []).map(children => this.toConditionModel(children, authProviderId)));
        }

        return model;
    }

    private async toActionModel(action: AuthProviderMappingAction, units: HierarchyUnitWithPath[]): Promise<AuthProviderMappingActionModel> {

        const model: AuthProviderMappingActionModel = {
            type: action.type,
            identifier: action.identifier,
            value: action.value // for AssignClaim type of action
        };

        if (action.type === AuthProviderMappingActionType.AssignUnit) {
            model.unit = units.find(unit => unit.id === action.identifier);
        }

        return model;
    }

    private loadUnits(mappings: AuthProviderMapping[]) {
        const allUnits = mappings.reduce((ids: string[], mapping: AuthProviderMapping) => {
            mapping.actions.forEach(action => {
                if (action.type === AuthProviderMappingActionType.AssignUnit) {
                    ids.push(action.identifier);
                }
            });
            return ids;
        }, []);
        const distinctUnits = new Set(allUnits);
        return this.hierarchyProvider.getUnits(Array.from(distinctUnits));
    }

    private toConditionData(condition: AuthProviderMappingConditionFormModel): AuthProviderMappingCondition {
        const data: AuthProviderMappingCondition = {
            type: condition.type,
            children: (condition.children ?? []).map(c => this.toConditionData(c)),
            identifier: condition.identifier,
            value: condition.value
        };

        if (condition.type === AuthProviderMappingConditionType.GroupMembership) {
            data.identifier = condition.group?.id;
        }

        return data;
    }

    private toActionData(action: AuthProviderMappingActionModel): AuthProviderMappingAction {
        const data: AuthProviderMappingAction = {
            type: action.type,
            identifier: action.identifier,
            value: action.value
        };

        if (action.type === AuthProviderMappingActionType.AssignUnit) {
            data.identifier = action.unit?.id ?? action.identifier;
        }

        return data;
    }
}