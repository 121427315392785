import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TableContainerManager, TableInputManager } from '@unifii/components';
import { FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';

import { AppInfo, SchemaInfo, UcProject } from 'client';

import { BucketsDataSource } from './buckets-datasource';


@Injectable()
export class BucketsTableManager implements TableContainerManager<SchemaInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<AppInfo>;
    addActionConfig = true;
    reload = new Subject<void>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    constructor(
        private ucProject: UcProject,
    ) {
        this.tableConfig = {
            columns: [{
                name: 'id',
                label: 'Bucket'
            }],
            row: {
                link: item => [item.id]
            },
            pageSize: 100
        };
    }

    createDataSource() {
        return new BucketsDataSource(this.ucProject);
    }
}
