import { Field, FieldTemplate, HorizontalTableTemplate } from '@unifii/sdk';


export interface HorizontalTableConfig {
    hideFromColumnsOnMobile?: Field[];
    hideFromColumnsOnDesktop: Field[];
}

export class TableTemplateManager {

    readonly horizontalTableTemplates = [FieldTemplate.HorizontalTable, FieldTemplate.HorizontalTableMobile];
    private config = new Map<Field, HorizontalTableConfig>();

    set(field: Field) {

        if (!this.horizontalTableTemplates.includes(field.template as FieldTemplate)) {
            this.clear(field);
            return;
        }

        field.templateConfig = this.updateTemplateConfig(field);

        const config: HorizontalTableConfig = {
            hideFromColumnsOnDesktop: this.fieldMapper(field.templateConfig.hideFromColumnsOnDesktop, field.fields),
        };

        if (field.templateConfig.hideFromColumnsOnMobile) {
            config.hideFromColumnsOnMobile = this.fieldMapper(field.templateConfig.hideFromColumnsOnMobile, field.fields);
        }
        this.config.set(field, config);
    }

    clear(field: Field) {
        this.config.delete(field);
        delete field.templateConfig;
    }

    update() {

        this.config.forEach((config, field) => {

            const templateConfig: HorizontalTableTemplate = {
                hideFromColumnsOnDesktop: this.identifierMapper(config.hideFromColumnsOnDesktop)
            };

            if (config.hideFromColumnsOnMobile != null) {
                templateConfig.hideFromColumnsOnMobile = this.identifierMapper(config.hideFromColumnsOnMobile);
            }
            field.templateConfig = templateConfig;
        });
    }
    /**
     * Assign emtpy values and delete
     * unwanted fields
     */
    private updateTemplateConfig({ template, templateConfig }: Field): HorizontalTableTemplate {

        if (templateConfig == null) {
            templateConfig = {
                hideFromColumnsOnDesktop: []
            };
        }

        if (template === FieldTemplate.HorizontalTable) {
            if (templateConfig.hideFromColumnsOnMobile != null) {
                delete templateConfig.hideFromColumnsOnMobile;
            }
            return templateConfig;
        }

        if (templateConfig.hideFromColumnsOnMobile == null) {
            templateConfig.hideFromColumnsOnMobile = [];
        }
        return templateConfig;
    }

    private identifierMapper(fields: Field[] = []): string[] {
        return fields.filter(f => !!f?.identifier).map(f => f.identifier) as string[];
    }

    private fieldMapper(idenitiers: string[] = [], fields: Field[] = []): Field[] {
        return idenitiers.map(i => fields.find(f => f.identifier === i)).filter(f => f != null) as Field[];
    }

}

