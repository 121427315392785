import { ReportingExceptionHandler } from 'reporting-exception-handler';
import { firstValueFrom } from 'rxjs';

import { APP_BASE_HREF } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    BucketDataDescriptorAdapter, BucketDataDescriptorAdapterLoader, CollectionDataDescriptorAdapter, CollectionDataDescriptorAdapterLoader,
    CompanyDataDescriptorAdapter, CompanyDataDescriptorAdapterLoader, DataDescriptorAdapterCache, DataDescriptorService, DeviceInfo, ExpressionParser,
    getGMaps, getStorage, getWindow, GoogleMaps, HierarchyUnitProvider, Repository, StorageWrapper, TablePreferencesProvider,
    UserDataDescriptorAdapter, UserDataDescriptorAdapterLoader, WindowWrapper
} from '@unifii/library/common';
import {
    Client, Interceptor, ProjectContentOptions, ProjectContentOptionsInterace, TenantClient, TokenStorage, TokenStorageInterface, UsersClient
} from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

import {
    SdkInterceptor, StructureService, TokenService, UcAPIKeys, UcApps, UcAuditLog, UcAuthProviders, UcClient, UcDataSources, UcHierarchy,
    UcHierarchyUnitProvider, UcIntegrations, UcMedia, UcProject, UcResources, UcRoles, UcSmtp, UcUsers, UserProvisioning
} from 'client';

import { CanDeactivateEditDataComponent } from 'components/common/edit-data';
import { CanDeactivateBuilder } from 'components/compound-builder/builder-basic';

import { TableResolver } from 'pages/tables/table-resolver';

import { AccountGuard } from 'guards/account-guard';
import { FeatureFlagGuard } from 'guards/feature-flag-guard';
import { sequenceGuardFactory, TenantSequenceGuard } from 'guards/main-sequence-guard';
import { TenantGuard } from 'guards/tenant-guard';

import { ProjectResolver } from 'resolvers/project-resolver';

import { BrowserGuard } from 'services/browser-guard';
import { ConfigService } from 'services/config.service';
import { UcBucketDataDescriptorAdapterLoader } from 'services/console-bucket-data-descriptor-adapter-loader';
import { UcCollectionDataDescriptorAdapterLoader } from 'services/console-collection-data-descriptor-adapter-loader';
import { UcCompanyDataDescriptorAdapterLoader } from 'services/console-company-data-descriptor-adapter-loader';
import { UcUserDataDescriptorAdapterLoader } from 'services/console-user-data-descriptor-adapter-loader';
import { ContextService } from 'services/context.service';
import { SSOService } from 'services/sso.service';
import { TenantSettingsService } from 'services/tenant-settings.service';
import { TitleService } from 'services/title.service';
import { ConsoleTranslateLoader } from 'services/translate-loader';
import { UserPreferencesService } from 'services/user-preferences.service';

import { Config } from './app-config';
import { AppUpdateService } from './app-update/app-update.service';
import { AppComponent } from './app.component';
import { ConsoleModule } from './console.module';
import { ConsoleRouteReuseStrategy } from './route-reuse-strategy';
import { AppRoutes } from './routes';


const browserAllowed = (browserGuard: BrowserGuard) => () => browserGuard.allowed;

const initTranslations = (translate: TranslateService) => () => {
    translate.addLangs(['en']);
    return firstValueFrom(translate.use('en'));
};

const initTitleService = (title: TitleService) => () => title.init();

@NgModule({
    bootstrap: [AppComponent], // root component
    declarations: [AppComponent], // components and directives
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        RouterModule.forRoot(AppRoutes, { useHash: false }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (config: Config) => new ConsoleTranslateLoader(config.translationsUrl),
                deps: [Config]
            }
        }),
        // Application Module
        ConsoleModule,
    ], // module dependencies
    providers: [
        { provide: RouteReuseStrategy, useClass: ConsoleRouteReuseStrategy },
        { provide: APP_INITIALIZER, useFactory: browserAllowed, multi: true, deps: [BrowserGuard] },
        { provide: APP_INITIALIZER, useFactory: initTranslations, multi: true, deps: [TranslateService] },
        { provide: APP_INITIALIZER, useFactory: initTitleService, multi: true, deps: [TitleService] },
        { provide: LOCALE_ID, useValue: 'en-AU' },
        { provide: ErrorHandler, useClass: ReportingExceptionHandler },
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: ConfigService,
            useFactory: (config: Config, contextService: ContextService): ConfigService => new ConfigService(config, contextService),
            deps: [Config, ContextService]
        },
        // native
        { provide: WindowWrapper, useFactory: getWindow, deps: [PLATFORM_ID] },
        { provide: StorageWrapper, useFactory: getStorage, deps: [PLATFORM_ID] },
        // 3rd-party
        Title,
        { provide: GoogleMaps, useFactory: getGMaps, deps: [PLATFORM_ID] },
        TranslateService,
        // SDK
        TokenService,
        DeviceInfo,
        {
            provide: Client,
            useFactory: (config: Config, tokenStorage: TokenStorageInterface, interceptor: Interceptor): Client => new Client(config, tokenStorage, interceptor),
            deps: [Config, TokenService, Interceptor]
        },
        { provide: TenantClient, useFactory: (client: Client) => new TenantClient(client), deps: [Client] },
        { provide: Interceptor, useClass: SdkInterceptor },
        { provide: TokenStorage, useClass: TokenService },
        { provide: UsersClient, useFactory: (client: Client) => new UsersClient(client), deps: [Client] },
        { provide: TablePreferencesProvider, useClass: UserPreferencesService },
        { provide: HierarchyUnitProvider, useClass: UcHierarchyUnitProvider },
        { provide: UserProvisioningProvider, useClass: UserProvisioning },
        // Application
        UcClient,
        { provide: ProjectContentOptions, useValue: { projectId: null as any, id: '', preview: false } as ProjectContentOptionsInterace },
        UcProject,
        StructureService,
        UcAPIKeys,
        UcRoles,
        UcApps,
        UcMedia,
        UcUsers,
        UcAuditLog,
        UcHierarchy,
        UcResources,
        UcIntegrations,
        UcAuthProviders,
        UcSmtp,
        UcDataSources,
        { provide: BucketDataDescriptorAdapterLoader, useClass: UcBucketDataDescriptorAdapterLoader },
        { provide: UserDataDescriptorAdapterLoader, useClass: UcUserDataDescriptorAdapterLoader },
        { provide: CompanyDataDescriptorAdapterLoader, useClass: UcCompanyDataDescriptorAdapterLoader },
        { provide: CollectionDataDescriptorAdapterLoader, useClass: UcCollectionDataDescriptorAdapterLoader },
        DataDescriptorAdapterCache,
        BucketDataDescriptorAdapter,
        UserDataDescriptorAdapter,
        CompanyDataDescriptorAdapter,
        CollectionDataDescriptorAdapter,
        DataDescriptorService,
        BrowserGuard,
        FeatureFlagGuard,
        TitleService,
        TenantGuard,
        AccountGuard,
        TableResolver,
        ProjectResolver,
        CanDeactivateBuilder,
        CanDeactivateEditDataComponent,
        ExpressionParser,
        SSOService,
        TenantSettingsService,
        AppUpdateService,
        UserPreferencesService,
        { provide: TenantSequenceGuard, useFactory: sequenceGuardFactory, deps: [AccountGuard, TenantGuard] },
        // Misc
        Repository,
    ]
})
export class AppModule { }
