import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DataPropertyInfoService, ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Schema, SchemaField, SchemaTransition } from '@unifii/sdk';

import { DefinitionInfo } from 'client';

import { DialogsService } from 'services/dialogs.service';

import { FormEditorCache } from '../form-editor-cache';
import { FieldMappingControlKeys, TransitionTargetControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorFunctions } from '../form-editor-functions';

import { SchemaFieldMapperComponent, SchemaFieldMappingData } from './schema-field-mapper.component';


@Component({
    selector: 'uc-form-field-transition-target',
    templateUrl: './form-field-transition-target.html'
})
export class FormFieldTransitionTargetComponent implements OnInit {

    @Input() control: UfControlGroup;
    @Input() forms: DefinitionInfo[] = [];
    @Input() parentSchema: Schema | null | undefined; // for data mapping
    @Input() showFieldMappings: boolean;
    @Input() showCondition: boolean;
    @Input() startTransitionsOnly: boolean;
    @Output() remove = new EventEmitter<void>();

    readonly targetKeys = TransitionTargetControlKeys;
    readonly fieldMappingKeys = FieldMappingControlKeys;

    noParentSchemaMessage: string;
    formsResult: DefinitionInfo[] = [];
    transitionsResult: SchemaTransition[] = [];
    parentFields: SchemaField[];
    childFields: SchemaField[];

    constructor(
        private dataPropertyInfoService: DataPropertyInfoService,
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private formCtrl: FormEditorFormCtrl,
        private modal: ModalService,
        private dialogs: DialogsService
    ) { }

    get fieldMappings(): UfControlArray {
        return this.control.get(TransitionTargetControlKeys.FieldMappings) as UfControlArray;
    }

    async ngOnInit() {
        if (!this.parentSchema) {
            this.noParentSchemaMessage = 'Schema for this form not found, please save your form before configure the workflow';
            return;
        }

        this.parentFields = FormEditorFunctions.getTransitionMappingSchemaFields(this.parentSchema, this.dataPropertyInfoService, true);
        await this.updateChildFormResults();
    }

    async onTargetFormChange() {
        this.removeTransition();
        await this.updateChildFormResults();

        this.fieldMappings.controls.forEach((_, i) => this.removeMappedFields(i));
    }

    async findForms(query: string) {
        if (!query || query.trim().length === 0) {
            this.formsResult = [...this.forms];
        } else {
            this.formsResult = this.forms.filter(info => info.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0);
        }
    }

    async onRemove(event: Event) {
        event.stopPropagation();
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.remove.next();
    }

    async updateChildFormResults() {
        const form = this.control.get(TransitionTargetControlKeys.Form)?.value as DefinitionInfo | undefined;
        const childSchema = await this.cache.getSchema(form?.bucket);

        if (childSchema) {
            this.transitionsResult = childSchema.transitions.filter(t => !this.startTransitionsOnly || t.source === 'Start');
            this.childFields = FormEditorFunctions.getTransitionMappingSchemaFields(childSchema, this.dataPropertyInfoService, false);
        } else {
            this.transitionsResult = [];
            this.childFields = [];
        }
    }

    transitionLabel(transition: SchemaTransition): string {
        return `${transition.source} > ${transition.target} (${transition.action})`;
    }

    async mapFields(control?: UfControlGroup) {
        const previousValue = control?.value;
        const mappedFields = (this.fieldMappings.value as { childField: SchemaField }[]) ?? [];
        const selectedIdentifiers = mappedFields
            .map(v => v.childField.identifier)
            // Remove its own value so it can be reselected
            .filter(i => previousValue?.childField?.identifier !== i);

        let newControl: UfControlGroup | null = null;
        if (control == null) {
            newControl = this.formCtrl.buildFieldMapping({});
            this.fieldMappings.push(newControl);
            this.fieldMappings.updateDependencies();
        }

        const data: SchemaFieldMappingData = {
            sourceFields: this.parentFields,
            targetFields: this.childFields.filter(f => !selectedIdentifiers.includes(f.identifier)),
            mappingGroup: {
                control: control ?? newControl as UfControlGroup,
                sourceKey: FieldMappingControlKeys.ParentField,
                targetKey: FieldMappingControlKeys.ChildField
            },
            sourceFieldLabel: 'Field in Primary Form',
            targetFieldLabel: 'Field in Triggered Form'
        };

        const result = await this.modal.openMedium<SchemaFieldMappingData, UfControlGroup>(SchemaFieldMapperComponent, data);

        // The dialog apply the changes meanwhile it is open, hence on confirm return
        if (result != null) {
            return;
        }

        // Otherwise re-apply the old value or remove the new added control
        if (control != null) {
            control.patchValue(previousValue);
        } else {
            this.fieldMappings.removeAt(this.fieldMappings.controls.length - 1);
        }
    }

    async removeMappedFields(i: number, event?: Event) {

        event?.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.fieldMappings.removeAt(i);
    }

    namePropertyFunc = (field: SchemaField) => `${field.shortLabel ?? field.label} (${field.identifier})`;

    private removeTransition() {
        this.control.get(TransitionTargetControlKeys.Transition)?.setValue(null, { onlySelf: true, emitEvent: false });
    }
}
