import { Component } from '@angular/core';
import { Breadcrumb, FromNowPipe, ModalService } from '@unifii/library/common';
import { DefinitionPublishState, UserReference } from '@unifii/sdk';

import { SaveOption } from 'components/common/save-options/save-options.component';

import { PUBLISH_STATE_CLASS_DICTIONARY } from 'helpers/css-class-helper';

import { BuilderHeaderService } from './builder-header.service';
import { PublishInfoComponent } from './publish-info.component';


interface LastModifiedPublished {
    by?: UserReference;
    at?: string;
}

export interface HeaderConfig {
    breadcrumbs?: Breadcrumb[];
    saveOptions?: SaveOption[];
    lastPublished?: LastModifiedPublished;
    lastModified?: LastModifiedPublished;
    cancelRoute?: any[];
    restrictSave?: string;
    edited?: boolean;
    title?: string;
    publishState?: DefinitionPublishState;
    disabledSave?: boolean;
}

@Component({
    selector: 'uc-builder-header',
    templateUrl: './builder-header.html',
    styleUrls: ['./builder-header.less']
})
export class BuilderHeaderComponent {

    readonly publishStateClassDictionary = PUBLISH_STATE_CLASS_DICTIONARY;

    constructor(
        private modal: ModalService,
        private fromNowPipe: FromNowPipe,
        private builderHeaderService: BuilderHeaderService,
    ) { }

    get saveOptions() {
        return this.config.saveOptions ?? [];
    }

    get showSaveOptions() {
        return this.saveOptions.length > 0;
    }

    get config() {
        return this.builderHeaderService.config;
    }

    get showInfo() {
        return this.config.publishState || this.config.lastModified?.at || this.config.lastPublished?.at;
    }

    get publishState() {
        return this.config.publishState;
    }

    get title() {
        return this.config.title;
    }

    get lastModified(): string | null {
        return this.mapLastModifiedPublished(this.config.lastModified);
    }

    get lastPublished(): string | null {
        return this.mapLastModifiedPublished(this.config.lastPublished);
    }

    get edited() {
        return this.config.edited;
    }

    mapLastModifiedPublished(data?: LastModifiedPublished) {
        if (!data) {
            return null;
        }

        const lastModifiedPublished = this.fromNowPipe.transform(data.at);
        if (data.by) {
            return `${lastModifiedPublished}, by ${data.by.username}`;
        }
        return lastModifiedPublished;
    }

    onSave(saveOption?: SaveOption) {
        this.builderHeaderService.saveClicked.emit(saveOption);
    }

    openInfoModal() {
        this.modal.openFit(PublishInfoComponent, { publishState: this.publishState, lastModified: this.lastModified, lastPublished: this.lastPublished, title: this.config.title });
    }

}
