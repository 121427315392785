import { lastValueFrom, Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';


export const sequenceGuardFactory = (...guards: CanActivate[]): SequenceGuard => new SequenceGuard(guards);

export class SequenceGuard implements CanActivate {

    constructor(private guards: CanActivate[] = []) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        for (const guard of this.guards) {

            const succeed = await this.toPromise((guard.canActivate(route, state) as boolean | Promise<boolean> | Observable<boolean>));

            if (!succeed) {
                return false;
            }
        }

        return true;
    }

    toPromise(v: boolean | Promise<boolean> | Observable<boolean>): Promise<boolean> {

        if (typeof v === 'boolean') {
            return Promise.resolve(v);
        }

        if (v instanceof Promise) {
            return v;
        }

        return lastValueFrom(v);
    }

}

export const TenantSequenceGuard = new InjectionToken<SequenceGuard>('TenantSequenceGuard');
