import { AppUpdateService } from 'app-update/app-update.service';

import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalAnchoredPosition, ModalPositionAlignment, ModalService, StorageWrapper } from '@unifii/library/common';

import { Config } from 'app-config';
import { MyAccount as UcMyAccount, SystemRole, UcClient, UcProjectInfo } from 'client';
import { PREVIEW_FORM_STORAGE_KEY } from 'constant';

import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';

import { NavContextMenuComponent } from './nav-context-menu.component';
import { MenuConfig } from './nav-models';


@Component({
    templateUrl: './tenant.html',
    styleUrls: ['./tenant.less']
})
export class TenantComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    menuConfigs: MenuConfig[] = [];
    projectsContextMenu: MenuConfig[] = [];

    constructor(
        private client: UcClient,
        private router: Router,
        private modalService: ModalService,
        @Inject(StorageWrapper) private storage: StorageWrapper,
        public context: ContextService,
        @Inject(Config) public config: Config,
        private appUpdate: AppUpdateService,
        private projectService: ProjectService,
    ) {
        this.initMenuConfig();
    }

    get account(): UcMyAccount {
        return this.context.account as UcMyAccount;
    }

    get project(): UcProjectInfo | null {
        return this.context.project;
    }

    get projectMenuConfig(): MenuConfig {
        return {
            label: 'Projects',
            contextMenuConfig: this.context.project ? this.projectsContextMenu.filter(config => !config.link?.includes(this.context.project?.id || '')) : this.projectsContextMenu
        };
    }

    ngOnInit() {
        this.appUpdate.init();
        this.loadProjectOptions();
    }

    initMenuConfig() {

        if (this.config.env === 'localhost' || this.config.env === 'dev') {
            this.menuConfigs.push({
                label: 'Lab',
                icon: 'labs',
                link: ['/lab'],
            });
        }

        if (this.context.checkRoles(SystemRole.AssetManager, SystemRole.ContentEditor, SystemRole.Publisher, SystemRole.ProjectManager)
            || this.context.checkRoles(SystemRole.Translator)) {
            this.menuConfigs.push({
                label: 'Assets',
                icon: 'assets',
                link: ['/assets'],
                contextMenuConfig: [{
                    label: 'Media',
                    restrict: [SystemRole.AssetManager, SystemRole.ContentEditor, SystemRole.Publisher, SystemRole.ProjectManager].join(','),
                    link: ['/assets', 'media']
                }, {
                    label: 'Translations',
                    restrict: SystemRole.Translator,
                    link: ['/assets', 'translations']
                }]
            });
        }

        if (this.context.checkRoles(SystemRole.UserManager) || this.context.checkRoles(SystemRole.Auditor)) {
            this.menuConfigs.push({
                label: 'User Management',
                icon: 'user-settings',
                link: ['/user-management'],
                contextMenuConfig: [{
                    label: 'Users',
                    restrict: SystemRole.UserManager,
                    link: ['/user-management', 'users']
                }, {
                    label: 'Roles',
                    restrict: SystemRole.UserManager,
                    link: ['/user-management', 'roles']
                }, {
                    label: 'User Claims',
                    restrict: SystemRole.UserManager,
                    link: ['/user-management', 'user-claims']
                }, {
                    label: 'Hierarchy',
                    restrict: SystemRole.UserManager,
                    link: ['/user-management', 'hierarchy'],
                }, {
                    label: 'Audit Log',
                    restrict: SystemRole.Auditor,
                    link: ['/user-management', 'audit-log']
                }, {
                    label: 'API Keys',
                    restrict: SystemRole.UserManager,
                    link: ['/user-management', 'api-keys']
                }]
            });
        }

        if (this.context.checkRoles(SystemRole.SystemManager) || this.context.checkRoles(SystemRole.SuperUser, SystemRole.AppManager)) {
            this.menuConfigs.push({
                label: 'System Settings',
                icon: 'tenant-settings',
                link: ['/system-settings'],
                contextMenuConfig: [{
                    label: 'General',
                    restrict: SystemRole.SystemManager,
                    link: ['/system-settings', 'general']
                }, {
                    label: 'Identity',
                    restrict: SystemRole.SystemManager,
                    link: ['/system-settings', 'sso']
                }, {
                    label: 'Identity 2',
                    restrict: SystemRole.SystemManager,
                    link: ['/system-settings', 'sso-new']
                }, {
                    label: 'Email Settings',
                    restrict: SystemRole.SystemManager,
                    link: ['/system-settings', 'email']
                }, {
                    label: 'SMTP Settings',
                    restrict: SystemRole.SystemManager,
                    link: ['/system-settings', 'smtp']
                }, {
                    label: 'Integrations',
                    restrict: SystemRole.SystemManager,
                    link: ['/system-settings', 'integrations']
                }, {
                    label: 'Apps',
                    restrict: [SystemRole.SuperUser, SystemRole.AppManager].join(','),
                    link: ['/system-settings', 'apps']
                }]
            });
        }

        this.menuConfigs.push({
            label: 'My Account',
            icon: 'users-and-security',
            link: ['/my-account'],
            contextMenuConfig: [{
                label: 'My Account',
                link: ['/my-account']
            }, {
                label: 'Log Out',
                action: () => this.logout()
            }]
        });
    }

    async logout() {
        const consent = await this.modalService.openConfirm({
            title: 'Logout',
            cancelLabel: 'No', confirmLabel: 'Yes'
        });

        if (!consent) {
            return;
        }

        this.context.clear();
        this.client.deauthenticate();
        this.storage.removeItem(PREVIEW_FORM_STORAGE_KEY);
        this.router.navigate(['/login']);
    }

    projectSelect(projectId: string) {
        this.router.navigate(['/projects/', projectId]);
    }

    openContextMenu(event: Event, menuConfig: MenuConfig, label?: string) {
        if (menuConfig == null) {
            return;
        }

        this.modalService.closeLatest();

        const position = this.getAnchorPosition(event.target as HTMLElement);
        this.modalService.openAnchor(NavContextMenuComponent, event, position, menuConfig);
    }

    // TODO make a util function
    private getAnchorPosition(target: HTMLElement): ModalAnchoredPosition {
        return {
            target,
            originX: ModalPositionAlignment.Right,
            originY: ModalPositionAlignment.Top,
            alignmentX: ModalPositionAlignment.Right,
            alignmentY: ModalPositionAlignment.Bottom
        };
    }

    private async loadProjectOptions() {
        if (!this.projectService.checkProjectRoles()) {
            return;
        }

        const projects = await this.client.getProjects();
        this.projectsContextMenu = projects.map(project => ({
            label: project.name,
            link: ['/projects', project.id]
        }));
    }
}
