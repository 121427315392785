<div class="header">
    <button type="button" class="uf-action tertiary" (click)="runtime.close()" title="Close">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>Add / Edit Field Mapping</h3>
</div>
<uf-panel>
    <div *ngIf="form" class="uf-box flat col-12 gap-top gap-sides">
        <div class="uf-app-bar flat accent">
            <div class="title grow">Provider Values</div>
        </div>
        <div class="uf-grid">

            <uc-auth-provider-mapping-unit *ngIf="condition && !!condition.get(controlKeys.Type)?.value"
                [form]="condition" [sourceClaims]="sourceClaims" [selected]="selected"
                (deletedClick)="deleteCondition($event)" [authProviderId]="authProviderId"
                (selectedChange)="selectCondition($event)" class="col-12"></uc-auth-provider-mapping-unit>

            <!-- <uc-identity-mapping-unit *ngIf="!!condition" [selected]="selected"
                (selectedChange)="selectCondition($event)" [form]="condition" [authProviderId]="authProviderId"
                [sourceClaims]="sourceClaims" (deletedClick)="deleteCondition($event)"
                class="col-12"></uc-identity-mapping-unit> -->
            <div class="col-12 row right">
                <button *ngIf="showAddCondition" [options]="conditionOptions" (optionSelected)="addCondition($event)"
                    type="button" class="uf-button x-small right gap-bottom gap-right gap-top" listBox
                    nameProperty="name">
                    Add
                    <uf-icon name="more" class="gap-none"></uf-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="uf-box flat col-12 gap-top gap-sides">
        <div class="uf-app-bar flat accent">
            <div class="title grow">Unifii Values</div>
        </div>
        <div class="uf-grid">
            <uc-auth-provider-mapping-actions [actions]="actions" [sourceClaims]="sourceClaims"
                class="col-12"></uc-auth-provider-mapping-actions>
        </div>
    </div>
    <!--  -->
</uf-panel>
<div class="row space-children pad">
    <uf-error [control]="form"></uf-error>
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
    <!-- <button (click)="delete()" type="button" class="uf-button">Delete Mapping</button> -->
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>