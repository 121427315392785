import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import {
    DataPropertyDescriptor, DateAndTimeDisplayFormat, DateAndTimeFormat, DateDisplayFormat, DateFormat, DateWeekDayAndTimeDisplayFormat,
    DateWeekDayDisplayFormat, ExpressionParser, isIdentifiersPathExpression, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions
} from '@unifii/library/common';
import {
    CellTemplate, CellTemplateType, CellVariation, ColumnDescriptor, DataSeed, FieldType, generateUUID, IconCell, LozengeCell, MessageColour
} from '@unifii/sdk';

import { allowsLabelOverride } from 'helpers/data-descriptor-helper';

import { DialogsService } from 'services/dialogs.service';

import { TableComponent } from '../table.component';


interface ColumnInputValue {
    showOnDesktop: boolean;
    showOnMobile: boolean;
    heading: string;
    defaultTemplate?: CellTemplateType;
    defaultFormat?: string;
    defaultCellValue?: string;
    colour?: MessageColour;
    variations: VariationInputValue[];
}

interface VariationInputValue {
    condition: string;
    value?: string;
    template?: CellTemplateType;
    colour?: MessageColour;
}

@Component({
    selector: 'uc-column-descriptor-editor',
    templateUrl: 'column-descriptor-editor.html'
})
export class ColumnDescriptorEditorComponent implements OnInit, OnDestroy {

    @Input() parentControl: UfControlGroup;
    @Input() column: ColumnDescriptor;
    @Input() descriptor?: DataPropertyDescriptor;
    @Output() columnChange = new EventEmitter<ColumnDescriptor>();

    readonly templateOptions: DataSeed[] = [
        { _display: 'Default', _id: '' },
        { _display: 'Lozenge', _id: CellTemplateType.Lozenge }
    ];

    readonly colourOptions: DataSeed[] = [
        { _display: 'Accent', _id: MessageColour.Accent },
        { _display: 'Information', _id: MessageColour.Info },
        { _display: 'Warning', _id: MessageColour.Warning },
        { _display: 'Error', _id: MessageColour.Error },
        { _display: 'Success', _id: MessageColour.Success }
    ];

    readonly formatDatePlaceholder = DateDisplayFormat;
    readonly formatDateOptions = [
        DateFormat,
        DateWeekDayDisplayFormat,
        `E${DateWeekDayDisplayFormat}`
    ];

    readonly formatDateTimePlaceholder = DateAndTimeDisplayFormat;
    readonly formatDateTimeOptions = [
        DateAndTimeFormat,
        DateWeekDayAndTimeDisplayFormat,
        `E${DateWeekDayAndTimeDisplayFormat}`
    ];

    headingControl: UfControl;
    defaultCellValueControl: UfControl;
    control: UfControlGroup;
    formatOptions: string[] | null;
    formatPlaceholder: string | null;

    private controlIdentifier = generateUUID();
    private subscriptions = new Subscription();

    constructor(
        private dialogs: DialogsService,
        private expressionParser: ExpressionParser,
        @Optional() private tableComponent?: TableComponent
    ) { }

    ngOnInit() {

        const isCustomColumn = !isIdentifiersPathExpression(this.column.identifier);

        this.defaultCellValueControl = new UfControl();

        this.headingControl = new UfControl(ValidatorFunctions.custom(v =>
            !isCustomColumn ? true : !ValidatorFunctions.isEmpty(v)
            , 'Heading is required')
        );

        this.control = new UfControlGroup({
            showOnDesktop: new UfControl(),
            showOnMobile: new UfControl(),
            heading: this.headingControl,
            defaultTemplate: new UfControl(),
            defaultFormat: new UfControl(),
            defaultCellValue: this.defaultCellValueControl,
            colour: new UfControl(),
            variations: new UfControlArray([])
        });

        if (!isCustomColumn) {
            this.defaultCellValueControl.disable();

            if (!allowsLabelOverride(this.tableComponent?.info.dataDescriptor.type, this.descriptor)) {
                this.headingControl.disable();
            }
        }

        switch (this.descriptor?.type) {
            case FieldType.Date:
                this.formatPlaceholder = this.formatDatePlaceholder;
                this.formatOptions = this.formatDateOptions;
                break;
            case FieldType.DateTime:
            case FieldType.ZonedDateTime:
                this.formatPlaceholder = this.formatDateTimePlaceholder;
                this.formatOptions = [
                    ...this.formatDateTimeOptions,
                    this.formatDatePlaceholder,
                    ...this.formatDateOptions
                ];
                break;
        }

        const inputValue = this.toInputValue(this.column);
        inputValue.variations.forEach(() => this.addVariationControl());

        this.control.setValue(inputValue);
        this.subscriptions.add(this.control.valueChanges.subscribe((v: ColumnInputValue) => this.onValueChanges(v)));

        this.parentControl.setControl(this.controlIdentifier, this.control);
    }

    ngOnDestroy() {
        this.parentControl.removeControl(this.controlIdentifier);
        this.subscriptions.unsubscribe();
    }

    addVariationControl() {
        (this.control.get('variations') as UfControlArray).push(this.variationControl);
    }

    async removeVariation(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        (this.control.get('variations') as UfControlArray).removeAt(index);
    }

    emit() {
        this.control.get('variations')?.updateValueAndValidity();
    }

    private toColumnDescriptor(v: ColumnInputValue): ColumnDescriptor {

        const { showOnDesktop, showOnMobile, heading, defaultTemplate, defaultFormat, defaultCellValue, colour, variations } = v;

        const descriptor: ColumnDescriptor = {
            identifier: this.column.identifier,
            hideOnDesktop: !showOnDesktop,
            hideOnMobile: !showOnMobile,
            heading,
            defaultTemplate: defaultTemplate ? this.getTemplate(defaultTemplate, colour) : undefined,
            defaultFormat,
            defaultCellValue,
            variations: variations.map(variation => this.toColumnVariation(variation)),
        };

        this.purgeEmptyValues(descriptor);
        return descriptor;
    }

    private toColumnVariation(v: VariationInputValue): CellVariation {

        const { value, condition, template, colour } = v;
        const variation: CellVariation = { value, condition };

        if (template) {
            variation.template = this.getTemplate(template, colour);
        }
        this.purgeEmptyValues(variation);
        return variation;
    }

    private toInputValue(column: ColumnDescriptor): ColumnInputValue {

        const { hideOnMobile, hideOnDesktop, heading, defaultTemplate, defaultFormat, defaultCellValue, variations } = column;

        return {
            showOnDesktop: !hideOnDesktop,
            showOnMobile: !hideOnMobile,
            heading: heading || '',
            defaultTemplate: defaultTemplate?.type || '' as any,
            defaultFormat: defaultFormat ?? '',
            defaultCellValue: defaultCellValue || null as any,
            colour: (defaultTemplate as LozengeCell | IconCell)?.colour || null as any,
            variations: (variations || []).map(v => this.toInputVariation(v))
        };
    }

    private toInputVariation(variation: CellVariation): VariationInputValue {

        const { value, condition, template } = variation;

        return {
            value: value || '',
            condition: condition || '',
            template: template?.type || '' as CellTemplateType,
            colour: (template as LozengeCell | IconCell)?.colour || null as any
        };
    }

    private get variationControl(): UfControlGroup {
        const template = new UfControl();
        template.setValue('');
        return new UfControlGroup({
            value: new UfControl(),
            condition: new UfControl(ValidatorFunctions.compose([
                ValidatorFunctions.required('A condition is required'),
                ValidatorFunctions.isValidExpression(this.expressionParser, 'Provide a valid expression')
            ])),
            template,
            colour: new UfControl()
        });
    }

    private getTemplate(type: CellTemplateType, colour?: MessageColour): CellTemplate {
        const template: CellTemplate = { type };
        if (colour) {
            (template as LozengeCell | IconCell).colour = colour;
        }
        return template;
    }

    private onValueChanges(v: ColumnInputValue) {
        const descriptor = this.toColumnDescriptor(v);
        this.columnChange.emit(descriptor);
    }

    private purgeEmptyValues(obj: CellVariation | ColumnDescriptor) {

        for (const key of Object.keys(obj)) {
            const value = obj[key as keyof CellVariation];

            if (!value || (Array.isArray(value) && !value.length)) {
                delete obj[key as keyof CellVariation];
            }
        }
    }

}