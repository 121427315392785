

import { Component, HostBinding, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageLevel, ModalService, ToastService } from '@unifii/library/common';
import { ComponentRegistry, FormConfiguration, FormService } from '@unifii/library/smart-forms';
import { CompoundType, Definition, FieldType } from '@unifii/sdk';

import { BuilderField, UcDefinition, UcProject } from 'client';

import { EditMode } from 'components/common/edit-data';
import { SaveAndClose, SaveOption } from 'components/common/save-options/save-options.component';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { HeaderConfig } from 'components/compound-builder/builder-header/builder-header.component';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentEditorRegistry } from 'components/content-editor-registry';
import { ContentSettings } from 'components/content/content';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from 'components/content/modals/link-search.component';
import { ModalSearchData } from 'components/content/modals/modal-search';

import { IdentifierFunctions } from 'helpers/helpers';

import { CollectionService, collectionServiceFactory } from 'pages/content/collections/collection-service';

import { BreadcrumbService } from 'services/breadcrumb.service';


@Component({
    templateUrl: './collection-builder.html',
    styleUrls: ['./collection-builder.less', './../../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        { provide: CollectionService, useFactory: collectionServiceFactory, deps: [ActivatedRoute, UcProject] },
        { provide: ComponentRegistry, useClass: ContentEditorRegistry },
        { provide: ContentSettings, useValue: { mode: 'DataEntry' } },
        FormService
    ]
})
export class CollectionBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') get newClass() { return this.editMode === EditMode.New; }

    type = CompoundType.Collection;
    subject = BuilderCompoundSubjects.DEFINITION;
    ready: boolean;
    definition: Definition;
    selected: any;  // field
    config: FormConfiguration = { hideLabel: true };
    headerConfig: HeaderConfig = {};

    private lastInserted: any;  // field

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected router: Router,
        protected route: ActivatedRoute,
        private ucProject: UcProject,
        private toastService: ToastService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        @Optional() private collectionService: CollectionService,
    ) {
        super(builderService, modalService, route);
        this.editMode = route.snapshot.params.id === 'new' ? EditMode.New : EditMode.Existing;
    }

    async ngOnInit() {

        // TODO hacky way to make definition wait. Review collections service's async provider factory
        await this.collectionService?.definitionLoadPromise;

        // Override save options logic
        this.saveOptions = [SaveAndClose];

        // Load data
        const definition = this.load(this.editMode);
        this.definition = definition as Definition;
        // Subscribe to events
        this.addSubscribers();
        // Initialize the builder service
        this.builderService.init(this, this.definition);

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
        this.buildHeaderConfig(definition);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.editMode === EditMode.Existing) {
            this.collectionService?.restore();
        }
    }

    addSubscribers() {
        // React to builderService ready
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            if (this.editMode === EditMode.Existing && this.collectionService) {
                this.collectionService.edited = false;
            }
            this.builderService.memento.edited = false;
            this.ready = true;
            this.builderService.fieldSelect.next(null);
        }));

        this.subscriptions.add(this.builderService.fieldAdded.subscribe(i => {
            this.selectField(i);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldMoved.subscribe(i => {
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldRemoved.subscribe(i => {
            this.selectField(undefined);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe(i => {
            this.builderService.memento.setLastAtomic();
        }));

        this.subscriptions.add(this.builderService.fieldReady.subscribe(i => {
            if (this.lastInserted === i.subject) {
                this.selectField(i);
                this.lastInserted = null;
            }
        }));
    }

    selectField(i: BuilderEventInfo = { subject: null, atomic: false }) {
        // Apply a request to select a field
        this.selected = !i ? null : i.subject;
        this.builderService.fieldSelected.next(i);
    }

    removeField(i?: BuilderEventInfo) {
        // Apply a request to remove a field
        if (!i) {
            console.warn('removeField - BuilderEventInfo null!');
            return;
        }
        const position = this.getFieldPosition(i.subject);
        if (position) {
            position.parent[this.builderService.childrenProperty].splice(position.index, 1);
            this.builderService.fieldRemoved.next(i);
        }
    }

    canDrop = async (element: any, _parent: any): Promise<boolean> => {

        if (!element.type || (element.type && (FieldType as any)[element.type] == null)) {
            // Not a field (element from right panel like Validator, Option....)
            return false;
        }

        if (!element.reference) {
            // An actual field and not it's reference from the left tools list
            return true;
        }

        // LinkList (select a single collection)
        if (element.type === FieldType.LinkList) {

            const data: LinkSearchConfig = {
                title: 'Select Collection',
                multiSelect: false,
                minQuantitySelected: 1,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection
            };

            const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

            if (!result || !result.length) {
                return false;
            }

            element.field = {
                label: result[0].name,
                type: element.type,
                compoundType: CompoundType.Collection,
                definitionIdentifier: result[0].identifier
            };

            return true;
        }

        // Link of type Collection (select a list of collections)
        if (element.type === FieldType.Link && element.compoundType === CompoundType.Collection) {

            const data: LinkSearchConfig = {
                title: 'Select Collections',
                multiSelect: true,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection
            };

            const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

            if (!result) {
                return false;
            }

            element.field = {
                label: element.label,
                type: element.type,
                compoundType: CompoundType.Collection
            };

            if (result.length) {
                element.field.types = result.map((c: any) => c.identifier);
            }

            return true;
        }

        if ([FieldType.Text, FieldType.MultiText].indexOf(element.type) >= 0) {

            element.field = {
                type: element.type,
                isTranslatable: true
            };

            return true;
        }

        // No dialog needed
        return true;
    };

    back() {
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    convertFieldRef(ref: any) {
        if (ref.field) {
            return ref.field;
        }

        const res: any = { type: ref.type };
        if (ref.compoundType) {
            res.compoundType = ref.compoundType;
            res.label = ref.label;
        }
        return res;
    }

    inserted(field: BuilderField) {
        // Update label
        field.isNew = true;
        if (!field.label) {
            field.label = IdentifierFunctions.generateDisplayLabel(field.type);
        }
        field.identifier = IdentifierFunctions.safeFieldIdentifier(
            field,
            this.builderService.definition.fields,
            field.definitionIdentifier || field.type
        );
        this.lastInserted = field;
        this.builderService.fieldAdded.next({ subject: field, atomic: true });
    }


    restore(step: number) {
        const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();
        this.builderService.definition = value;
        this.builderService.fieldSelect.next(null);
    }

    async save(saveOption?: SaveOption) {

        this.builderService.submit.next(null);

        if (!this.builderService.isValid()) {
            this.builderService.markFieldsSubmitted();
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: 'Unable to save. There are errors in your Collection.' });
            return;
        }

        const cleanDefinition = this.builderService.cleanDefinition(this.builderService.definition);

        // Save the collection
        this.builderService.busy.next(true);

        try {
            const definition = await this.ucProject.saveCollection(cleanDefinition);

            this.builderService.memento.edited = false;

            if (this.collectionService) {
                // Update collection service if builder used in tabs
                this.collectionService.definition = definition;
                if (this.editMode === EditMode.Existing) {
                    this.collectionService.edited = false;
                }
            }

            // Save the id for dirty check directive
            this.toastService.success('Collection saved!');


            if (saveOption && saveOption.id) {
                // Other builders have more than one option
                this.back();
            }

            if (!saveOption) {
                const paths = ['../../', definition.identifier];
                if (cleanDefinition.id) {
                    paths.push('definition');
                }
                this.router.navigate(paths, { relativeTo: this.route });
            }

            // Need to refresh CollectionsComponent list cause
            // a) a new collection has been added
            // b) a collection has been edited in its id and/or label
            // Need to refresh CollectionComponent list cause on definition changed
            // All the collection items are set back to Draft

            this.definition = definition as Definition;
            this.saved(this.definition, saveOption);
            this.builderService.init(this, this.definition);
            this.buildHeaderConfig(definition);
        } catch (error) {
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: error?.data?.Message ?? error.message });
            /*
            console.warn('Comunication error custom handled');
            const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
            */
        } finally {
            this.builderService.busy.next(false);
        }
    }

    private load(mode: EditMode): UcDefinition {

        if (mode === EditMode.Existing) {
            return this.collectionService.definition;
        }

        return {
            recordName: '',
            label: '',
            identifier: '',
            compoundType: CompoundType.Collection,
            fields: [],
            version: 0,
            bucket: '',
            settings: {}
        };
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.definition, i.atomic);
        this.builderService.memento.edited = true;
        if (this.editMode === EditMode.Existing && this.collectionService) {
            this.collectionService.edited = true;
        }

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(definition: UcDefinition) {
        this.builderHeaderService.buildConfig({
            ...definition,
            title: 'New',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, ['New']),
            publishState: definition.publishState,
            saveOptions: this.saveOptions,
            cancelRoute: ['../../'],
        });
    }
}
