<uf-expander class="uc-group" [class.uc-group--draggable--no-shrink]="!disabled && draggable && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="!disabled && draggable && isSelected">
    <div expanderHeader class="uf-app-bar flat" [class.accent]="isSelected" dragHandle (click)="select()">
        <uf-icon [name]="icon"></uf-icon>
        <div class="title primary">
            {{ title }}<span *ngIf="field.label"> | {{ field.label }}</span>
        </div>
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning"></uf-icon>
        <uf-help class="uf-action tertiary" *ngIf="field.help" [content]="field.help"></uf-help>
        <a class="uf-action tertiary" *ngIf="canRemove" [restrict]="removeRestrictions" (click)="remove()"
            title="Delete">
            <uf-icon name="delete"></uf-icon>
        </a>
    </div>
    <div expanderBody class="uc-group-content" [class.content--accent]="isSelected">
        <ul class="uc-list" *ngIf="content">
            <li class="uc-list-item--small--no-shrink row center-all">
                <div class="list-content">
                    <div class="list-context">
                        <!-- <uf-icon
                            *ngIf="content.publishState && (content.publishState === 'ArchivePending' || content.publishState === 'Archived')"
                            name="warning" class="icon-warning"
                            title="This {{ field.compoundType | lowercase }} has been archived">
                        </uf-icon> -->
                        <a class="uf-action tertiary" [routerLink]="getLink(content)" target="_blank" title="Open">
                            <uf-icon name="open" class=""></uf-icon>
                        </a>
                        <a class="uf-action tertiary" *ngIf="canDelete && content" (click)="delete(0)" title="Delete">
                            <uf-icon name="delete"></uf-icon>
                        </a>
                    </div>
                    <div>{{ $any(content).name || $any(content).recordName }}</div>
                </div>
            </li>
        </ul>
        <div class="row padded" *ngIf="canAdd && !content">
            <button class="uf-button x-small right" (click)="add()">Add {{ title }}</button>
        </div>
    </div>
</uf-expander>
<uf-error [control]="control"></uf-error>