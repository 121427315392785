/* eslint-disable @typescript-eslint/member-ordering */
import { Directive, EventEmitter, Type } from '@angular/core';
import { ModalService } from '@unifii/library/common';

import { BuilderService } from 'components/compound-builder/builder.service';
import { Content, ContentSettings } from 'components/content/content';
import { LinkSearchConfig } from 'components/content/modals/link-search.component';
import { DialogsService } from 'services/dialogs.service';

import { ModalSearch, ModalSearchData } from './modals/modal-search';


@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ContentList extends Content {

    content: any[] | null;
    contentChange = new EventEmitter<any[]>();

    // contentList specializations
    protected abstract getSearchConfig(): LinkSearchConfig;
    protected abstract convertResultToContent(item: any): any;

    constructor(
        private modalSearchType: Type<ModalSearch>,
        protected modalService: ModalService,
        builderService: BuilderService,
        contentSettings: ContentSettings,
        dialogs: DialogsService
    ) {
        super(builderService, dialogs, contentSettings);
    }

    get canAdd() {
        return this.contentSettings.mode !== 'Preview' && !this.disabled;
    }

    get canDelete() {
        return this.contentSettings.mode !== 'Preview' && !this.disabled;
    }

    canDrop = (_element: any, _parent: any): boolean => false;

    moved() {
        this.builderService.fieldEdit.next({ subject: this.field, atomic: true });
    }

    async add() {

        const searchConfig = this.getSearchConfig();

        const entries = await this.modalService.openMedium<ModalSearchData, any[]>(
            this.modalSearchType, searchConfig
        );

        if (!this.content && searchConfig.multiSelect) {
            this.content = [];
            if (this.scope) {
                this.scope[this.field.identifier as string] = this.content;
            }
        }

        if (entries && entries.length) {
            if (searchConfig.multiSelect) {

                entries.forEach(e => {
                    (this.content as any[]).push(this.convertResultToContent(e));
                });

                if (this.scope) {
                    this.scope[this.field.identifier as string] = this.content;
                }

            } else {
                this.content = this.convertResultToContent(entries[0]);

                if (this.scope) {
                    this.scope[this.field.identifier as string] = this.content;
                }
            }
            this.builderService.fieldEdit.next({ subject: this.field, atomic: true });
        }

        this.control.markAsDirty();
        this.control.setValue(this.content);
        this.contentChange.emit(this.content || undefined);
    }

    delete(i: number) {

        if (this.getSearchConfig().multiSelect) {
            (this.content as any[]).splice(i, 1);

            if (this.scope) {
                this.scope[this.field.identifier as string] = this.content;
            }

        } else {
            this.content = null;
            if (this.builderService.compound) {
                this.builderService.compound[this.field.identifier as string] = null;
            }
        }

        this.control.markAsTouched();
        this.control.setValue(this.content);
        this.builderService.fieldEdit.next({ subject: this.field, atomic: true });
    }
}
