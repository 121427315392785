<div class="header-pane uf-app-bar transparent" [ngClass]="{'pad-sides': isMyAccount || isNew}">
    <uf-breadcrumbs *ngIf="(isNew || isMyAccount) && form" [breadcrumbs]="breadcrumbs" class="large">
    </uf-breadcrumbs>
    <a class="uf-button tertiary right" [routerLink]="['../']">Cancel</a>
    <button type="button" *ngIf="!isMyAccount && !isNew && status === userStatus.Pending" class="uf-button"
        (click)="delete()">
        Delete
    </button>
    <button type="button" *ngIf="!isNew" class="uf-button" [class.primary]="isMyAccount"
        [ngClass]="{'busy': inProgress}" (click)="save()">
        Save
    </button>
    <button type="button" *ngIf="!isMyAccount" class="uf-button primary" [ngClass]="{'busy': inProgress}"
        (click)="save(true)">
        Save &amp; Close
    </button>
</div>
<uf-panel *ngIf="form" class="content-pane container">
    <div *ngIf="!loading" [formGroup]="form" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="uf-grid pad-sides pad-top">

                    <uf-message *ngIf="error" class="error col-12" icon="errorSolid" [content]="error.message">
                    </uf-message>

                    <uf-user-description *ngIf="user" class="col-12" [userInfo]="userInfo"
                        [filterBy]="descriptionFilter">
                    </uf-user-description>

                    <ng-container *ngIf="!user.isExternal">
                        <uf-text *ngIf="!disabledLookup[controlKeys.Username]" class="col-12 col-md-6"
                            [label]="commonTK.UsernameLabel | translate" [formControlName]="controlKeys.Username">
                            <span class="suffix">*</span>
                        </uf-text>
                        <uf-text *ngIf="!disabledLookup[controlKeys.FirstName]" class="col-12 col-md-6"
                            [label]="commonTK.FirstNameLabel | translate" [formControlName]="controlKeys.FirstName">
                        </uf-text>
                        <uf-text *ngIf="!disabledLookup[controlKeys.LastName]" class="col-12 col-md-6"
                            [label]="commonTK.LastNameLabel | translate" [formControlName]="controlKeys.LastName">
                        </uf-text>
                        <uf-text *ngIf="!disabledLookup[controlKeys.Email]" class="col-12 col-md-6"
                            [label]="commonTK.EmailLabel | translate" [formControlName]="controlKeys.Email">
                            <span class="suffix" *ngIf="tenant.isUserEmailRequired">*</span>
                        </uf-text>
                        <uf-text *ngIf="!disabledLookup[controlKeys.Phone]" class="col-12 col-md-6"
                            [label]="commonTK.PhoneLabel | translate" [formControlName]="controlKeys.Phone">
                        </uf-text>
                        <uf-company-input *ngIf="companyControl && !disabledLookup[controlKeys.Company]"
                            class="col-12 col-md-6" [formControlName]="controlKeys.Company"
                            [label]="labelDictionary[controlKeys.Company]">
                        </uf-company-input>
                    </ng-container>
                </div>

                <uf-user-description *ngIf="claimDescriptionFilter.length && claimsControl?.disabled"
                    [userInfo]="userInfo" [filterBy]="claimDescriptionFilter" class="col-12 pad border-bottom">
                </uf-user-description>

                <div class="uf-grid uf-box flat gap-top gap-bottom" *ngIf="claimsControl?.enabled">
                    <uf-claim-editor *ngIf="claimsControl?.enabled" [control]="claimsControl"
                        [claimSource]="claimSource" [width]="fieldWidth.Half" class="col-12">
                    </uf-claim-editor>
                </div>

                <div class="uf-grid pad-bottom">
                    <uf-password-change class="col-12" *ngIf="passwordControl" [user]="userInfo"
                        [passwordControl]="passwordControl" [oldPasswordControl]="oldPasswordControl"
                        [changePasswordOnNextLoginControl]="changePasswordOnNextLoginControl" [showToggle]="!isNew">
                    </uf-password-change>

                    <uf-status-change class="col-12" *ngIf="lastActivationReasonControl && isActiveControl"
                        [lastActivationReasonControl]="lastActivationReasonControl" [isActiveControl]="isActiveControl"
                        [user]="userInfo">
                    </uf-status-change>
                </div>
            </div>

            <div class="uf-box col-12 gap-top-sm" *ngIf="managerInfo || managerMappedFrom || managerControl.enabled">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Manager
                    </div>
                    <button *ngIf="managerControl.enabled && managerInfo != null && !managerMappedFrom" type="button"
                        title="Delete" (click)="selectManager(null)" class="uf-action">
                        <uf-icon name="delete"></uf-icon>
                    </button>
                </div>
                <div class="uf-grid pad">
                    <uf-search-result *ngIf="managerControl.enabled && managerInfo == null && !managerMappedFrom"
                        class="col-12 default" placeholder="Select manager" nameProperty="_display"
                        [options]="managerOptions" (searchChange)="searchUsers($event)"
                        (valueChange)="selectManager($event)">
                    </uf-search-result>
                    <uf-description-list *ngIf="managerInfo" class="col-12" [items]="managerInfo">
                    </uf-description-list>
                    <div class="col-12" *ngIf="managerInfo == null && managerMappedFrom">
                        <p>
                            Manager set by <b>{{ managerMappedFrom }}</b>
                        </p>
                    </div>
                </div>
            </div>

            <div class="uf-box col-12" *ngIf="user.isExternal || (authProviders.length && isMyAccount)">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        External Authentication
                    </div>
                </div>
                <div class="uc-group-content uf-grid pad">

                    <ng-container *ngFor="let provider of userAuthProviders">
                        <div class="col-12 body-copy">
                            <h5>{{provider.type}} - {{provider.tenant}}</h5>
                            <uf-description-list>
                                <ng-container *ngIf="authProviderInfo[provider.id].systemRoles.length">
                                    <dt>System Roles:</dt>
                                    <dd>{{ authProviderInfo[provider.id].systemRoles.join(', ') }}</dd>
                                </ng-container>
                                <ng-container *ngIf="authProviderInfo[provider.id].roles.length">
                                    <dt>Tenant Roles:</dt>
                                    <dd>{{ authProviderInfo[provider.id].roles.join(', ') }}</dd>
                                </ng-container>
                                <ng-container *ngIf="authProviderInfo[provider.id].claims.length">
                                    <dt>Claims:</dt>
                                    <dd>
                                        <span *ngFor="let claim of authProviderInfo[provider.id].claims">
                                            {{ claim }}<br></span>
                                    </dd>
                                </ng-container>
                                <ng-container *ngIf="authProviderInfo[provider.id].units?.length">
                                    <dt>Units:</dt>
                                    <dd>
                                        <span *ngFor="let unit of authProviderInfo[provider.id].units">
                                            {{ unit.name }}<br></span>
                                    </dd>
                                </ng-container>
                            </uf-description-list>
                        </div>
                    </ng-container>


                    <ng-container *ngIf="authProviders.length && isMyAccount && !user.isExternal">

                        <uf-message *ngIf="authProviderError" class="error col-12"
                            [content]="authProviderError.message || 'Failed to linke to provider'" icon="error">
                        </uf-message>

                        <uf-checkbox class="col-12" label="Connect your Unifii account to an Identity Provider"
                            [(value)]="connectToExternal">
                        </uf-checkbox>

                        <ng-container *ngIf="connectToExternal">
                            <uf-blockquote class="info col-12" icon="infoSolid"
                                heading="Connect your Unifii account to an Identity Provider"
                                content="If you’re currently logged in to the selected provider in the browser, this account will be selected automatically. Otherwise you will be required to log in.">
                            </uf-blockquote>

                            <div class="col-12">
                                <ng-template ngFor [ngForOf]="authProviders" let-provider>
                                    <label *ngIf="provider.providerLoginLabel"
                                        class="small">{{provider.providerLoginLabel}}</label>
                                    <button type="button" class="authentication-provider gap-md-bottom"
                                        (click)="linkToProvider(provider)">
                                        <img [src]="provider.loginIcon" />
                                        <p class="small">{{provider.loginLabel}}</p>
                                    </button>
                                </ng-template>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="uf-box col-12 gap-top-sm" *ngIf="unitsControl?.enabled">
                <div class="uf-app-bar flat accent">
                    <div class="title">{{ labelDictionary[controlKeys.Units] }}</div>
                </div>
                <div class="uf-grid pad-bottom">
                    <uf-hierarchy-input class=" col-12" [control]="unitsControl"
                        [unselectableUnits]="lockedProperties.units">
                    </uf-hierarchy-input>
                </div>
            </div>

            <div *ngIf="systemRolesControl.enabled || superUserControl.enabled" class=" uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        System Roles
                    </div>
                </div>
                <div class="uc-group-content uf-grid pad">
                    <uf-checkbox *ngIf="superUserControl.enabled" class="col-12" label="SuperUser"
                        [control]="superUserControl" (valueChange)="toggleSuperUser($event)">
                    </uf-checkbox>
                    <uf-multi-choice *ngIf="systemRoleOptions.length" [formControlName]="controlKeys.SystemRoles"
                        [options]="systemRoleOptions" label="System Roles" columns="4" class="col-12">
                    </uf-multi-choice>
                </div>
            </div>

            <div class="uf-box col-12" *ngIf="rolesControl.enabled">
                <div class="uf-app-bar  accent">
                    <div class="title">{{tenant.name}} Roles</div>
                </div>
                <div class="pad">
                    <uf-role-input [formControlName]="controlKeys.Roles" [lockedRoles]="lockedProperties.roles">
                    </uf-role-input>
                </div>
            </div>
        </div>
    </div>
</uf-panel>