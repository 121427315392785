import { differenceInMilliseconds, parseISO } from 'date-fns';
import { Subscription, timer } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, HierarchyUnitProvider, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions,
    WindowWrapper
} from '@unifii/library/common';
import { AuthProvider, Dictionary, Error, ErrorType, HierarchyUnitWithPath, TenantClient } from '@unifii/sdk';

import {
    AuthProviderDetails, AuthProviderSourceGroup, FieldMapping, IntegrationFeatureConfig, SystemRole, TenantSettings, UcAuthProviders, UcRoles
} from 'client';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { AuthProviderMappingConditionType } from '../new-identity/models';

import { conditionDictionary, getMappingConditionLabels } from './auth-provider-helpers';
import { AuthProviderMappingModalComponent } from './auth-provider-mapping-modal.component';
import { AuthProviderMappingsController } from './auth-provider-mappings-controller';
import { ConditionControlKeys, MappingsControlKeys } from './auth-provider-model';
import { AuthProvidersTableManager } from './auth-providers-table-manager';
import { AuthProviderClaimValueMapFormModel, ClaimControlKeys, ClaimMappingController } from './claim-mapping-controller';
import { ClaimMappingModalComponent } from './claim-mapping-modal.component';
import { loadMappingUnits } from './identity-functions';


export interface FieldMappingOption extends FieldMapping, Dictionary<any> {
}

enum ControlKeys {
    Type = 'type',
    Id = 'id',
    ClientId = 'clientId',
    ClientSecret = 'clientSecret',
    Extras = 'extras',
    Tenant = 'tenant',
    ManualRegistration = 'manualRegistration',
    Manual = 'manual',
    IsActive = 'isActive',
    SystemRolesMapping = 'systemRolesMapping',
    TenantRolesMapping = 'tenantRolesMapping',
    ClaimsMapping = 'claimsMapping',
    FeatureConfig = 'featureConfig',
    UserFieldsMapping = 'userFieldsMapping',
    Mappings = 'mappings',
    ScimToken = 'scimToken',
    ProviderLoginLabel = 'providerLoginLabel',
    DisableSsoMapping = 'disableSsoMapping'
}

enum FeatureConfigControlKeys {
    Enabled = 'enabled',
    Label = 'label'
}

const DefaultFeatureConfig: IntegrationFeatureConfig = {
    getManager: {
        disabled: true,
        label: 'Get Manager Info (requires delegated permission User.Read.All)'
    }
};

@Component({
    templateUrl: './microsoft.html',
    styleUrls: ['./auth-providers.less']
})
export class MicrosoftComponent implements OnInit, OnDestroy, EditData {

    readonly mappingsControlKeys = MappingsControlKeys;
    readonly conditionControlKeys = ConditionControlKeys;
    readonly conditionDictionary = conditionDictionary;
    readonly authProviderMappingConditionType = AuthProviderMappingConditionType;
    readonly controlKeys = ControlKeys;
    readonly featureConfigControlKeys = FeatureConfigControlKeys;
    readonly claimControlKeys = ClaimControlKeys;
    readonly getMappingConditionLabels = getMappingConditionLabels;

    tenant: TenantSettings;
    details: AuthProviderDetails;
    error: Error | undefined;
    groupsError: Error | null;
    claimsError: Error | null;
    edited: boolean;
    recentlyModified = true;

    filteredSourceGroups: AuthProviderSourceGroup[] = [];
    filteredTenantRoles: string[];
    filteredSystemRoles: string[];

    allMappingFieldsOptions: {
        [key: string]: FieldMappingOption[];
    } = {};

    form: UfControlGroup;
    featureConfigKeys: string[] = [];
    claimsLoading: boolean;

    breadcrumbs: Breadcrumb[] = this.breadcrumbService.getBreadcrumbs(this.route, ['Microsoft']);

    private readonly authUrl = 'https://login.microsoftonline.com';
    private units: HierarchyUnitWithPath[];

    private id?: string;
    private recentlyModifiedLimit = 300000;
    private retryInterval = 10000;
    private sourceClaims: string[] = [];
    private tenantRoles: string[];

    private claimsLoaderSubscription: Subscription;
    private statusChangesSubscription: Subscription;

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private tenantClient: TenantClient,
        private router: Router,
        private route: ActivatedRoute,
        private ucRoles: UcRoles,
        private ucAuthProviders: UcAuthProviders,
        private context: ContextService,
        private toastService: ToastService,
        protected modalService: ModalService,
        private ufb: UfFormBuilder,
        private dialogs: DialogsService,
        private breadcrumbService: BreadcrumbService,
        private mappingsController: AuthProviderMappingsController,
        private claimMappingController: ClaimMappingController,
        @Inject(TableContainerManager) private manager: AuthProvidersTableManager,
        @Inject(HierarchyUnitProvider) private hierarchyProvider: HierarchyUnitProvider,
    ) {
        this.tenant = this.context.tenantSettings as TenantSettings;
    }

    get tenantRolesControl(): UfControlArray {
        return this.form.get(ControlKeys.TenantRolesMapping) as UfControlArray;
    };

    get systemRolesControl(): UfControlArray {
        return this.form.get(ControlKeys.SystemRolesMapping) as UfControlArray;
    };

    get claimsControl(): UfControlArray {
        return this.form.get(ControlKeys.ClaimsMapping) as UfControlArray;
    };

    get fieldMappingControl(): UfControlArray {
        return this.form.get(ControlKeys.UserFieldsMapping) as UfControlArray;
    };

    get mappingsControl(): UfControlArray {
        return this.form.get(ControlKeys.Mappings) as UfControlArray;
    };

    get isActiveControl(): UfControl {
        return this.form.get(ControlKeys.IsActive) as UfControl;
    }

    async ngOnInit() {

        try {
            const { id, tenant, manualRegistration } = await this.parseURLParams();

            this.id = id;
            this.details = await this.getAuthDetails(id, tenant, manualRegistration);
            this.featureConfigKeys = Object.keys(this.details.featureConfig || {});
            this.form = await this.createForm(this.details);

            if (this.details.tenant) {
                await this.loadMappedData();
                this.recentlyModified = this.isRecentlyModified(this.details);
            }

            this.statusChangesSubscription = this.form.statusChanges.subscribe(() => this.edited = true);

            if (tenant) {
                // if tenant param exists auth details has been recently saved to provider list needs to be refreshed
                this.manager.reload.next();
            }

        } catch (e) {
            this.error = e;
        }
    }

    ngOnDestroy() {
        if (this.claimsLoaderSubscription) {
            this.claimsLoaderSubscription.unsubscribe();
        }

        if (this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    async addSystemRole() {
        this.systemRolesControl.push(await this.createRoleControl());
    }

    async addTenantRole() {
        this.tenantRolesControl.push(await this.createRoleControl());
    }

    removeSystemRole(index: number) {
        this.systemRolesControl.removeAt(index);
    }

    removeTenantRole(index: number) {
        this.tenantRolesControl.removeAt(index);
    }

    async addClaim() {
        const newControl = await this.modalService.openLarge(ClaimMappingModalComponent, { control: this.claimMappingController.createClaimControl(), sourceClaims: this.sourceClaims });
        if (newControl != null) {
            this.claimsControl.push(newControl);
        }
    }

    async editClaim(index: number) {
        const claimControl = this.claimsControl.at(index);
        if (claimControl) {
            const newControl = await this.modalService.openLarge(ClaimMappingModalComponent, { control: claimControl, sourceClaims: this.sourceClaims });
            if (newControl) {
                this.claimsControl.removeAt(index);
                this.claimsControl.insert(index, newControl);
            }
        }
    }

    removeClaim(index: number, event: MouseEvent) {
        event.stopPropagation();
        this.claimsControl.removeAt(index);
    }

    async addMapping() {
        if (!this.details.id) {
            return;
        }

        const newMapping = await this.modalService.openMedium(AuthProviderMappingModalComponent, {
            mappingControl: this.mappingsController.createMappingControl(),
            sourceClaims: this.sourceClaims,
            providerId: this.details.id
        });
        if (newMapping != null) {
            this.mappingsControl.push(newMapping);
        }
    }

    removeMapping(index: number, event?: MouseEvent) {
        event?.stopPropagation();
        this.mappingsControl.removeAt(index);
    }

    async editMapping(index: number, event?: MouseEvent) {
        event?.stopPropagation();

        if (!this.details.id) {
            return;
        }

        // create a copy of the control to edit in a modal
        const mappingControl = await this.modalService.openMedium(AuthProviderMappingModalComponent, {
            mappingControl: this.mappingsControl.at(index) as UfControlGroup,
            sourceClaims: this.sourceClaims,
            providerId: this.details.id
        });

        // replace old control with new
        if (mappingControl) {
            this.mappingsControl.removeAt(index);
            this.mappingsControl.insert(index, mappingControl);
        }
    }

    // Duplicated in mapping modal (all mappings will move there eventually, until then we'll have it in two places)
    async findSourceGroups(query?: string) {
        if (query && query.trim().length) {
            try {
                this.filteredSourceGroups = await this.ucAuthProviders.getAuthProviderGroups(this.details.id as string, query.trim());
                this.groupsError = null;
            } catch (e) {
                this.groupsError = e;
                this.filteredSourceGroups = [];
            }
        } else {
            this.filteredSourceGroups = [];
        }
    }

    async findFieldMapping(field: string, query?: string) {
        const options = (await this.ucAuthProviders.getMappingFields(this.details.id || '', field))
            .map(this.mapMappingFieldDisplay)
            .filter(f => !query || f.display.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0);
        this.allMappingFieldsOptions[field] = [...options];
    }

    findSystemRoles(query?: string) {
        const roles = Object.keys(SystemRole)
            .filter(key => key !== SystemRole.SuperUser);

        this.filteredSystemRoles = (query && query.trim().length) ?
            roles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...roles];
    }

    findTenantRole(query?: string) {
        this.filteredTenantRoles = (query && query.trim().length) ?
            this.tenantRoles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...this.tenantRoles];
    }

    async disconnect() {

        if (!await this.dialogs.confirmSSODisconnect()) {
            return;
        }

        await this.ucAuthProviders.delete(this.details.id as string);
        this.toastService.success('Disconnected');
        this.close();
    }

    async connect() {

        if (!this.form.valid) {
            this.form.setSubmitted();
            return;
        }

        if (!await this.dialogs.confirmSSOConnect()) {
            return;
        }

        const details = this.toDataModel(this.form);

        if (details.id == null && details.clientId == null) {
            const microsoftConfig = await this.ucAuthProviders.getMicrosoftConfig();
            details.clientId = microsoftConfig.clientId;
        }

        const redirectUrl = await this.getRedirectUrl();
        const url = `${this.authUrl}/${details.tenant}/adminconsent?response_mode=form_post&client_id=${details.clientId}&redirect_uri=${redirectUrl}`;
        this.window.location.assign(url);
    }

    async deactivate() {

        if (!await this.dialogs.confirmSSODeactivate()) {
            return;
        }

        this.isActiveControl.setValue(false);
        await this.save();
        this.toastService.success('Deactivated');
    }

    async activate() {

        if (!await this.dialogs.confirmSSOActivate()) {
            return;
        }

        this.isActiveControl.setValue(true);
        await this.save();
        this.toastService.success('Activate');
    }

    async save(close = false) {

        if (this.form.invalid) {
            this.form.setSubmitted();
            return;
        }

        const details = this.toDataModel(this.form);

        try {

            if (details.scimToken?.length === 0) {
                delete details.scimToken;
            }

            const authDetails = await this.ucAuthProviders.save(details);
            this.edited = false;
            this.manager.reload.next();
            this.toastService.success('Saved');

            if (close) {
                this.close();
            } else if (this.form.controls.systemRoles == null) {
                this.router.navigate([`../azure/${authDetails.id}`], { relativeTo: this.route });
            }

        } catch (e) {
            this.error = {
                type: e?.type || ErrorType.Unknown,
                message: e.message || 'Failed to save Microsoft details'
            };
            this.toastService.error(this.error.message as string);
        }
    }

    close() {
        const backRoute = this.id ? ['../../'] : ['..'];
        this.manager.reload.next();
        this.router.navigate(backRoute, { relativeTo: this.route });
    }

    private async getAuthDetails(id?: string, tenant?: string, manualRegistration?: boolean): Promise<AuthProviderDetails> {

        // Load existing
        if (id) {
            const authProvider = await this.ucAuthProviders.get(id);

            // load units before mapping it
            this.units = await loadMappingUnits(authProvider.mappings ?? [], this.hierarchyProvider);

            return authProvider;
        }

        const details: AuthProviderDetails = {
            isActive: true,
            type: AuthProvider.Azure
        };

        // Automatic setup, save tenant
        if (tenant) {
            details.tenant = tenant;
            return this.ucAuthProviders.save(details);
        }

        if (manualRegistration) {
            details.extras = {
                useDirectory: false,
                manualRegistration: true
            };
            details.featureConfig = DefaultFeatureConfig;
        }
        return details;
    }

    private async parseURLParams(): Promise<{ id?: string; tenant?: string; manualRegistration?: boolean }> {

        const { error, error_description, tenant } = this.route.snapshot.queryParams;
        const { manualRegistration, id } = this.route.snapshot.params;

        if (error) {

            const unknownError: Error = {
                type: ErrorType.Unknown,
                message: error_description || error
            };

            throw unknownError;
        }

        if (id) {
            return { id };
        }

        return {
            tenant,
            manualRegistration: manualRegistration === 'true'
        };
    }

    private async createForm(details: AuthProviderDetails): Promise<UfControlGroup> {

        const controlGroup = new UfControlGroup({
            [ControlKeys.Type]: this.ufb.control({ value: details.type, disabled: true }),
            [ControlKeys.Id]: this.ufb.control({ value: details.id, disabled: true }),
            [ControlKeys.IsActive]: this.ufb.control(details.isActive),
            [ControlKeys.Extras]: this.ufb.control({ value: details.extras, disabled: true }),
            [ControlKeys.ManualRegistration]: this.ufb.control({ value: details.manualRegistration, disabled: true }),
            [ControlKeys.Manual]: this.ufb.control({ value: details.manual, disabled: true }),
            [ControlKeys.Tenant]: this.ufb.control({ value: details.tenant, disabled: details.tenant != null }, [
                ValidatorFunctions.required('Tenant name is required'),
                ValidatorFunctions.custom(v => !this.tenant.authProviders?.find(p => p.type === AuthProvider.Azure && v === p.tenant), 'Tenant name has existing connection')
            ]),
            [ControlKeys.SystemRolesMapping]: new UfControlArray([]),
            [ControlKeys.TenantRolesMapping]: new UfControlArray([]),
            [ControlKeys.UserFieldsMapping]: new UfControlArray([]),
            [ControlKeys.ClaimsMapping]: new UfControlArray([]),
            [ControlKeys.Mappings]: new UfControlArray([]),
            [ControlKeys.ProviderLoginLabel]: this.ufb.control(details.providerLoginLabel),
            [ControlKeys.ClientSecret]: this.ufb.control(details.clientSecret),
            [ControlKeys.ScimToken]: this.ufb.control(details.scimToken),
            [ControlKeys.FeatureConfig]: this.createFeatureConfigControl(details.featureConfig),
            [ControlKeys.ClientId]: this.ufb.control(details.clientId),
            [ControlKeys.DisableSsoMapping]: this.ufb.control(details.disableSsoMapping)
        });

        if (details.tenant == null && details?.extras?.manualRegistration === true) {
            controlGroup.get(ControlKeys.ClientId)?.addValidators(ValidatorFunctions.required('Client ID is required'));
            controlGroup.get(ControlKeys.ClientSecret)?.addValidators(ValidatorFunctions.required('Client secret is required'));
        }

        return controlGroup;
    }

    private async loadMappedData(): Promise<void> {

        await this.loadClaims(this.details);

        this.tenantRoles = (await this.ucRoles.get()).map(r => r.name);

        // Add controls
        if (this.details.systemRolesMapping) {
            for (const data of this.details.systemRolesMapping) {
                this.systemRolesControl.push(await this.createRoleControl(data));
            }
        }
        if (this.details.tenantRolesMapping) {
            for (const data of this.details.tenantRolesMapping) {
                this.tenantRolesControl.push(await this.createRoleControl(data));
            }
        }
        if (this.details.claimsMapping) {
            for (const claimMapping of this.details.claimsMapping) {
                this.claimsControl.push(this.claimMappingController.createClaimControl(await this.claimMappingController.toFormModel(claimMapping)));
            }
        }

        if (this.details.mappings) {
            for (const data of this.details.mappings) {
                const formModel = await this.mappingsController.toFormModel(this.details.id, data, this.units);
                this.mappingsControl.push(this.mappingsController.createMappingControl(formModel));
            }
        }

        if (this.details.userFieldsMapping) {
            for (const key of Object.keys(this.details.userFieldsMapping)) {
                if (this.details.userFieldsMapping && this.details.userFieldsMapping[key]) {
                    this.fieldMappingControl.push(this.createFieldMappingControl({ key, ...this.details.userFieldsMapping[key] }));
                    this.allMappingFieldsOptions[key] = [];
                }
            }
        }
    }

    private async loadClaims(details: AuthProviderDetails) {

        this.claimsLoading = true;

        this.claimsLoaderSubscription = timer(0, this.retryInterval).subscribe(async () => {
            this.recentlyModified = this.isRecentlyModified(details);
            try {
                this.sourceClaims = await this.ucAuthProviders.getAuthProviderClaims(details.id as string);
                this.claimsError = null;
                this.claimsLoaderSubscription.unsubscribe();
                this.claimsLoading = false;
            } catch (e) {
                this.claimsError = e;
                if (!this.recentlyModified) {
                    this.claimsLoaderSubscription.unsubscribe();
                    this.claimsLoading = false;
                }
            }
        });
    }

    private async createRoleControl(data?: { source: string; targets: string[] }) {

        const source = new UfControl(ValidatorFunctions.required('Select a group'));
        const targets = new UfControl(ValidatorFunctions.required('Select at least a role'));

        let sourceGroup;
        if (data) {
            try {
                sourceGroup = await this.ucAuthProviders.getAuthProviderGroup(this.details.id as string, data.source);
            } catch (e) {
                console.error(e);
            }

            source.setValue(sourceGroup || { id: data.source, name: '' });
            targets.setValue(data.targets);
        }

        return new UfControlGroup({ source, targets });
    }

    private createFieldMappingControl(data: FieldMappingOption) {
        const key = new UfControl();
        const source = new UfControl(ValidatorFunctions.required('Select a source'));

        key.setValue(data.key);
        source.setValue(this.mapMappingFieldDisplay(data));

        return new UfControlGroup({ key, source });
    }

    private createFeatureConfigControl(featureConfig?: IntegrationFeatureConfig) {
        const group = this.ufb.group({});

        if (featureConfig != null) {
            for (const key of Object.keys(featureConfig)) {
                group.addControl(key, this.ufb.group({
                    [FeatureConfigControlKeys.Enabled]: !featureConfig[key].disabled,
                    [FeatureConfigControlKeys.Label]: featureConfig[key].label,
                }));
            }
        }

        return group;
    }

    private async getRedirectUrl(): Promise<string> {

        const baseUrl = `${this.window.location.origin}/system-settings/sso/azure;tenant=${this.details.tenant}`;
        const oidcState = await this.tenantClient.getOIDCState(baseUrl, {});
        return `https://directory.unifii.net/azure/callback&state=${oidcState.state}`;
    }

    private isRecentlyModified(authDetails: AuthProviderDetails): boolean {
        return !!authDetails.lastModifiedAt && differenceInMilliseconds(new Date(), parseISO(authDetails.lastModifiedAt)) <= this.recentlyModifiedLimit;
    }

    private mapMappingFieldDisplay(data: FieldMapping): FieldMappingOption {
        return {
            source: data.source,
            label: data.label,
            display: data.label ? `${data.source} (${data.label})` : data.source
        };
    }

    private toDataModel(form: UfControlGroup): AuthProviderDetails {
        const details = form.getRawValue();

        details.systemRolesMapping = (this.systemRolesControl.value as any[] || []).map(i => ({
            source: i.source.id,
            targets: i.targets
        }));

        details.tenantRolesMapping = (this.tenantRolesControl.value as any[] || []).map(i => ({
            source: i.source.id,
            targets: i.targets
        }));

        // convert claim mapping
        for (const mapping of details.claimsMapping) {
            mapping.target = mapping.target.type;
            mapping.valueMap = (mapping.valueMap as []).reduce((p: Dictionary<string>, c: AuthProviderClaimValueMapFormModel) => {
                p[c.key] = c.value as string;
                return p;
            }, {} as Dictionary<string>);
        }

        details.userFieldsMapping = (this.fieldMappingControl.value).reduce((obj: any, item: any) => ({
            ...obj,
            [item.key]: {
                source: item.source.source,
                label: item.source.label
            }
        }), {});

        if (this.claimsControl) {
            details.claimMapping = this.claimsControl.controls.map(c => this.claimMappingController.toDataModel(c.getRawValue()));
        }

        if (this.mappingsControl) {
            details.mappings = this.mappingsControl.controls.map(c => this.mappingsController.toDataModel(c.getRawValue()));
        }

        for (const key of Object.keys(details.featureConfig)) {
            details.featureConfig[key].disabled = !details.featureConfig[key].enabled;
            delete details.featureConfig[key].enabled;
        }

        return details;
    }

}
