<ng-container *ngIf="ready">
    <uc-builder-header>
    </uc-builder-header>

    <div class="builder-content">

        <uc-field-reference-picker restrict="ProjectManager" *ngIf="canEdit">
        </uc-field-reference-picker>

        <div class="builder-growing-section">
            <div #fieldlist class="field-list uf-box flat">

                <uc-builder-fields-header>
                    <uc-expander-controls *ngIf="definition?.fields?.length" [container]="fieldlist"
                        [className]="selected == null ? 'primary' : ''">
                    </uc-expander-controls>
                </uc-builder-fields-header>

                <uc-drag-list class="scrollable" [items]="definition.fields" [canDrop]="canDrop"
                    [convertAdded]="convertFieldRef" (insert)="inserted($event.item)" (moved)="moved($event.item)">
                    <uf-field class="col-1of1--no-margin--no-padding" *ngFor="let field of definition.fields"
                        [field]="field" [scope]="compound" [(content)]="compound[field.identifier ?? '']" dragItem>
                    </uf-field>
                    <div *ngIf="!definition.fields.length" class="empty">
                        <div>
                            <img src="/assets/svg/icon-projects.svg">
                            <p>Drag your items here!</p>
                        </div>
                    </div>
                </uc-drag-list>

            </div>

            <div class="field-settings">
                <div>
                    <uf-tabs class="scrollable">
                        <uf-tab label="Details">
                            <div class="tab-content scrollable uf-box flat">
                                <uc-view-settings *ngIf="!canEdit || !selected"></uc-view-settings>
                                <uc-field-builder *ngIf="selected && canEdit" [field]="selected"
                                    (valueChange)="fieldBuilderChange($event)">
                                </uc-field-builder>
                            </div>
                        </uf-tab>
                        <uf-tab label="Preview" *ngIf="!selected">
                            <div class="tab-content scrollable uf-box flat">
                                <uf-view *ngIf="displayCompound && displayDefinition"
                                    [classList]="['grid', 'body-copy']" [definition]="displayDefinition"
                                    [compound]="displayCompound"></uf-view>
                            </div>
                        </uf-tab>
                    </uf-tabs>
                </div>
            </div>
        </div>

    </div>

</ng-container>