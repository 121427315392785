import { Injectable } from '@angular/core';
import {
    DataLoaderFactory, DataPropertyDescriptor, HierarchyUnitProvider, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions
} from '@unifii/library/common';
import { AstNode, DataSeed, DataSource, DataSourceType, FieldType, HierarchyUnit, NodeType, OperatorComparison, UsersClient } from '@unifii/sdk';


export enum FilterNodeControlKeys {
    Field = 'field',
    Operator = 'operator',
    ValueType = 'valueType',
    Value = 'value'
}

export interface FilterEditorNode {
    field?: DataPropertyDescriptor;
    operator?: OperatorComparison;
    valueType?: NodeType;
    value?: any;
}

@Injectable()
export class FilterEditorFormCtrl {

    constructor(
        private fb: UfFormBuilder,
        private dataLoaderFactory: DataLoaderFactory,
        private usersClient: UsersClient,
        private hierarchyProvider: HierarchyUnitProvider
    ) { }

    async mapFilterToFilterNodes(filter: AstNode, dataProperties: DataPropertyDescriptor[]): Promise<FilterEditorNode[]> {
        return Promise.all((filter.args ?? []).map(arg => this.mapAstNodeToFilterNode(arg, dataProperties)));
    }

    async mapAstNodeToFilterNode(ast: AstNode, dataProperties: DataPropertyDescriptor[]): Promise<FilterEditorNode> {

        const leftNode = ast.args != null && ast.args[0] != null ? ast.args[0] : null;
        const rightNode = ast.args != null && ast.args[1] != null ? ast.args[1] : null;
        const fieldIdentifier = leftNode?.value as string | undefined;

        // Field
        const matchField = !fieldIdentifier ? undefined : dataProperties.find(dp => {
            // AstNode for a ZoneDateTime is save with <identifier>.value
            if (dp.type === FieldType.ZonedDateTime) {
                return `${dp.identifier}.value` === fieldIdentifier;
            }

            if (dp.type === FieldType.Hierarchy) {
                return `${dp.identifier}.id` === fieldIdentifier;
            }

            // Exclude the 'artificial' datasource like _lastModifiedBy that are of type FieldType.Text
            if ([FieldType.Choice, FieldType.Lookup].includes(dp.type) && dp.dataSource) {
                // AstNode for a DS based field is saved with <identifier>._id
                if (fieldIdentifier === dp.identifier && !fieldIdentifier.endsWith('._id')) {
                    // The direct <identifier> entry is not valid
                    return false;
                }
                if (`${dp.identifier}._id` === fieldIdentifier && dp.dataSource && fieldIdentifier.endsWith('._id')) {
                    // The entry <identifier>._id match the DS based field data property
                    return true;
                }
            }

            // Standard
            return fieldIdentifier === dp.identifier;

        });
        const field = !fieldIdentifier ? undefined : matchField ?? {
            identifier: fieldIdentifier,
            type: FieldType.Text,
            label: fieldIdentifier,
            display: fieldIdentifier,
            asDisplay: false, asSearch: false, asSort: false, asInputFilter: false, asStaticFilter: false
        } as DataPropertyDescriptor;

        // Operator
        const operator = ast.op as OperatorComparison | undefined;

        // ValueType
        const valueType = rightNode?.type ?? NodeType.Value;

        // Value
        let value = rightNode?.value; // Any transformation here?
        if (valueType === NodeType.Value && field && value) {
            if (field.dataSource) {
                value = await this.getSeeds(value, field.dataSource);
            }
            if (field.type === FieldType.Hierarchy) {
                value = await this.hierarchyProvider.getUnit(value);
            }
        }

        return { field, operator, valueType, value };
    }

    mapFilterNodesToFilter(nodes: FilterEditorNode[]): AstNode | undefined {

        if (!nodes.length) {
            return;
        }

        const args = nodes.map(node => this.mapFilterNodeToAstNode(node)).filter(e => e != null) as AstNode[];
        return { type: NodeType.Combinator, op: 'and', args };
    }

    mapFilterNodeToAstNode(node: FilterEditorNode): AstNode | undefined {

        if (!node.field || node.value == null) {
            return;
        }

        let identifier = node.field.identifier;
        // AstNode for a ZoneDateTime is save with <identifier>.value
        if (node.field.type === FieldType.ZonedDateTime) {
            identifier = `${node.field.identifier}.value`;
        }
        // AstNode for a DS based field is saved with <identifier>._id
        if (node.field.dataSource && [FieldType.Choice, FieldType.Lookup].includes(node.field.type)) {
            identifier = `${node.field.identifier}._id`;
        }

        let value = node.value;
        if (node.field.dataSource && node.field.dataSource.type !== DataSourceType.Named) {
            value = Array.isArray(value) ? value.map(v => v._id ?? v) : value._id ?? value;
        }

        if (node.field.type === FieldType.Hierarchy) {
            identifier = `${node.field.identifier}.id`;

            if (node.valueType === NodeType.Value) {
                value = (value as HierarchyUnit).id;
            }
        }

        return {
            type: NodeType.Operator,
            op: node.operator,
            args: [
                {
                    type: NodeType.Identifier,
                    value: identifier
                },
                {
                    type: node.valueType ?? NodeType.Value,
                    value
                }
            ]
        };
    }

    buildRootControl(nodes: FilterEditorNode[]): UfControlArray {
        return this.fb.array(nodes.map(arg => this.buildNodeControl(arg)));
    }

    buildNodeControl(node: FilterEditorNode): UfControlGroup {

        const control = this.fb.group({
            [FilterNodeControlKeys.Field]: [node.field, ValidatorFunctions.required('A field is required')],
            [FilterNodeControlKeys.Operator]: [node.operator, ValidatorFunctions.required('An operator is required')],
            [FilterNodeControlKeys.ValueType]: [node.valueType, ValidatorFunctions.required('A value type is required')],
            [FilterNodeControlKeys.Value]: [node.value, ValidatorFunctions.required('A value is required')]
        }, {
            // Not a staticFilter field validation and flag ad touched
            validators: ValidatorFunctions.custom(v => {
                const controlNode = v as FilterEditorNode;
                if (!controlNode.field) {
                    return true;
                }
                return controlNode.field?.asStaticFilter === true;
            }, `Field '${node.field?.identifier ?? ''}' not available`)
        });

        // Force error to be visible for non mapped fields
        if (node.field && !node.field.asStaticFilter) {
            control.markAsTouched();
        }

        return control;
    }

    isValid(filter: AstNode): boolean {

        // First level attributes check
        if (!filter.args || !filter.op || filter.type == null) {
            return false;
        }

        // Check args are only 2 and valids
        for (const node of filter.args) {
            if (!node.args) {
                return false;
            }

            return node.args.filter(arg => arg.type != null && arg.value != null).length === 2;
        }

        return true;
    }

    private async getSeeds(value: string | string[], dataSource: DataSource): Promise<DataSeed | null | DataSeed[]> {

        const getSeed = async (key: string, ds: DataSource): Promise<DataSeed | null> => {
            switch (ds.type) {
                case DataSourceType.Users:
                    try {
                        const user = await this.usersClient.getByUsername(key);
                        if (user.lastName == null && user.firstName == null) {
                            return { _id: key, _display: 'First and Last name undefined' };
                        } else {
                            return { _id: key, _display: `${user.firstName ?? ''} ${user.lastName ?? ''}` };
                        }
                    } catch (e) {
                        return null;
                    }
                case DataSourceType.Named:
                    return { _id: key, _display: key };
                default:
                    const dataSourceLoader = this.dataLoaderFactory.create(dataSource);
                    if (!dataSourceLoader) {
                        return null;
                    }
                    return dataSourceLoader.get(key);
            }
        };

        if (Array.isArray(value)) {
            const seeds: DataSeed[] = [];
            for (const v of value) {
                const seed = await getSeed(v, dataSource);
                if (seed) {
                    seeds.push(seed);
                }
            }
            return seeds;
        }

        return getSeed(value, dataSource);
    }
}