<uf-expander *ngIf="field" class="uc-group"
    [class.uc-group--draggable--no-shrink]="!disabled && draggable && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="!disabled && draggable && isSelected">
    <div class="uf-app-bar flat" [class.accent]="isSelected" dragHandle expanderHeader (click)="select()">
        <uf-icon name="collectionLink"></uf-icon>
        <div class="title primary">
            Collection | {{collectionLabel}}
        </div>
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning"></uf-icon>
        <uf-help class="uf-action tertiary" *ngIf="field.help" [content]="field.help"></uf-help>
        <a class="uf-action tertiary" *ngIf="canRemove" [restrict]="removeRestrictions" (click)="remove()"
            title="Delete">
            <uf-icon name="delete"></uf-icon>
        </a>
    </div>
    <div expanderBody class="uc-group-content">
        <!-- to do replace with list from library -->
        <uc-drag-list *ngIf="content" canDrop="false" canReorder="true" [items]="content" [parent]="content"
            (moved)="moved()">
            <ul class="uc-list--gap">
                <li class="row center-all" [class.uc-list-item--small--no-shrink--bordered]="disabled"
                    [class.uc-list-item--draggable--small--no-shrink]="!disabled"
                    *ngFor="let link of content, let i = index" dragItem [dragDisabled]="disabled">
                    <div class="list-content">
                        <div class="list-context">
                            <a [routerLink]="getLink(link)" class="uf-action tertiary" target="_blank" title="Open">
                                <uf-icon name="open"></uf-icon>
                            </a>
                            <button class="uf-action tertiary" *ngIf="canDelete" (click)="delete(i)" tabindex="-1"
                                title="Delete">
                                <uf-icon name="delete"></uf-icon>
                            </button>
                        </div>
                        <div>{{link.recordName}}</div>
                    </div>
                </li>
            </ul>
        </uc-drag-list>
        <div *ngIf="searchConfig" class="row padded">
            <button class="uf-button x-small right" *ngIf="canAdd" (click)="add()">{{searchConfig.title}}</button>
        </div>
    </div>
</uf-expander>