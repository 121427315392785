import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DataDescriptor, DataDescriptorService } from '@unifii/library/common';
import { Schema } from '@unifii/sdk';

import { UcProject } from 'client';


@Injectable({
    providedIn: 'root',
})
export class BucketConfigResolver implements Resolve<{ schema?: Schema; dataDescriptor?: DataDescriptor | undefined }> {

    constructor(
        private ucProject: UcProject,
        private dataDescriptorService: DataDescriptorService
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<{ schema?: Schema; dataDescriptor?: DataDescriptor | undefined }> {
        const bucketId = route.params.id;

        try {
            return {
                schema: await this.ucProject.getBucket(bucketId),
                dataDescriptor: await this.dataDescriptorService.getBucketDataDescriptor(bucketId)
            };
        } catch (e) {
            console.error(e);
            return {};
        }
    }
}
