<div class="grid" *ngIf="ready && notifyEnabled">

	<uf-text class="col-1of1" label="Item Label" *ngIf="config.itemLabel.show" [(value)]="field.itemLabel"
		[control]="form.controls.itemLabel | asUfControl"></uf-text>

	<uf-text class="col-1of1" label="Add Button Label" *ngIf="config.addButtonLabel.show"
		[(value)]="field.addButtonLabel" [control]="form.controls.addButtonLabel | asUfControl"></uf-text>

	<uf-select class="col-1of1" label="Field Width" *ngIf="config.width.show" [(value)]="field.width"
		[control]="form.controls.width | asUfControl" [disabled]="field.template === fieldTemplate.RadioWithContent"
		valueProperty="value" nameProperty="name" [options]="widthOptions">
	</uf-select>

	<uf-checkbox class="col-1of1" label="Break after" *ngIf="config.breakAfter.show" [(value)]="field.breakAfter"
		[control]="form.controls.breakAfter | asUfControl"
		[disabled]="!field.width || field.width === fieldWidth.Stretch">
	</uf-checkbox>

	<uf-select class="col-1of1" label="Template" *ngIf="config.template.show" [options]="templateOptions"
		valueProperty="value" nameProperty="name" (valueChange)="templateChange($event)"
		[control]="form.controls.template | asUfControl" [(value)]="field.template">
	</uf-select>

	<div class="col-1of1" *ngIf="config.columnVisibility.show">
		<label class="x-small">Column Visibility</label>
		<div class="pad-sm-top">
			<ul class="uf-list uf-box flat column-toggles-list">
				<ng-template ngFor let-child [ngForOf]="field.fields">
					<ng-container *ngIf="child.identifier">
						<li class="row center-all space-children pad-sm-sides">
							<div class="grow">
								<p>{{child.label}}</p>
							</div>
							<uf-checkbox *ngIf="field.templateConfig?.hideFromColumnsOnMobile != null" label="Mobile"
								[value]="!field.templateConfig?.hideFromColumnsOnMobile?.includes(child.identifier)"
								(valueChange)="columnVisibilityChange($event, child.identifier, displayType.Mobile)">
							</uf-checkbox>
							<uf-checkbox label="Desktop"
								[value]="!field.templateConfig?.hideFromColumnsOnDesktop?.includes(child.identifier)"
								(valueChange)="columnVisibilityChange($event, child.identifier, displayType.Desktop)">
							</uf-checkbox>
						</li>
					</ng-container>
				</ng-template>
			</ul>
		</div>
	</div>

	<uf-select class="col-1of1" label="Position" *ngIf="config.position.show" [(value)]="field.template"
		[control]="form.controls.position | asUfControl" valueProperty="value" nameProperty="name"
		[options]="positionOptions">
	</uf-select>

	<uf-select class="col-1of1" label="Columns" *ngIf="config.columnCount.show"
		[control]="form.controls.columnCount | asUfControl" [(value)]="field.columnCount" [options]="columnCountOptions"
		valueProperty="value" nameProperty="name"></uf-select>

	<uf-select class="col-1of1" label="Direction" *ngIf="config.layoutDirection.show" [(value)]="field.layoutDirection"
		[control]="form.controls.layoutDirection | asUfControl" valueProperty="value" nameProperty="name"
		[options]="directionOptions"></uf-select>

	<uf-number class="col-1of1" label="Min Width" *ngIf="config.minWidth.show" [(value)]="field.minWidth"
		[control]="form.controls.minWidth | asUfControl"></uf-number>

	<uf-number class="col-1of1" label="Min Height" *ngIf="config.minHeight.show" [(value)]="field.minHeight"
		[control]="form.controls.minHeight | asUfControl"></uf-number>

	<div class="uc-group col-1of1--no-padding" *ngIf="field.template === 'table' && config.columns.show">
		<div class="uf-app-bar flat">
			<div class="title primary">
				Columns
			</div>
		</div>
		<div class="uc-group-content grid">
			<uf-search-result class="col-1of1" [control]="form.controls.columns | asUfControl" label="Add Columns"
				placeholder="Search for..." (valueChange)="addColumn($event)" (searchChange)="filterColumns($event)"
				[options]="fieldColumns" nameProperty="label"></uf-search-result>
			<ng-template [ngIf]="field.columns && field.columns.length">
				<uc-drag-list canDrop="false" canReorder="true" class="col-1of1" [items]="field.columns"
					(moved)="notifyEdited()">
					<ul class="uc-list">
						<li class="uc-list-item--draggable--small row center-all"
							*ngFor="let column of field.columns, let i = index" dragItem>
							<div class="list-content">
								<div class="list-context">
									<button class="uf-action tertiary" (click)="removeColumn(column)" title="Delete">
										<uf-icon name="delete"></uf-icon>
									</button>
								</div>
								<div class="grow">{{ column.label }}</div>
							</div>
						</li>
					</ul>
				</uc-drag-list>
			</ng-template>
		</div>
	</div>

</div>