import { fromEvent, Subscription } from 'rxjs';

import { AfterViewInit, Component, ElementRef, HostBinding, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Modal, ModalData, ModalRuntime, WindowResizeController, WindowResizeInfo } from '@unifii/library/common';

import { Dimension, ImageCrop } from 'components/image-cropper/imageCropper';


export interface ImageCropData {
    image: any;
    options?: {
        minWidth?: number;
        minHeight?: number;
    };
}

@Component({
    templateUrl: './image-crop.html',
})
export class ImageCropComponent implements Modal<ImageCropData, ImageCrop>, AfterViewInit, OnDestroy {

    @HostBinding('class.uc-form-card') class = true;
    @ViewChild('container', { static: true }) container: ElementRef;

    ready: boolean;
    image: any;
    enabled: boolean;
    minWidth: number;
    minHeight: number;
    value: ImageCrop | null;
    canvasSize: Dimension;

    private onDelete: Subscription;

    constructor(
        public runtime: ModalRuntime<ImageCropData, ImageCrop>,
        @Inject(ModalData) data: ImageCropData,
        private windowResizeController: WindowResizeController
    ) {
        this.image = data.image;
        this.value = data.image.crop;

        this.onDelete = fromEvent(document, 'keydown')
            .subscribe(e => this.remove());

        if (!data.options || !data.options.minWidth || !data.options.minHeight) {
            return;
        }

        this.minWidth = data.options.minWidth;
        this.minHeight = data.options.minHeight;

        this.enabled = this.image.width >= this.minWidth &&
            this.image.height >= this.minHeight;
    }

    ngAfterViewInit() {
        this.windowResizeController.register(this.onResize, this.container);
    }

    ngOnDestroy() {
        this.onDelete.unsubscribe();
        this.windowResizeController.deregister(this.onResize);
    }

    open(event: MouseEvent) {
        event.stopPropagation();
    }

    remove() {
        this.value = null;
    }

    save() {
        this.runtime.close(this.value || undefined);
    }

    close() {
        this.runtime.close();
    }

    private getCanvasSize(containerHeight: number, containerWidth: number) {
        const canvas: any = {};
        const isPortrait: boolean = (this.image.height > this.image.width);
        const offset = 20;

        // assumes there will be 30px padding
        const maxHeight = Math.round(containerHeight) - offset;
        const maxWidth = Math.round(containerWidth) - offset;

        const imageLargerThanWindow = (this.image.width > maxWidth || this.image.height > maxHeight);
        const imageLargerThanWindowY = (this.image.height > maxHeight);

        // Canvas height and Width
        if (imageLargerThanWindow && isPortrait) {

            if (!imageLargerThanWindowY) {
                canvas.height = maxHeight;
                canvas.width = Math.round(maxHeight * this.getRatio(true, this.image));
            } else {
                let height = Math.round(maxHeight);
                let width = Math.round(maxHeight * this.getRatio(true, this.image));

                if (width > maxWidth) {
                    height = Math.round(maxWidth * this.getRatio(false, this.image));
                    width = Math.round(maxWidth);
                }
                canvas.height = height;
                canvas.width = width;
            }

        }

        if (imageLargerThanWindow && !isPortrait) {
            if (!imageLargerThanWindowY) {
                canvas.height = Math.round(maxWidth * this.getRatio(true, this.image));
                canvas.width = Math.round(maxWidth);
            } else {
                let height = maxWidth * this.getRatio(true, this.image);
                let width = maxWidth;

                if (height > maxHeight) {
                    height = Math.round(maxHeight);
                    width = Math.round(maxHeight * this.getRatio(false, this.image));
                }
                canvas.height = height;
                canvas.width = width;
            }
        }

        if (!imageLargerThanWindow && isPortrait) {
            canvas.height = this.image.height;
            canvas.width = Math.round(this.image.height * this.getRatio(true, this.image));
        }

        if (!imageLargerThanWindow && !isPortrait) {
            canvas.height = Math.round(this.image.width * this.getRatio(true, this.image));
            canvas.width = this.image.width;
        }

        return canvas;

    }

    private getRatio(getSmallerLength: boolean, size: { width: number; height: number }) {
        if (getSmallerLength) {
            if (size.height >= size.width) {
                // Case 2w 3h > .666666
                return size.width / size.height;
            } else {
                // Case 3w 2h .666666
                return size.height / size.width;
            }
        } else {
            if (size.height >= size.width) {
                // Case 2w 3h > 1.5
                return size.height / size.width;
            } else {
                // Case 3w 2h > 1.5
                return size.width / size.height;
            }
        }
    }

    private onResize = (res: WindowResizeInfo) => {
        if (!res.elementHeight || !res.elementWidth) {
            return;
        }
        this.canvasSize = this.getCanvasSize(res.elementHeight, res.elementWidth);

        if (!this.ready) {
            this.ready = true;
        }
    };
}
