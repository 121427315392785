<ng-container *ngIf="ready">
    <uc-builder-header>
    </uc-builder-header>
    <div class="content">

        <div class="data-container uf-box flat">
            <div class="uf-app-bar flat">
                <span class="title">Data</span>
                <uc-expander-controls *ngIf="definition?.fields?.length"></uc-expander-controls>
            </div>
            <uf-panel class="container">
                <div class="uf-grid pad-sides pad-top">
                    <ng-template [ngIf]="rootControl">
                        <uf-text [(value)]="compound._title" label="Title"
                            [control]="rootControl.controls._title | asUfControl" class="col-12">
                        </uf-text>
                        <uf-text [(value)]="compound.recordName" placeholder="New Record" label="Record Name"
                            [control]="rootControl.controls._recordName | asUfControl" class="col-12">
                        </uf-text>
                    </ng-template>
                </div>
                <uf-form #form [definition]="definition" [(formData)]="compound" [config]="config"></uf-form>
            </uf-panel>
        </div>

        <uf-panel class="preview-container uf-box flat">
            <div class="uf-app-bar flat accent">
                <span class="title">Preview</span>
            </div>
            <uf-panel *ngIf="displayDefinition && displayCompound" class="container">
                <uf-collection-item [definition]="displayDefinition" [compound]="displayCompound"></uf-collection-item>
            </uf-panel>
        </uf-panel>

    </div>
</ng-container>