import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DescriptionListItem, UfControlGroup } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetailsModel, DetailsControlKeys } from '../models';


export enum AuthProviderDetailsButtonEvents {
    Edit,
    Deactivate,
    Activate,
    Delete
}

@Component({
    selector: 'uc-auth-provider-detail',
    templateUrl: './auth-provider-detail.html',
    styleUrls: ['./auth-provider-detail.less']
})
export class AuthProviderDetailComponent implements OnInit, OnDestroy {

    @Input() form: UfControlGroup;

    @Output() buttonClicked = new EventEmitter<AuthProviderDetailsButtonEvents>();

    protected readonly controlKeys = DetailsControlKeys;
    protected readonly events = AuthProviderDetailsButtonEvents;

    protected showAuthorizationServerId: boolean;
    protected showManagementApiAudience: boolean;
    protected showProviderLoginLabel: boolean;
    protected extrasControl: UfControlGroup;
    protected isNew: boolean;
    protected edit: boolean;
    protected descriptionListItem?: DescriptionListItem[];

    private type: AuthProvider;
    private previousValue: AuthProviderDetailsModel;
    private subscriptions = new Subscription();

    ngOnInit() {
        const isActiveControl = this.form.get(DetailsControlKeys.IsActive);
        const idControl = this.form.get(DetailsControlKeys.Id);
        this.subscriptions.add(isActiveControl?.valueChanges.subscribe(_ => this.buildDescriptionList()));
        this.subscriptions.add(idControl?.valueChanges.subscribe(_ => this.setup()));

        this.setup();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    saveDetails() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.buildDescriptionList();
        this.edit = false;
    }

    editDetails() {
        this.previousValue = this.form.getRawValue();
        this.edit = true;
    }

    cancelEdit() {
        this.form.setValue(this.previousValue, { emitEvent: false });
        this.edit = false;
    }

    private setup() {
        this.isNew = !this.form.get(DetailsControlKeys.Id)?.value;
        this.showManagementApiAudience = this.form.get(DetailsControlKeys.Type)?.value === AuthProvider.Auth0;
        this.showProviderLoginLabel = this.form.get(DetailsControlKeys.Type)?.value !== AuthProvider.Auth0;
        this.showAuthorizationServerId = this.form.get(DetailsControlKeys.Type)?.value === AuthProvider.Okta;

        this.extrasControl = this.form.get(DetailsControlKeys.Extras) as UfControlGroup;

        this.buildDescriptionList();
    }

    private buildDescriptionList() {
        const isActive = this.form.get(DetailsControlKeys.IsActive)?.value;
        this.descriptionListItem = [
            { term: 'Tenant Name', description: this.form.get(DetailsControlKeys.Tenant)?.value },
            { term: 'Client ID', description: this.form.get(DetailsControlKeys.ClientId)?.value },
            { term: 'Status', description: { description: isActive ? 'Active' : 'Inactive', status: isActive ? 'success' : 'error' } },
        ];

        const extrasControl = this.form.get(DetailsControlKeys.Extras) as UfControlGroup;
        if (this.type !== AuthProvider.Auth0) {
            this.descriptionListItem.push({ term: 'Provider Login Label', description: this.form.get(DetailsControlKeys.ProviderLoginLabel)?.value });
        } else {
            this.descriptionListItem.push({ term: 'Management API Audience', description: extrasControl.get(DetailsControlKeys.Audience)?.value });
        }

        if (this.type === AuthProvider.Okta) {
            this.descriptionListItem.push({ term: 'Authorization Server ID', description: extrasControl.get(DetailsControlKeys.AuthorizationServer)?.value });
        }
    }
}