import { Component, Input, OnInit } from '@angular/core';
import { DataLoaderFactory, DataPropertyDescriptor, DataSourceLoader, UfControl, UserInfoIdentifiers } from '@unifii/library/common';
import { sortRoles } from '@unifii/library/smart-forms';
import { DataSeed, DataSourceType, FieldType, OperatorComparison, Option } from '@unifii/sdk';




@Component({
    selector: 'uc-filter-value',
    templateUrl: './filter-value.html',
    providers: [
        DataLoaderFactory
    ],
    styles: [`uf-checkbox { margin-top: 1rem; }`]
})
export class FilterValueComponent implements OnInit {

    @Input() field: DataPropertyDescriptor;
    @Input() operator: OperatorComparison;
    @Input() control: UfControl;
    @Input() cssClass: string | string[];

    readonly types = FieldType;

    dataSourceLoader: DataSourceLoader | null;
    seeds: DataSeed[] = [];
    options: DataSeed[];
    results: DataSeed[] = [];

    constructor(
        private dataLoaderFactory: DataLoaderFactory
    ) { }

    ngOnInit() {

        // 1) Datasource (excluded Named that cannot be quiried in Console)
        if (this.field.dataSource && this.field.dataSource.type !== DataSourceType.Named) {
            this.dataSourceLoader = this.dataLoaderFactory.create(this.field.dataSource);
        } // 2) Static Option[]
        else if (this.field.options) {
            this.options = this.field.options.map(o => this.optionToSeed(o, this.field));
        }

        // UNIFII-6086 - Roles need to be sorted
        if (this.field.identifier === UserInfoIdentifiers.Roles && Array.isArray(this.control.value)) {
            this.control.setValue(this.control.value.sort(sortRoles), { emitEvent: false });
        }
    }

    async query(q: string | undefined) {

        if (this.dataSourceLoader != null) {
            this.results = await this.dataSourceLoader.search(q);
            return;
        }

        if (q == null) {
            this.results = [...this.options];
            return;
        }

        const lowerCaseQuery = q.toLowerCase();
        this.results = this.options.filter(seed => seed._display.toLowerCase().includes(lowerCaseQuery));
    }
    private optionToSeed(option: Option, field: DataPropertyDescriptor): DataSeed {

        if (field.type === FieldType.Bool) {
            return { _id: (option.identifier === 'true' ? true : false) as any, _display: option.name };
        }
        return { _id: option.identifier, _display: option.name };
    }
}