import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, CommonTranslationKey, DescriptionListItem, FilterEntry, FilterValue, ModalService, ToastService, UfControl, UfControlArray,
    UfControlGroup, WindowWrapper
} from '@unifii/library/common';
import {
    AppAuthProviderConfiguration, AuthProvider, AuthProviderConfiguration, Claim, DataSeed, Dictionary, Error, ErrorType, FieldWidth, getUserStatus,
    HierarchyStep, Manager, Option, UserStatus
} from '@unifii/sdk';
import {
    ClaimSourceType, LockedConfig, UserCreateFormController, UserDescriptionService, UserDescriptionServiceProvider, UserFieldLabelService,
    UserInfoKey, UserUpdateFormController, UserUpdateMeFormController, UserValidatorService
} from '@unifii/user-provisioning';

import {
    ExternalLogin, IntegrationFeatureConfig, SystemRole, TenantSettings, UcAuthProviders, UcClient, UcUserAuthProvider, UcUserInfo, UcUsers
} from 'client';

import { EditData } from 'components/common/edit-data';

import { sortValue } from 'pages/form-editor/field-configuration/field-utils';
import { UserComponent } from 'pages/users/user.component';
import { UsersComponent } from 'pages/users/users.component';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';
import { SSOService } from 'services/sso.service';

import { mapProviderLoginLabel } from './provider-utils';


enum InactiveReasonOptionIds {
    HasLeft = 'hasLeft',
    NoLongerRequireAccess = 'noLongerRequireAccess',
    TemporarilySuspended = 'temporarlySuspended', // TODO spelling error? Is it like that on Back end??
    Other = 'other'
}


@Component({
    templateUrl: './user-details.html',
    styleUrls: ['./../../styles/external-branding.less']
})
export class UserDetailsComponent implements EditData, OnInit, OnDestroy {

    readonly commonTK = CommonTranslationKey;

    readonly controlKeys = UserInfoKey;
    readonly inactiveReasonOptionIds = InactiveReasonOptionIds;
    readonly userStatus = UserStatus;
    readonly fieldWidth = FieldWidth;
    readonly claimSource = ClaimSourceType.User;

    // TODO move translations out of DiscoverTranslationKey
    readonly inactiveReasonOptions: Option[] = [
        { identifier: InactiveReasonOptionIds.HasLeft, name: 'User has left' },
        { identifier: InactiveReasonOptionIds.NoLongerRequireAccess, name: 'User no longer requires access' },
        { identifier: InactiveReasonOptionIds.TemporarilySuspended, name: 'User is temporarily suspended' },
        { identifier: InactiveReasonOptionIds.Other, name: 'Other' }
    ];

    userInfo: UcUserInfo;

    // Controls
    form: UfControlGroup;
    superUserControl: UfControl;
    labelDictionary: Dictionary<string>;

    // Input Options
    systemRoleOptions: string[] = [];
    managerOptions: DataSeed[] = [];
    authProviderInfo: Dictionary<{ claims: string[]; roles: string[]; systemRoles: string[]; features?: IntegrationFeatureConfig; units?: Option[] }> = {};
    managerMappedFrom: string;

    // Page status & info
    error: Error;
    managerInfo: DescriptionListItem[] | undefined;
    authProviders: AppAuthProviderConfiguration[] = [];
    userAuthProviders: UcUserAuthProvider[] = [];
    lockedProperties: LockedConfig = {
        claimTypes: [],
        roles: [],
        systemRoles: [],
        units: []
    };
    status: UserStatus | null;
    tenant: TenantSettings;
    disabledLookup: Dictionary<boolean>;
    descriptionFilter: string[];
    claimDescriptionFilter: (UserInfoKey | string)[];
    loading = true; // this is important for refreshing binding of [formGroup] when we create new instance of form after save
    breadcrumbs: Breadcrumb[];

    // Subscriptions
    subscriptions: Subscription[] = [];

    // Form state
    inProgress: boolean;
    authProviderError: Error;
    connectToExternal: boolean;

    private userLookup = new Map<DataSeed, UcUserInfo>();
    private formController: UserUpdateFormController | UserUpdateMeFormController | UserCreateFormController;

    constructor(
        private cd: ChangeDetectorRef,
        @Inject(WindowWrapper) private window: Window,
        private router: Router,
        private route: ActivatedRoute,
        private ucUsers: UcUsers,
        private modalService: ModalService,
        private dialogs: DialogsService,
        private toast: ToastService,
        private context: ContextService,
        @Inject(UsersComponent) @Optional() private usersComponent: UsersComponent,
        @Inject(UserComponent) private parent: UserComponent,
        private ssoService: SSOService,
        private client: UcClient,
        private ucAuthProviders: UcAuthProviders,
        private userValidatorService: UserValidatorService,
        private userFieldLabelService: UserFieldLabelService,
        private updateFormController: UserUpdateFormController,
        private createFormController: UserCreateFormController,
        private updateMeFormController: UserUpdateMeFormController,
        private breadcrumbService: BreadcrumbService,
        @Inject(UserDescriptionServiceProvider) private userDescriptionService: UserDescriptionService,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<UcUserInfo, FilterValue, FilterEntry>
    ) {
        if (this.isNew) {
            this.formController = this.createFormController;
            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, ['New']);
        } else if (this.isMyAccount) {
            this.formController = this.updateMeFormController;
            this.breadcrumbs = [{ name: 'My Account' }];
        } else {
            this.formController = this.updateFormController;
        }

        this.tenant = this.context.tenantSettings as TenantSettings;
        this.labelDictionary = this.userFieldLabelService.labelDictionary;
    }

    get isNew() {
        return this.parent.isNew;
    }

    get isMyAccount() {
        return this.usersComponent == null;
    }

    get amUserManager() {
        return this.context.checkRoles(SystemRole.UserManager);
    }

    get amSuperUser() {
        return this.context.checkRoles(SystemRole.SuperUser);
    }

    get edited() {
        return this.parent.edited;
    }

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get user(): UcUserInfo {
        return this.form.getRawValue();
    }

    // Control getters

    get isActiveControl(): UfControl {
        return this.form.get(UserInfoKey.IsActive) as UfControl;
    }

    get usernameControl(): UfControl {
        return this.form.get(UserInfoKey.Username) as UfControl;
    }

    get claimsControl(): UfControlGroup {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup;
    }

    get rolesControl(): UfControlArray {
        return this.form.get(UserInfoKey.Roles) as UfControlArray;
    }

    get lastActivationReasonControl(): UfControl {
        return this.form.get(UserInfoKey.LastActivationReason) as UfControl;
    }

    get companyControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Company) as UfControl | undefined;
    }

    get systemRolesControl(): UfControl {
        return this.form.get(UserInfoKey.SystemRoles) as UfControl;
    }

    get passwordControl(): UfControl {
        return this.form.get(UserInfoKey.Password) as UfControl;
    }

    get oldPasswordControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.OldPassword) as UfControl | undefined;
    }

    get changePasswordOnNextLoginControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.ChangePasswordOnNextLogin) as UfControl | undefined;
    }

    get unitsControl(): UfControl {
        return this.form.get(UserInfoKey.Units) as UfControl;
    }

    get managerControl(): UfControl {
        return this.form.get(UserInfoKey.Manager) as UfControl;
    }

    async ngOnInit() {
        await this.init();
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
        this.edited = false;
    }

    toggleSuperUser(v: boolean) {
        if (v) {
            this.systemRolesControl.setValue([...this.systemRoleOptions]);
            this.systemRolesControl.disable();
        } else if (this.amSuperUser) {
            this.systemRolesControl.enable();
        }
    }

    claimsChange(claims: Claim[]) {
        this.claimsControl.setValue(claims);
        this.edited = true;
    }

    selectManager(seed: DataSeed | null) {
        let user = null;
        this.managerInfo = undefined;

        if (seed != null) {
            user = this.userLookup.get(seed) as UcUserInfo;
            this.managerInfo = this.getManagerInfo(user);
        }

        this.managerControl.setValue(user);
    }

    async searchUsers(q: string) {

        try {
            const users = await this.ucUsers.get(q.trim());

            this.managerOptions = (users || [])
                .filter(u => u.id != null)
                .map(user => {

                    const seed: DataSeed = {
                        _display: `${user.firstName} ${user.lastName} (${user.username})`,
                        _id: user.id as string
                    };

                    this.userLookup.set(seed, user);
                    return seed;
                });
        } catch (e) { }
    }

    async save(close?: boolean) {

        this.form.setSubmitted();
        this.superUserControl.setSubmitted();

        if (this.form.invalid
            || this.superUserControl.enabled && this.superUserControl.invalid) {
            this.toast.error('There are errors in this form');
            return;
        }

        const user: any = this.formController.toDataModel(this.form);

        // Super user value
        if (this.superUserControl.value) {
            user.systemRoles?.push(SystemRole.SuperUser);
        }

        try {
            this.inProgress = true;
            const savedUser = await this.ucUsers.save(user as UcUserInfo);

            this.toast.success('User details saved!');

            if (this.usersComponent) {
                this.refreshUsersList(savedUser);
            }

            this.edited = false;

            if (close) {
                this.back();
            } else {
                // Update user in parent and internally
                this.parent.user = Object.assign({}, savedUser);
                await this.init();
            }
        } catch (e) {
            console.error(e);

            let message = e.message || 'Failed to save';

            if (e.type === ErrorType.Validation && e.message === 'Invalid password') {
                message = 'Incorrect current password';
            }

            this.toast.error(message);
        } finally {
            this.inProgress = false;
        }
    }

    async linkToProvider(provider: AppAuthProviderConfiguration) {

        const accept = await this.modalService.openConfirm({
            title: `Connect Account To ${provider.type}`,
            message: `This action can't be undone. Please confirm you wish to continue.`,
            confirmLabel: 'Connect',
            cancelLabel: `Don't Connect`
        });

        if (!accept) {
            return;
        }

        try {
            if (!this.edited) {
                const redirectUri = `${this.window.location.origin}/my-account/sso-link/${provider.id}`;
                const redirectUrl = await this.ssoService.getProviderUrl(provider, redirectUri);

                if (redirectUrl) {
                    this.window.location.href = redirectUrl;
                }
            } else {
                this.modalService.openAlert({
                    title: 'Unsaved changes',
                    message: 'Please save before connecting to a provider.',
                    confirmLabel: 'Ok',
                });
            }
        } catch (e) {
            this.authProviderError = {
                message: this.route.snapshot.params[provider.type.toLocaleLowerCase()],
                type: ErrorType.Unknown
            };
        }
    }

    async delete() {

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            await this.ucUsers.delete(this.user.id as string);
            this.toast.success('Delete succeed');
            this.tableManager?.reload?.next();
            this.back();
        } catch (error) {
            this.toast.error('Delete failed');
        }
    }

    back() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    private async initUser(user: UcUserInfo) {
        this.status = getUserStatus(user);

        if (user.manager != null) {
            this.managerInfo = this.getManagerInfo(user.manager);
        }

        this.authProviders = this.ssoService.providerList
            .filter(p => this.providerFilter(p, user.logins)) // filter connected providers
            .filter(p => [AuthProvider.Azure].includes(p.type))
            .map((provider, _, providers) => mapProviderLoginLabel(provider, providers));

        if (user.id) {
            this.userAuthProviders = await this.ucUsers.getAuthProviders(user.id);

            if (user.isExternal === true) {
                for (const provider of this.userAuthProviders) {
                    this.lockedProperties.roles = this.lockedProperties.roles.concat(provider.lockedRoles);
                    this.lockedProperties.systemRoles = (this.lockedProperties.systemRoles || []).concat(provider.lockedSystemRoles);
                    this.lockedProperties.claimTypes = this.lockedProperties.claimTypes.concat(provider.lockedClaims);
                    this.lockedProperties.units = this.lockedProperties.units.concat(provider.lockedUnits);
                }

                await this.setAuthProviderInfo(user);
            }
        }
    }

    private async initPage() {
        const providerToLink: string = this.route.snapshot.params.id;
        if (providerToLink != null) {
            await this.linkExternalProvider(providerToLink);
        }
        this.systemRoleOptions = this.getSystemRoleOptions();

        this.disabledLookup = Object.keys(this.form.controls).reduce((dictionary, key) => {
            dictionary[key] = this.form.get(key)?.disabled === true;
            return dictionary;
        }, {} as Dictionary<boolean>);

        this.descriptionFilter = this.userDescriptionService.getDescriptionFilter(this.form, this.superUserControl);
        this.claimDescriptionFilter = this.userDescriptionService.getClaimDescriptionFilter(this.claimsControl, this.lockedProperties.claimTypes);
    }

    private async initForm(user: UcUserInfo) {

        let form: UfControlGroup;
        if (this.formController instanceof UserUpdateFormController) {
            form = await this.formController.buildRoot(this.lockedProperties, user, this.userAuthProviders);
        } else {
            form = await this.formController.buildRoot(this.lockedProperties, user);
        }

        // roles not required in console as setting no roles can be a way to restrict access
        form.get(UserInfoKey.Roles)?.removeValidators(this.userValidatorService.validators.roles);

        if (!this.amUserManager) {
            form.get(UserInfoKey.Username)?.disable();
            form.get(UserInfoKey.Email)?.disable();
            form.get(UserInfoKey.FirstName)?.disable();
            form.get(UserInfoKey.LastName)?.disable();
            form.get(UserInfoKey.Phone)?.disable();
            form.get(UserInfoKey.Manager)?.disable();
            form.get(UserInfoKey.Company)?.disable();
            form.get(UserInfoKey.Claims)?.disable();
            form.get(UserInfoKey.Roles)?.disable();
            form.removeControl(UserInfoKey.Password);
            form.removeControl(UserInfoKey.OldPassword);
            form.removeControl(UserInfoKey.ChangePasswordOnNextLogin);
            form.removeControl(UserInfoKey.LastActivationReason);
        }

        // system roles logic
        if (this.amUserManager && !this.isSuperUser(user)) {
            form.get(UserInfoKey.SystemRoles)?.enable();
        }
        if (this.isSuperUser(user)) {
            form.get(UserInfoKey.SystemRoles)?.setValue(form.get(UserInfoKey.SystemRoles)?.value.filter((r: SystemRole) => r !== SystemRole.SuperUser));
        }

        this.superUserControl = new UfControl();
        this.superUserControl.setValue(this.isSuperUser(user));
        if (!this.amSuperUser) {
            this.superUserControl.disable();
        }

        this.subscriptions.push(form.statusChanges.pipe(filter(() => !form.pristine)).subscribe(() => {
            this.edited = true;
        }));

        this.subscriptions.push(this.superUserControl.valueChanges.subscribe(() => {
            this.edited = true;
        }));

        this.form = form;

        sortValue(this.form, UserInfoKey.Roles);
    }

    private async setAuthProviderInfo(user: UcUserInfo) {
        for (const provider of this.userAuthProviders) {
            const providerDetails = await this.ucAuthProviders.get(provider.id);

            this.authProviderInfo[provider.id] = {
                claims: (provider.lockedClaims || []).map(claim => this.claimMapper(claim, user)) as string[],
                roles: (provider.lockedRoles || []).filter(role => (user.roles || []).includes(role)),
                systemRoles: (provider.lockedSystemRoles || []).filter(role => (user.systemRoles || []).includes(role)),
                features: providerDetails.featureConfig,
                units: this.getLockedUnits(user.unitPaths)
            };

            if (providerDetails.featureConfig?.getAdManager?.disabled === false) {
                this.managerMappedFrom = `${provider.type} - ${provider.tenant}`;
            }
        }
    }

    private claimMapper(source: string, user: UcUserInfo): string | undefined {
        const claim = (user.claims || []).find(c => c.type === source);
        return `${source}: ${claim?.value || ''}`;
    }

    private getManagerInfo(manager?: UcUserInfo | Manager): DescriptionListItem[] | undefined {

        if (manager == null) {
            return;
        }

        return [
            { term: 'Username', description: { description: manager.username, routerLink: ['/', 'user-management', 'users', `${manager.id}`] } },
            { term: 'First Name', description: { description: manager.firstName } },
            { term: 'Last Name', description: { description: manager.lastName } },
            { term: 'Email', description: { description: manager.email, href: `mailto:${manager.email}` } },
            { term: 'Phone', description: { description: manager.phone, href: `tel:${manager.phone}` } },
        ].filter(d => !!d.description.description);
    }

    private providerFilter(provider: AppAuthProviderConfiguration, logins?: ExternalLogin[]): boolean {
        // filter providers not already in user login list
        return (logins || []).find(login => login.tenant === provider.tenant) == null;
    }

    private async linkExternalProvider(id: string): Promise<void> {
        // check if any providers need to be linked
        const authProvider = this.tenant.authProviders ? this.tenant.authProviders.find(provider => provider.id.toString() === id) : null;
        const code = this.route.snapshot.queryParams.code;
        if (authProvider && code) {
            try {
                await this.linkUserToProvider(authProvider, code);
                await this.parent.reload();
                await this.init();
            } catch (e) {
                this.authProviderError = e;
            }
        }
    }

    private refreshUsersList(user: UcUserInfo) {
        if (this.isNew) {
            this.tableManager?.reload?.next();
            return;
        }
        this.tableManager?.updateItem?.next(user);
    }

    private linkUserToProvider(provider: AuthProviderConfiguration, code: string): Promise<any> {
        return this.client.post(this.client.buildUrl(['my-account', 'logins']), {
            params: {
                auhProvider: provider.type,
                tenant: provider.tenant,
                code
            }
        });
    }
    private getSystemRoleOptions(): string[] {

        return Object.keys(SystemRole)
            .filter(key => {
                const externalyMappedRole = this.lockedProperties.systemRoles?.includes(key);
                return key !== SystemRole.SuperUser && !externalyMappedRole;
            });
    }

    private isSuperUser(user: UcUserInfo) {
        return (user.systemRoles as string[]).includes(SystemRole.SuperUser);
    }

    private getLockedUnits(unitsPaths?: HierarchyStep[][]) {
        const options = (unitsPaths || []).map(paths => {
            const path = paths.find(p => this.lockedProperties.units.includes(p.id));
            if (!path) {
                return;
            }
            return {
                identifier: path.id,
                name: paths.map(d => d.label).join(' / '),
                paths
            };
        }).filter(Boolean);
        return options as any as Option[];
    }

    private async init() {
        this.loading = true;
        this.cd.detectChanges();

        this.userInfo = await this.parent.getUser();
        await this.initUser(this.userInfo);
        await this.initForm(this.userInfo);
        await this.initPage();

        this.loading = false;
    }

}
