import { Dictionary } from '@unifii/sdk';

import { AuthProviderMappingConditionType } from '../new-identity/models';


export const ProviderTypeDictionary: Dictionary<string> = {
    Azure: 'Microsoft',
    Auth0: 'Auth0',
    Okta: 'Okta'
};

export const ConditionOptions = [
    { identifier: AuthProviderMappingConditionType.GroupMembership, name: 'Group Membership' },
    { identifier: AuthProviderMappingConditionType.ClaimValue, name: 'Claim Value' },
    { identifier: AuthProviderMappingConditionType.And, name: 'Multiple Claim Values' },
];

// TODO currently inline with UI rather than model
export enum MappingsControlKeys {
    Condition = 'condition',
    Targets = 'targets'
}

export enum ConditionControlKeys {
    Type = 'type',
    Identifier = 'identifier',
    Value = 'value',
    Chlidren = 'children'
}

export enum ExtrasControlKeys {
    AuthorizationServer = 'authorizationServer',
}