import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UfControlArray, UfControlGroup } from '@unifii/library/common';

import { AuthProviderSourceGroup, UcAuthProviders } from 'client';

import { ArrayHelper } from 'helpers/array-helper';

import { AuthProviderMappingConditionType, ConditionTypesDescription, MappingsControlKeys } from '../models';


@Component({
    selector: 'uc-auth-provider-mapping-unit',
    templateUrl: './auth-provider-mapping-unit.html',
    styleUrls: ['./auth-provider-mapping-unit.less']
})
export class AuthProviderMappingUnitComponent implements OnInit {

    @Input() form: UfControlGroup;
    @Input() selected: string;
    @Input() parent?: UfControlGroup;
    @Input() sourceClaims: string[];
    @Input() authProviderId: string;
    @Input() identifier?: number;

    @Output() selectedChange = new EventEmitter<UfControlGroup>();
    @Output() deletedClick = new EventEmitter<{ position?: number | undefined; parent?: UfControlGroup }>();

    protected readonly controlKeys = MappingsControlKeys;
    protected readonly types = AuthProviderMappingConditionType;
    protected readonly typeDescription = ConditionTypesDescription;

    protected id: string;
    protected type: AuthProviderMappingConditionType;
    protected childrenControls: UfControlArray;

    protected filteredSourceGroups: AuthProviderSourceGroup[];
    protected filteredSourceClaims: string[];

    constructor(
        private ucAuthProviders: UcAuthProviders,
    ) { }

    ngOnInit() {
        this.setup();
    }

    protected async findSourceGroups(query?: string) {
        if (query && query.trim().length) {
            try {
                this.filteredSourceGroups = await this.ucAuthProviders.getAuthProviderGroups(this.authProviderId, query.trim());
                return;
            } catch (e) { }
        }
        this.filteredSourceGroups = [];
    }

    protected findSourceClaims(query?: string) {
        this.filteredSourceClaims = ArrayHelper.filterList(this.sourceClaims, query);
    }

    private setup() {
        this.id = this.form.get(MappingsControlKeys.Id)?.value;
        this.type = this.form.get(MappingsControlKeys.Type)?.value;
        this.childrenControls = this.form.get(MappingsControlKeys.Children) as UfControlArray;
    }
}