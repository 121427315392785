import { Routes } from '@angular/router';
import { CompoundType } from '@unifii/sdk';

import { ClaimSource } from 'client';

// Components
import { CanDeactivateEditDataComponent } from 'components/common/edit-data';
import { CanDeactivateBuilder } from 'components/compound-builder/builder-basic';
import { NotFoundComponent } from 'components/not-found/not-found.component';
import { PermissionsComponent } from 'components/permissions/permissions.component';
import { ResourceDataResolver } from 'components/permissions/resource-data-resolver';

// Navigation
import { LanguageComponent } from 'navigation/language.component';
import { ProjectComponent } from 'navigation/project.component';
import { TenantComponent } from 'navigation/tenant.component';

// Pages
import { ClaimDetailComponent, ClaimTableComponent } from 'pages';
import { APIKeyDetailsComponent } from 'pages/api-keys/api-key-details.component';
import { APIKeysComponent } from 'pages/api-keys/api-keys.component';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { AppDevicesComponent } from 'pages/apps/app-devices.component';
import { AppSendNotificationComponent } from 'pages/apps/app-send-notification.component';
import { AppSettingsComponent } from 'pages/apps/app-settings.component';
import { AppsComponent } from 'pages/apps/apps.component';
import { DeviceDetailComponent } from 'pages/apps/device-detail.component';
import { AssetsComponent } from 'pages/assets/assets.component';
import { AuditLogComponent } from 'pages/audit-log/audit-log.component';
import {
    CollectionBuilderComponent, CollectionDataComponent, CollectionItemBuilderComponent, CollectionsComponent
} from 'pages/content/collections/_index';
import { ContentComponent } from 'pages/content/content.component';
import { PageBuilderComponent, PagesComponent } from 'pages/content/pages/_index';
import { ViewBuilderComponent, ViewsComponent } from 'pages/content/views/_index';
import { UnauthorizedComponent } from 'pages/errors/unauthorized.component';
import { BucketConfigResolver } from 'pages/form-data/bucket-config-resolver';
import { BucketDataComponent } from 'pages/form-data/bucket-data.component';
import { BucketComponent } from 'pages/form-data/bucket.component';
import { BucketsComponent } from 'pages/form-data/buckets.component';
import { FormDataComponent } from 'pages/form-data/form-data.component';
import { FormEditorComponent } from 'pages/form-editor/form-editor.component';
import { FormPreviewComponent } from 'pages/forms/form-preview.component';
import { FormsComponent } from 'pages/forms/forms.component';
import { HierarchyComponent } from 'pages/hierarchy/hierarchy.component';
import { ImportComponent } from 'pages/import/import.component';
import { LabDragListComponent, ReactiveFormsComponent } from 'pages/lab';
import { EmptyComponent } from 'pages/lab/empty.component';
import { ErrorHandlingComponent } from 'pages/lab/error-handling.component';
import { IconsComponent } from 'pages/lab/icons.component';
import { LabComponent } from 'pages/lab/lab.component';
import { ListsComponent } from 'pages/lab/lists.component';
import { StyleGuideComponent } from 'pages/lab/style-guide.component';
import { TemplateFormComponent } from 'pages/lab/template-form.component';
import { TemplateTableComponent } from 'pages/lab/template-table.component';
import { TemplateTabsComponent } from 'pages/lab/template-tabs.component';
import { LandingComponent } from 'pages/landing/landing.component';
import { MediaDetailComponent } from 'pages/media/media-detail.component';
import { MediaNewComponent } from 'pages/media/media-new.component';
import { MediaComponent } from 'pages/media/media.component';
import { PageNotFoundComponent } from 'pages/page-not-found/page-not-found.component';
import { DataSourceDetailsComponent } from 'pages/project-settings/data-sources/data-source-details.compontent';
import { DataSourcesComponent } from 'pages/project-settings/data-sources/data-sources.component';
import { SettingsGeneralComponent } from 'pages/project-settings/settings-general.component';
import { SettingsThemingComponent } from 'pages/project-settings/settings-theming.component';
import { SettingsComponent } from 'pages/project-settings/settings.component';
import { ProjectOverviewComponent } from 'pages/projects/project-overview.component';
import { ProjectsComponent } from 'pages/projects/projects.component';
import { PublishComponent } from 'pages/publish/publish.component';
import { RoleDetailsComponent } from 'pages/roles/role-details.component';
import { RolesComponent } from 'pages/roles/roles.component';
import { StructureComponent } from 'pages/structure';
import { AuthProvidersComponent } from 'pages/system-settings/identity/auth-providers.component';
import { Auth0Component } from 'pages/system-settings/identity/auth0.component';
import { MicrosoftComponent } from 'pages/system-settings/identity/microsoft.component';
import { OktaComponent } from 'pages/system-settings/identity/okta.component';
import { NewAuthProvidersComponent } from 'pages/system-settings/new-identity';
import { AuthProviderComponent } from 'pages/system-settings/new-identity/auth-provider.component';
import { TenantSettingsComponent as SystemSettingsComponent } from 'pages/system-settings/settings.component';
import { SystemEmailSettingsComponent } from 'pages/system-settings/system-email-settings.component';
import { SystemGeneralComponent } from 'pages/system-settings/system-general.component';
import { SystemIntegrationComponent } from 'pages/system-settings/system-integration.component';
import { SystemIntegrationsComponent } from 'pages/system-settings/system-integrations.component';
import { SystemSmtpComponent } from 'pages/system-settings/system-smtp.component';
import { TableResolver } from 'pages/tables/table-resolver';
import { TableComponent, TableConfigurationComponent, TableDetailComponent, TablesComponent } from 'pages/tables/tables';
import { LanguageCollectionResolver } from 'pages/translations/language-collection-resolver';
import { LanguageCollectionComponent } from 'pages/translations/language-collection.component';
import { LanguageCompoundComponent } from 'pages/translations/language-compound.component';
import { LanguageOverviewComponent } from 'pages/translations/language-overview.component';
import { LanguageViewComponent } from 'pages/translations/language-view.component';
import { LanguageViewsComponent } from 'pages/translations/language-views.component';
import { TranslationsOverviewComponent } from 'pages/translations/translations-overview.component';
import { LoginComponent } from 'pages/user-access/login.component';
import { UserManagementComponent } from 'pages/user-management/user-management.component';
import { UserDetailsComponent } from 'pages/users/user-details.component';
import { UserDevicesComponent } from 'pages/users/user-devices.component';
import { UserComponent } from 'pages/users/user.component';
import { UsersTableDataResolver } from 'pages/users/users-table-data-resolver';
import { UsersComponent } from 'pages/users/users.component';
import {
    DataForwardersComponent, NewWorkflowComponent, WorkflowDataForwardersFormComponent, WorkflowNotificationComponent, WorkflowNotificationsComponent,
    WorkflowRuleFormComponent, WorkflowRulesComponent, WorkflowTimersComponent, WorkflowTimersFormComponent
} from 'pages/workflows';

// Guards
import { TenantSequenceGuard } from 'guards/main-sequence-guard';
import { SsoGuard } from 'guards/sso-guard';
import { TenantGuard } from 'guards/tenant-guard';

// Resolver
import { DefaultProjectResolver } from 'resolvers/default-project-resolver';
import { DefinitionResolver } from 'resolvers/definition-resolver';
import { ProjectResolver } from 'resolvers/project-resolver';


export const AppRoutes: Routes = [
    // Routes
    { path: 'page-not-found', component: PageNotFoundComponent, data: { title: 'Not Found' } },
    { path: 'page-not-found/:type', component: PageNotFoundComponent, data: { title: 'Not Found' } },
    { path: 'not-found', component: NotFoundComponent, data: { title: 'Not Found' } },
    { path: 'unauthorized', component: UnauthorizedComponent, data: { title: 'Unauthorized' } },
    { path: 'login', component: LoginComponent, data: { title: 'Login' } },
    { path: 'sso', component: LoginComponent, data: { title: 'Login' } },
    { path: 'form-preview', component: FormPreviewComponent, data: { title: 'Form Preview' }, canActivate: [TenantGuard] },
    {
        path: '', component: TenantComponent, canActivate: [TenantSequenceGuard], resolve: [DefaultProjectResolver],
        children: [
            { path: '', component: LandingComponent, pathMatch: 'full' },
            {
                path: 'my-account', component: UserComponent, data: { title: 'My Account' }, canDeactivate: [CanDeactivateEditDataComponent], children: [
                    {
                        path: '', component: UserDetailsComponent
                    }, {
                        path: 'sso-link', children: [
                            { path: ':id', component: UserDetailsComponent }
                        ]
                    }
                ]
            },
            {
                path: 'user-management', component: UserManagementComponent, data: { title: 'User Management' },
                children: [
                    { path: '', redirectTo: 'users', pathMatch: 'full' },
                    {
                        path: 'users', component: UsersComponent, data: { title: 'Users' }, resolve: { tableData: UsersTableDataResolver },
                        children: [
                            {
                                path: ':id', component: UserComponent, children: [
                                    { path: '', component: UserDetailsComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                                    { path: 'devices', component: UserDevicesComponent, data: { title: 'Devices' } },
                                    { path: 'permissions', component: PermissionsComponent, data: { stretchedContainer: true, title: 'Permissions' }, resolve: { resourceData: ResourceDataResolver } },
                                    { path: 'inherited-permissions', component: PermissionsComponent, data: { title: 'Inherited Permissions', readonly: true, stretchedContainer: true }, resolve: { resourceData: ResourceDataResolver } }
                                ],
                            }
                        ]
                    },
                    {
                        path: 'roles', component: RolesComponent, data: { title: 'Roles' },
                        children: [
                            { path: ':id', component: RoleDetailsComponent, resolve: { resourceData: ResourceDataResolver } }
                        ]
                    },
                    {
                        path: 'user-claims', component: ClaimTableComponent, data: { title: 'User Claims', claimSource: ClaimSource.User }, children: [
                            { path: ':claimId', component: ClaimDetailComponent, data: { title: 'Claim', claimSource: ClaimSource.User }, canDeactivate: [CanDeactivateEditDataComponent] }
                        ]
                    },
                    {
                        path: 'api-keys', component: APIKeysComponent, data: { title: 'API Keys' }, children: [
                            { path: ':id', component: APIKeyDetailsComponent, resolve: { resourceData: ResourceDataResolver } }
                        ]
                    },
                    {
                        path: 'audit-log', component: AuditLogComponent
                    },

                    {
                        path: 'hierarchy', component: HierarchyComponent, data: { title: 'Hierarchy' }
                    }
                ]
            },
            {
                path: 'assets', component: AssetsComponent, data: { title: 'Assets' }, children: [
                    { path: '', redirectTo: 'media', pathMatch: 'full' },
                    {
                        path: 'media', component: MediaComponent, data: { title: 'Media' }, children: [
                            { path: 'new', component: MediaNewComponent, pathMatch: 'full', data: { title: 'New Media' }, canDeactivate: [CanDeactivateEditDataComponent] },
                            { path: ':mediaId', component: MediaDetailComponent, canDeactivate: [CanDeactivateEditDataComponent], pathMatch: 'prefix' },
                        ]
                    },
                    {
                        path: 'translations', component: TranslationsOverviewComponent, data: { title: 'Translations' }, children: [{
                            path: 'projects/:projectId/:languageId', component: LanguageComponent, resolve: { project: ProjectResolver },
                            children: [
                                { path: '', component: LanguageOverviewComponent },
                                {
                                    path: 'views', component: LanguageViewsComponent, data: { title: 'Views' }, children: [
                                        { path: ':id', component: LanguageViewComponent, canDeactivate: [CanDeactivateBuilder], data: { title: 'View' } }
                                    ]
                                },
                                {
                                    path: 'collections', component: CollectionsComponent, data: { title: 'Collections', inTranslations: true }, children: [
                                        {
                                            path: ':collectionId', component: LanguageCollectionComponent,
                                            data: { title: 'Collection' }, resolve: { collectionData: LanguageCollectionResolver }, children: [
                                                { path: ':id', component: LanguageCompoundComponent, canDeactivate: [CanDeactivateBuilder], data: { title: 'Collection' } }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }]
                    }]
            },
            { path: 'projects', component: ProjectsComponent, data: { title: 'Projects' } },
            {
                path: 'projects/:projectId', component: ProjectComponent, resolve: { project: ProjectResolver }, canActivate: [TenantGuard],
                children: [
                    { path: '', component: ProjectOverviewComponent },
                    { path: 'structure', component: StructureComponent, canDeactivate: [CanDeactivateEditDataComponent], data: { title: 'Structure' } },
                    {
                        path: 'content', component: ContentComponent, data: { title: 'Content' }, children: [
                            { path: '', redirectTo: 'collections', pathMatch: 'full' },
                            {
                                path: 'collections', component: CollectionsComponent, data: { title: 'Collections' }, children: [
                                    {
                                        path: ':id/definition', pathMatch: 'full', component: CollectionBuilderComponent, canDeactivate: [CanDeactivateBuilder], data: { title: 'Definition' }
                                    },
                                    {
                                        path: ':id', component: CollectionDataComponent, data: { title: 'Collection' }, children: [
                                            {
                                                path: ':id', component: CollectionItemBuilderComponent, canDeactivate: [CanDeactivateBuilder], data: { title: 'Collection' }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'views', component: ViewsComponent, data: { title: 'Views' }, children: [
                                    { path: ':id', component: ViewBuilderComponent, canDeactivate: [CanDeactivateBuilder], data: { title: 'View' } },
                                ]
                            },
                            {
                                path: 'pages', component: PagesComponent, data: { title: 'Pages' }, children: [
                                    { path: ':id', component: PageBuilderComponent, canDeactivate: [CanDeactivateBuilder], data: { title: 'Pages' } }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'forms', component: FormsComponent, data: { title: 'Forms' }, children: [
                            {
                                path: ':id', component: FormEditorComponent, data: { type: CompoundType.Form, title: 'Form' },
                                resolve: { definition: DefinitionResolver }, canDeactivate: [CanDeactivateEditDataComponent]
                            }
                        ]
                    },
                    {
                        path: 'form-data', component: BucketsComponent, data: { title: 'Form Data' }, children: [
                            {
                                path: ':id', component: BucketComponent, resolve: { bucketConfig: BucketConfigResolver }, data: { title: 'Bucket', reuse: false }, children: [
                                    { path: '', redirectTo: 'live', pathMatch: 'full' },
                                    {
                                        path: 'preview', component: BucketDataComponent, data: { title: 'Preview Form Data', preview: true }, children: [
                                            { path: ':id', component: FormDataComponent, data: { title: 'Form Preview', preview: true } }
                                        ]
                                    },
                                    {
                                        path: 'live', pathMatch: 'prefix', component: BucketDataComponent, data: { title: 'Live Form Data' }, children: [
                                            { path: ':id', component: FormDataComponent, data: { title: 'Form Preview' } }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'workflows', component: NewWorkflowComponent, data: { title: 'Workflows' }, children: [
                            { path: '', redirectTo: 'rules', pathMatch: 'full' },
                            {
                                path: 'rules', component: WorkflowRulesComponent, data: { title: 'Workflow Rules' }, children: [
                                    { path: ':id', component: WorkflowRuleFormComponent, canDeactivate: [CanDeactivateEditDataComponent] }
                                ]
                            },
                            {
                                path: 'timers', component: WorkflowTimersComponent, data: { title: 'Workflow Timers' }, children: [
                                    { path: ':id', component: WorkflowTimersFormComponent, canDeactivate: [CanDeactivateEditDataComponent] }
                                ]
                            },
                            {
                                path: 'data-forwarders', component: DataForwardersComponent, data: { title: 'Workflow Data Forwarders' }, children: [
                                    { path: ':id', component: WorkflowDataForwardersFormComponent, canDeactivate: [CanDeactivateEditDataComponent] }
                                ]
                            },
                            {
                                path: 'notifications', component: WorkflowNotificationsComponent, data: { title: 'Workflow Notifications' }, children: [
                                    { path: ':id', component: WorkflowNotificationComponent, canDeactivate: [CanDeactivateEditDataComponent] }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'tables', component: TablesComponent, data: { title: 'Tables' }, children: [
                            {
                                path: ':id', component: TableComponent, resolve: { info: TableResolver }, children: [
                                    { path: '', component: TableConfigurationComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                                    { path: 'details-page', component: TableDetailComponent, canDeactivate: [CanDeactivateEditDataComponent] }
                                ]
                            }
                        ]
                    },
                    { path: 'import', component: ImportComponent, data: { title: 'Import' } },
                    { path: 'publish', component: PublishComponent, data: { title: 'Publish' } },
                    {
                        path: 'settings', component: SettingsComponent, data: { title: 'Project Settings' },
                        children: [
                            { path: '', redirectTo: 'general', pathMatch: 'full' },
                            { path: 'general', component: SettingsGeneralComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                            { path: 'theming', component: SettingsThemingComponent, canDeactivate: [CanDeactivateEditDataComponent], data: { title: 'Theming' } },
                            {
                                path: 'data-sources', component: DataSourcesComponent, data: { title: 'Data Sources' }, children: [
                                    { path: ':id', component: DataSourceDetailsComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'system-settings', component: SystemSettingsComponent, data: { title: 'System Settings' },
                children: [
                    { path: '', redirectTo: 'general', pathMatch: 'full' },
                    { path: 'general', component: SystemGeneralComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                    {
                        path: 'integrations', component: SystemIntegrationsComponent, data: { title: 'Integrations' }, children: [
                            { path: ':id', component: SystemIntegrationComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                        ]
                    },
                    {
                        path: 'sso', component: AuthProvidersComponent, data: { title: 'Identity' }, children: [
                            { path: 'azure/:id', component: MicrosoftComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                            { path: 'azure', component: MicrosoftComponent, canDeactivate: [CanDeactivateEditDataComponent], canActivate: [SsoGuard] },
                            { path: 'auth0/:id', component: Auth0Component, canDeactivate: [CanDeactivateEditDataComponent] },
                            { path: 'auth0', component: Auth0Component, canDeactivate: [CanDeactivateEditDataComponent] },
                            { path: 'okta/:id', component: OktaComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                            { path: 'okta', component: OktaComponent, canDeactivate: [CanDeactivateEditDataComponent] }

                        ]
                    },
                    {
                        path: 'sso-new', component: NewAuthProvidersComponent, data: { title: 'Identity 2' }, children: [
                            { path: ':id', component: AuthProviderComponent, canDeactivate: [CanDeactivateEditDataComponent], canActivate: [SsoGuard] },

                        ]
                    },
                    { path: 'email', component: SystemEmailSettingsComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                    { path: 'smtp', pathMatch: 'prefix', component: SystemSmtpComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                    {
                        path: 'apps', component: AppsComponent, data: { title: 'Apps' }, children: [
                            {
                                path: ':id', component: AppDetailsComponent, data: { title: 'Apps' }, children: [
                                    { path: '', component: AppSettingsComponent, canDeactivate: [CanDeactivateEditDataComponent] },
                                    {
                                        path: 'devices', component: AppDevicesComponent, children: [
                                            { path: ':id', component: DeviceDetailComponent, data: { title: 'Apps' } }
                                        ]
                                    },
                                    { path: 'send-notification', component: AppSendNotificationComponent },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                path: 'lab', component: LabComponent, data: { title: 'Lab' }, children: [
                    {
                        path: '', redirectTo: 'style-guide', pathMatch: 'full'
                    },
                    {
                        path: 'style-guide', component: StyleGuideComponent, data: { title: 'Style Guide' }
                    },
                    {
                        path: 'empty', component: EmptyComponent, data: { title: 'Empty' }
                    },
                    {
                        path: 'icons', component: IconsComponent, data: { title: 'Icons' }
                    },
                    {
                        path: 'lists', component: ListsComponent, data: { title: 'Lists' }
                    },
                    {
                        path: 'drag-list', component: LabDragListComponent, data: { title: 'Drag List' }
                    },
                    {
                        path: 'reactive-forms', component: ReactiveFormsComponent, data: { title: 'Reactive Forms' }
                    },
                    {
                        path: 'template-form', component: TemplateFormComponent, data: { title: 'Form' }
                    },
                    {
                        path: 'template-table', component: TemplateTableComponent, data: { title: 'Table' }
                    },
                    {
                        path: 'template-tabs', component: TemplateTabsComponent, data: { title: 'Tabs' }, children: [
                            { path: '', pathMatch: 'prefix', component: TemplateFormComponent },
                            { path: 'table', component: TemplateTableComponent }
                        ]
                    },
                    {
                        path: 'error-handling', component: ErrorHandlingComponent, data: { title: 'Error Handling' }
                    },
                ]
            }
        ]
    },
    { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' }
];
