import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService } from '@unifii/library/common';
import { AuthProvider, Dictionary } from '@unifii/sdk';

import { TypeSelectComponent, TypeSelectConfig, TypeSelectOption } from 'components/common/type-select.component';

import { ProviderTypeDictionary } from './auth-provider-model';
import { AuthProvidersTableManager } from './auth-providers-table-manager';


@Component({
    template: `
    <uf-table-container ufSyncRoute ufMasterDetail (addItem)="selectProvider()" class="cards container">
    </uf-table-container>
    <router-outlet></router-outlet>
`,
    providers: [
        { provide: TableContainerManager, useClass: AuthProvidersTableManager }
    ]
})
export class AuthProvidersComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) { }

    async selectProvider() {

        const config: TypeSelectConfig = {
            title: 'Select SSO Provider',
            types: [
                {
                    type: AuthProvider.Azure,
                    label: ProviderTypeDictionary.Azure,
                    logo: `assets/svg/${AuthProvider.Azure.toLowerCase()}.svg`
                },
                {
                    type: AuthProvider.Azure,
                    label: `${ProviderTypeDictionary.Azure} - Manual Registration`,
                    logo: `assets/svg/${AuthProvider.Azure.toLowerCase()}.svg`,
                    manualRegistration: true,
                    isActive: false
                },
                {
                    type: AuthProvider.Auth0,
                    label: ProviderTypeDictionary.Auth0,
                    logo: `assets/svg/${AuthProvider.Auth0.toLowerCase()}.svg`,
                    isActive: false
                },
                {
                    type: AuthProvider.Okta,
                    label: ProviderTypeDictionary.Okta,
                    logo: `assets/svg/${AuthProvider.Okta.toLowerCase()}.svg`,
                    isActive: false
                }]
        };

        const provider = await this.modalService.openMedium<TypeSelectConfig, TypeSelectOption>(TypeSelectComponent, config);
        if (!provider) {
            return;
        }

        const params: Dictionary<any> = {};

        if (provider.manualRegistration) {
            params.manualRegistration = true;
        }

        this.router.navigate([provider.type.toLowerCase(), params], { relativeTo: this.route });
    }
}
