import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { UcClaimConfig, UcUserClaims } from 'client';

import { ArrayHelper } from 'helpers/array-helper';

import { ClaimControlKeys, ClaimMappingController } from './claim-mapping-controller';


export interface MappingModalData {
    mappingControl: UfControlGroup;
    sourceClaims: string[];
    providerId: string;
}

@Component({
    templateUrl: './claim-mapping-modal.html'
})
export class ClaimMappingModalComponent implements Modal<{ control: UfControlGroup; sourceClaims: string[] }, UfControlGroup> {

    @HostBinding('class.uf-form-card') cardClass = true;

    readonly fieldType = FieldType;
    readonly claimControlKeys = ClaimControlKeys;

    control: UfControlGroup;
    filteredSourceClaims: string[];
    claimOptions: UcClaimConfig[];

    constructor(
        @Inject(ModalData) public data: { control: UfControlGroup; sourceClaims: string[] },
        public runtime: ModalRuntime<{ control: UfControlGroup; sourceClaims: string[] }, UfControlGroup>,
        private claimMappingController: ClaimMappingController,
        private ucUserClaims: UcUserClaims
    ) {
        this.control = this.claimMappingController.createClaimControl(this.data.control.getRawValue());
    }

    get valueMapControls(): UfControlArray {
        return this.control.get(ClaimControlKeys.ValueMap) as UfControlArray;
    }

    get targetControl(): UfControlGroup {
        return this.control.get(ClaimControlKeys.Target) as UfControlGroup;
    }

    get sourceControl(): UfControl {
        return this.control.get(ClaimControlKeys.Source) as UfControl;
    }

    addMapping() {
        this.valueMapControls.push(this.claimMappingController.createMappingControl());
    }

    removeMapping(index: number) {
        this.valueMapControls.removeAt(index);
    }

    save() {
        this.control.setSubmitted();
        if (this.control.invalid) {
            return;
        }

        this.runtime.close(this.control);
    }

    // Duplicated in mapping modal (all mappings will move there eventually, until then we'll have it in multiple places)
    findSourceClaims(query?: string) {
        this.filteredSourceClaims = ArrayHelper.filterList(this.data.sourceClaims, query);
    }

    async findClaims(q: string | null = null) {
        this.claimOptions = await this.ucUserClaims.list({ params: { q } });
    }
}
