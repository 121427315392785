import { Component, Inject, Input, OnInit } from '@angular/core';
import { ModalService, UfControlArray } from '@unifii/library/common';
import { Option } from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

import { AuthProviderMappingActionType, SystemRole } from 'client';

import { ArrayHelper } from 'helpers/array-helper';

import { ActionOptions, ActionsTypesDescription, MappingsControlKeys } from '../models';

import { AuthProviderMappingsController } from './auth-provider-mapping.controller';


@Component({
    selector: 'uc-auth-provider-mapping-actions',
    templateUrl: './auth-provider-mapping-actions.html',
    // styleUrls: ['./auth-provider-mapping-actions.less']
    styleUrls: ['./auth-provider-mapping-unit.less']
})
export class AuthProviderMappingActionsComponent implements OnInit {

    @Input() actions: UfControlArray;
    @Input() sourceClaims: string[];

    protected readonly controlKeys = MappingsControlKeys;
    protected readonly actionOptions: Option[] = ActionOptions;
    protected readonly types = AuthProviderMappingActionType;

    protected filteredSourceClaims: string[];
    protected filteredSystemRoles: string[];
    protected filteredRoles: string[];

    private readonly typeDescription = ActionsTypesDescription;

    constructor(
        private modalService: ModalService,
        private mappingsController: AuthProviderMappingsController,
        @Inject(UserProvisioningProvider) private userProvisioning: UserProvisioningProvider
    ) { }

    ngOnInit() {
    }

    addAction(option: Option) {
        this.actions.push(this.mappingsController.buildAction({ type: option.identifier as AuthProviderMappingActionType, identifier: '' }));
    }

    async deleteAction(position: number) {
        const confirm = await this.modalService.openConfirm({
            cancelLabel: `Don't Delete`,
            confirmLabel: 'Delete',
            message: 'Are you sure you want to delete this action?'
        });

        if (confirm) {
            this.actions.removeAt(position);
        }
    }

    protected findSourceClaims(query?: string) {
        this.filteredSourceClaims = ArrayHelper.filterList(this.sourceClaims, query);
    }

    protected findSystemRoles(query?: string) {
        const roles = Object.keys(SystemRole)
            .filter(key => key !== SystemRole.SuperUser);

        this.filteredSystemRoles = (query && query.trim().length) ?
            roles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...roles];
    }

    protected mapActionTypeDescription(type: AuthProviderMappingActionType) {
        return this.typeDescription[type];
    }

    protected async findRoles(query?: string) {
        const roles = await this.userProvisioning.queryRoles(query);
        this.filteredRoles = roles.map(r => r.name);
    }

}