import { Subscription } from 'rxjs';

import { Component, Inject, Injectable, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ToastService, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { ComponentRegistryType, FormComponentRegistryOptions, FormConfiguration, FormField, FormSettings } from '@unifii/library/smart-forms';
import { FormComponentRegistry, InputFormSettings, UfFormComponent, UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { Definition, Dictionary, Error, ErrorType, FieldType, FormData } from '@unifii/sdk';

import { Integration, IntegrationFeatureType, IntegrationProvider, IntegrationProviderFeature, UcClient, UcIntegrations } from 'client';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { SecretFieldComponent } from './secret-input-field.component';
import { SystemIntegrationsTableManager } from './system-integrations-table-manager';


export const Messages: Dictionary<string> = {
    FormError: 'There are errors with your form',
    ConnectionSuccess: 'Connection test was successful',
    ConnectionFailure: 'Connection test failed',
    LoadError: 'Failed to load integration',
    SaveError: 'Failed to save integration',
    UnknownError: 'Unhandled error'
};

export const SecretTag = 'secret';

@Injectable()
export class SystemIntergrationRegistry extends UfFormComponentRegistry {

    type = ComponentRegistryType.Input;

    get(type: FieldType, options?: FormComponentRegistryOptions): Type<FormField> {

        if (options?.tags?.includes(SecretTag)) {
            return SecretFieldComponent;
        }
        return super.get(type, options);
    }
}


@Component({
    templateUrl: './system-integration.html',
    styleUrls: ['./system-integration.less'],
    providers: [
        { provide: FormComponentRegistry, useClass: SystemIntergrationRegistry },
        { provide: FormSettings, useClass: InputFormSettings }
    ]
})
export class SystemIntegrationComponent implements OnInit, OnDestroy, EditData {

    form: UfFormComponent;
    integration: Integration | null;
    lookupFeatures: IntegrationProviderFeature[] = [];
    sinkFeatures: IntegrationProviderFeature[] = [];
    ticketProviderFeatures: IntegrationProviderFeature[] = [];
    validatorFeatures: IntegrationProviderFeature[] = [];

    formConfig: FormConfiguration = {
        hideLabel: true,
    };
    control: UfControl = new UfControl(ValidatorFunctions.required('This field is required'));
    error: Error;
    loading = false;
    edited: boolean;
    definition: Definition;
    secretIdentifier: string;
    breadcrumbs: Breadcrumb[];

    // todo: use provider on the side create integration
    // private provider: IntegrationProvider;
    private integrationId: string;
    private providerId: string;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucIntegrations: UcIntegrations,
        private ucClient: UcClient,
        // private systemIntegrationsComponent: SystemIntegrationsComponent,
        @Inject(TableContainerManager) private manager: SystemIntegrationsTableManager,
        private toast: ToastService,
        private breadcrumbService: BreadcrumbService
    ) {
        this.integrationId = this.route.snapshot.params.id;
        this.providerId = this.route.snapshot.queryParams.provider;

        this.subscriptions.add(this.control.valueChanges.subscribe(() => this.edited = this.control.dirty));
    }

    async ngOnInit() {

        try {

            this.integration = await this.getIntegration();
            const defaultFeatureConfig = this.createFeatureConfig(this.integration.provider.features, this.integration.featureConfig || {});
            this.integration.featureConfig = Object.assign(defaultFeatureConfig, this.integration.featureConfig || {});

            this.setupFeaturesList(this.integration.provider as IntegrationProvider);
            this.setupForm(this.integration.provider as IntegrationProvider);

        } catch (e) {
            this.error = this.getError(e, Messages.LoadError);
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.integration?.name ?? 'New']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    @ViewChild(UfFormComponent, { static: true }) set formComponent(form: UfFormComponent) {

        if (form == null) {
            return;
        }

        this.form = form;
        this.subscriptions.add(this.form.rootControl.statusChanges.subscribe(() => this.edited = true));
    }

    test() {

        if (!this.valid || this.integration == null) {
            this.setSubmitted();
            this.toast.error(Messages.FormError);
            return;
        }

        const providerId = this.providerId || this.integration.provider.id;

        this.ucClient.testAvailableIntegration(providerId, this.integration.config)
            .then(() => {
                this.toast.success(Messages.ConnectionSuccess);
            }, () => {
                this.toast.error(Messages.ConnectionFailure);
            });
    }

    async submit() {

        if (!this.valid || this.integration == null) {
            this.setSubmitted();
            this.toast.error(Messages.FormError);
            return;
        }

        const integration: Integration = Object.assign({}, this.integration);
        // Provider can't be edited, on PUT call only id should be supplied
        integration.provider = { id: this.integration.provider.id } as IntegrationProvider;

        /** todo: remove when not needed */
        this.removeSmartFormData(integration.config);

        try {
            this.loading = true;
            await this.ucIntegrations.save(integration);
            this.manager.reload.next();
            this.edited = false;
            this.close();
        } catch (err) {
            const error = this.getError(err, Messages.SaveError);
            this.toast.error(error.message as string);
        } finally {
            this.loading = false;
        }
    }

    onFormDataChange() {
        this.edited = true;
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    private createFeatureConfig(features: IntegrationProviderFeature[], featureConfig: Dictionary<{ disabled: boolean }>): Dictionary<{ disabled: boolean }> {

        return features.reduce((result, feature) => {

            if (feature.configurable) {
                result[feature.id] = { disabled: feature.disabled };
            }
            return result;
        }, {} as Dictionary<{ disabled: boolean }>);
    }

    private async getIntegration(): Promise<Integration> {

        if (this.isNew) {
            const provider = await this.ucClient.getAvailableIntegration(this.providerId);

            const name = '';
            const config = {};
            return { provider, name, config };
        }

        return this.ucIntegrations.get(this.integrationId);
    }

    private setupFeaturesList(provider: IntegrationProvider) {

        if (provider == null) {
            return;
        }

        for (const feature of (provider.features || [])) {

            switch (feature.type) {
                case IntegrationFeatureType.Lookup: this.lookupFeatures.push(feature); break;
                case IntegrationFeatureType.Sink: this.sinkFeatures.push(feature); break;
                case IntegrationFeatureType.TicketProvider: this.ticketProviderFeatures.push(feature); break;
                case IntegrationFeatureType.Validator: this.validatorFeatures.push(feature); break;
            }
        }
    }

    private setupForm(provider: IntegrationProvider) {

        if (provider == null || provider.configForm == null) {
            return;
        }

        this.definition = provider.configForm;
    }

    private setSubmitted() {

        if (!this.integration) {
            return;
        }

        this.control.setSubmitted();

        if ((this.integration.provider as IntegrationProvider).configForm && this.form) {
            this.form.rootControl.setSubmitted();
        }
    }

    private get valid(): boolean {

        if (!this.integration) {
            return false;
        }

        if ((this.integration.provider as IntegrationProvider).configForm && this.form) {
            return this.form.rootControl.valid && this.control.valid;
        }

        return this.control.valid;
    }

    private get isNew(): boolean {
        return this.integrationId === 'new';
    }

    private getError(error: Error | any = {}, message?: string): Error {

        // Set defaults
        message = message || Messages.UnknownError;
        const type = ErrorType.Unknown;

        if (error.message == null) {
            delete error.message;
        }

        return Object.assign({ message, type }, error);
    }

    private removeSmartFormData(formData: FormData) {

        /**
         * todo: remove this once uf-form has been improved
         */
        const smartFormsKeys = ['id', '_definitionVersion', '_history', '_openedAt', '_state'];

        for (const key of smartFormsKeys) {
            if (formData[key]) {
                delete formData[key];
            }
        }
    }

}
