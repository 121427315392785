import { ChangeDetectorRef, Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Definition, FormData } from '@unifii/sdk';


export interface ModalFormData {
    title: string;
    message?: string;
    cancelText?: string;
    actionText?: string;
    definition: Definition;
    formData?: FormData;
}

@Component({
    templateUrl: './modal-form.html',
    providers: [{ provide: FormSettings, useClass: InputFormSettings }]
})
export class ModalFormComponent implements Modal<ModalFormData, FormData>, OnInit {

    @HostBinding('class.uc-form-card') class = true;

    @ViewChild(UfFormComponent) form: UfFormComponent;

    ready: boolean;
    title: string;
    message?: string;
    definition: Definition;
    config: FormConfiguration;
    formData?: FormData;
    cancelText: string;
    actionText: string;

    modalData: ModalFormData;

    constructor(
        public runtime: ModalRuntime<ModalFormData, FormData>,
        @Inject(ModalData) data: ModalFormData,
        private cdr: ChangeDetectorRef
    ) {
        this.modalData = data;
    }

    ngOnInit() {
        this.title = this.modalData.title;
        this.message = this.modalData.message;
        this.actionText = this.modalData.actionText || 'Submit';
        this.cancelText = this.modalData.cancelText || 'Cancel';
        this.formData = this.modalData.formData;
        this.config = { hideLabel: true };
        this.definition = this.modalData.definition;
        this.ready = true;
        this.cdr.detectChanges();
    }

    save() {

        this.form.rootControl.setSubmitted();

        if (this.form.rootControl.invalid) {
            return;
        }

        this.runtime.close(this.form.formData);
    }

    close() {
        this.runtime.close();
    }
}
