import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, MessageLevel, ModalService, ToastService } from '@unifii/library/common';
import {
    ComponentRegistry, FieldDependencyTracker, FormService, FormSettings, FormValidators, ScopeManager, ValidatorBuilder
} from '@unifii/library/smart-forms';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { CompoundType, FieldType, Page, PageField } from '@unifii/sdk';

import { CompoundInfo, UcPage, UcProject } from 'client';

import { EditMode } from 'components/common/edit-data';
import { SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentComponentRegistry } from 'components/content-component-registry';
import { ContentSettings } from 'components/content/content';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from 'components/content/modals/link-search.component';
import { ModalSearchData } from 'components/content/modals/modal-search';

import { FieldDetailHelper } from 'helpers/helpers';

import { PagesComponent } from 'pages/content/pages/pages.component';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';
import { UcTableManager } from 'services/table/models';


@Component({
    templateUrl: './page-builder.html',
    styleUrls: ['./../../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        ValidatorBuilder,
        ScopeManager,
        FormValidators,
        FieldDependencyTracker,
        FormService,
        { provide: FormSettings, useValue: {} },
        { provide: ComponentRegistry, useClass: ContentComponentRegistry },
        { provide: ContentSettings, useValue: { mode: 'Edit' } },
    ]
})
export class PageBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.Page;
    subject = BuilderCompoundSubjects.DEFINITION;
    ready: boolean;
    selected: PageField;
    displayPage: Page | null;

    private lastInserted: PageField | null;
    private pageChanged: Subject<UcPage> = new Subject();
    private _page: UcPage;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected parent: PagesComponent,
        protected router: Router,
        protected route: ActivatedRoute,
        private ucProject: UcProject,
        private toastService: ToastService,
        private displayService: DisplayService,
        private limitService: LimitService,
        private breadcrumbService: BreadcrumbService,
        private context: ContextService,
        private builderHeaderService: BuilderHeaderService,
        @Optional() @Inject(TableContainerManager) protected tableManager: UcTableManager<CompoundInfo>
    ) {
        super(builderService, modalService, route, tableManager);
    }

    get title() {
        return this.builderService.definition.recordName;
    }

    get page(): UcPage {
        return this._page;
    }

    set page(v: UcPage) {
        if (v === this.page) {
            return;
        }

        this._page = v;
        this.pageChanged.next(this.page);
    }

    async ngOnInit() {

        // Load page
        this.page = await this.load();

        // Register event handlers
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.page);

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
        this.buildHeaderConfig(this.page);
    }

    addSubscribers() {

        this.subscriptions.add(this.pageChanged.pipe(debounceTime(500)).subscribe(() => {
            this.updatePreview();
        }));

        // React to builderService ready
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
            this.builderService.fieldSelect.next(null);
        }));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe(_i => {
            this.pageChanged.next(this.page);
            this.builderService.memento.setLastAtomic();
        }));

        this.subscriptions.add(this.builderService.fieldAdded.subscribe(i => {
            this.selectField(i);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldMoved.subscribe(i => {
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldRemoved.subscribe(i => {
            this.builderService.fieldSelect.next(null);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));

        this.subscriptions.add(this.builderService.fieldReady.subscribe(i => {
            // Auto-select last inserted field
            if (this.lastInserted === i.subject) {
                this.selectField(i);
                this.lastInserted = null;
            }
        }));
    }

    selectField(i: BuilderEventInfo = { subject: null, atomic: false }) {

        const next = !i ? null : i.subject;

        if (this.selected !== next) {
            this.selected = next;
            this.builderService.fieldSelected.next(i);
        }
    }

    removeField(i: BuilderEventInfo) {
        const position = this.getFieldPosition(i.subject);
        if (position) {
            position.parent[this.builderService.childrenProperty].splice(position.index, 1);
            this.builderService.fieldRemoved.next(i);
        }
    }

    async save(saveOption?: SaveOption) {
        // Notify the other components of a builder save/submit action
        this.builderService.submit.next(null);

        // Validate builder status
        if (!this.builderService.isValid()) {
            this.builderService.markFieldsSubmitted();
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: 'Unable to save. There are errors in your Page.' });
            return;
        }

        const cleanPage = this.builderService.cleanPage(this.page);

        // Save the page
        try {
            this.builderService.busy.next(true);
            this.page = await this.ucProject.savePage(cleanPage);

            this.saved(this.page, saveOption);
            this.builderService.init(this, this.page);

            // Notify user of the save success
            this.toastService.success('Page saved!');
            this.buildHeaderConfig(this.page);
            if (saveOption?.id === SaveOptionType.New &&
                !(await this.limitService.canAddPages())) {
                saveOption = undefined;
            }
        } catch (err) {
            const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message });
        } finally {
            this.builderService.busy.next(false);
        }
    }

    canDrop = async (element: any, _parent: any): Promise<boolean> => {

        if (!element.type || (element.type && FieldType[element.type as FieldType] == null)) {
            // Not a field (element from right panel like Validator, Option....)
            return false;
        }

        if (!element.reference) {
            // An actual field and not it's reference from the left tools list
            return true;
        }

        if (element.type === FieldType.LinkList) {

            const data: LinkSearchConfig = {
                title: 'Select Collection',
                multiSelect: false,
                minQuantitySelected: 1,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection
            };
            // LinkList (select a single collection)
            const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

            if (!result || !result.length) {
                return false;
            }

            element.field = {
                type: element.type,
                compoundType: CompoundType.Collection,
                definitionIdentifier: result[0].identifier
            };

            return true;
        }

        if (element.type === FieldType.Link && element.compoundType === CompoundType.Collection) {
            element.field = {
                type: element.type,
                compoundType: CompoundType.Collection
            };
            return true;
        }

        if ([FieldType.Text, FieldType.MultiText].indexOf(element.type) >= 0) {
            element.field = {
                type: element.type,
                isTranslatable: true
            };
            return true;
        }

        // No dialog needed
        return true;
    };

    convertFieldRef(ref: any) {
        if (ref.field) {
            return ref.field;
        }

        const res: any = { type: ref.type };
        if (ref.compoundType) {
            res.compoundType = ref.compoundType;
        }
        return res;
    }

    inserted(field: PageField) {
        delete field.value;
        (field as any).isNew = true;
        this.lastInserted = field;

        const fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type);
        if (fm.hasArrayValues === true) {
            field.value = [];
        }
        this.builderService.fieldAdded.next({ subject: field, atomic: true });
    }

    moved(field: PageField) {
        this.builderService.fieldMoved.next({ subject: field, atomic: true });
    }

    restore(step: number) {
        const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();
        this.builderService.definition = value;
        this.builderService.fieldSelect.next(null);
    }

    private async load(): Promise<UcPage> {

        if (this.editMode === EditMode.New) {
            return { recordName: '', _title: '', title: '', identifier: '', fields: [] };
        }

        const page = await this.ucProject.getPage(this.route.snapshot.params.id);

        if (this.editMode === EditMode.Existing) {
            return page;
        }

        delete page.id;
        delete page.lastModifiedAt;
        delete page.lastPublishedAt;
        page.identifier = '';
        page.recordName = '';
        return page;

    }

    private async updatePreview() {

        /**
         * No need for an async call if definition doesn't contain a LinkList
         * note - Links need to be converted to Compounds before rendered
         */
        if (this.page.fields.find(field => field.type === FieldType.LinkList) == null) {
            this.displayPage = this.page;
            return;
        }

        try {
            const displayContent = await this.displayService.renderPage(this.page);
            this.displayPage = displayContent.page || null;
        } catch (e) {
            console.error(e);
        }
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.definition, i.atomic);
        this.builderService.memento.edited = true;

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }

        this.pageChanged.next(this.page);
    }

    private buildHeaderConfig(page: UcPage) {
        this.builderHeaderService.buildConfig({
            ...page,
            title: this.title,
            cancelRoute: ['../'],
            saveOptions: this.saveOptions,
            publishState: page.publishState,
            restrictSave: 'ContentEditor',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.title || 'New']),
        });
    }
}
