/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component } from '@angular/core';
import { ExpressionParser, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, FieldValidator, ValidatorType } from '@unifii/sdk';

import { BuilderField } from 'client';

import { FieldDetailHelper, ValidatorConfig } from 'helpers/helpers';
import { DialogsService } from 'services/dialogs.service';

import { BuilderService } from '../compound-builder/builder.service';

import { FieldAttributeConfig } from './field-detail-basic';
import { FieldDetailListBasic } from './field-detail-list-basic';


interface InputsConfig {
    type: FieldAttributeConfig;
    message: FieldAttributeConfig;
    pattern: FieldAttributeConfig;
    minLength: FieldAttributeConfig;
    min: FieldAttributeConfig;
    max: FieldAttributeConfig;
    expression: FieldAttributeConfig;
    itemExpression: FieldAttributeConfig;
}

@Component({
    selector: 'uc-field-validators',
    templateUrl: './field-validators.html'
})
export class FieldValidatorsComponent extends FieldDetailListBasic<FieldValidator, InputsConfig> {

    validatorDictionary: Dictionary<ValidatorConfig>;
    validatorOptions: ValidatorConfig[];
    expanded: Dictionary<boolean> = {};

    constructor(
        builderService: BuilderService,
        ref: ChangeDetectorRef,
        dialogs: DialogsService,
        private expressionParser: ExpressionParser
    ) {
        super(builderService, 'validators', ref, dialogs);
    }

    get elements(): FieldValidator[] {
        return this.field.validators as FieldValidator[];
    }

    set elements(validators: FieldValidator[]) {
        this.field.validators = validators;
    }

    protected beforeSetup(field: BuilderField): boolean {
        // Filter validator dictionary based on field
        this.validatorDictionary = FieldDetailHelper.validators;
        this.validatorOptions = [];
        Object.keys(this.validatorDictionary).forEach(key => {
            if (this.validatorDictionary[key].restricted.length === 0 || this.validatorDictionary[key].restricted.indexOf(field.type) >= 0) {
                this.validatorOptions.push(Object.create(this.validatorDictionary[key]));
            }
        });

        return true;
    }

    protected update() { }

    addValidator() {

        const fieldValidator: FieldValidator = { type: null as any, message: null as any };
        this.addElementGroupControl(fieldValidator, this.elements.length);

        this.expanded['' + this.elements.length] = true;
        this.elements.push(fieldValidator);
        this.formList.markAsTouched();
    }

    protected addElementGroupControl(_validator: FieldValidator, _i: number) {
        const validatorGroup = new UfControlGroup({});
        this.formList.push(validatorGroup);

        const cfg: InputsConfig = {
            type: { show: false, control: new UfControl(ValidatorFunctions.required('Type is required')) },
            message: { show: false, control: new UfControl(ValidatorFunctions.required('Message is required')) },
            expression: {
                show: false, control: new UfControl([
                    ValidatorFunctions.required('Expression is required'),
                    ValidatorFunctions.custom((v) => this.expressionParser.validate(v), 'Invalid Expression')
                ])
            },
            minLength: { show: false, control: new UfControl(ValidatorFunctions.required('Number is required')) },
            min: { show: false, control: new UfControl(ValidatorFunctions.required('Number is required')) },
            max: { show: false, control: new UfControl(ValidatorFunctions.required('Number is required')) },
            pattern: {
                show: false, control: new UfControl(ValidatorFunctions.compose([
                    ValidatorFunctions.required('Pattern is required'),
                    // ValidatorFunctions.custom(v => {
                    //     try {
                    //         console.log('Pattern', v);
                    //         const regex = new RegExp(v);
                    //         console.log('Valid RegExp', regex);
                    //         return true;
                    //     } catch (e) {
                    //         console.log('Invalid', e);
                    //         return false;
                    //     }
                    // }, 'Patter is not valid')
                ]))
            },
            itemExpression: { show: false, control: new UfControl(ValidatorFunctions.required('Item Expressoin is required')) }
        };

        this.configs.push(cfg);

        validatorGroup.addControl('type', cfg.type.control);
        cfg.type.show = true;
    }

    protected afterSetup() {

        this.expanded = this.elements
            .reduce((expanded: Dictionary<boolean>, item, index) => {
                expanded['' + index] = false;
                return expanded;
            }, {});
    }

    protected afterAddElementGroupControl(validator: FieldValidator, i: number) {
        this.changeFormValidatorType(validator.type, i);
    }

    changeType(value: ValidatorType, idx: number) {
        this.applyDefaultValues(this.elements[idx]);
        this.changeFormValidatorType(value, idx);
    }

    private changeFormValidatorType(type: ValidatorType, idx: number) {
        const group = this.formList.at(idx) as UfControlGroup;
        const cfg = this.configs[idx];
        cfg.message.show = [
            ValidatorType.Pattern, ValidatorType.MinLength, ValidatorType.Min, ValidatorType.Max, ValidatorType.Expression,
            ValidatorType.ItemExpression, ValidatorType.LettersOnly, ValidatorType.Alphanumeric, ValidatorType.BeforeNow,
            ValidatorType.AfterNow, ValidatorType.Email, ValidatorType.Website].indexOf(type) >= 0;
        cfg.pattern.show = [ValidatorType.Pattern].indexOf(type) >= 0;
        cfg.minLength.show = [ValidatorType.MinLength].indexOf(type) >= 0;
        cfg.min.show = [ValidatorType.Min].indexOf(type) >= 0;
        cfg.max.show = [ValidatorType.Max].indexOf(type) >= 0;
        cfg.expression.show = [ValidatorType.Expression].indexOf(type) >= 0;
        cfg.itemExpression.show = [ValidatorType.ItemExpression].indexOf(type) >= 0;
        // Add/Remove controls from the form
        Object.keys(cfg).forEach(k => {
            if (cfg[k as keyof InputsConfig].show === true && group.controls[k] == null) {
                // Add control
                group.addControl(k, cfg[k as keyof InputsConfig].control);
            }
            if (cfg[k as keyof InputsConfig].show === false && group.controls[k] != null) {
                group.removeControl(k);
            }
        });
    }

    private applyDefaultValues(validator: FieldValidator) {
        if (validator.type && this.validatorDictionary[validator.type]) {
            const v: ValidatorConfig = this.validatorDictionary[validator.type];
            if (v.values) {
                Object.keys(v.values).forEach(k => {
                    if (validator[k as keyof FieldValidator] == null) {
                        validator[k as keyof FieldValidator] = (v.values as Dictionary<any>)[k];
                    }
                });
                setTimeout(() => {
                    // Form is slow to update values after set of new field values
                    this.notifyRefresh();
                }, 0);
            }
        }
    }
}
