import { AfterViewInit, Component, Inject, Optional } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { CompoundType } from '@unifii/sdk';

import { SystemRole } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';
import { ContentSettings } from 'components/content/content';
import { ContentList } from 'components/content/content-list';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { DialogsService } from 'services/dialogs.service';


@Component({
    templateUrl: './file-list.html',
    styleUrls: ['./group-input.less']
})
export class FileListComponent extends ContentList implements FormField, AfterViewInit {

    draggable = true;

    constructor(
        modalService: ModalService,
        builderService: BuilderService,
        dialogs: DialogsService,
        @Inject(ContentSettings) @Optional() contentSettings: ContentSettings
    ) {
        super(MediaSearchComponent, modalService, builderService, contentSettings, dialogs);

        if (this.builderService.builder.type === CompoundType.Collection) {
            this.draggable = false;
        }
    }

    protected getSearchConfig(): any {
        return {
            type: 'File',
            title: 'Add File(s)',
            multiSelect: true
        };
    }

    protected convertResultToContent(item: any): any {
        return item;
    }

    get removeRestrictions(): string | null {

        if (this.builderService.builder.type === CompoundType.Page) {
            return null;
        }

        return SystemRole.ProjectManager;
    }

}
