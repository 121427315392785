import { Injectable } from '@angular/core';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetails, AuthProviderExtras } from 'client';

import { AuthProviderDetailsModel, DetailsControlKeys } from '../models';


@Injectable({
    providedIn: 'root'
})
export class AuthProviderDetailController {
    constructor(
        private ufb: UfFormBuilder
    ) { }

    buildRoot(type: AuthProvider, provider?: AuthProviderDetailsModel): UfControlGroup {
        return this.ufb.group({
            [DetailsControlKeys.Id]: [provider?.id],
            [DetailsControlKeys.Tenant]: this.ufb.control(provider?.tenant, ValidatorFunctions.required('Tenant Name is required')),
            [DetailsControlKeys.ClientId]: this.ufb.control(provider?.clientId, ValidatorFunctions.required('Client Id is required')),
            [DetailsControlKeys.ClientSecret]: this.ufb.control(provider?.clientSecret),
            [DetailsControlKeys.ScimToken]: this.ufb.control(provider?.scimToken),
            [DetailsControlKeys.Type]: this.ufb.control(type),
            [DetailsControlKeys.Extras]: this.buildExtra(provider?.extras),
            [DetailsControlKeys.Manual]: provider?.manual,
            [DetailsControlKeys.IsActive]: provider?.isActive,
            [DetailsControlKeys.ProviderLoginLabel]: provider?.providerLoginLabel,
        });
    }

    toFormModel(provider?: AuthProviderDetails): AuthProviderDetailsModel | undefined {
        if (!provider) {
            return;
        }

        const { mappings, ...providerDetails } = provider;

        return providerDetails;
    }

    toDataModel(model: AuthProviderDetailsModel): AuthProviderDetails {
        return model;
    }

    private buildExtra(extras?: AuthProviderExtras): UfControlGroup {
        return this.ufb.group({
            [DetailsControlKeys.Audience]: extras?.audience,
            [DetailsControlKeys.ManualRegistration]: extras?.manualRegistration,
            [DetailsControlKeys.AuthorizationServer]: extras?.authorizationServer,
            [DetailsControlKeys.UseDirectory]: extras?.useDirectory,
        });
    }
}