import { Subscription } from 'rxjs';

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompoundType, Field, FieldType } from '@unifii/sdk';

import { BuilderEventInfo, BuilderService } from './builder.service';
import { DialogsService } from 'services/dialogs.service';


@Component({
    selector: 'uc-builder-node',
    templateUrl: './builder-node.html',
    styleUrls: ['./builder-node.less'],
})
export class BuilderNodeComponent implements AfterViewInit, OnDestroy, OnInit {

    @Input() selected: boolean;

    readonly fieldTypes = FieldType;

    valid = true;
    ready = true;
    expanded = true;
    container: boolean;
    iconName: string;

    private _node: any;
    private _childrenProperty: string;
    private subscribers: Subscription[] = [];

    constructor(
        private builderService: BuilderService,
        private cdr: ChangeDetectorRef,
        private dialogs: DialogsService
    ) { }

    ngOnInit() {
        this.subscribers.push(this.builderService.ready.subscribe(() => { this.init(); }));

        this.subscribers.push(this.builderService.fieldSelected.subscribe(i => {
            this.selected = this.isMe(i);
            this.cdr.detectChanges();
        }));

        this.subscribers.push(this.builderService.fieldRefreshed.subscribe(i => {
            if (this.isMe(i)) {
                this.valid = this.builderService.isValid(this.node);
            }
        }));

    }

    ngOnDestroy() {
        this.subscribers.forEach(s => s.unsubscribe());
    }

    @Input()
    set childrenProperty(v) {
        this._childrenProperty = v;
    }

    get childrenProperty() {
        return this._childrenProperty;
    }

    @Input()
    set node(v) {
        if (!this._node || this._node !== v) {
            this._node = v;
        }
    }

    get node() {
        return this._node;
    }

    get label() {
        if (!this._node.label && this._node.type) {
            if ((this._node as Field).type === FieldType.Content) {
                const MaxContentLength = 100;
                if (!this._node.help) {
                    return FieldType.Content as string;
                } else {
                    return `${this._node.help.substr(0, MaxContentLength)}${(this._node.help.length > MaxContentLength ? '...' : '')}`;
                }

            }
        } else {
            return this._node.label;
        }
    }

    ngAfterViewInit() {
        this.builderService.fieldReady.next({ subject: this.node, atomic: true });
    }

    init() {
        switch (this.builderService.builder.type) {
            case CompoundType.Collection:
                this.container = false;
                break;
            case CompoundType.Form:
                this.container = (this.builderService.builder as any).isContainer(this.node);
                break;
        }

        this.iconName = this.builderService.builder.getFieldRef(this.node ? this.node : undefined).icon;
        this.ready = true;
    }

    select(event: Event) {
        event.stopPropagation();
        this.builderService.fieldSelect.next({ subject: this.node });
    }

    async remove(event: Event) {
        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.builderService.fieldRemove.next({ subject: this.node, atomic: true });
    }

    private isMe(i: BuilderEventInfo = { subject: null }): boolean {
        return i.subject != null && i.subject === this.node;
    }
}
