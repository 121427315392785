

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService, UfControl } from '@unifii/library/common';
import { ComponentRegistry, FormSettings } from '@unifii/library/smart-forms';
import { CompoundType, Definition } from '@unifii/sdk';

import { BuilderField, CompoundInfo, UcCompound, UcLanguage, UcProject } from 'client';

import { SaveAndClose, SaveAndNext, SaveOption } from 'components/common/save-options/save-options.component';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentEditorRegistry } from 'components/content-editor-registry';
import { ContentSettings } from 'components/content/content';

import { LanguageViewsComponent } from 'pages/translations/language-views.component';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { LanguageViewsTableManager } from './language-views-table-manager';


@Component({
    templateUrl: './language-view.html',
    styleUrls: ['./../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        { provide: FormSettings, useValue: {} },
        { provide: ComponentRegistry, useClass: ContentEditorRegistry },
        { provide: ContentSettings, useValue: { mode: 'DataEntry' } }
    ],
})
export class LanguageViewComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.View;
    subject = BuilderCompoundSubjects.TRANSLATION;
    ready: boolean;
    definition: Definition;
    compound: UcCompound;
    translation: UcCompound;
    showUntranslated: boolean;

    // Check if this is working correctly
    protected lastEditedField: BuilderField | null;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected parent: LanguageViewsComponent,
        protected router: Router,
        protected route: ActivatedRoute,
        @Inject(TableContainerManager) protected tableManager: LanguageViewsTableManager,
        private ucProject: UcProject,
        private ucLanguage: UcLanguage,
        private toastService: ToastService,
        private context: ContextService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
    ) {
        super(builderService, modalService, route);
    }

    get id(): number {
        return +this.route.snapshot.params.id;
    }

    async ngOnInit() {
        // Fix save options
        this.saveOptions = [SaveAndClose, SaveAndNext];

        // Load View
        const data = await this.ucProject.getView(this.id);
        this.definition = data._definition as Definition;
        this.compound = data;

        // Load View Translation
        try {
            const translationRaw = await this.ucLanguage.getView(this.id);
            this.translation = this.builderService.filterDefinedFields(this.definition.fields, translationRaw);
        } catch (e) {
            this.translation = {};
        }

        // Register event handlers
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.definition, this.translation);

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
        this.buildHeaderConfig(this.compound);
    }

    addSubscribers() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));
    }

    contentChanged(data: any, field?: BuilderField, control?: UfControl) {

        const errors: string[] = [];
        const messages: { message: string }[] = [];
        const changed = field !== this.lastEditedField;
        this.lastEditedField = field || null;

        if (changed) {
            this.builderService.memento.setLastAtomic();
        }

        if (control) {

            if (control.errors && control.errors.message) {
                const { message } = control.errors;
                errors.push(message);
                messages.push({ message });
            }

            this.builderService.setErrors(this.builderService.compound, errors, 'all');
        }

        this.builderService.fieldEdit.next({ subject: field, errors: messages, atomic: false });
    }

    async save(saveOption?: SaveOption) {
        try {
            this.builderService.busy.next(true);
            this.translation = await this.ucLanguage.saveView(this.id, this.translation);
            this.toastService.success('Translation saved');
            /**
             * The deal with the missaligned interfaces between the table and language component
             * (CompoundInfo and UcCompound) the table item has to be updated with
             * Find the souce item from the table manager and merge with updated compound,
             */
            const item = this.tableManager.items.find(i => +i.id === this.id) as CompoundInfo;
            const updated = Object.assign({}, item, this.translation);
            this.saved(updated, saveOption);
            this.builderService.init(this, this.definition, this.translation);
            this.buildHeaderConfig(updated);
        } finally {
            this.builderService.busy.next(false);
        }
    }

    restore(step: number) {
        const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();
        this.translation = value;
        this.builderService.fieldSelect.next(null);
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.translation, i.atomic);
        this.builderService.memento.edited = true;

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(translation: UcCompound) {
        const currentTranslationName = [this.context.project?.name, this.context.language?.name].filter(s => s != null).join(' ');

        this.builderHeaderService.buildConfig({
            ...translation,
            title: translation._title,
            publishState: this.builderService.compound?.publishState || translation.publishState,
            saveOptions: this.saveOptions,
            cancelRoute: ['../'],
            restrictSave: 'Translator',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [currentTranslationName, translation._title])
        });
    }
}
