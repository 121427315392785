import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagService } from '@unifii/library/common';
import { ClaimConfig } from '@unifii/sdk';

import { UcUserClaims } from 'client';


export interface UsersTableData {
    claimConfig: ClaimConfig[];
    companiesEnabled: boolean;
}


@Injectable({ providedIn: 'root' })
export class UsersTableDataResolver implements Resolve<UsersTableData> {

    constructor(
        private ucUserClaims: UcUserClaims,
        private featureFlagService: FeatureFlagService
    ) { }

    async resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<UsersTableData> {
        const companiesEnabled = await this.featureFlagService.isEnabled('companies');
        let claimConfig: ClaimConfig[] = [];
        try {
            claimConfig = await this.ucUserClaims.list();
        } catch (e) {
            console.error('missing claim config');
        }
        return { companiesEnabled, claimConfig };
    }
}

