import { Component, HostBinding, Inject } from '@angular/core';
import {
    Modal, ModalData, ModalRuntime, ModalService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions
} from '@unifii/library/common';
import { Option } from '@unifii/sdk';

import { AuthProviderMappingConditionType, AuthProviderMappingModel, ConditionOptions, MappingsControlKeys } from '../models';

import { AuthProviderMappingsController } from './auth-provider-mapping.controller';


export interface AuthProviderMappingUnit extends AuthProviderMappingModel {
    sourceClaims: string[];
    authProviderId: string;
}

@Component({
    templateUrl: './auth-provider-mapping-modal.html'
})
export class AuthProviderMappingModalComponent implements Modal<AuthProviderMappingUnit, AuthProviderMappingModel> {

    @HostBinding('class.uc-form-card') cardClass = true;

    protected readonly conditionOptions = ConditionOptions;
    protected readonly controlKeys = MappingsControlKeys;
    protected readonly emptyCondition = {
        type: undefined, value: undefined, identifier: undefined, children: []
    };

    protected actions: UfControlArray;

    protected form: UfControlGroup;

    protected selected: string;
    protected selectedControl?: UfControlGroup;
    protected authProviderId: string;
    protected sourceClaims: string[];
    protected showAddCondition: boolean;
    protected control: UfControl;

    constructor(
        @Inject(ModalData) public data: AuthProviderMappingUnit,
        public runtime: ModalRuntime<AuthProviderMappingUnit, AuthProviderMappingModel>,
        private mappingsController: AuthProviderMappingsController,
        private ufb: UfFormBuilder,
        private modalService: ModalService
    ) {
        this.authProviderId = data.authProviderId;
        this.sourceClaims = data.sourceClaims;

        this.actions = this.mappingsController.buildActions(data.actions ?? []);
        this.form = this.ufb.group({
            [MappingsControlKeys.Actions]: this.actions,
        });

        if (data.condition) {
            this.form.addControl(MappingsControlKeys.Condition, this.mappingsController.buildCondition(data.condition));
        }

        this.form.addValidators(ValidatorFunctions.custom(formData => formData.condition !== undefined, 'At least one condition'));

        this.selectCondition(this.condition);
    }

    get condition() {
        return this.form.get(MappingsControlKeys.Condition) as UfControlGroup;
    }

    selectCondition(control?: UfControlGroup) {
        this.showAddCondition = this.canAddCondition(control);

        if (!control) {
            return;
        }

        this.selected = control.get(MappingsControlKeys.Id)?.value;
        this.selectedControl = control;
    }

    addCondition(option: Option) {
        const type = option.identifier as AuthProviderMappingConditionType;
        const controlToAdd = this.mappingsController.buildCondition({ type });
        if (this.condition && this.selectedControl) {
            const childrenControl = this.selectedControl.get(MappingsControlKeys.Children) as UfControlArray;
            childrenControl.push(controlToAdd);
            this.selectCondition(controlToAdd);
        } else {
            this.form.addControl(MappingsControlKeys.Condition, this.mappingsController.buildCondition({ type }));
            this.selectCondition(this.condition);
        }

    }

    deleteCondition({ position, parent }: { position?: number; parent?: UfControlGroup }) {
        if (!parent) {
            this.form.removeControl(MappingsControlKeys.Condition);
            this.showAddCondition = true;
            return;
        }

        if (position === undefined) {
            return;
        }

        const childrenControl = parent.get(MappingsControlKeys.Children) as UfControlArray;
        childrenControl.removeAt(position);
        this.selectCondition(parent);
    }

    save() {
        this.form.setSubmitted();

        console.log(this.form);

        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form.getRawValue());
    }

    private canAddCondition(control?: UfControlGroup) {
        if (!control) {
            return true;
        }
        const type = control.get(MappingsControlKeys.Type)?.value;
        return [AuthProviderMappingConditionType.And, AuthProviderMappingConditionType.Or].includes(type);
    }

}