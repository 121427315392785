import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Language } from '@unifii/sdk';

import { UcCollection, UcCollectionTranslation, UcDefinition, UcProject } from 'client';

import { ContextService } from 'services/context.service';


export interface LanguageCollectionData {
    definition: UcDefinition;
    ucCollection: UcCollection;
    ucCollectionTranslation: UcCollectionTranslation;
}


@Injectable({ providedIn: 'root' })
export class LanguageCollectionResolver implements Resolve<any | undefined> {

    constructor(
        private ucProject: UcProject,
        private context: ContextService,
    ) { }

    async resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<LanguageCollectionData> {
        const collectionId = route.params.collectionId;
        const ucCollection = this.ucProject.collection(collectionId);
        const ucCollectionTranslation = ucCollection.translation((this.context.language as Language).code);

        try {
            const definition = await ucCollection.getDefinition();
            return { definition, ucCollection, ucCollectionTranslation };
        } catch (e) {
            throw new Error(e);
        }
    }

}

