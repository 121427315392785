import { Subscription } from 'rxjs';

import { Directive, Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanDeactivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';

/* eslint-disable @typescript-eslint/member-ordering */
import { Config } from 'app-config';

import { EditMode, getEditMode } from 'components/common/edit-data';
import { SaveAndClose, SaveAndNew, SaveAndNext, SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';

import { FieldReference } from 'helpers/helpers';

import { DialogsService } from 'services/dialogs.service';
import { UcTableManager } from 'services/table/models';


// TODO: Add Angular decorator.
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BuilderBasic implements OnDestroy {

    abstract type: CompoundType;
    abstract subject: BuilderCompoundSubjects;

    // The data edit mode for this builder instance
    editMode: EditMode;
    // List of available save option for this builder instance
    saveOptions: SaveOption[] = [];

    // Track all subscriptions, to be unsubscribed on ngDestroy life-cycle
    protected subscriptions = new Subscription();
    protected router?: Router;

    // Child class methods
    removeField?(i?: BuilderEventInfo): void;
    selectField?(i?: BuilderEventInfo): void;

    constructor(
        public builderService: BuilderService,
        public modalService: ModalService,
        protected route: ActivatedRoute,
        protected tableManager?: UcTableManager<any>
    ) {
        this.init(route.snapshot.params);
    }

    init(params: Params) {
        // Detect data mode
        if (params) {
            this.editMode = getEditMode(params);
        }

        // Default save options based on the edit mode
        this.saveOptions = this.editMode === EditMode.Existing ?
            [SaveAndClose, SaveAndNext, SaveAndNew] :
            [SaveAndClose, SaveAndNew];

        // Initialize memento service
        if (this.builderService.memento) {
            this.builderService.memento.reset();
        }

        // Field remove
        if (this.removeField != null) {
            this.subscriptions.add(this.builderService.fieldRemove.subscribe(i => {
                if (this.removeField) {
                    this.removeField(i);
                }
            }));
        }

        // Field removed
        this.subscriptions.add(this.builderService.fieldRemoved.subscribe(i => {
            this.builderService.removeErrors(i.subject);
            this.builderService.fieldRefreshed.next({ subject: i.subject });
        }));

        // Field select
        if (this.selectField) {
            this.subscriptions.add(this.builderService.fieldSelect.subscribe(i => {
                if (this.selectField) {
                    this.selectField(i ?? undefined);
                }
            }));
        }
    }

    saved(item: any, saveOption?: SaveOption) {
        // Reset edited status
        this.builderService.memento.edited = false;

        // Update parent table
        if (this.editMode === EditMode.Existing) {
            // Mark this existing item as updated
            this.tableManager?.updateItem.next(item);
        } else {
            // Add this new item to the table
            this.tableManager?.reload.next();
        }

        if (this.editMode === EditMode.New || this.editMode === EditMode.Duplicate) {
            this.editMode = EditMode.Existing;
        }

        // Nothing to do on save
        if (!saveOption) {
            this.builderService.ready.next();
            return;
        }

        if (saveOption?.id === SaveOptionType.New) {
            if (!this.router) {
                return;
            }

            if (this.router.url.endsWith('/new')) {
                this.reloadCurrentRoute(this.router);
                return;
            } else {
                this.router.navigate(['..', 'new'], { relativeTo: this.route });
                return;
            }
        }

        if (saveOption?.id === SaveOptionType.Next) {
            const nextId = this.tableManager?.getNextItem(item.id)?.id;
            if (nextId) {
                this.router?.navigate(['..', nextId], { relativeTo: this.route });
                return;
            }
        }

        if (this.back != null) {
            this.back();
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getFieldRef(field?: any): FieldReference {
        return this.builderService.getFieldRef(field);
    }

    getFieldPosition(field: any, parent?: any, depth: number = 0): { index: number; parent: any; depth: number } | null {

        parent = parent || this.builderService.definition;
        if (!parent[this.builderService.childrenProperty] || parent[this.builderService.childrenProperty].length === 0) {
            return null;
        }

        const idx = (parent[this.builderService.childrenProperty] as any[]).indexOf(field);
        if (idx >= 0) {
            return { index: idx, parent, depth: (depth + 1) };
        }

        let found = null;
        (parent[this.builderService.childrenProperty] as any[]).forEach(f => {
            const res = this.getFieldPosition(field, f, (depth + 1));
            if (res) {
                found = res;
                return;
            }
        });

        return found;
    }

    back() {
        if (this.router) {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    /** Force reload of the current route */
    private reloadCurrentRoute(router: Router) {

        const shouldReuseRoute = router.routeReuseStrategy.shouldReuseRoute;
        const onSameUrlNavigation = router.onSameUrlNavigation;
        router.routeReuseStrategy.shouldReuseRoute = () => false;
        router.onSameUrlNavigation = 'reload';
        const currentUrl = router.url;

        router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigateByUrl(currentUrl)).then(() => {
            router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
            router.onSameUrlNavigation = onSameUrlNavigation;
        });
    }
}

@Injectable()
export class CanDeactivateBuilder implements CanDeactivate<BuilderBasic> {

    constructor(@Inject(Config) private config: Config, private dialogsService: DialogsService) { }

    canDeactivate(builder: BuilderBasic, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {

        if (this.config.debug || !builder.builderService.memento.edited) {
            return Promise.resolve(true);
        }

        return this.dialogsService.confirmLeaveWithoutSaving();
    }
}
