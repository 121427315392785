import { Directive, EventEmitter } from '@angular/core';

import { DialogsService } from 'services/dialogs.service';

import { BuilderService } from '../compound-builder/builder.service';

import { Content, ContentSettings } from './content';


@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ContentValue extends Content {

    contentChange: EventEmitter<any>;

    constructor(
        builderService: BuilderService,
        dialogs: DialogsService,
        contentSettings: ContentSettings
    ) {
        super(builderService, dialogs, contentSettings);
        // Field ready
        this.subscribers.push(this.builderService.fieldReady.subscribe(() => {
            this.init();
        }));
    }

    contentChanged(v: any) {
        this.builderService.fieldEdit.next({ subject: this.field, atomic: false });
        this.contentChange.emit(v);
    }

    // component custom initialization
    protected abstract init(): void;
}
