<div class="uf-box col-12 gap-top gap-sides">
    <div class="uf-app-bar flat accent">
    </div>
    <div class="uf-grid gap">
        <table class=" col-12 uf-table inputs accent clickable">
            <thead>
                <tr>
                    <th>Provider Values</th>
                    <th>Unifii Values</th>
                    <th> <uf-icon name="delete"></uf-icon></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of table" class="body-row" (click)="rowClick(row.identifier)">
                    <td>
                        <div class="uf-grid">
                            <ng-container *ngFor="let providerValue of row.providerValue">
                                <div class="col-12">
                                    <div class="grow">
                                        <strong>
                                            {{providerValue.title}}:
                                        </strong>
                                        {{providerValue.value}}
                                    </div>
                                    <!-- <uf-underline class="active"></uf-underline> -->
                                </div>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        <div class="uf-grid">
                            <ng-container *ngFor="let unifiiValue of row.unifiiValue">
                                <div class="col-12">
                                    <div class="grow">
                                        <strong>
                                            {{unifiiValue.title}}:
                                        </strong>
                                        {{unifiiValue.value}}
                                    </div>
                                    <!-- <uf-underline class="active"></uf-underline> -->
                                </div>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        <button (click)="$event.stopPropagation(); deleteMapping(row.identifier)" type="button"
                            class="uf-action tertiary" title="Delete">
                            <uf-icon name="delete"></uf-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="col-12 uf-button small right" (click)="addProviderValue()">Add</button>
    </div>