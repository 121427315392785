<uc-drag-list *ngIf="ready && parent" [parent]="parent" [childrenProperty]="childrenProperty"
    [convertAdded]="convertFieldRef" [canDrop]="canDrop" (insert)="inserted($event.item)" (moved)="moved($event.item)">

    <ul *ngIf="parent" class="uc-list">
        <ng-container *ngFor="let child of parent[childrenProperty]">
            <uc-builder-node [node]="child" [childrenProperty]="childrenProperty" [nestable]="nestable(child)"
                [class.container]="nestable(child)" dragItem>
            </uc-builder-node>
        </ng-container>
    </ul>

    <div *ngIf="!parent[childrenProperty].length" [class.root-empty]="root" class="empty">
        <img *ngIf="root" src="/assets/svg/icon-projects.svg" />
        <span>Drag your items here</span>
        <ng-template ngFor [ngForOf]="errors" let-error>
            <div [ufErrorMessage]="error"></div>
        </ng-template>
    </div>
</uc-drag-list>