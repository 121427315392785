<form *ngIf="show" class="wrap">
    <uc-drag-list [items]="elements" canDrop="false" canReorder="true" (moved)="moved($event.item)" class="uc-list">
        <uf-expander *ngFor="let e of elements, let i = index"
            class="col-1of1--no-margin--no-padding uc-list-item--small--draggable--no-shrink"
            [isExpanded]="expanded['' + i]" dragItem>
            <div class="uf-app-bar flat" expanderHeader dragHandle>
                <div class="title primary">
                    <span *ngIf="e.type">{{e.type}}</span>
                </div>
                <uf-icon *ngIf="$any(form.controls.list).at(i).invalid" name="warning" class="icon-warning">
                </uf-icon>
                <button class="uf-action tertiary" (click)="remove(i)" title="Delete">
                    <uf-icon name="delete"></uf-icon>
                </button>
            </div>
            <div expanderBody class="uc-group-content grid">
                <uf-select class="col-1of1" label="Type" *ngIf="configs[i].type.show" [(value)]="e.type"
                    [control]="$any(form.controls.list).at(i).controls.type" (valueChange)="changeType($event, i)"
                    placeholder="Select type" [options]="validatorOptions" nameProperty="label" valueProperty="type">
                </uf-select>

                <uf-text class="col-1of1" label="Expression" *ngIf="configs[i].expression.show" [(value)]="e.value"
                    [control]="$any(form.controls.list).at(i).controls.expression"
                    [placeholder]="validatorDictionary[e.type].placeholders?.value"></uf-text>

                <uf-text class="col-1of1" label="Item Expression" *ngIf="configs[i].itemExpression.show"
                    [(value)]="e.value" [control]="$any(form.controls.list).at(i).controls.itemExpression"
                    [placeholder]="validatorDictionary[e.type].placeholders?.value"></uf-text>

                <uf-text class="col-1of1" label="Pattern" *ngIf="configs[i].pattern.show" [(value)]="e.value"
                    [control]="$any(form.controls.list).at(i).controls.pattern"
                    [placeholder]="validatorDictionary[e.type].placeholders?.value">
                </uf-text>

                <uf-number class="col-1of1" label="Min Length" *ngIf="configs[i].minLength.show" [(value)]="e.value"
                    [control]="$any(form.controls.list).at(i).controls.minLength"
                    [placeholder]="validatorDictionary[e.type].placeholders?.value"></uf-number>

                <uf-number class="col-1of1" label="Min" *ngIf="configs[i].min.show" [(value)]="e.value"
                    [control]="$any(form.controls.list).at(i).controls.min"
                    [placeholder]="validatorDictionary[e.type].placeholders?.value">
                </uf-number>

                <uf-number class="col-1of1" label="Max" *ngIf="configs[i].max.show" [(value)]="e.value"
                    [control]="$any(form.controls.list).at(i).controls.max"
                    [placeholder]="validatorDictionary[e.type].placeholders?.value">
                </uf-number>

                <uf-text class="col-1of1" label="Message" *ngIf="configs[i].message.show" [(value)]="e.message"
                    [control]="$any(form.controls.list).at(i).controls.message"
                    [placeholder]="validatorDictionary[e.type].placeholders?.message"></uf-text>
            </div>
        </uf-expander>
    </uc-drag-list>
</form>
<uf-error [control]="form.controls.list | asUfControl" style="display: block; padding-left: 8px;"></uf-error>