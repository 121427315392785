import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FeatureFlagProvider } from '@unifii/library/common';
import { FeatureFlagKey } from '@unifii/sdk';


@Injectable()
export class FeatureFlagGuard implements CanActivate {

    constructor(
        private router: Router,
        @Inject(FeatureFlagProvider) private provider: FeatureFlagProvider
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        const flags = await this.provider.get();
        const keys = route.data.flags as FeatureFlagKey[];

        const matches = keys.filter(k => flags[k]);

        if (matches.length < keys.length) {
            this.router.navigate([route.data.redirect || '/']);
            return false;
        }

        return true;
    }
}