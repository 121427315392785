import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { TenantSettingsService } from 'services/tenant-settings.service';


@Injectable()
export class TenantGuard implements CanActivate {

    constructor(
        private tenantSettings: TenantSettingsService,
        private router: Router
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        // successfully synced tenant settings
        try {
            await this.tenantSettings.sync();
            return true;
        } catch (err) {
            // Not enough tenant info provided
            this.router.navigate(['./page-not-found/server']);
            return false;
        }
    }
}
