import { TableDataSource } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { Permission, UcPermissions } from 'client';


export class PermissionsDataSource extends TableDataSource<Permission> {

    filtered: boolean;
    sorted: boolean;

    constructor(private ucPermissions: UcPermissions) {
        super();
    }

    async load() {
        try {
            const result = await this.ucPermissions.get({
                analytics: { origin: RequestAnalyticsOrigin.Table }
            });
            this.stream.next({ data: result });
        } catch (error) {
            this.stream.next({ error });
        }
    }
}
