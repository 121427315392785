import { Injectable, Type } from '@angular/core';
import { ComponentRegistry, ComponentRegistryType } from '@unifii/library/smart-forms';
import * as input from '@unifii/library/smart-forms/input';

import * as content from './content';


@Injectable()
export class ContentComponentRegistry implements ComponentRegistry {

    type = ComponentRegistryType.Input;

    private fieldMap = {
        // Library contents
        Separator: input.SeparatorComponent,
        Group: input.GroupComponent,
        Section: input.SectionComponent,
        // Project contents
        Text: content.GroupInputComponent,
        MultiText: content.GroupInputComponent,
        // eslint-disable-next-line id-blacklist
        Number: content.GroupInputComponent,
        Date: content.GroupInputComponent,
        Time: content.GroupInputComponent,
        DateTime: content.GroupInputComponent,
        Phone: content.GroupInputComponent,
        Email: content.GroupInputComponent,
        Website: content.GroupInputComponent,
        Cost: content.GroupInputComponent,
        Bool: content.GroupInputComponent,
        Choice: content.GroupInputComponent,
        MultiChoice: content.GroupInputComponent,
        Signature: content.GroupInputComponent,
        Address: content.GroupInputComponent,
        GeoLocation: content.GroupInputComponent,
        ImageList: content.ImageListComponent,
        SoundList: content.SoundListComponent,
        VideoList: content.VideoListComponent,
        FileList: content.FileListComponent,
        LinkList: content.LinkListComponent,
        Link: content.LinkComponent,
        DefinitionLink: content.LinkComponent
    };

    get(name: string): Type<any> {
        return (this.fieldMap as any)[name];
    }
}
