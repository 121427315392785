import { TableDataSource } from '@unifii/library/common';

import { AuthProviderDetails, UcAuthProviders } from 'client';


export class AuthProviderDatasource extends TableDataSource<AuthProviderDetails> {

    filtered: boolean;
    sorted: boolean;

    constructor(
        private ucAuthProviders: UcAuthProviders,
    ) {
        super();
    }

    load() {
        this.ucAuthProviders.list().then(items => {
            this.stream.next({ data: items });
        }, error => {
            this.stream.next({ error });
        });
    }
}
