<div *ngIf="actions" class="uf-grid col-12 pad-sm-sides">
    <div *ngFor="let action of actions.controls | asUfControlsGroups; let i = index" class="uf-grid col-12 gap-bottom">

        <div *ngIf="action" [formGroup]="action" class="uf-box flat col-12">
            <div class="uf-app-bar gap-sm-bottom">

                <div class="title">
                    {{mapActionTypeDescription(action.get(controlKeys.Type)?.value)}}
                </div>

                <button (click)="deleteAction(i)" title="Delete" class="uf-action">
                    <uf-icon name="delete"></uf-icon>
                </button>

            </div>

            <div class="pad-sm-sides pad-sm-bottom">

                <div class="uf-grid col-12 pad-sm-bottom">
                    <ng-container [ngSwitch]="action.get(controlKeys.Type)?.value">

                        <!-- ClaimValue -->
                        <ng-container *ngSwitchCase="types.AssignClaim">
                            <uf-autocomplete label="Claim" [formControlName]="controlKeys.Identifier"
                                (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims"
                                placeholder="Select a source" [allowCustom]="true" class="col-6 gap-botton">
                            </uf-autocomplete>
                            <uf-text label="Claim Value" [formControlName]="controlKeys.Value" class="col-6">
                            </uf-text>
                        </ng-container>

                        <!-- ClaimValue -->
                        <ng-container *ngSwitchCase="types.AssignClaimFrom">
                            <uf-autocomplete label="Claim" [formControlName]="controlKeys.Identifier"
                                (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims"
                                placeholder="Select a source" [allowCustom]="true" class="col-6 gap-botton">
                            </uf-autocomplete>
                            <uf-text label="Claim Value" [formControlName]="controlKeys.Value" class="col-6">
                            </uf-text>
                        </ng-container>

                        <!-- AssignUnit -->
                        <uf-hierarchy-unit *ngSwitchCase="types.AssignUnit" class="col-12" label="Hierarchy Unit"
                            [formControlName]="controlKeys.Unit" [selectLeafsOnly]="false" placeholder="Root">
                        </uf-hierarchy-unit>

                        <!-- System Role -->
                        <uf-autocomplete *ngSwitchCase="types.AssignSystemRole" placeholder="Select a System Role"
                            label="System Role" [formControlName]="controlKeys.Identifier"
                            (searchChange)="findSystemRoles($event)" [options]="filteredSystemRoles" class="col-12">
                        </uf-autocomplete>

                        <!-- System Role -->
                        <uf-autocomplete *ngSwitchCase="types.AssignRole" placeholder="Select a Role" label="Role"
                            [formControlName]="controlKeys.Identifier" (searchChange)="findRoles($event)"
                            [options]="filteredRoles" class="col-12">
                        </uf-autocomplete>

                    </ng-container>
                </div>
            </div>
        </div>

    </div>

    <div class="col-12 row right">
        <button [options]="actionOptions" (optionSelected)="addAction($event)" type="button"
            class="uf-button x-small right gap-bottom gap-right gap-top" listBox nameProperty="name">
            Add
            <uf-icon name="more" class="gap-none"></uf-icon>
        </button>
    </div>
</div>