import { Component, Input, OnInit } from '@angular/core';
import { DescriptionListItem, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { StructureNodeBucketOptions, TableSourceType } from '@unifii/sdk';

import { TableInfo, UcProject } from 'client';

import { DialogsService } from 'services/dialogs.service';

import { StructureNodeBucketOptionsControlKeys } from '../structure-control-keys';
import { StructureFormCtrl } from '../structure-form-ctrl';


@Component({
    selector: 'uc-node-dashboard-tables',
    templateUrl: './node-dashboard-tables.html',
})
export class NodeDashboardTablesComponent implements OnInit {

    @Input() control: UfControlArray;

    readonly formKeys = StructureNodeBucketOptionsControlKeys;

    allTables: TableInfo[];
    searchResults: TableInfo[] = [];
    ready: boolean;

    constructor(
        private project: UcProject,
        private sfb: StructureFormCtrl,
        private dialogs: DialogsService
    ) { }

    get bucketOptions(): StructureNodeBucketOptions[] {
        return this.control.getRawValue();
    }

    async ngOnInit() {
        this.allTables = await this.project.getTables({ params: { sort: 'title', source: TableSourceType.Bucket } });
        this.ready = true;
    }

    search(query?: string) {

        // Exclude already listed Table and search by query
        let availableTables = this.allTables
            .filter(t => this.bucketOptions.find(bo => bo.identifier === t.identifier) == null);

        if (query && query.trim().length > 0) {
            const q = query.toLowerCase();
            availableTables = availableTables.filter(t =>
                t.title.toLowerCase().indexOf(q) >= 0 || t.identifier.toLowerCase().indexOf(q) >= 0
            );
        }

        this.searchResults = availableTables;
    }

    add(table?: TableInfo) {

        if (!table) {
            return;
        }

        this.control.push(this.sfb.buildBucketOptionControl({
            identifier: table.identifier,
            pageSize: 5
        }));
    }

    async remove(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.control.removeAt(index);
    }

    getLabel(control: UfControlGroup): string | undefined {
        return this.findTableDefinition(control)?.title;
    }

    getTableLink(control: UfControlGroup): any[] | undefined {
        const tableInfo = this.findTableDefinition(control);

        if (!tableInfo) {
            return;
        }

        return ['..', 'tables', tableInfo.id];
    }

    getTableDescription(control: UfControlGroup): DescriptionListItem[] | undefined {

        const tableInfo = this.findTableDefinition(control);

        if (tableInfo) {
            return [
                { term: 'Identifier', description: tableInfo.identifier },
            ];
        }

        return;
    }

    getTableIdentifier(control: UfControlGroup): string | undefined {
        return control.get(StructureNodeBucketOptionsControlKeys.Identifier)?.value;
    }

    findTableDefinition(control: UfControlGroup): TableInfo | undefined {
        const identifier = this.getTableIdentifier(control);
        return identifier ? this.allTables.find(t => t.identifier === identifier) : undefined;
    }

    protected mapDescription(table: TableInfo): string {
        return `${table.title} (${table.identifier})`;
    }

}
