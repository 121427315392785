import jsep, {
    ArrayExpression, BinaryExpression, CallExpression, ConditionalExpression, Expression, Identifier, Literal, MemberExpression, UnaryExpression
} from 'jsep';

import { Params } from '@angular/router';
import {
    DataPropertyDescriptor, DataPropertyInfoService, DataSourceFunctions, ExpressionTypes, FieldFunctions, fieldIterator,
    FormDefinitionMetadataIdentifiers, getDisplay, UfControlArray, UfControlGroup
} from '@unifii/library/common';
import {
    DataCapture, DataSource, Dictionary, FieldOption, FieldTemplate, FieldType, FieldValidator, FieldWidth, generateUUID, HierarchyConfiguration,
    HierarchyUnitFormData, HierarchyUnitSelectionMode, HorizontalTableTemplate, LayoutDirection, LinkContentType, Option, OutputField, Schema,
    SchemaField, SchemaTransition, Transition, ValidatorType, Variation
} from '@unifii/sdk';

import { DefinitionInfo, UcDefinition, UcField } from 'client';
import { BOOL_OPTIONS } from 'constant';

import { EditMode } from 'components/common/edit-data';

import { IdentifierFunctions } from 'helpers/helpers';

import { ContextService } from 'services/context.service';

import { FormEditorCache } from './form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import {
    AddressNestedControlKey, DefinitionControlKeys, FieldControlKeys, GeoLocationNestedControlKey, OptionControlKeys, TransitionControlKeys,
    TransitionTargetControlKeys, ValidatorControlKeys, VariationControlKeys
} from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import {
    FormAddressNestedFields, FormEditorDefinition, FormEditorField, FormEditorFieldMapping, FormEditorHierarchyConfiguration, FormEditorOption,
    FormEditorTemplateConfig, FormEditorTransition, FormEditorTransitionTarget, FormEditorVariation, FormFieldDetailSections, FormFieldMetadata,
    FormFieldScopedInfo, FormGeoLocationNestedFields, FormNestedField, IdentifiersMaxLength
} from './form-editor-model';


const detectEditMode = (params: Params): EditMode => {

    if (params.id && params.duplicate) {
        return EditMode.Duplicate;
    }

    if (!!params.id && params.id !== 'new') {
        return EditMode.Existing;
    }

    return EditMode.New;
};

const getFieldCleanConfiguration = (field: FormEditorField): FormEditorField => {

    const cleanFieldConfiguration = (f: FormEditorField) => {
        delete f.id;
        for (const option of f.options ?? []) {
            delete option.id;
        }

        if (f.fields) {
            for (const child of f.fields) {
                cleanFieldConfiguration(child);
            }
        }

    };

    const copy = JSON.parse(JSON.stringify(field)) as FormEditorField;
    cleanFieldConfiguration(copy);
    return copy;
};

const getFieldWithDefaults = (meta: FormFieldMetadata, label?: string): UcField => {

    let computedLabel: string | undefined;
    if (meta.label) {
        computedLabel = label ?? IdentifierFunctions.generateDisplayLabel(meta.type);
    }

    const field: UcField = { type: meta.type, label: computedLabel };

    if (field.type === FieldType.Email) {
        field.validators = [FormEditorFunctions.emptyValidatorByType(ValidatorType.Email)];
    }

    if (field.type === FieldType.Bool) {
        field.options = BOOL_OPTIONS.map(o => Object.assign({}, o));
        field.template = FieldTemplate.Radio;
    }

    if (field.type === FieldType.Date) {
        field.format = FORM_EDITOR_CONSTANTS.DATE_FORMATS[0];
    }

    if ([FieldType.Time, FieldType.DateTime, FieldType.ZonedDateTime].includes(field.type)) {
        field.step = 5 * 60;

        if (field.type === FieldType.Time) {
            field.format = FORM_EDITOR_CONSTANTS.TIME_FORMATS[0];
        } else {
            field.format = `${FORM_EDITOR_CONSTANTS.DATE_FORMATS[0]} ${FORM_EDITOR_CONSTANTS.TIME_FORMATS[0]}`;
        }
    }

    if (field.type === FieldType.Cost) {
        field.currency = FORM_EDITOR_CONSTANTS.CURRENCIES[0].identifier;
    }

    if (field.type === FieldType.Content) {
        field.template = '' as FieldTemplate;
    }

    if (field.type === FieldType.Separator) {
        field.template = '' as FieldTemplate;
    }

    if (field.type === FieldType.Repeat) {
        field.template = FieldTemplate.Form;
    }

    if (field.type === FieldType.Choice) {
        field.template = FieldTemplate.DropDown;
        field.placeholder = 'Please choose';
    }

    if (field.type === FieldType.MultiChoice) {
        field.template = FieldTemplate.Checkbox;
    }

    if (field.type === FieldType.Address) {
        field.visibleFields = Object.values(AddressNestedControlKey);
    }

    if (field.type === FieldType.GeoLocation) {
        field.visibleFields = Object.values(GeoLocationNestedControlKey);
    }

    if (field.type === FieldType.Hierarchy) {
        field.hierarchyConfig = { selectionMode: HierarchyUnitSelectionMode.Leaf };
    }

    if (field.type === FieldType.Link) {
        field.visibleFields = Object.values(LinkContentType);
    }

    if (meta.columnCount) {
        field.columnCount = 1;
    }

    if (meta.layoutDirection) {
        field.layoutDirection = LayoutDirection.Row;
    }

    if (meta.width) {
        field.width = FieldWidth.Stretch;
    }

    return field;
};

/**
 * Calculate the sortable entries of a Repeat field
 * The entries are of type Number | Date | DateTime | ZonedDateTime and sourced from
 *    Mapped entries of the Repeat DataSource
 *    Children field of the repeat, stopping at inner Repeats due to scope change
 *    The children can be fields of allowed types or fields with a DS with allowed mapped type
 */
const calculateRepeatFieldSortableDescriptors = (repeatField: FormEditorField): DataPropertyDescriptor[] => {

    const properties: DataPropertyDescriptor[] = [];

    if (repeatField.type !== FieldType.Repeat) {
        return properties;
    }

    // Property from the Repeat DS
    properties.push(...getDataSourceFilterableMappingsAsDataPropertyDescriptors({ dsFieldLabel: repeatField.label, ds: repeatField.dataSourceConfig }));

    const fieldsIterator = fieldIterator([repeatField], undefined, {
        canDive: field => field.type !== FieldType.Repeat || field.uuid === repeatField.uuid,
        canIterate: field =>
            field.uuid !== repeatField.uuid && (
                FORM_EDITOR_CONSTANTS.REPEAT_SORTABLE_FIELD_TYPES.includes(field.type) ||
                field.dataSourceConfig != null
            )
    });

    for (const { field } of fieldsIterator) {
        if (field.dataSourceConfig) {
            properties.push(...getDataSourceFilterableMappingsAsDataPropertyDescriptors({
                scopeIdentifier: field.identifier,
                ds: field.dataSourceConfig
            }));
            continue;
        }

        // Property from the Repeat sortable fields
        properties.push(mapFieldSortableInfoToDataDescriptorProperty({
            identifier: field.identifier as string,
            type: field.type,
            label: field.label as string,
            shortLabel: field.shortLabel
        }));
    }

    return properties;
};

const controlMetadata = (control: UfControlGroup, context: ContextService): FormFieldMetadata => {

    const field = control.getRawValue() as FormEditorField;
    const parent = control.parent?.parent as UfControlGroup;
    const parentType = (parent.get(FieldControlKeys.Type)?.value ?? undefined) as FieldType | undefined;

    return fieldMetadata(field.uuid, field.scopeUuid, field.type, context, parentType);
};

const fieldMetadata = (uuid: string, scopeUuid: string, type: FieldType, context: ContextService, parentType?: FieldType): FormFieldMetadata => {

    const meta: FormFieldMetadata = {
        uuid,
        scopeUuid,
        type,
        addButtonLabel: [FieldType.Repeat].indexOf(type) >= 0,
        autoDetect: [FieldType.GeoLocation, FieldType.Address].indexOf(type) >= 0,
        autofill: [FieldType.Text, FieldType.MultiText, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.ZonedDateTime, FieldType.Number, FieldType.Choice, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.ImageList, FieldType.FileList, FieldType.Bool, FieldType.Lookup, FieldType.Hierarchy, FieldType.Repeat].indexOf(type) >= 0,
        breakAfter: [FieldType.Bool, FieldType.Text, FieldType.MultiText, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Number, FieldType.Choice, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.Lookup].indexOf(type) >= 0,
        bindTo: [FieldType.Text, FieldType.MultiText, FieldType.Number, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Choice, FieldType.Lookup].indexOf(type) >= 0,
        columnCount: [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool].indexOf(type) >= 0,
        columnVisibility: [FieldType.Repeat].includes(type),
        hierarchy: type === FieldType.Hierarchy,
        sort: type === FieldType.Repeat,
        templateConfig: [FieldType.Repeat].includes(type),
        currency: [FieldType.Cost].indexOf(type) >= 0,
        customFields: [FieldType.Section, FieldType.Group, FieldType.Repeat, FieldType.ActionGroup, FieldType.Survey].indexOf(type) < 0,
        description: true,
        dataSourceConfig: [FieldType.Repeat, FieldType.Choice, FieldType.Lookup].indexOf(type) >= 0,
        avoidDuplicates: [FieldType.Repeat].includes(type),
        format: [FieldType.Date, FieldType.DateTime, FieldType.Time, FieldType.ZonedDateTime].indexOf(type) >= 0,
        addressAutocomplete: type === FieldType.Address,
        addressNested: type === FieldType.Address,
        geoLocationNested: type === FieldType.GeoLocation,
        help: [FieldType.Separator].indexOf(type) < 0,
        identifier: [FieldType.Section, FieldType.Group, FieldType.ActionGroup, FieldType.Content, FieldType.Separator, FieldType.Survey].indexOf(type) < 0,
        isContainer: [FieldType.Section, FieldType.Group, FieldType.Repeat, FieldType.ActionGroup, FieldType.Survey].indexOf(type) >= 0,
        isReportable: (context?.tenantSettings?.features?.reporting ?? false) && [FieldType.ImageList, FieldType.GeoLocation, FieldType.Text, FieldType.Cost, FieldType.Address, FieldType.MultiText, FieldType.Choice, FieldType.MultiChoice, FieldType.Date, FieldType.Time, FieldType.Website, FieldType.Phone, FieldType.Email, FieldType.Bool, FieldType.Number, FieldType.Signature, FieldType.FileList, FieldType.Repeat, FieldType.DateTime, FieldType.Lookup, FieldType.ZonedDateTime, FieldType.Hierarchy].indexOf(type) >= 0,
        isSearchable: [FieldType.Text, FieldType.MultiText, FieldType.Phone, FieldType.Email, FieldType.Website].indexOf(type) >= 0,
        isTranslatable: [FieldType.Text, FieldType.MultiText, FieldType.Number, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.ImageList, FieldType.VideoList, FieldType.SoundList, FieldType.FileList].indexOf(type) >= 0,
        dataCaptures: [FieldType.Text, FieldType.MultiText, FieldType.Lookup, FieldType.Repeat].indexOf(type) >= 0,
        itemLabel: [FieldType.Repeat].indexOf(type) >= 0,
        label: [FieldType.Separator, FieldType.Content].indexOf(type) < 0,
        layoutDirection: [FieldType.Choice, FieldType.MultiChoice].indexOf(type) >= 0,
        maxLength: [FieldType.Repeat, FieldType.Text, FieldType.MultiText, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.ImageList, FieldType.FileList, FieldType.VideoList, FieldType.SoundList].indexOf(type) >= 0,
        options: [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool, FieldType.Survey].indexOf(type) >= 0,
        placeholder: [FieldType.Text, FieldType.MultiText, FieldType.Number, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.Lookup, FieldType.Bool, FieldType.Choice, FieldType.MultiChoice, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.ZonedDateTime, FieldType.Link].indexOf(type) >= 0,
        precision: [FieldType.Number].indexOf(type) >= 0,
        isReadOnly: [FieldType.Section, FieldType.Group, FieldType.Separator, FieldType.Content, FieldType.ActionGroup, FieldType.Address, FieldType.GeoLocation, FieldType.Link].indexOf(type) < 0,
        isRequired: [FieldType.Section, FieldType.Group, FieldType.Content, FieldType.ActionGroup, FieldType.Separator, FieldType.Address, FieldType.GeoLocation, FieldType.Survey].indexOf(type) < 0,
        role: [FieldType.Section].indexOf(type) >= 0,
        shortLabel: [FieldType.Section, FieldType.Group, FieldType.ActionGroup, FieldType.Repeat, FieldType.Separator, FieldType.Content, FieldType.Survey].indexOf(type) < 0,
        showIf: [FieldType.ActionGroup, FieldType.Section].indexOf(type) < 0,
        showOn: [FieldType.ActionGroup].indexOf(type) >= 0,
        step: [FieldType.Time, FieldType.DateTime, FieldType.ZonedDateTime].indexOf(type) >= 0,
        tags: true,
        template: [FieldType.Choice, FieldType.MultiChoice, FieldType.Repeat, FieldType.Bool, FieldType.Content, FieldType.Separator].indexOf(type) >= 0,
        transitions: [FieldType.Section].indexOf(type) >= 0,
        validators: [FieldType.Section, FieldType.Group, FieldType.ActionGroup, FieldType.Separator, FieldType.Content, FieldType.Signature, FieldType.Address, FieldType.GeoLocation, FieldType.Survey, FieldType.Hierarchy].indexOf(type) < 0,
        variations: [FieldType.Choice, FieldType.MultiChoice, FieldType.Bool].indexOf(type) >= 0,
        visibleTo: [FieldType.Section].indexOf(type) < 0,
        allowedTypes: [FieldType.Link].indexOf(type) >= 0,
        width: [FieldType.Bool, FieldType.Text, FieldType.MultiText, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Number, FieldType.Choice, FieldType.MultiChoice, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Cost, FieldType.Lookup].indexOf(type) >= 0
    };

    if (parentType === FieldType.Survey) {
        meta.placeholder = false;
        meta.options = false;
        meta.dataSourceConfig = false;
        meta.width = false;
        meta.breakAfter = false;
        meta.template = false;
        meta.columnCount = false;
        meta.layoutDirection = false;
        meta.variations = false;
        meta.customFields = false;
    }

    return meta;
};

const fieldVisibleSections = (meta: FormFieldMetadata): FormFieldDetailSections =>
({
    details: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_DETAILS_FIELDS),
    settings: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_SETTINGS_FIELDS),
    options: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_OPTIONS_FIELDS),
    nestedFields: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_NESTEDFIELDS_FIELDS),
    display: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_DISPLAY_FIELDS),
    variations: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_VARIATIONS_FIELDS),
    validators: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_VALIDATORS_FIELDS),
    transitions: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_WORKFLOW_FIELDS),
    advanced: isSectionVisible(meta, FORM_EDITOR_CONSTANTS.SECTION_ADVANCED_FIELDS)
});

const fieldTemplateOptions = (field: FormEditorField): Option[] => {

    switch (field.type) {
        case FieldType.Choice:
            return [
                { identifier: FieldTemplate.DropDown, name: 'Drop down' },
                { identifier: FieldTemplate.Radio, name: 'Radio buttons' },
                { identifier: FieldTemplate.RadioWithContent, name: 'Advanced (with Radio Buttons)' },
                { identifier: FieldTemplate.OptionWithContent, name: 'Advanced (without Radio buttons)' }
            ];
        case FieldType.MultiChoice:
            return [
                { identifier: FieldTemplate.Checkbox, name: 'Checkboxes' },
                { identifier: FieldTemplate.CheckboxWithContent, name: 'Advanced (with Checkboxes)' },
                { identifier: FieldTemplate.OptionWithContent, name: 'Advanced (without Checkboxes)' },
                { identifier: FieldTemplate.Chips, name: 'Look up' }
            ];
        case FieldType.Repeat:
            return [
                { identifier: FieldTemplate.Form, name: 'Form' },
                { identifier: FieldTemplate.HorizontalTable, name: 'Horizontal Table (Switch to Form on mobile)' },
                { identifier: FieldTemplate.HorizontalTableMobile, name: 'Horizontal Table' },
                { identifier: FieldTemplate.VerticalTable, name: 'Vertical Table (Switch to Form on mobile)' },
                { identifier: FieldTemplate.VerticalTableMobile, name: 'Vertical Table' }
            ];
        case FieldType.ImageList:
            return [
                { identifier: FieldTemplate.Left, name: 'Left' },
                { identifier: FieldTemplate.Right, name: 'Right' },
                { identifier: FieldTemplate.Stretch, name: 'Stretch' },
                { identifier: FieldTemplate.Banner, name: 'Banner' }
            ];
        case FieldType.LinkList:
            return [
                { identifier: FieldTemplate.List, name: 'List' },
                { identifier: FieldTemplate.Table, name: 'Table' }
            ];
        case FieldType.Bool:
            return [
                { identifier: FieldTemplate.Checkbox, name: 'Check box' },
                { identifier: FieldTemplate.DropDown, name: 'Drop down' },
                { identifier: FieldTemplate.Radio, name: 'Radio buttons' },
                { identifier: FieldTemplate.BoolTickCross, name: 'Tick/Cross' },
                { identifier: FieldTemplate.Buttons, name: 'Buttons' },
            ];
        case FieldType.Content:
            return [
                { identifier: '', name: 'Content' },
                { identifier: FieldTemplate.Callout, name: 'Callout' },
                { identifier: FieldTemplate.Alert, name: 'Alert' },
                { identifier: FieldTemplate.Info, name: 'Info' },
                { identifier: FieldTemplate.Warning, name: 'Warning' }
            ];
        case FieldType.Separator:
            return [
                { identifier: '', name: 'Solid Line' },
                { identifier: FieldTemplate.DashedDivider, name: 'Dashed Line' },
                { identifier: FieldTemplate.DottedDivider, name: 'Dotted Line' },
                { identifier: FieldTemplate.DoubleDivider, name: 'Double Line' },
                { identifier: FieldTemplate.Space, name: 'Space' },
            ];
    }

    return [];
};

const emptyValidatorByType = (type: ValidatorType): FieldValidator => {

    switch (type) {
        case ValidatorType.Pattern:
            return { type, message: 'Incorrect format' };
        case ValidatorType.MinLength:
            return { type, value: 0, message: 'Minimum length' };
        case ValidatorType.Min:
            return { type, value: 0, message: 'Minimum value' };
        case ValidatorType.Max:
            return { type, value: 0, message: 'Maximum value' };
        case ValidatorType.Expression:
            return { type, message: '' };
        case ValidatorType.ItemExpression:
            return { type, message: '' };
        case ValidatorType.LettersOnly:
            return { type, message: 'Value needs to be letters only' };
        case ValidatorType.Alphanumeric:
            return { type, message: 'Value needs to be letters and numbers' };
        case ValidatorType.BeforeNow:
            return { type, message: 'Value must be before the specified date' };
        case ValidatorType.AfterNow:
            return { type, message: 'Value must be after the specified date' };
        case ValidatorType.Email:
            return { type, message: 'Value must be an email' };
        case ValidatorType.Website:
            return { type, message: 'Value must be a website' };
        default:
            console.warn('FormEditorFunctions.validatorDefaultByType - Unsupported type', type);
            return null as any;
    }
};


/** Legacy form editor allowed for longer field identifier
 * if that's the case then the identifier lenght must be supported
 * otherwise the current limit must be enforced
 */
const detectIdentifiersMaxLength = (definition?: FormEditorDefinition): IdentifiersMaxLength => {

    const result: IdentifiersMaxLength = {
        definition: FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH,
        bucket: FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH,
        field: FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH,
        option: FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH
    };

    if (!definition) {
        return result;
    }

    if ((definition.identifier ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH) {
        result.definition = (definition.identifier ?? '').length;
    }

    if (definition.bucket.length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH) {
        result.bucket = definition.bucket.length;
    }

    for (const { field } of fieldIterable(definition.fields ?? [])) {
        if (field.identifier && field.identifier.length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH) {
            result.field = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH_LEGACY_LIMIT;
        }

        if (field.options?.find(o => o.identifier.length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH) != null) {
            result.option = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH_LEGACY_LIMIT;
        }

        for (const variation of (field.variations ?? [])) {
            if (variation.options?.find(o => o.identifier.length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH) != null) {
                result.option = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH_LEGACY_LIMIT;
            }
        }
    }

    return result;
};

const fieldIterable = function* <T extends { fields?: T[] }>(fields: T[], parent?: T): Iterable<{ field: T; parent?: T }> {

    for (const field of fields) {

        if (field.fields) {
            yield* fieldIterable(field.fields, field);
        }
        yield { field, parent };
    }
};

const mapFieldToFormEditorField = async (field: UcField, scopeUuid: string, cache: FormEditorCache, bucket: string, dataPropertyInfoService: DataPropertyInfoService): Promise<FormEditorField> => {

    const roles = field.role ? field.role.split(',') : [];
    const visibleTo = field.visibleTo ? field.visibleTo.split(',') : [];

    const visible = field.visibleFields ?? [];
    const required = field.requiredFields ?? [];
    const readOnly = field.readOnlyFields ?? [];

    let address: FormAddressNestedFields | null = null;
    let geoLocation: FormGeoLocationNestedFields | null = null;

    if (field.type === FieldType.Address) {

        address = {
            address1: {
                visible: visible.includes(AddressNestedControlKey.Address1),
                readOnly: readOnly.includes(AddressNestedControlKey.Address1),
                required: required.includes(AddressNestedControlKey.Address1)
            },
            address2: {
                visible: visible.includes(AddressNestedControlKey.Address2),
                readOnly: readOnly.includes(AddressNestedControlKey.Address2),
                required: required.includes(AddressNestedControlKey.Address2)
            },
            suburb: {
                visible: visible.includes(AddressNestedControlKey.Suburb),
                readOnly: readOnly.includes(AddressNestedControlKey.Suburb),
                required: required.includes(AddressNestedControlKey.Suburb)
            },
            state: {
                visible: visible.includes(AddressNestedControlKey.State),
                readOnly: readOnly.includes(AddressNestedControlKey.State),
                required: required.includes(AddressNestedControlKey.State)
            },
            postcode: {
                visible: visible.includes(AddressNestedControlKey.Postcode),
                readOnly: readOnly.includes(AddressNestedControlKey.Postcode),
                required: required.includes(AddressNestedControlKey.Postcode)
            },
            country: {
                visible: visible.includes(AddressNestedControlKey.Country),
                readOnly: readOnly.includes(AddressNestedControlKey.Country),
                required: required.includes(AddressNestedControlKey.Country)
            },
            map: {
                visible: visible.includes(AddressNestedControlKey.Map),
                readOnly: readOnly.includes(AddressNestedControlKey.Map),
                required: required.includes(AddressNestedControlKey.Map)
            }
        };
    }

    if (field.type === FieldType.Content || field.type === FieldType.Separator) {
        if (!field.template) {
            field.template = '' as FieldTemplate;
        }
    }

    if (field.type === FieldType.GeoLocation) {

        geoLocation = {
            latlng: {
                visible: visible.includes(GeoLocationNestedControlKey.LatitudeLongitude),
                readOnly: readOnly.includes(GeoLocationNestedControlKey.LatitudeLongitude),
                required: required.includes(GeoLocationNestedControlKey.LatitudeLongitude)
            },
            map: {
                visible: visible.includes(GeoLocationNestedControlKey.Map),
                readOnly: readOnly.includes(GeoLocationNestedControlKey.Map),
                required: required.includes(GeoLocationNestedControlKey.Map)
            }
        };

    }

    /* DataSource to DataSourceConfig
    * migrate fields with the old dataSource format to the new format into dataSourceConfig */
    if (field.dataSourceConfig) {
        delete field.dataSource;
    }
    if (!field.dataSourceConfig && field.dataSource) {
        field.dataSourceConfig = DataSourceFunctions.parseDataSource(field.dataSource) || undefined;
        delete field.dataSource;
    }

    let hierarchyConfig: FormEditorHierarchyConfiguration | undefined;
    if (field.type === FieldType.Hierarchy) {
        if (field.hierarchyConfig) {
            let ceiling: HierarchyUnitFormData | undefined;
            if (field.hierarchyConfig.ceiling) {
                const id = field.hierarchyConfig.ceiling;
                const ceilingExtended = await cache.getHierarchyUnit(id);

                ceiling = {
                    id,
                    label: ceilingExtended?.label ?? id,
                    path: ceilingExtended?.path ?? [{ id, label: id }]
                };
            }

            hierarchyConfig = { ceiling, selectionMode: field.hierarchyConfig.selectionMode };
        }
    }

    const options: FormEditorOption[] | undefined = field.options?.map(o => Object.assign({ uuid: generateUUID() }, o));

    const transitions: FormEditorTransition[] | undefined = field.transitions ? await Promise.all(field.transitions.map(async t => {

        const transitionRoles = t.role ? t.role.split(',') : [];

        const triggers: FormEditorTransitionTarget[] | undefined = t.triggers ? await (Promise.all(t.triggers.map(async trigger => {

            let form: DefinitionInfo | undefined;
            if (trigger.targetFormIdentifier) {
                form = (await cache.getFormsInfo()).find(i => trigger.targetFormIdentifier === i.identifier) ?? {
                    identifier: trigger.targetFormIdentifier,
                    name: trigger.targetFormIdentifier,
                    bucket: null
                } as any as DefinitionInfo;
            }

            let transition: SchemaTransition | undefined;
            if (trigger.targetFormAction) {
                transition = (await cache.getSchema(form?.bucket))?.transitions
                    .find(st => st.source === FORM_EDITOR_CONSTANTS.WORKFLOW_INITIAL_STATE && st.action === trigger.targetFormAction);
                transition = transition ?? {
                    source: FORM_EDITOR_CONSTANTS.WORKFLOW_INITIAL_STATE,
                    action: trigger.targetFormAction,
                    target: ''
                } as any as SchemaTransition;
            }

            return {
                label: trigger.label,
                form,
                transition,
                condition: trigger.condition
            };
        }))) : undefined;

        const spawns: FormEditorTransitionTarget[] | undefined = t.spawnFormActions ? await Promise.all(t.spawnFormActions.map(async spawn => {

            let form: DefinitionInfo | undefined;
            if (spawn.targetFormIdentifier) {
                form = (await cache.getFormsInfo()).find(i => spawn.targetFormIdentifier === i.identifier) ?? {
                    identifier: spawn.targetFormIdentifier,
                    name: spawn.targetFormIdentifier,
                    bucket: null
                } as any as DefinitionInfo;
            }

            let transition: SchemaTransition | undefined;
            if (spawn.targetFormSource || spawn.targetFormAction) {
                transition = (await cache.getSchema(form?.bucket))?.transitions
                    .find(st => st.source === spawn.targetFormSource && st.action === spawn.targetFormAction);
                transition = transition ?? {
                    source: spawn.targetFormSource ?? '',
                    action: spawn.targetFormAction ?? '',
                    target: ''
                } as any as SchemaTransition;
            }

            const childSchema = await cache.getSchema(form?.bucket);
            const parentSchema = await cache.getSchema(bucket);
            const parentFields = parentSchema ? getTransitionMappingSchemaFields(parentSchema, dataPropertyInfoService, true) : [];
            const childFields = childSchema ? getTransitionMappingSchemaFields(childSchema, dataPropertyInfoService, false) : [];

            const fieldMappings: FormEditorFieldMapping[] =
                spawn.fieldMappings?.map(fm => {
                    let parentField: SchemaField | undefined;
                    if (fm.parentFieldIdentifier != null) {
                        parentField = parentFields.find(f => f.identifier === fm.parentFieldIdentifier) ?? {
                            identifier: fm.parentFieldIdentifier,
                            label: fm.parentFieldIdentifier
                        } as any as SchemaField;
                    }

                    let childField: SchemaField | undefined;
                    if (fm.childFieldIdentifier != null) {
                        childField = childFields.find(f => f.identifier === fm.childFieldIdentifier) ?? {
                            identifier: fm.childFieldIdentifier,
                            label: fm.childFieldIdentifier
                        } as any as SchemaField;
                    }

                    return { parentField, childField };
                }) ?? [];

            return {
                label: spawn.label,
                form,
                transition,
                fieldMappings
            };
        })) : undefined;

        const clonedTransition = JSON.parse(JSON.stringify(t)) as Transition;
        delete clonedTransition.role;
        delete clonedTransition.triggers;
        delete clonedTransition.spawnFormActions;

        const editorTransition: FormEditorTransition = Object.assign(clonedTransition, {
            roles: transitionRoles,
            triggers,
            spawns
        });

        return editorTransition;
    })) : undefined;

    const variations: FormEditorVariation[] | undefined = field.variations?.map(v => {
        const variationOptions = v.options?.map(o => Object.assign({ uuid: generateUUID() }, o));
        return Object.assign({}, v, { options: variationOptions });
    });

    const templateConfig: FormEditorTemplateConfig = {
        hideOnDesktop: {},
        hideOnMobile: {}
    };

    if (field.templateConfig) {
        if (field.templateConfig.hideFromColumnsOnDesktop) {
            for (const key of field.templateConfig.hideFromColumnsOnDesktop) {
                templateConfig.hideOnDesktop[key] = true;
            }
        }
        if (field.templateConfig.hideFromColumnsOnMobile) {
            for (const key of field.templateConfig.hideFromColumnsOnMobile) {
                templateConfig.hideOnMobile[key] = true;
            }
        }
    }

    let allowedTypes;
    if (field.type === FieldType.Link && field.visibleFields?.length) {
        allowedTypes = field.visibleFields as LinkContentType[];
    }

    const dataCaptures = Object.keys(field.dataCapture ?? {});
    const step = field.step ? `${field.step}` : undefined;

    const origin = Object.assign({}, field);
    delete origin.dataCapture;
    delete origin.visibleFields;
    delete origin.requiredFields;
    delete origin.readOnlyFields;
    delete origin.transitions;
    delete origin.templateConfig;
    delete origin.step;

    const uuid = generateUUID();
    const editorField: FormEditorField = Object.assign(origin, {
        uuid,
        scopeUuid,
        roles,
        visibleTo,
        options,
        templateConfig,
        step,
        dataCaptures,
        allowedTypes,
        addressAutocomplete: visible.includes('autocomplete'),
        addressNested: address || undefined,
        geoLocationNested: geoLocation || undefined,
        variations,
        transitions,
        hierarchyConfig,
        fields: await Promise.all((field.fields || []).map(f => mapFieldToFormEditorField(f, FormEditorFunctions.determineFieldScopeUuid(field.type, scopeUuid, uuid), cache, bucket, dataPropertyInfoService)))
    });

    return editorField;
};

const mapFormEditorFieldToField = (editorField: FormEditorField): UcField => {

    const role = editorField.roles?.length ? editorField.roles.join(',') : undefined;
    const visibleTo = editorField.visibleTo?.length ? editorField.visibleTo.join(',') : undefined;

    const visibleFields: string[] = [];
    const requiredFields: string[] = [];
    const readOnlyFields: string[] = [];

    if (editorField.type === FieldType.Address) {
        const addressNested = editorField.addressNested as any as Dictionary<FormNestedField>;

        for (const key of Object.keys(addressNested)) {
            if (addressNested[key].visible) {
                visibleFields.push(key);
            }
            if (addressNested[key].required) {
                requiredFields.push(key);
            }
            if (addressNested[key].readOnly) {
                readOnlyFields.push(key);
            }
        }

        if (editorField.addressAutocomplete) {
            visibleFields.push('autocomplete');
        }
    }

    if (editorField.type === FieldType.GeoLocation) {
        const geoLocation = editorField.geoLocationNested as any as Dictionary<FormNestedField>;

        for (const key of Object.keys(geoLocation)) {
            if (geoLocation[key].visible) {
                visibleFields.push(key);
            }
            if (geoLocation[key].required) {
                requiredFields.push(key);
            }
            if (geoLocation[key].readOnly) {
                readOnlyFields.push(key);
            }
        }
    }

    if (editorField.type === FieldType.Link && editorField.allowedTypes) {
        visibleFields.push(...editorField.allowedTypes);
    }

    const options: FieldOption[] | undefined = (editorField.options ?? []).length ? editorField.options?.map(o => {
        const result = Object.assign({}, o);
        delete (result as any).uuid;
        return result;
    }) : undefined;

    const variations: Variation[] | undefined = (editorField.variations ?? []).length ? editorField.variations?.map(v => {
        const variationOptions = v.options?.map(o => {
            const variationOption = Object.assign({}, o);
            delete (variationOption as any).uuid;
            return variationOption;
        });
        return Object.assign({}, v, { options: variationOptions });
    }) : undefined;

    const transitions: Transition[] | undefined = (editorField.transitions ?? []).length ? editorField.transitions?.map(t => {

        const clone: FormEditorTransition = JSON.parse(JSON.stringify(t));
        const transition: Transition = JSON.parse(JSON.stringify(t));

        transition.role = clone.roles.length ? clone.roles.join(',') : undefined;
        delete (transition as any).roles;

        transition.triggers = (clone.triggers ?? []).length ? clone.triggers?.map(trigger => ({
            label: trigger.label,
            condition: trigger.condition,
            targetFormIdentifier: trigger.form?.identifier as string,
            targetFormAction: trigger.transition?.action as string
        })) : undefined;
        delete clone.triggers;

        transition.spawnFormActions = (clone.spawns ?? []).length ? clone.spawns?.map(spawn => ({
            label: spawn.label,
            targetFormId: spawn.form?.id as any as number,
            targetFormIdentifier: spawn.form?.identifier as string,
            targetFormSource: spawn.transition?.source as string,
            targetFormAction: spawn.transition?.action as string,
            fieldMappings: (spawn.fieldMappings ?? []).length ? (spawn.fieldMappings?.filter(fm => fm.parentField != null && fm.childField != null)
                .map(fm => ({
                    parentFieldIdentifier: (fm.parentField as SchemaField).identifier,
                    childFieldIdentifier: (fm.childField as SchemaField).identifier
                })) ?? []) : []
        })) : undefined;
        delete (transition as any).spawns;

        return transition;
    }) : undefined;

    const hierarchyConfig: HierarchyConfiguration | undefined = editorField.hierarchyConfig ? {
        ceiling: editorField.hierarchyConfig.ceiling?.id,
        selectionMode: editorField.hierarchyConfig.selectionMode
    } : undefined;


    let templateConfig: HorizontalTableTemplate | undefined;

    if (editorField.templateConfig) {
        const hideOnDesktopFieldIdentifiers = Object
            .keys(editorField.templateConfig.hideOnDesktop)
            .filter(key => (editorField.templateConfig as FormEditorTemplateConfig).hideOnDesktop[key] === true);

        const hideOnMobileFieldIdentifiers = Object
            .keys(editorField.templateConfig.hideOnMobile)
            .filter(key => (editorField.templateConfig as FormEditorTemplateConfig).hideOnMobile[key] === true);

        if (hideOnDesktopFieldIdentifiers.length > 0 || hideOnMobileFieldIdentifiers.length > 0) {
            templateConfig = {
                hideFromColumnsOnDesktop: hideOnDesktopFieldIdentifiers.length > 0 ? hideOnDesktopFieldIdentifiers : undefined,
                hideFromColumnsOnMobile: hideOnMobileFieldIdentifiers.length > 0 ? hideOnMobileFieldIdentifiers : undefined
            };
        }
    }

    let dataCapture: DataCapture | undefined;
    if (editorField.dataCaptures?.length) {
        dataCapture = Object.assign({}, ...editorField.dataCaptures.map((x) => ({ [x]: {} })));
    }

    const validators = (editorField.validators ?? []).length ? editorField.validators : undefined;
    const step = editorField.step ? +editorField.step : undefined;
    const origin = Object.assign({}, editorField);

    delete (origin as any).addressAutocomplete;
    delete origin.addressNested;
    delete origin.geoLocationNested;
    delete origin.geoLocationNested;
    delete origin.hierarchyConfig;
    delete origin.step;
    delete (origin as any).dataCaptures;
    delete (origin as any).roles;
    delete (origin as any).uuid;
    delete (origin as any).scopeUuid;

    const field: UcField = Object.assign(origin, {
        role,
        visibleTo,
        visibleFields: visibleFields.length ? visibleFields : undefined,
        requiredFields: requiredFields.length ? requiredFields : undefined,
        readOnlyFields: readOnlyFields.length ? readOnlyFields : undefined,
        templateConfig,
        transitions,
        variations,
        options,
        validators,
        step,
        dataCapture,
        hierarchyConfig,
        fields: (editorField.fields ?? []).length ? editorField.fields?.map(f => mapFormEditorFieldToField(f)) : undefined
    });

    removeFieldEmptyAttributes(field);
    return field;
};

const mapDataToControlValue = async (definition: UcDefinition, cache: FormEditorCache, dataPropertyInfoService: DataPropertyInfoService): Promise<FormEditorDefinition> => {
    const formFields = await Promise.all((definition.fields ?? []).map(f => mapFieldToFormEditorField(f, FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID, cache, definition.bucket as string, dataPropertyInfoService)));
    const result: FormEditorDefinition = Object.assign({}, definition, { fields: formFields, bucket: definition.bucket as string });
    return result;
};

const mapControlValueToData = (definition: FormEditorDefinition): UcDefinition => {

    const fields = (definition.fields ?? []).map(f => mapFormEditorFieldToField(f));
    const result: UcDefinition = Object.assign({}, definition, { fields });

    if (result.lastModifiedBy == null || (result.lastModifiedBy.id == null && result.lastModifiedBy.username == null)) {
        delete result.lastModifiedBy;
    }
    if (result.lastPublishedBy == null || (result.lastPublishedBy.id == null && result.lastPublishedBy.username == null)) {
        delete result.lastPublishedBy;
    }
    if (result.settings != null) {
        if (result.settings.inputStyle == null) {
            delete result.settings.inputStyle;
        }
        if (result.settings.optionalSuffix == null) {
            delete result.settings.optionalSuffix;
        }
        if (result.settings.requiredSuffix == null) {
            delete result.settings.requiredSuffix;
        }
    }
    if (result.settings == null || Object.values(result.settings).find(v => v != null) == null) {
        delete result.settings;
    }

    removeAttributesByKey(result, [
        DefinitionControlKeys.LastModifiedAt, DefinitionControlKeys.LastPublishedAt, DefinitionControlKeys.PublishState,
        DefinitionControlKeys.ReportableMetaFields, DefinitionControlKeys.SequenceNumberFormat, DefinitionControlKeys.State,
        DefinitionControlKeys.Tags, DefinitionControlKeys.Version
    ]);

    delete (result as any).fieldsRootScope;

    return result;
};

const determineFieldScopeUuid = (parentFieldType?: FieldType, parentScopeUuid?: string, parentUuid?: string): string => {
    if (!parentFieldType) {
        return FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID;
    }

    if (parentFieldType === FieldType.Repeat) {
        return parentUuid as string;
    }

    return parentScopeUuid as string;
};

const generateSafeIdentifier = (base: string, otherIdentifiers: string[]): string => {

    let result = `${base}`;
    let suffix = 1;
    while (otherIdentifiers.includes(result)) {
        result = base.substring(0, FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH - 2) + suffix;
        suffix = suffix + 1;
    }
    return result.substring(0, FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH);
};

/** Invokable after Field control has been created */
const generateFieldSafeIdentifier = (meta: FormFieldMetadata, fieldsScopes: UfControlGroup, source: string): string => {
    const otherIdentifiers = Array
        .from((fieldsScopes.get(meta.scopeUuid)?.value as Map<string, FormFieldScopedInfo>).values() as IterableIterator<FormFieldScopedInfo>)
        .filter(i => i.uuid !== meta.uuid)
        .map(i => i.identifier as string);
    return FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.camelize(source), otherIdentifiers);
};

const getTransitionMappingSchemaFields = (schema: Schema, dataPropertyInfoService: DataPropertyInfoService, isParentForm: boolean): SchemaField[] => {
    const metadataFields = !isParentForm ? [] : dataPropertyInfoService.formDefinitionReferences.filter(sf => [
        FormDefinitionMetadataIdentifiers.CreatedBy,
        FormDefinitionMetadataIdentifiers.LastModifiedBy,
        FormDefinitionMetadataIdentifiers.Id
    ].includes(sf.identifier as FormDefinitionMetadataIdentifiers));

    return [...metadataFields, ...schema.fields];
};

const areTransitionMappingFieldsCompatible = (source: SchemaField, target: SchemaField): boolean => {
    if (// Same FieldType
        target.type === source.type ||
        // Source is a Text and target has a DS that will autofill using the source value as its DataSeed._id
        (source?.type === FieldType.Text && (target.dataSource ?? target.dataSourceConfig) != null)
    ) {
        return true;
    }

    // Not compatible
    return false;
};

const removeFieldOptionEmptyAttributes = (option: FieldOption): void => {
    removeAttributesByKey(option, [OptionControlKeys.Id, OptionControlKeys.Content]);
};

const removeFieldValidatorEmptyAttributes = (validator: FieldValidator): void => {
    removeAttributesByKey(validator, [ValidatorControlKeys.Value]);
};

const removeVariationEmptyAttributes = (variation: Variation): void => {
    removeAttributesByKey(variation, [VariationControlKeys.Condition, VariationControlKeys.Label, VariationControlKeys.Placeholder, VariationControlKeys.Help]);
    variation.options?.forEach(o => removeFieldOptionEmptyAttributes(o));
    variation.validators?.forEach(v => removeFieldValidatorEmptyAttributes(v));
};

const removeTransitionEmptyAttributes = (transition: Transition): void => {
    removeAttributesByKey(transition, [TransitionControlKeys.Result, TransitionControlKeys.Notify, TransitionControlKeys.Validate, 'role', TransitionControlKeys.ShowIf, TransitionControlKeys.Tags]);
    transition.triggers?.forEach(t => removeAttributesByKey(t, [TransitionTargetControlKeys.Condition]));
};

const removeFieldEmptyAttributes = (field: UcField) => {

    field.options?.forEach(o => removeFieldOptionEmptyAttributes(o));
    field.validators?.forEach(v => removeFieldValidatorEmptyAttributes(v));
    field.variations?.forEach(v => removeVariationEmptyAttributes(v));
    field.transitions?.forEach(t => removeTransitionEmptyAttributes(t));

    removeAttributesByKey(field, [
        FieldControlKeys.Id, FieldControlKeys.Identifier, FieldControlKeys.Label, FieldControlKeys.ShortLabel, FieldControlKeys.Description,
        FieldControlKeys.Help, FieldControlKeys.Currency, FieldControlKeys.Placeholder, FieldControlKeys.Step,
        FieldControlKeys.Format, FieldControlKeys.Tags, FieldControlKeys.DataSourceConfig, FieldControlKeys.AutoDetect,
        FieldControlKeys.Autofill, FieldControlKeys.BindTo,
        FieldControlKeys.IsReadOnly, FieldControlKeys.IsRequired, FieldControlKeys.IsSearchable,
        FieldControlKeys.IsReportable, FieldControlKeys.IsTranslatable, FieldControlKeys.MaxLength, FieldControlKeys.Precision,
        FieldControlKeys.ItemLabel, FieldControlKeys.AddButtonLabel, FieldControlKeys.Width, FieldControlKeys.BreakAfter,
        FieldControlKeys.Template, FieldControlKeys.ColumnCount, FieldControlKeys.AvoidDuplicates, FieldControlKeys.DataCaptures,
        FieldControlKeys.LayoutDirection, FieldControlKeys.TemplateConfig, FieldControlKeys.VisibleTo,
        FieldControlKeys.ShowIf, FieldControlKeys.ShowOn, FieldControlKeys.VisibleTo, FieldControlKeys.HierarchyConfig,
        FieldControlKeys.Options, FieldControlKeys.Validators, FieldControlKeys.Variations, FieldControlKeys.Transitions, FieldControlKeys.Fields,
        FieldControlKeys.AllowedTypes, 'visibleFields', 'requiredFields', 'readOnlyFields', 'role', 'dataCapture'
    ]);
};

const removeAttributesByKey = (item: any, attributes: string[]): void => {
    for (const attribute of attributes) {
        if (item[attribute] == null) {
            delete item[attribute];
        }
        // string
        if (typeof item[attribute] === 'string' && (item[attribute] as string).trim().length === 0) {
            delete item[attribute];
        }
        // boolean
        if (typeof item[attribute] === 'boolean' && item[attribute] === false) {
            delete item[attribute];
        }
        // array
        if (Array.isArray(item[attribute]) && (item[attribute] as any[]).length === 0) {
            delete item[attribute];
        }
    }
};

const isSectionVisible = (meta: FormFieldMetadata, fields: FieldControlKeys[]): boolean =>
    fields.find(key => (meta as any)[key] === true) != null;

const isControlAField = (control: UfControlGroup): boolean =>
    control.controls &&
    control.controls[FieldControlKeys.Uuid] != null &&
    control.controls[FieldControlKeys.Type] != null;

const getFieldControlDepth = (field: UfControlGroup, count = 0): number => {

    if (field.parent instanceof UfControlArray && field.parent.parent instanceof UfControlGroup) {
        return getFieldControlDepth(field.parent.parent, count + 1);
    }

    return count;
};

const getFieldControlInnerDept = (field: UfControlGroup, count = 1): number => {

    if (!field) {
        return 0;
    }

    const fields = field.get(FieldControlKeys.Fields);

    if (!fields || !(fields instanceof UfControlArray) || !fields.length) {
        return count;
    }

    const depths = fields.controls.map((c: UfControlGroup) => getFieldControlInnerDept(c, (count + 1)));
    const max = Math.max(...depths);
    return max;
};

const getFieldPoistionError = (field: UfControlGroup, depth: number, parent?: UfControlGroup, parentDepth?: number): string | undefined => {

    if (parentDepth == null) {
        return;
    }

    const totalDepth = depth + parentDepth;
    const type = field.get(FieldControlKeys.Type)?.value as FieldType;
    const parentType = parent?.get(FieldControlKeys.Type)?.value as FieldType | undefined;
    const parentTemplate = parent?.get(FieldControlKeys.Template)?.value as FieldTemplate | undefined;

    if (type !== FieldType.Section && parentDepth === 0) {
        return `All items must be placed within a section`;
    }

    switch (type) {

        case FieldType.Section:
            if (parentDepth > 0) {
                return `Sections must be the highest level.`;
            }
            break;

        case FieldType.Group:
            if (parentDepth > 3) {
                return `You've reached the maximum levels.`;
            }
            if (parentTemplate && parentType === FieldType.Repeat &&
                [FieldTemplate.HorizontalTable, FieldTemplate.HorizontalTableMobile, FieldTemplate.VerticalTable, FieldTemplate.VerticalTableMobile].includes(parentTemplate)) {
                return `Groups can't be placed inside a Repeating Group with Table template`;
            }
            break;

        case FieldType.Repeat:
            if (parentDepth > 3) {
                return `You've reached the maximum levels.`;
            }
            if (parentType === FieldType.ActionGroup) {
                return `Repeating Groups can't be placed inside Action Groups.`;
            }
            break;

        case FieldType.Survey:
            if (parentDepth > 3) {
                return `You've reached the maximum levels.`;
            }
            break;

        case FieldType.ActionGroup:
            if (parentType !== FieldType.Section) {
                return `Action Groups must be placed inside Sections`;
            }
            break;

        default:
            if (totalDepth > 5) {
                return `You've reached the maximum levels.`;
            }
            break;
    }

    return;
};

const missingRoleError = async (cache: FormEditorCache, valueRoles?: string[]): Promise<string | null> => {
    if (!valueRoles) {
        return null;
    }

    const existingRoles = (await cache.getRoles()).map(r => r.name);

    for (const role of valueRoles) {
        if (!existingRoles.includes(role)) {
            return `Role "${role}" doesn't exist`;
        }
    }

    return null;
};

const getFieldIdentifiersScopes = (fieldScopeManager: FormEditorFieldScopeManager, control: UfControlGroup): Map<string, string[]> => {

    const map = new Map<string, string[]>();

    const field = control.getRawValue() as FormEditorField;
    const dsIdentifiers = Object.keys(field.dataSourceConfig?.outputs ?? {});

    const scopeIdentifers = getScopeIdentifiers(fieldScopeManager, field.scopeUuid);
    const rootIdentifiers = field.scopeUuid === FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID ? scopeIdentifers : getScopeIdentifiers(fieldScopeManager, FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID);
    const itemIdentifiers = field.type === FieldType.Repeat ? getScopeIdentifiers(fieldScopeManager, field.uuid) : [];
    const seedIdentifiers = field.type === FieldType.Repeat ? dsIdentifiers : [];
    const optionsIdentifiers = field.dataSourceConfig ? [] : Array.from(Array(field.options?.length ?? 0), (_, i) => `${i}`);
    const selfIdentifiers = dsIdentifiers;

    map.set('', scopeIdentifers);
    map.set('$root', rootIdentifiers);

    if (itemIdentifiers.length) {
        map.set('$item', itemIdentifiers);
    }
    if (seedIdentifiers.length) {
        map.set('$seed', seedIdentifiers);
    }
    if (selfIdentifiers.length) {
        map.set('$self', selfIdentifiers);
    }
    if (optionsIdentifiers.length) {
        map.set('#options', optionsIdentifiers);
    }

    return map;
};

const getNonAvailableIdentifiers = (exp: string, availables: Map<string, string[]>): string[] => {

    const missingIdentifiers = [] as string[];

    try {
        const identifiers = [] as string[];
        const expression = jsep(exp);
        extractIdentifiers(expression, identifiers);

        let tokens: string[];
        let $scopeKey: string;
        let $scope: string[] | undefined;
        let $identifier: string;

        for (const identifier of identifiers) {

            tokens = identifier.split('.');
            $scopeKey = identifier.startsWith('$') ? tokens[0] : '';
            if (['$self', '$seed', '$user', '$now'].includes($scopeKey)) {
                // TODO Implement check for those scopes
                continue;
            }
            $scope = availables.get($scopeKey);
            if (!$scope) {
                missingIdentifiers.push(identifier);
                continue;
            }
            if ($scopeKey.length && (tokens.length < 2 || tokens[1].length === 0)) {
                missingIdentifiers.push(identifier);
                continue;
            }
            $identifier = $scopeKey.length ? tokens[1] : tokens[0];
            if (!$scope.includes($identifier)) {
                missingIdentifiers.push(identifier);
            }
        }

    } catch (e) {
        console.warn(`Can't parse expression ${exp}`);
    }

    return missingIdentifiers;
};

/** PRIVATE START */
const getScopeIdentifiers = (fieldScopeManager: FormEditorFieldScopeManager, uuid: string): string[] => {
    const identifiers: string[] = [];
    const scope = fieldScopeManager.getScope(uuid);
    if (!scope) {
        return identifiers;
    }

    for (const info of scope.values()) {
        if (info.identifier) {
            identifiers.push(info.identifier);
        }
    }

    return identifiers;
};

const extractIdentifiers = (exp: Expression, identifiers: string[]) => {

    const type = exp.type;

    if (type === ExpressionTypes.BinaryExpression) {
        const binExp = exp as BinaryExpression;
        extractIdentifiers(binExp.left, identifiers);
        extractIdentifiers(binExp.right, identifiers);
    }

    if (type === ExpressionTypes.CallExpression) {
        const callExp = exp as CallExpression;
        callExp.arguments.forEach(e => extractIdentifiers(e, identifiers));
    }

    if (type === ExpressionTypes.UnaryExpression) {
        const unExp = exp as UnaryExpression;
        extractIdentifiers(unExp.argument, identifiers);
    }

    if (type === ExpressionTypes.ArrayExpression) {
        const arrExp = exp as ArrayExpression;
        arrExp.elements.forEach(e => extractIdentifiers(e, identifiers));
    }

    // if (type === ExpressionTypes.Compound) {
    //     const compExp = exp as Compound;
    //     compExp.body.forEach(e => extractIdentifiers(e, identifiers));
    // }

    if (type === ExpressionTypes.ConditionalExpression) {
        const condExp = exp as ConditionalExpression;
        extractIdentifiers(condExp.test, identifiers);
        extractIdentifiers(condExp.consequent, identifiers);
        extractIdentifiers(condExp.alternate, identifiers);
    }

    if (type === ExpressionTypes.Identifier) {
        identifiers.push((exp as Identifier).name);
    }

    if (exp.type === ExpressionTypes.MemberExpression) {
        identifiers.push(getIdentifierFromMember(exp as MemberExpression));
    }
};

const getIdentifierFromMember = (member: MemberExpression): string => {

    let property = '';
    if (member.property.type === ExpressionTypes.Identifier) {
        const propertyValue = (member.property as Identifier).name;
        property = member.computed ? `[${propertyValue}]` : `.${propertyValue}`;
    }
    if (member.property.type === ExpressionTypes.Literal) {
        const propertyValue = (member.property as Literal).raw;
        property = member.computed ? `[${propertyValue}]` : `.${propertyValue}`;
    }

    switch (member.object.type) {
        case ExpressionTypes.Identifier:
            return (member.object as Identifier).name + property;
        case ExpressionTypes.MemberExpression:
            return getIdentifierFromMember(member.object as MemberExpression) + property;
        default:
            return '';
    }
};

const mapFieldSortableInfoToDataDescriptorProperty = (config: {
    identifier: string;
    type: FieldType;
    label: string;
    shortLabel?: string;
    scopeIdentifier?: string;
    dsFieldLabel?: string;
}): DataPropertyDescriptor => {

    const { identifier, scopeIdentifier, dsFieldLabel, label, type, shortLabel } = config;
    const scopedIdentifier = scopeIdentifier ? `${scopeIdentifier}.${identifier}` : identifier;
    const scopedLabel = (!scopeIdentifier && dsFieldLabel) ? `${dsFieldLabel} - ${label}` : label;

    return {
        identifier: scopedIdentifier,
        type,
        label: scopedLabel,
        display: getDisplay(scopedIdentifier, scopedLabel, shortLabel),
        icon: FieldFunctions.getFieldTypeIcon(type) ?? undefined
    };
};

const getDataSourceFilterableMappingsAsDataPropertyDescriptors = (config: {
    scopeIdentifier?: string;
    dsFieldLabel?: string;
    ds?: DataSource;
}): DataPropertyDescriptor[] => {

    const { ds, scopeIdentifier, dsFieldLabel } = config;

    if (!ds) {
        return [];
    }

    const outputFields: Dictionary<OutputField> = ds.outputFields ?? {};

    return Object.keys(outputFields).map(identifier => {
        const of = outputFields[identifier];
        if (!FORM_EDITOR_CONSTANTS.REPEAT_SORTABLE_FIELD_TYPES.includes(of.type)) {
            return;
        }

        return mapFieldSortableInfoToDataDescriptorProperty({
            scopeIdentifier,
            dsFieldLabel,
            identifier,
            type: of.type,
            label: of.label
        });
    }).filter(dpd => dpd != null) as DataPropertyDescriptor[];
};

/** PRIVATE END */

export const FormEditorFunctions = {
    detectEditMode,
    getFieldCleanConfiguration,
    getFieldWithDefaults,
    fieldMetadata,
    controlMetadata,
    fieldTemplateOptions,
    fieldVisibleSections,
    emptyValidatorByType,
    detectIdentifiersMaxLength,
    determineFieldScopeUuid,
    fieldIterable,
    mapFieldToFormEditorField,
    mapFormEditorFieldToField,
    mapDataToControlValue,
    mapControlValueToData,
    generateSafeIdentifier,
    generateFieldSafeIdentifier,
    getTransitionMappingSchemaFields,
    areTransitionMappingFieldsCompatible,
    getFieldPoistionError,
    isControlAField,
    getFieldControlDepth,
    getFieldControlInnerDept,
    missingRoleError,
    getFieldIdentifiersScopes,
    getNonAvailableIdentifiers,
    calculateRepeatFieldSortableDescriptors
};