import { differenceInMilliseconds, parseISO } from 'date-fns';
import { Subscription, timer } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { AuthProvider, Dictionary, Error } from '@unifii/sdk';

import { AuthProviderDetails, FieldMapping, SystemRole, TenantSettings, UcAuthProviders, UcRoles } from 'client';

import { EditData } from 'components/common/edit-data';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { AuthProvidersTableManager } from './auth-providers-table-manager';
import { FieldMappingOption } from './microsoft.component';


@Component({
    templateUrl: './auth0.html',
    styleUrls: ['./auth-providers.less']
})
export class Auth0Component implements OnInit, OnDestroy, EditData {

    providerId: string;
    tenant: TenantSettings;
    details: AuthProviderDetails;
    error: Error;
    groupsError: Error | null = null;
    edited = false;
    recentlyModified: boolean;

    filteredSourceGroups: { id: string; name: string }[];
    filteredTenantRoles: string[];
    filteredSystemRoles: string[];
    allMappingFieldsOptions: Dictionary<FieldMappingOption[]> = {};

    form: UfControlGroup;
    tenantRolesControl: UfControlArray = new UfControlArray([]);
    systemRolesControl: UfControlArray = new UfControlArray([]);
    fieldMappingControl: UfControlArray = new UfControlArray([]);

    loading = true;
    groupsLoading = false;

    breadcrumbs: Breadcrumb[] = this.breadcrumbService.getBreadcrumbs(this.route, ['Auth0']);

    private recentlyModifiedLimit = 300000;
    private reloadInterval = 10000;

    private readonly authName: AuthProvider = AuthProvider.Auth0;


    private sourceGroups: { id: string; name: string }[] = [];
    private tenantRoles: string[];

    private groupsLoaderSubscription: Subscription;
    private statusChangesSubscription: Subscription;

    constructor(
        protected modalService: ModalService,
        private router: Router,
        private route: ActivatedRoute,
        private ucAuthProviders: UcAuthProviders,
        private toastService: ToastService,
        private context: ContextService,
        private ucRoles: UcRoles,
        private dialogs: DialogsService,
        private breadcrumbService: BreadcrumbService,
        @Inject(TableContainerManager) private manager: AuthProvidersTableManager
    ) { }

    async ngOnInit() {

        this.loading = true;

        this.tenant = this.context.tenantSettings as TenantSettings;
        this.providerId = this.route.snapshot.params.id;

        try {
            // Load auth provider details
            let details: AuthProviderDetails = {
                isActive: false,
                type: this.authName
            };

            const controlGroup: { [key: string]: AbstractControl } = {
                clientSecret: new UfControl(ValidatorFunctions.required('Client Secret is required')),
                audience: new UfControl(ValidatorFunctions.required('Audience is required'))
            };

            if (this.providerId) {
                details = await this.ucAuthProviders.get(this.providerId);
                await this.loadGroups(details);

                this.tenantRoles = (await this.ucRoles.get()).map(r => r.name);

                // Add controls
                if (details.systemRolesMapping) {
                    details.systemRolesMapping.forEach(data => this.systemRolesControl.push(this.createRoleControl(data)));
                }

                controlGroup.systemRoles = this.systemRolesControl;
                controlGroup.tenantRoles = this.tenantRolesControl;

                if (details.tenantRolesMapping) {
                    details.tenantRolesMapping.forEach(data => this.tenantRolesControl.push(this.createRoleControl(data)));
                }

                if (details.userFieldsMapping) {
                    Object.keys(details.userFieldsMapping).forEach(key => {
                        if (details.userFieldsMapping && details.userFieldsMapping[key]) {
                            this.fieldMappingControl.push(this.createFieldMappingControl({ key, ...details.userFieldsMapping[key] }));
                            this.allMappingFieldsOptions[key] = [];
                        }
                    });
                }
            } else {
                controlGroup.tenant = new UfControl(ValidatorFunctions.required('Tenant name is required'));
                controlGroup.clientId = new UfControl(ValidatorFunctions.required('Client Id is required'));
            }

            this.form = new UfControlGroup(controlGroup);
            details.extras = details.extras ? details.extras : {};

            this.statusChangesSubscription = this.form.statusChanges.subscribe(() => this.edited = true);

            // Ready
            this.details = details;
        } catch (e) {
            this.error = e;
        }

        this.loading = false;
    }

    ngOnDestroy() {
        if (this.groupsLoaderSubscription) {
            this.groupsLoaderSubscription.unsubscribe();
        }

        if (this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    async deactivate() {
        if (!this.valid) {
            this.toastService.error('Check configuration');
            return;
        }

        if (!await this.dialogs.confirmSSODeactivate()) {
            return;
        }

        this.details.isActive = false;
        this.save(true);
    }

    async activate() {
        if (!this.valid) {
            this.toastService.error('Check configuration');
            return;
        }

        if (!await this.dialogs.confirmSSOActivate()) {
            return;
        }

        this.details.isActive = true;
        this.save(true);
    }

    async disconnect() {

        if (!await this.dialogs.confirmSSODisconnect()) {
            return;
        }

        await this.ucAuthProviders.delete(this.providerId);
        this.toastService.success('Disconnected');
        this.close();
    }

    async save(close = false) {

        if (!this.valid) {
            return;
        }

        this.details.systemRolesMapping = (this.systemRolesControl.value as any[]).map(i => ({
            source: i.source.id,
            targets: i.targets
        }));

        this.details.tenantRolesMapping = (this.tenantRolesControl.value as any[]).map(i => ({
            source: i.source.id,
            targets: i.targets
        }));

        this.details.userFieldsMapping = (this.fieldMappingControl.value).reduce((obj: any, item: any) => ({
            ...obj,
            [item.key]: {
                source: item.source.source,
                label: item.source.label
            }
        }), {});

        try {
            await this.ucAuthProviders.save(this.details);

            this.edited = false;
            this.toastService.success('Saved!');

            if (close) {
                this.close();
            }
        } catch (e) {
            this.error = e;
        }
    }

    addSystemRole() {
        this.systemRolesControl.push(this.createRoleControl());
    }

    addTenantRole() {
        this.tenantRolesControl.push(this.createRoleControl());
    }

    removeSystemRole(index: number) {
        this.systemRolesControl.removeAt(index);
    }

    removeTenantRole(index: number) {
        this.tenantRolesControl.removeAt(index);
    }

    async findFieldMapping(field: string, query?: string) {
        const options = (await this.ucAuthProviders.getMappingFields(this.details.id || '', field))
            .map(this.mapMappingFieldDisplay)
            .filter(f => !query || f.display.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0);
        this.allMappingFieldsOptions[field] = [...options];
    }

    findSourceGroups(query?: string) {
        this.filteredSourceGroups = (query && query.trim().length) ?
            this.sourceGroups.filter(g => g.name.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...this.sourceGroups];
    }

    findTenantRole(query?: string) {
        this.filteredTenantRoles = (query && query.trim().length) ?
            this.tenantRoles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...this.tenantRoles];
    }

    findSystemRoles(query?: string) {
        const roles = Object.keys(SystemRole)
            .filter(key => key !== SystemRole.SuperUser);

        this.filteredSystemRoles = (query && query.trim().length) ?
            roles.filter(r => r.toLowerCase().indexOf(query.trim().toLowerCase()) >= 0) :
            [...roles];
    }

    close() {
        const backRoute = this.details.id ? ['../../'] : ['..'];
        this.manager.reload.next();
        this.router.navigate(backRoute, { relativeTo: this.route });
    }

    private get valid(): boolean {
        this.form.setSubmitted();
        return this.form.valid;
    }

    private async loadGroups(details: AuthProviderDetails) {

        this.groupsLoading = true;
        this.recentlyModified = this.isRecentlyModified(details);
        this.groupsLoaderSubscription = timer(0, this.reloadInterval).subscribe(async () => {
            try {
                this.sourceGroups = await this.ucAuthProviders.getAuthProviderRoles(details.id as string);
                this.filteredSourceGroups = this.sourceGroups;

                this.groupsError = null;
                this.groupsLoaderSubscription.unsubscribe();

                this.updateControlsWithLoadedOptions(this.systemRolesControl.controls);
                this.updateControlsWithLoadedOptions(this.tenantRolesControl.controls);

                this.groupsLoading = false;
            } catch (e) {
                console.error(e);
                this.groupsError = e;
                if (!this.recentlyModified) {
                    this.groupsLoaderSubscription.unsubscribe();
                    this.groupsLoading = false;
                }
            }
        });
    }

    private updateControlsWithLoadedOptions(controls: AbstractControl[]) {
        if (this.form && this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe(); // unsubscribe from changes while we're changing controls
        }

        // update controls
        for (const control of controls) {
            control.value.source = this.sourceGroups.find(g => g.id === control.value.source.id) || { id: control.value.source, name: control.value.source };
            control.setValue(control.value);
        }

        if (this.form && this.statusChangesSubscription.closed) {
            this.statusChangesSubscription = this.form.statusChanges.subscribe(() => this.edited = true); // resubscribe
        }
    }

    private createRoleControl(data?: { source: string; targets: string[] }) {

        const source = new UfControl(ValidatorFunctions.required('Select a group'));
        const targets = new UfControl(ValidatorFunctions.required('Select at least a role'));

        if (data) {
            const group = this.sourceGroups.find(g => g.id === data.source) || { id: data.source, name: '' };

            source.setValue(group);
            targets.setValue(data.targets);
        }

        return new UfControlGroup({ source, targets });
    }

    private createFieldMappingControl(data: FieldMappingOption) {
        const key = new UfControl();
        const source = new UfControl(ValidatorFunctions.required('Select a source'));

        key.setValue(data.key);
        source.setValue(this.mapMappingFieldDisplay(data));

        return new UfControlGroup({ key, source });
    }

    private isRecentlyModified(authDetails: AuthProviderDetails): boolean {
        return !!authDetails.lastModifiedAt && differenceInMilliseconds(new Date(), parseISO(authDetails.lastModifiedAt)) <= this.recentlyModifiedLimit;
    }

    private mapMappingFieldDisplay(data: FieldMapping): FieldMappingOption {
        return {
            source: data.source,
            label: data.label,
            display: data.label ? `${data.source} (${data.label})` : data.source
        };
    }

}
