import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { LimitService } from 'services/limit.service';

import { BuilderEventInfo, BuilderService } from './builder.service';


@Component({
    selector: 'uc-builder-nodes',
    templateUrl: './builder-nodes.html',
    styleUrls: ['./builder-nodes.less']
})
export class BuilderNodesComponent implements OnInit, OnDestroy {

    @Input() root: any;
    @Input() parent: any;
    @Input() childrenProperty: string;

    ready: boolean;
    errors: string[] = [];

    private subscribers: Subscription[] = [];

    constructor(private builderService: BuilderService, private limitService: LimitService) { }

    ngOnInit() {
        this.subscribers.push(this.builderService.ready.subscribe(() => this.ready = true));

        this.subscribers.push(this.builderService.fieldRefreshed.subscribe(i => {
            if (this.isParent(i)) {
                this.errors = this.builderService.getErrors(this.parent, 'fields');
            }
        }));
    }

    ngOnDestroy() {
        this.subscribers.forEach(s => s.unsubscribe());
    }

    inserted(field: any) {
        if ((this.builderService.builder as any).inserted) {
            return (this.builderService.builder as any).inserted(field);
        }
    }

    nestable(field: any) {
        if ((this.builderService.builder as any).nestable) {
            return (this.builderService.builder as any).nestable(field);
        }
    }

    canDrop = (fieldRef: any, parent: any): boolean => {

        if ((this.builderService.builder as any).canDrop == null) {
            return true;
        }

        return (this.builderService.builder as any).canDrop(fieldRef, parent);
    };

    convertFieldRef = (fieldRef: any): any => {
        if ((this.builderService.builder as any).convertFieldRef) {
            return (this.builderService.builder as any).convertFieldRef(fieldRef);
        }
    };

    moved(field: any) {
        this.builderService.fieldMoved.next({ subject: field, atomic: true });
    }

    private isParent(i: BuilderEventInfo = { subject: null }): boolean {
        return i.subject != null && i.subject === this.parent;
    }
}
