import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { UfControlArray, UfControlGroup, UfFormBuilder, UfFormControl, ValidatorFunctions } from '@unifii/library/common';
import { FormFunctions } from '@unifii/library/smart-forms';
import { StructureNode, StructureNodeArg, StructureNodeBucketOptions, StructureNodeType, StructureNodeVariation } from '@unifii/sdk';

import { UcStructure } from 'client';

import {
    StructureControlKeys, StructureNodeArgControlKeys, StructureNodeBucketOptionsControlKeys, StructureNodeControlKeys,
    StructureNodeVariationControlKeys, UserReferenceControlKeys
} from './structure-control-keys';
import { StructureEditorCache } from './structure-editor-cache';
import { StructureFunctions } from './structure-functions';
import { ConsoleStructure, ConsoleStructureNode, StructureNodeArgControlValue } from './structure-model';


//TODO Adopt ControlAccessor
interface DependantInfo {
    dependant: string; // control key
    dependencies: string[]; // list of control keys
}

@Injectable()
export class StructureFormCtrl {

    private dependantConfig: DependantInfo[] = [{
        dependant: StructureNodeControlKeys.Name,
        dependencies: [StructureNodeControlKeys.Type, StructureNodeControlKeys.NodeId]
    }];

    constructor(
        private formBuilder: UfFormBuilder,
        private cache: StructureEditorCache
    ) { }

    mapDataToControlValue(structure: UcStructure): ConsoleStructure {

        const mapArgDataToArgControlValue = (arg: StructureNodeArg): StructureNodeArgControlValue => ({
            [StructureNodeArgControlKeys.Key]: arg.key,
            [StructureNodeArgControlKeys.Value]: { value: arg?.value ?? null, isExpression: arg.isExpression ?? false }
        });

        const copy: UcStructure = JSON.parse(JSON.stringify(structure));

        const iframes = StructureFunctions.getAllNodesOfType(copy, StructureNodeType.IFrame);

        for (const iframe of iframes) {

            if (iframe.args) {
                iframe.args = iframe.args.map(mapArgDataToArgControlValue);
            }
        }


        return copy as ConsoleStructure;
    }

    mapControlValueToData(structure: ConsoleStructure): UcStructure {

        const mapArgControlValueToArgData = (arg: StructureNodeArgControlValue): StructureNodeArg => ({
            [StructureNodeArgControlKeys.Key]: arg.key,
            [StructureNodeArgControlKeys.Value]: arg.value?.value,
            isExpression: arg.value?.isExpression
        });

        const copy: ConsoleStructure = JSON.parse(JSON.stringify(structure));
        const iframes = StructureFunctions.getAllNodesOfType(copy as UcStructure, StructureNodeType.IFrame) as ConsoleStructureNode[];

        for (const iframe of iframes) {

            if (iframe.args) {
                (iframe as StructureNode).args = iframe.args.map(mapArgControlValueToArgData);
            }
        }

        return copy as UcStructure;
    }

    buildRoot(structure: UcStructure): UfControlGroup {
        const controlStructureValue = this.mapDataToControlValue(structure);

        const group = this.buildNodeControl(controlStructureValue as ConsoleStructureNode);
        group.addControl(StructureControlKeys.Rev, this.formBuilder.control(controlStructureValue.rev));
        group.addControl(StructureControlKeys.LastNodeId, this.formBuilder.control(controlStructureValue.lastNodeId));
        group.addControl(StructureControlKeys.Variations, this.formBuilder.array(controlStructureValue.variations?.map(vr => this.buildNodeVariationControl(vr)) || []));
        group.addControl(StructureControlKeys.StructurePublishState, this.formBuilder.control(controlStructureValue.structurePublishState));

        return group;
    }

    buildNodeControl(node: ConsoleStructureNode): UfControlGroup {

        const group = this.formBuilder.group({
            [StructureNodeControlKeys.Type]: [node.type, ValidatorFunctions.required('Type is required')],
            [StructureNodeControlKeys.Id]: node.id,
            [StructureNodeControlKeys.NodeId]: [node.nodeId, ValidatorFunctions.required('NodeId is required')],
            [StructureNodeControlKeys.Name]: [node.name, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;
                const nodeId = c?.parent?.get(StructureNodeControlKeys.NodeId)?.value as string;

                let result: boolean;

                if (nodeId === '0' && type === StructureNodeType.Empty) {
                    result = true;
                } else {
                    result = !ValidatorFunctions.isEmpty(v);
                }

                return result;

            }, 'Menu name is required')],
            [StructureNodeControlKeys.DefinitionIdentifier]: [node.definitionIdentifier, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;
                return type !== StructureNodeType.Custom || !ValidatorFunctions.isEmpty(v);
            }, 'Identifier is required')],
            [StructureNodeControlKeys.DefinitionLabel]: node.definitionLabel,
            [StructureNodeControlKeys.PublishState]: node.publishState,
            [StructureNodeControlKeys.LastPublishedAt]: node.lastPublishedAt,
            [StructureNodeControlKeys.LastModifiedAt]: node.lastModifiedAt,
            [StructureNodeControlKeys.LastModifiedBy]: this.formBuilder.group({
                [UserReferenceControlKeys.Id]: node.lastModifiedBy?.id,
                [UserReferenceControlKeys.Username]: node.lastModifiedBy?.username
            }),
            [StructureNodeControlKeys.LastPublishedBy]: this.formBuilder.group({
                [UserReferenceControlKeys.Id]: node.lastPublishedBy?.id,
                [UserReferenceControlKeys.Username]: node.lastPublishedBy?.username
            }),
            [StructureNodeControlKeys.Url]: [node.url, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;
                return [StructureNodeType.IFrame, StructureNodeType.Link].indexOf(type) < 0
                    || !ValidatorFunctions.isEmpty(v);
            }, 'Url is required')],
            [StructureNodeControlKeys.Tags]: [node.tags],
            [StructureNodeControlKeys.Roles]: [node.roles],
            [StructureNodeControlKeys.Buckets]: [node.buckets],
            [StructureNodeControlKeys.Hidden]: node.hidden,
            [StructureNodeControlKeys.BucketOptions]: this.formBuilder.array(node.bucketOptions?.map(bo => this.buildBucketOptionControl(bo)) || []),
            [StructureNodeControlKeys.Args]: this.formBuilder.array(node.args?.map(arg => this.buildArgControl(arg as StructureNodeArgControlValue)) || []),
            [StructureNodeControlKeys.Children]: this.buildNodeChildrenControl(node.children)
        });

        this.setDependencies(this.dependantConfig, group);
        return group;
    }

    buildNodeChildrenControl(children?: ConsoleStructureNode[]): UfControlArray {
        return this.formBuilder.array(children?.map(child => this.buildNodeControl(child)) || []);
    }

    buildNodeVariationControl(variation: StructureNodeVariation): UfControlGroup {
        return this.formBuilder.group({
            [StructureNodeVariationControlKeys.Type]: [variation.type, ValidatorFunctions.required('Variation type is required')],
            [StructureNodeVariationControlKeys.Name]: [variation.name, ValidatorFunctions.required('Variation name is required')],
            [StructureNodeVariationControlKeys.Id]: variation.id,
            [StructureNodeVariationControlKeys.PublishState]: variation.publishState,
            [StructureNodeVariationControlKeys.DefinitionIdentifier]: [variation.definitionIdentifier, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;
                return type !== StructureNodeType.Custom || !ValidatorFunctions.isEmpty(v);
            }, 'Variation identifier is required')],
            [StructureNodeVariationControlKeys.DefinitionLabel]: variation.definitionLabel,
            [StructureNodeVariationControlKeys.Roles]: [variation.roles],
            [StructureNodeVariationControlKeys.Tags]: [variation.tags],
            [StructureNodeVariationControlKeys.BucketOptions]: this.formBuilder.array(variation.bucketOptions?.map(bo => this.buildBucketOptionControl(bo)) || [])
        });
    }

    buildBucketOptionControl(bucketOption: StructureNodeBucketOptions): UfControlGroup {
        return this.formBuilder.group({
            [StructureNodeBucketOptionsControlKeys.NodeId]: bucketOption.nodeId,
            [StructureNodeBucketOptionsControlKeys.Identifier]: bucketOption.identifier,
            [StructureNodeBucketOptionsControlKeys.PageSize]: [bucketOption.pageSize, ValidatorFunctions.custom(v => v >= 1 && v <= 100, 'Between 1 and 100 rows')]
        }, { asyncValidators: this.createBucketOptionAsyncValidator() });
    }

    buildArgControl(nodeArg: StructureNodeArgControlValue): UfControlGroup {
        return this.formBuilder.group({
            [StructureNodeArgControlKeys.Key]: [nodeArg.key, ValidatorFunctions.required('A key is required')],
            [StructureNodeArgControlKeys.Value]: nodeArg.value
        });
    }

    private createBucketOptionAsyncValidator(): AsyncValidatorFn {
        return async (control: UfControlGroup): Promise<ValidationErrors | null> => {

            const bucketOptions = control.value as StructureNodeBucketOptions;

            if (!bucketOptions.identifier) {
                return null;
            }

            const table = await this.cache.getTable(bucketOptions.identifier);
            if (!table) {
                return { table: 'Table not found' };
            }

            return null;
        };
    }

    private setDependencies(info: DependantInfo[], rootControl: UfControlGroup) {
        /**
         * limited to scope
         */
        for (const groupControl of FormFunctions.controlIterator(rootControl)) {
            if ((groupControl instanceof UfControlGroup) === false) {
                continue;
            }
            for (const { dependant, dependencies } of info) {
                const control = groupControl.get(dependant) as UfFormControl;
                if (control != null) {
                    const deps = dependencies.map(n => groupControl.get(n) as UfFormControl).filter(c => c != null);
                    control.addDependencies(deps);
                    // Refresh validators now all controls are initialised
                    control.updateValueAndValidity();
                }
            }
        }

    }

}

// TODO replace for iterable and move it into the library
export const flattenControls = (control: AbstractControl, key: string = 'root'): { key: string; control: AbstractControl }[] => {

    let extracted: { key: string; control: AbstractControl }[] = [{ key: key ?? '', control }];

    if (control instanceof UntypedFormArray) {
        const children = control.controls.map((c, i) => flattenControls(c, `${key}[${i}]`));
        extracted = extracted.concat(...children);
    }

    if (control instanceof UntypedFormGroup) {
        const children = Object.keys(control.controls).map(k => flattenControls(control.controls[k], `${key}.${k}`));
        extracted = extracted.concat(...children);
    }

    return extracted;
};