

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Repository } from '@unifii/library/common';

import { UcClient, UcProjectInfo } from 'client';
import { LAST_USED_PROJECT_ID_KEY } from 'constant';

import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';


@Injectable({
    providedIn: 'root'
})
export class DefaultProjectResolver implements Resolve<UcProjectInfo | undefined> {

    constructor(
        private context: ContextService,
        private projectService: ProjectService,
        private repository: Repository,
        private ucClient: UcClient
    ) { }

    async resolve(): Promise<UcProjectInfo | undefined> {
        // if project is already set or user doesn't have project roles, return
        if (this.context.project != null || !this.projectService.checkProjectRoles()) {
            return;
        }

        let projectId;

        const lastUsedProjectId = this.repository.load(LAST_USED_PROJECT_ID_KEY) as string | undefined;
        if (lastUsedProjectId) { // check if user previously used a project
            projectId = lastUsedProjectId;
        } else { // if no project was used previously check if tenant only has one project and set that as default
            const projects = await this.ucClient.getProjects();
            projectId = projects.length === 1 ? projects[0].id : undefined;
        }

        if (!projectId) {
            return;
        }

        return this.projectService.setProject(projectId);
    }
}
