import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { Repository } from '@unifii/library/common';
import { ProjectContentOptions, ProjectContentOptionsInterace } from '@unifii/sdk';

import { SystemRole, UcProject, UcProjectInfo } from 'client';
import { LAST_USED_PROJECT_ID_KEY } from 'constant';

import { ContextService } from './context.service';


/**
 *  Project Service is responsible for setting current project during navigation,
 *   and providing other project-related shared functions
 */
@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    projectChange = new Subject<UcProjectInfo>();

    constructor(
        private context: ContextService,
        private ucProject: UcProject,
        @Inject(ProjectContentOptions) private projectContentOptions: ProjectContentOptionsInterace,
        private repository: Repository
    ) { }

    async setProject(projectId: string): Promise<UcProjectInfo | undefined> {
        this.projectContentOptions.projectId = projectId;
        const project = await this.ucProject.get();

        if (project) {
            this.context.project = project;
            this.repository.storeString(LAST_USED_PROJECT_ID_KEY, projectId);
        }

        this.projectChange.next(project);

        return project;
    }

    checkProjectRoles(): boolean {
        return this.context.checkRoles(
            SystemRole.ProjectManager,
            SystemRole.ContentEditor,
            SystemRole.FormDesigner,
            SystemRole.Publisher,
            SystemRole.Importer,
            SystemRole.FormDataViewer,
            SystemRole.WorkflowDesigner);
    }
}