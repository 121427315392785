/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, Component, Inject, Optional } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { CompoundType } from '@unifii/sdk';

import { SystemRole } from 'client';

import { BuilderService } from 'components/compound-builder/builder.service';
import { ContentSettings } from 'components/content/content';
import { ContentList } from 'components/content/content-list';
import { ImageCropComponent, ImageCropData } from 'components/content/modals/image-crop.component';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { ImageCrop } from 'components/image-cropper/imageCropper';
import { DialogsService } from 'services/dialogs.service';

import { LinkSearchConfig, LinkSearchType } from './modals/link-search.component';


@Component({
    templateUrl: './image-list.html',
    styleUrls: ['./group-input.less']

})
export class ImageListComponent extends ContentList implements FormField, AfterViewInit {

    draggable = true;

    constructor(
        modalService: ModalService,
        builderService: BuilderService,
        dialogs: DialogsService,
        @Inject(ContentSettings) @Optional() contentSettings: ContentSettings
    ) {
        super(MediaSearchComponent, modalService, builderService, contentSettings, dialogs);

        if (this.builderService.builder.type === CompoundType.Collection) {
            this.draggable = false;
        }
    }

    protected getSearchConfig(): LinkSearchConfig {
        return {
            type: LinkSearchType.Image,
            title: 'Add Image(s)',
            multiSelect: true
        };
    }

    protected convertResultToContent(item: any): any {
        return item;
    }

    get canEdit() {
        return this.contentSettings.mode !== 'Preview' && !this.disabled;
    }

    get canCrop() {
        return this.field.minHeight && this.field.minHeight > 0 && this.field.minWidth && this.field.minWidth > 0;
    }

    get removeRestrictions(): string | null {

        if (this.builderService.builder.type === CompoundType.Page) {
            return null;
        }

        return SystemRole.ProjectManager;
    }

    async crop(item: any, i: number) {

        if (!this.canCrop) {
            this.modalService.openAlert({
                title: 'Error',
                message: 'Image needs a minimum height and width to be cropped.'
            });

            return;
        }

        const options = {
            minWidth: this.field.minWidth,
            minHeight: this.field.minHeight
        };

        const data = await this.modalService.openLarge<ImageCropData, ImageCrop>(
            ImageCropComponent,
            { image: Object.assign({}, item), options }
        );

        (this.content as any[])[i].crop = data;
        this.builderService.fieldEdit.next({ subject: this.field });

    }

}
