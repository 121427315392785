

import { Component, HostBinding } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { ContextService } from 'services/context.service';

import { LanguageCollectionStore } from './language-collection-store';
import { LanguageCollectionTableManager } from './language-collection-table-manager';
import { LanguageColumnFactory } from './language-column-factory';
import { filterEntries } from './language-filter-entires';
import { LanguageFilterRegistry } from './language-filter-registry';


@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute  class="accent list-md">
                <uf-breadcrumbs info [breadcrumbs]="breadcrumbs" class="large"></uf-breadcrumbs>
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    providers: [
        LanguageCollectionStore,
        LanguageColumnFactory,
        { provide: FilterComponentRegistry, useClass: LanguageFilterRegistry },
        { provide: FilterEntries, useValue: filterEntries },
        { provide: TableContainerManager, useClass: LanguageCollectionTableManager }
    ]
})
export class LanguageCollectionComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs: Breadcrumb[] = [];

    constructor(
        private context: ContextService,
        private languageCollectionStore: LanguageCollectionStore
    ) {
        this.breadcrumbs = [
            { urlSegments: ['/translations'], name: 'Translation' },
            { urlSegments: ['../..'], name: this.context.language?.name as string },
            { name: this.languageCollectionStore.definition.label },
        ];
    }

}
