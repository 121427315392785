import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { WindowWrapper } from '@unifii/library/common';
import { AuthProvider, TenantClient } from '@unifii/sdk';

import { UcAuthProviders } from 'client';


@Injectable({ providedIn: 'root' })
export class SsoGuard implements CanActivate {

    private readonly authUrl = 'https://login.microsoftonline.com';

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private tenantClient: TenantClient,
        private ucAuthProviders: UcAuthProviders,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const { manualRegistration, id, type } = route.params;
        const { tenant } = route.queryParams;

        if (tenant) {
            return true;
        }

        if (manualRegistration || id !== 'new' || type !== AuthProvider.Azure) {
            return true;
        }

        this.redirectToAzure();
        return false;
    }

    private async redirectToAzure() {
        const microsoftConfig = await this.ucAuthProviders.getMicrosoftConfig();
        const redirectUrl = await this.getRedirectUrl();
        const url = `${this.authUrl}/common/adminconsent?response_mode=form_post&client_id=${microsoftConfig.clientId}&redirect_uri=${redirectUrl}`;
        this.window.location.assign(url);
    }

    private async getRedirectUrl(): Promise<string> {
        const baseUrl = `${this.window.location.origin}/system-settings/sso-new/azure`;
        const oidcState = await this.tenantClient.getOIDCState(baseUrl, {});
        return `https://directory.unifii.net/azure/callback&state=${oidcState.state}`;
    }
}