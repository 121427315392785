import { Dictionary } from '@unifii/sdk';


export interface TranslationEntry {
    identifier: string;
    dictionary: Dictionary<string>;
}

interface MergedTranslationEntry {
    identifier: string;
    key: string;
    value: string;
}


const loadDictionaries = async (lang: string, translationUrl: string, entries: TranslationEntry[]): Promise<Dictionary<string>> => {

    const langBundle = await getLangBundle(translationUrl, lang);

    verifyKeys(entries, langBundle, lang);

    return langBundle;
};


const getLangBundle = async (path: string, lang: string): Promise<Dictionary<string>> => {
    try {
        const response = await fetch(`${path}/${lang}.json`);
        return await response.json() as Dictionary<string>;
    } catch (e) {
        return {};
    }
};

const verifyKeys = (entries: TranslationEntry[], langBundle: Dictionary<string>, lang: string) => {

    const mergedEntries = entries.reduce(mergeEntries, []);
    langBundle = Object.assign({}, langBundle);

    const limit = 25;
    let missingCount = 0;
    let deprecatedCount = 0;

    for (const entry of mergedEntries) {
        if (!langBundle[entry.value]) {
            missingCount += 1;
            if (missingCount < limit) {
                console.warn(`(-) ${entry.identifier}: Missing ${lang} translation for key '${entry.key}'`);
            }
        } {
            delete langBundle[entry.value];
        }
    }

    if (missingCount > limit) {
        console.error(`(-) Missing an additional ${missingCount - 25} keys`);
    }

    for (const key of Object.keys(langBundle)) {
        deprecatedCount += 1;
        if (deprecatedCount < limit) {
            console.warn(`(+) Deprecated ${lang} translation for key '${key}'`);
        }
    }

    if (deprecatedCount > limit) {
        console.error(`(+) An additional ${missingCount - 25} deprecated keys have been found`);
    }
};

const mergeEntries = (merged: MergedTranslationEntry[], { dictionary, identifier }: TranslationEntry) => {

    const flattened = Object.keys(dictionary || []).map(key => ({ identifier, key, value: dictionary[key] }));
    return [...merged, ...flattened];
};

export const TranslationsFunctions = { loadDictionaries };