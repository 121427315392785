import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, MessageLevel, ModalService, ToastService } from '@unifii/library/common';
import { ComponentRegistry, FieldProviderInit, FieldProviders, FormSettings } from '@unifii/library/smart-forms';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { Compound, CompoundType, Definition, Field, FieldType } from '@unifii/sdk';

import { BuilderField, CompoundInfo, SystemRole, UcDefinition, UcProject, UcView } from 'client';

import { EditMode } from 'components/common/edit-data';
import { SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderHeaderService } from 'components/compound-builder/builder-header/builder-header.service';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentComponentRegistry } from 'components/content-component-registry';
import { ContentSettings } from 'components/content/content';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from 'components/content/modals/link-search.component';
import { ModalSearchData } from 'components/content/modals/modal-search';

import { FieldDetailHelper, IdentifierFunctions } from 'helpers/helpers';

import { ViewsComponent } from 'pages/content/views/views.component';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';
import { UcTableManager } from 'services/table/models';


@Component({
    templateUrl: './view-builder.html',
    styleUrls: ['./../../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        ...FieldProviders,
        { provide: FormSettings, useValue: {} },
        { provide: ComponentRegistry, useClass: ContentComponentRegistry },
        { provide: ContentSettings, useValue: { mode: 'Edit' } }
    ]
})
export class ViewBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.View;
    subject = BuilderCompoundSubjects.DEFINITION;
    ready: boolean;
    canEdit: boolean;
    definition: Definition;
    displayDefinition: Definition | null;
    displayCompound: Compound | null;
    selected: Field;

    private lastInserted: BuilderField | null;
    private _compound: UcView;
    private compoundChanged: Subject<UcView> = new Subject();

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected parent: ViewsComponent,
        protected router: Router,
        protected route: ActivatedRoute,
        private context: ContextService,
        private ucProject: UcProject,
        private toastService: ToastService,
        private displayService: DisplayService,
        private fieldProviderInit: FieldProviderInit,
        private limitService: LimitService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        @Optional() @Inject(TableContainerManager) protected tableManager: UcTableManager<CompoundInfo>
    ) {
        super(builderService, modalService, route, tableManager);
    }

    set compound(v: UcView) {
        if (v === this._compound) {
            return;
        }

        this._compound = v;
        this.compoundChanged.next(this.compound);
    }

    get compound(): UcView {
        return this._compound;
    }

    async ngOnInit() {
        // Editable based on user Role
        this.canEdit = this.context.checkRoles(SystemRole.ProjectManager);

        // Load data
        const view = await this.load(this.editMode, this.route.snapshot.params);

        // Copy recordName inside the compound (for UI purpose)
        this.compound = view;
        this.definition = view._definition as Definition;

        this.fieldProviderInit.init(this.definition as Definition, this.compound);

        // Register event handlers
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.definition, this.compound);

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(saveOption => this.save(saveOption)));
        this.buildHeaderConfig(view);
    }

    addSubscribers() {

        // React to changes and prepare definition and compound for display
        this.subscriptions.add(this.compoundChanged.pipe(debounceTime(500)).subscribe(() => {
            this.updatePreview();
        }));

        // React to builderService ready
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
            this.builderService.fieldSelect.next(null);
        }));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe(i => {
            this.compoundChanged.next(this.compound);
            this.builderService.memento.setLastAtomic();
        }));

        this.subscriptions.add(this.builderService.fieldAdded.subscribe(i => {
            this.selectField(i);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldMoved.subscribe(i => {
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldRemoved.subscribe(i => {
            this.builderService.fieldSelect.next(null);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe(i => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));

        this.subscriptions.add(this.builderService.fieldReady.subscribe(i => {
            if (this.lastInserted === i.subject) {
                this.selectField(i);
                this.lastInserted = null;
            }
        }));
    }

    selectField(i: BuilderEventInfo = { subject: null, atomic: false }) {
        this.selected = !i ? null : i.subject;
        this.builderService.fieldSelected.next(i);
    }

    removeField(i: BuilderEventInfo) {
        const position = this.getFieldPosition(i.subject);
        if (position) {
            position.parent[this.builderService.childrenProperty].splice(position.index, 1);
            this.builderService.fieldRemoved.next(i);
        }
    }

    async save(saveOption?: SaveOption) {

        // Notify the other components of a builder save/submit action
        this.builderService.submit.next(null);

        // Validate builder status
        if (!this.builderService.isValid()) {
            this.builderService.markFieldsSubmitted();
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: 'Unable to save. There are errors in your View.' });
            return;
        }

        const view = this.compound;
        const definition = this.builderService.cleanDefinition(this.definition as UcDefinition);
        view._assetRefs = this.builderService.getAssetsInView(view);

        // Copy recordName to definition.label
        definition.label = view.recordName;

        // delete definition.recordName; // TODO Need to be done or not ?
        // Copy definition inside view
        view._definition = definition;

        try {
            // Save the view
            this.builderService.busy.next(true);
            this.compound = await this.ucProject.saveView(view);

            // Notify user of the save success
            this.toastService.success('View saved!');

            if (saveOption?.id === SaveOptionType.New &&
                !(await this.limitService.canAddViews())) {
                saveOption = undefined;
            }

            this.saved(this.compound, saveOption);
            this.builderService.init(this, this.definition, this.compound);

            this.buildHeaderConfig(this.compound);
        } catch (err) {
            const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message });

        } finally {
            this.builderService.busy.next(false);
        }
    }

    fieldBuilderChange(event: BuilderEventInfo) {

        if (event.source === 'nested') {
            this.refreshNgForElement();
        }
    }

    // Draglist START
    canDrop = async (element: any, parent: any): Promise<boolean> => {

        if (!element.type || (element.type && (FieldType as any)[element.type] == null)) {
            // Not a field (element from right panel like Validator, Option....)
            return false;
        }

        if (!element.reference) {
            // An actual field and not it's reference from the left tools list
            return true;
        }

        if (element.type === FieldType.LinkList) {
            // LinkList (select a single collection)
            const result = await this.modalService.openMedium(LinkSearchComponent, {
                title: 'Select Collection',
                multiSelect: false,
                minQuantity: 1,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection
            } as LinkSearchConfig);

            if (!result || !result.length) {
                return false;
            }

            element.field = {
                label: result[0].name,
                type: element.type,
                compoundType: CompoundType.Collection,
                definitionIdentifier: result[0].identifier
            };

            return true;

        }

        if (element.type === FieldType.Link && element.compoundType === CompoundType.Collection) {

            const data: LinkSearchConfig = {
                title: 'Select Collections',
                multiSelect: true,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection
            };

            // Link of type Collection (select a list of collections)
            const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

            if (!result) {
                return false;
            }

            element.field = {
                label: element.label,
                type: element.type,
                compoundType: CompoundType.Collection
            };

            if (result.length) {
                element.field.types = result.map(c => c.identifier);
            }

            return true;

        }

        if ([FieldType.Text, FieldType.MultiText].indexOf(element.type) >= 0) {
            element.field = {
                label: element.label,
                type: element.type,
                isTranslatable: true
            };
            return true;
        }

        // No dialog needed
        return true;
    };

    convertFieldRef(ref: any) {
        if (ref.field) {
            return ref.field;
        }

        const res: any = { type: ref.type, label: ref.label };
        if (ref.compoundType) {
            res.compoundType = ref.compoundType;
        }
        return res;
    }

    inserted(field: BuilderField) {
        // Use service to generate a unique identifier
        field.isNew = true;

        field.identifier = IdentifierFunctions.safeFieldIdentifier(
            field,
            this.builderService.definition.fields,
            field.definitionIdentifier || field.type
        );

        this.lastInserted = field;
        const parent = this.builderService.builder.getFieldPosition(field)?.parent as Field | undefined;
        const fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type, parent);

        if (fm.hasArrayValues === true) {
            this.compound[field.identifier] = [];
        }

        /***
         * Set default values for complex inputs otherwise they will display as blank
         * as empty visible fields is a valid setting
         */
        if (field.type === FieldType.Address) {
            field.visibleFields = ['address1', 'address2', 'suburb', 'state', 'postcode', 'map'];
        }

        if (field.type === FieldType.GeoLocation) {
            field.visibleFields = ['lat', 'lng', 'map'];
        }

        // Notify field inserted
        this.builderService.fieldAdded.next({ subject: field, atomic: true });
    }

    moved(field: BuilderField) {
        this.builderService.fieldMoved.next({ subject: field, atomic: true });
    }

    restore(step: number) {
        const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();
        this.builderService.definition = value;
        this.builderService.fieldSelect.next(null);
    }

    private async load(mode: EditMode, params: Params): Promise<UcView> {

        if (mode === EditMode.New) {
            return {
                recordName: '',
                _definition: {
                    compoundType: CompoundType.View,
                    identifier: '',
                    label: '',
                    recordName: '',
                    version: 0,
                    fields: [],
                }
            };
        }

        const view = await this.ucProject.getView(+params.id);

        if (mode === EditMode.Duplicate) {
            delete view.id;
            delete view.publishState;
            delete view.lastModifiedAt;
            delete view._definition.id;
            delete view.lastPublishedAt;
            view._title = '';
            view.recordName = '';
            view._definition.identifier = '';
            view._definition.label = '';
            view._definition.version = 0;
            view._definition.lastModifiedAt = undefined;
            view._definition.lastPublishedAt = undefined;
        }

        return view;
    }

    private refreshNgForElement() {
        /**
         * Temporary fix for fields with nested fields, by design only some
         * inputs on field inputs react to change, fields with nested visible fields
         * will only render once
         */
        const index = this.definition.fields.findIndex(f => f === this.selected);

        if (index < 0) {
            return;
        }

        this.definition.fields.splice(index, 1);

        setTimeout(() => {
            (this.definition.fields as Field[]).splice(index, 0, this.selected);
        }, 0);
    }

    private async updatePreview() {

        /**
         * No need for an async call if definition doesn't contain a LinkList
         * note - Links need to be converted to Compounds before rendered
         */
        if (this.definition.fields.find(field => field.type === FieldType.LinkList) == null) {
            this.displayDefinition = this.definition as Definition;
            this.displayCompound = this.compound;
            return;
        }

        const displayContent = await this.displayService.renderCompound(this.definition as Definition, this.compound);
        this.displayDefinition = displayContent.definition || null;
        this.displayCompound = displayContent.compound || null;
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.definition, i.atomic);
        this.builderService.memento.edited = true;
        // Force preview panel refresh
        this.compoundChanged.next(this.compound);

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(view: UcView) {

        this.builderHeaderService.buildConfig({
            ...view,
            title: view._title,
            cancelRoute: ['../'],
            saveOptions: this.saveOptions,
            publishState: view.publishState,
            restrictSave: 'ProjectManager,ContentEditor',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [view._title || 'New']),
        });
    }
}
