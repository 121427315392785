<div *ngIf="form" [formGroup]="form" class="uf-box flat col-12">
    <div [class.accent]="selected === id" class="uf-app-bar gap-sm-bottom">

        <div class="title clickable" (click)="selectedChange.emit(form)">
            {{typeDescription[type]}}
        </div>

        <button title="Delete" class="uf-action">
            <uf-icon name="delete" (click)="deletedClick.emit({position: identifier, parent})"></uf-icon>
        </button>

    </div>

    <div class="pad-sm-sides pad-sm-bottom">

        <div class="uf-grid col-12 gap-bottom">
            <ng-container [ngSwitch]="type">
                <!-- GroupMembership -->
                <uf-autocomplete *ngSwitchCase="types.GroupMembership" placeholder="Select a source" label="Group"
                    [formControlName]="controlKeys.Group" (searchChange)="findSourceGroups($event)"
                    [options]="filteredSourceGroups" [allowCustom]="true" nameProperty="name" class="col-12">
                </uf-autocomplete>

                <!-- ClaimValue -->
                <ng-container *ngSwitchCase="types.ClaimValue">
                    <uf-autocomplete label="Claim" [formControlName]="controlKeys.Identifier"
                        (searchChange)="findSourceClaims($event)" [options]="filteredSourceClaims"
                        placeholder="Select a source" [allowCustom]="true" class="col-6 gap-botton">
                    </uf-autocomplete>
                    <uf-text label="Claim Value" [formControlName]="controlKeys.Value" class="col-6">
                    </uf-text>
                </ng-container>

                <!-- And / Or -->
                <ng-container *ngSwitchDefault>
                    <uc-auth-provider-mapping-unit
                        *ngFor="let childrenMapping of childrenControls.controls; let i = index" [parent]="form"
                        [form]="childrenMapping | asUfControlGroup" [sourceClaims]="sourceClaims" [identifier]="i"
                        [selected]="selected" (deletedClick)="deletedClick.emit($event)"
                        [authProviderId]="authProviderId" (selectedChange)="selectedChange.emit($event)"
                        class="col-12"></uc-auth-provider-mapping-unit>
                    <uf-error [control]="childrenControls" class="col-12"></uf-error>
                </ng-container>
            </ng-container>



        </div>
    </div>
</div>