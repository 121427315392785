import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Params, RouterStateSnapshot } from '@angular/router';

import { Config } from 'app-config';

import { DialogsService } from 'services/dialogs.service';


export interface EditData {
    edited: boolean;
    onLeavePage?: () => Promise<void>;
}

export enum EditMode {
    New, Existing, Duplicate
}

export const getEditMode = (state: Params): EditMode => {
    if (state.id && state.duplicate) {
        return EditMode.Duplicate;
    }

    if (!!state.id && state.id !== 'new') {
        return EditMode.Existing;
    }

    return EditMode.New;
};

@Injectable()
export class CanDeactivateEditDataComponent implements CanDeactivate<EditData> {

    constructor(private dialogs: DialogsService, @Inject(Config) private config: Config) { }

    async canDeactivate(component: EditData, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        /**
         * strange behaviour component is null
         * on 401 the user is redirected to the login which must trigger canDeactive before the
         * component is set
         */
        if (this.config.debug || !component?.edited) {
            return true;
        }

        const confirmLeave = await this.dialogs.confirmLeaveWithoutSaving();

        if (confirmLeave && component.onLeavePage) {
            await component.onLeavePage();
        }

        return confirmLeave;
    }
}
