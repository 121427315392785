<uf-expander class="uc-group" [class.uc-group--draggable--no-shrink]="!disabled && draggable && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="!disabled && draggable && isSelected">
    <div expanderHeader class="row uf-app-bar flat" [class.accent]="isSelected" dragHandle (click)="select()">
        <uf-icon [name]="icon"></uf-icon>
        <div *ngIf="field.label" class="title primary grow">{{field.label}}</div>
        <div *ngIf="!field.label" class="title primary grow">{{ field.type | toDisplayName }}</div>
        <uf-help class="uf-action tertiary" *ngIf="field.help" [content]="field.help"></uf-help>
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning"></uf-icon>
        <a class="uf-action tertiary" *ngIf="canRemove" [restrict]="removeAuthorizedRoles" (click)="remove()"
            title="Delete">
            <uf-icon name="delete"></uf-icon>
        </a>
    </div>
    <div expanderBody #container class="uc-group-content" [class.grid]="!isGroup && field.type !== fieldTypes.MultiText"
        [class.content--accent]="isSelected">
        <uf-field class="col-1of1--no-padding" [field]="field" [(content)]="content" [scope]="scope"
            (contentChange)="contentChanged($event)">
        </uf-field>
    </div>
</uf-expander>